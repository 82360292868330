import { useCallback, useMemo, useState } from 'react';

import { Flex, NavLink, useMantineTheme } from '@mantine/core';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import type { NavigationItem, NavigationSet } from 'app/Modules/types';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import { SESSION_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { isNotNil } from 'helpers/isNotNil';
import { getNavIcon, navIconMap } from 'helpers/MenuIcons';
import { replaceSpaceToDash } from 'helpers/stringFunctions';
import { uuIDValidator } from 'helpers/uuIDValidator';
import useSetupModule from 'hooks/useSetupModule';
import useUrlParams from 'hooks/useUrlParams';

const { REACHED_CATALOG_SETUP_THROUGH_LINK } = SESSION_STORAGE_CONSTANTS;

type Props = {
  item: NavigationItem | NavigationSet;
};

export const NavItem = ({ item }: Props) => {
  const theme = useMantineTheme();
  const { currentModule, module } = useSetupModule();
  const location = useLocation();
  const navigate = useEvolveNavigate('', true);
  const {
    params: { nav },
  } = useUrlParams();

  const routeIsActive = useCallback(
    (routePath: string) => {
      const pathNames = location.pathname.split('/').filter((x) => x);
      const pathNameWithoutUUID = pathNames.filter((x) => !uuIDValidator(x)).join('/');

      if (nav) {
        return nav === routePath;
      }
      return pathNameWithoutUUID === `${module?.toLowerCase()}/${routePath}`;
    },
    [location.pathname, module, nav],
  );

  const [opened, setOpened] = useState(
    'navigationSetItems' in item &&
      item.navigationSetItems?.some(
        (subItem) => isNotNil(subItem.navigationItem) && routeIsActive(subItem.navigationItem.routePath),
      ),
  );

  const handleClick = () => {
    if ('navigationItemName' in item) {
      if (item.navigationItemName === 'Catalog Setup') {
        sessionStorage.setItem(REACHED_CATALOG_SETUP_THROUGH_LINK, 'false');
      }
      navigate(`${currentModule().toLowerCase()}/${item.routePath}`);
    }
  };

  const dataTestIdOfItem = useMemo(() => {
    if ('navigationItemName' in item) {
      return `nav-item-${replaceSpaceToDash(item.navigationItemName)}`;
    }
    return `nav-menu-${replaceSpaceToDash(item.navigationSetName)}`;
  }, [item]);

  const navigationSetItems = useMemo(
    () =>
      'isMenu' in item && item.isMenu
        ? item.navigationSetItems?.map(({ navigationItem }) => navigationItem).filter(isNotNil)
        : null,
    [item],
  );

  const active = 'routePath' in item && routeIsActive(item.routePath);

  return (
    <Flex direction="column">
      <NavLink
        label={'navigationItemName' in item ? item.navigationItemName : item.navigationSetName}
        active={active}
        onClick={handleClick}
        opened={opened}
        onChange={setOpened}
        py="xs"
        icon={
          item.icon in navIconMap ? (
            <EvolveIcon icon={navIconMap[item.icon] ?? null} color="inherit" />
          ) : (
            // TODO: Remove this once all legacy icons are converted to navIconMap
            <Flex
              style={{
                width: 24,
                height: 24,
              }}
              align="center"
              justify="center"
            >
              <Box sx={{ path: { fill: active || opened ? 'white' : '' } }}>{getNavIcon(item.icon)}</Box>
            </Flex>
          )
        }
        variant="filled"
        childrenOffset={0}
        color={theme.primaryColor}
        styles={{
          root: {
            borderRadius: 6,
            color: opened ? 'white' : '#9e9e9e',
          },
          children: {
            borderRadius: 6,
            backgroundColor: '#424242',
          },
        }}
        data-testid={dataTestIdOfItem}
      >
        {navigationSetItems?.map((navigationItem) => (
          <NavItem item={navigationItem} key={navigationItem.navigationItemId} />
        ))}
      </NavLink>
    </Flex>
  );
};
