import { useMemo } from 'react';

import { SegmentedControl, SegmentedControlItem, Tooltip } from '@mantine/core';

import { EvolveIcon, EvolveIconName } from 'assets/icons/EvolveIcon';

import { SelectedView, selectableViews, useChangeTableView } from './useChangeTableView';

const tabIcon: Record<SelectedView, EvolveIconName> = {
  list: 'TableChart',
  kanban: 'Kanban',
  calendar: 'CalendarBoard',
};

const tooltips: Record<SelectedView, string> = {
  list: 'List View',
  kanban: 'Kanban Board',
  calendar: 'Calendar',
};

export type ChangeTableViewProps = {
  viewsToShow: SelectedView[];
};

export const ChangeTableView = ({ viewsToShow }: ChangeTableViewProps) => {
  const { selectedView, setSelectedView } = useChangeTableView();

  const assembledData = useMemo(() => {
    const data: SegmentedControlItem[] = [];
    selectableViews.forEach((v) => {
      if (viewsToShow.includes(v)) {
        data.push({
          value: v,
          label: (
            <Tooltip position="bottom" label={tooltips[v]} withinPortal withArrow offset={10}>
              <EvolveIcon icon={tabIcon[v]} color="inherit" />
            </Tooltip>
          ),
        });
      }
    });
    return data;
  }, [viewsToShow]);

  return (
    <SegmentedControl
      // Mantine unfortunately doesn't have a way to change the default height
      // of a SegmentedControl (at least in v6) so this is what works
      styles={{
        root: { height: 40 },
        control: { height: 32 },
        indicator: { height: 32 },
        label: { height: 32, color: 'var(--mantine-color-gray-6)' },
      }}
      color="gray.7"
      size="xs"
      onChange={(v) => setSelectedView(v as SelectedView)}
      value={selectedView}
      data={assembledData}
    />
  );
};
