import React, { useState, useEffect } from 'react';

import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';

import RowIcon from 'components/Table/RowIcon';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { EIconType, getNavIcon } from 'helpers/MenuIcons';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { Spool } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';
import { surfaceColors } from 'styles/theme/colors';

type DetailsPanelProps = {
  workRequestId: string;
};

const SpoolsItemsGroup = ({ workRequestId }: DetailsPanelProps) => {
  const [loading, setLoading] = useState(false);
  const [spoolItems, setSpoolItems] = useState<Spool[]>([]);
  const [openSpools, setOpenSpools] = useState(false);
  const { fetchPage } = useWrappedPaginatedGet<Spool>(`shop/spool/mappedToWorkRequest`, { lazy: true });

  useEffect(() => {
    if (openSpools) {
      setLoading(true);
      getAllDataFromFetcher(fetchPage, { params: { workRequestId } })
        .then(setSpoolItems)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [workRequestId, fetchPage, openSpools]);

  const { palette } = useTheme();
  const rowStyles = {
    backgroundColor: 'white',
    padding: '0px 16px 0px 118px',
    borderBottom: ` 1px solid  ${palette.grey[300]}`,
    height: '31px',
  };

  return (
    <>
      {loading ? (
        <Stack sx={{ ...rowStyles, display: 'flex', alignItems: 'center' }} direction="row">
          <CircularProgress color="secondary" size={20} />
        </Stack>
      ) : (
        <>
          <Stack onClick={() => setOpenSpools((prev) => !prev)} sx={rowStyles} direction="row">
            <RowIcon
              label="Spools"
              icon={EIconType.SPOOLS}
              opened={openSpools}
              onOpening={() => setOpenSpools((prev) => !prev)}
            />
          </Stack>
          {openSpools &&
            spoolItems.length > 0 &&
            spoolItems.map(({ spool }) => (
              <Stack direction="row" style={{ ...rowStyles, alignItems: 'center', paddingLeft: '183px' }}>
                {getNavIcon(EIconType.SPOOLS, surfaceColors.lightSurface.disabled)}
                <Typography variant="body2" sx={{ ml: '10px' }}>
                  {spool}
                </Typography>
              </Stack>
            ))}
        </>
      )}
    </>
  );
};

export default SpoolsItemsGroup;
