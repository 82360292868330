import React, { ReactNode, useMemo } from 'react';

import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedGetWithLocalStorage } from 'hooks-api/useWrappedGetWithLocalStorage';

import type { TaskStatusType } from './types';

type TasksContextType = {
  taskStatusTypes: TaskStatusType[] | undefined;
  loading: boolean;
};

const TasksContext = React.createContext<TasksContextType | undefined>(undefined);

export const TasksProvider = ({ children }: { children: ReactNode }) => {
  const { data: taskStatusTypes, loading } = useWrappedGetWithLocalStorage<'TASK_STATUSES'>(
    'TASK_STATUSES',
    undefined,
    'shop/taskStatusType',
  );
  const value = useMemo<TasksContextType>(
    () => ({
      taskStatusTypes,
      loading,
    }),
    [loading, taskStatusTypes],
  );
  return <TasksContext.Provider value={value}>{children}</TasksContext.Provider>;
};

export const useTasks = () => useGeneralContext(TasksContext, 'Tasks');
