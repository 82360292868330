import { useEffect, useState } from 'react';

import { SelectProps } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';

import { TypeSafeSelectProps, WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';

import type { PartCatalog, PartCatalogId } from './types';

type Props = {
  selectedCatalogId: PartCatalogId | null;
  onCatalogSelected: (partCatalogId: PartCatalogId | null) => void;
  selectedItem: SelectedItem;
  disabled?: boolean;
  showLabel?: boolean;
  selectProps?: Partial<Omit<SelectProps, keyof TypeSafeSelectProps>>;
};

export const CatalogSelect = ({
  selectedCatalogId,
  selectedItem,
  disabled,
  showLabel,
  selectProps,
  onCatalogSelected,
}: Props) => {
  const [catalogSelectOpened, setCatalogSelectOpened] = useState(true);
  const [catalogSearchPhrase, setCatalogSearchPhrase] = useDebouncedState('', 350, { leading: true });
  const {
    data: catalogs,
    setDefaultOpts: setDefaultOptsCatalogs,
    loading,
  } = useWrappedPaginatedGet<PartCatalog>('moab/partCatalog', {
    lazy: true,
  });

  useEffect(() => {
    if (catalogSelectOpened) {
      setDefaultOptsCatalogs({
        lazy: false,
        defaultConfig: {
          params: {
            companyId: selectedItem.companyId,
            ...(catalogSearchPhrase.length > 0
              ? {
                  name: `like:${catalogSearchPhrase}`,
                }
              : {}),
          },
        },
      });
    }
  }, [catalogSelectOpened, catalogSearchPhrase, selectedItem.companyId, setDefaultOptsCatalogs]);

  return (
    <WrappedSelect
      label={showLabel ? 'Catalog' : undefined}
      placeholder="Select a catalog..."
      value={selectedCatalogId}
      onChange={onCatalogSelected}
      onDropdownOpen={() => {
        setCatalogSearchPhrase('');
        setCatalogSelectOpened(true);
      }}
      onDropdownClose={() => setCatalogSelectOpened(false)}
      searchable
      onSearchChange={setCatalogSearchPhrase}
      filter={() => true}
      disabled={disabled}
      style={{ minWidth: 150 }}
      nothingFound={loading ? 'Loading...' : 'No results.'}
      data={catalogs.map((c) => ({
        label: c.partCatalogName,
        value: c.partCatalogId,
      }))}
      {...selectProps}
    />
  );
};
