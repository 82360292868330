import { useState } from 'react';

import { Button, Flex, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { AlertingPopover } from 'components/Mantine/AlertingPopover';
import { SearchableCheckList } from 'components/Mantine/SearchableCheckList';
import { TextWithRef } from 'components/Mantine/TextWithRef';
import { useWrappedPaginatedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import { WRITE_IN_ASSEMBLY } from 'modules/Field/WorkRequests/WorkRequest/constants';
import type {
  TaskType,
  TaskTypeId,
  WorkRequestItem,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type { WorkRequestId } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type { WorkOrderId } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import type { BillOfProcessId, WorkOrderItem } from '../../types';

type Props<T extends WorkOrderItem | WorkRequestItem> = {
  taskTypes: TaskType[];
  onUpdateBillOfProcess?: (billOfProcessId: BillOfProcessId) => void;
} & (T extends WorkOrderItem
  ? { workOrderId: WorkOrderId }
  : T extends WorkRequestItem
  ? { workRequestId: WorkRequestId }
  : never);

export const AddTaskPopover = <T extends WorkOrderItem | WorkRequestItem>({ ...props }: Props<T>) => {
  const { taskTypes } = props;
  const [saving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [opened, setOpened] = useState(false);
  const isWorkOrder = 'workOrderId' in props;
  const paginatedGet = useWrappedPaginatedGet<T>(isWorkOrder ? 'shop/workOrderItem' : 'shop/workRequestItem', {
    lazy: true,
    defaultConfig: {
      params:
        'workOrderId' in props
          ? {
              workOrderId: props.workOrderId,
              workOrderItemTypeId: WRITE_IN_ASSEMBLY,
              orderBy: 'workOrderItemName:asc',
            }
          : {
              workRequestId: props.workRequestId,
              workRequestItemTypeId: WRITE_IN_ASSEMBLY,
              orderBy: 'workRequestItemName:asc',
            },
    },
  });
  const { apiCall: addTaskToBillOfProcess } = useWrappedPost<
    unknown,
    {
      billOfProcessId: BillOfProcessId;
      taskTypeIds: TaskTypeId[];
    }
  >('shop/task');
  const [selected, setSelected] = useState<T[]>([]);

  const onSubmit = async () => {
    setSaving(true);
    const taskTypeIds = taskTypes.map((t) => t.taskTypeId);
    try {
      await Promise.all(
        selected.flatMap(({ billOfProcessId }) =>
          addTaskToBillOfProcess({
            billOfProcessId,
            taskTypeIds,
          }).then(() => props.onUpdateBillOfProcess?.(billOfProcessId)),
        ),
      );
      setOpened(false);
      notifications.show({
        title: 'Successfully added',
        message: `Added ${taskTypes.length} task${taskTypes.length === 1 ? '' : 's'} to ${selected.length} item${
          selected.length === 1 ? '' : 's'
        }`,
        color: 'green',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <AlertingPopover
      width={300}
      opened={opened}
      isDirty={isDirty}
      onClose={() => setOpened(false)}
      onOpen={() => {
        setSelected([]);
        setIsDirty(false);
      }}
      confirmButtonText="Map"
      onSubmit={onSubmit}
      disabled={selected.length === 0}
      loading={saving}
      target={
        <Button
          onClick={() => setOpened((o) => !o)}
          leftIcon={<EvolveIcon size="xs" color="inherit" icon="Add" />}
          disabled={taskTypes.length === 0}
          variant="outline"
        >
          Map tasks to items
        </Button>
      }
    >
      <Text size="sm" mb="xs">
        Select items to map selected task{taskTypes.length === 1 ? '' : 's'}.
      </Text>
      <SearchableCheckList
        paginatedGet={paginatedGet}
        searchKey={'workOrderId' in props ? 'workOrderItemName' : 'workRequestItemName'}
        getItemLabel={(item) => {
          const label = 'workOrderItemName' in item ? item.workOrderItemName : item.workRequestItemName;
          return (
            <AgGridStyleTooltip label={label}>
              <TextWithRef fz="sm">{label}</TextWithRef>
            </AgGridStyleTooltip>
          );
        }}
        idKey="billOfProcessId"
        value={selected}
        onChange={setSelected}
        disabled={saving}
        setIsDirty={setIsDirty}
        textInputProps={{ size: 'xs', placeholder: 'Search items...', style: { width: '100%' } }}
        flexListProps={{
          gap: 'xs',
          style: {
            maxHeight: 200,
          },
        }}
        nothingFound={
          <Flex gap={5} align="center">
            No write-in items found.
            <AgGridStyleTooltip
              withArrow
              label={
                <Flex gap={5} align="center">
                  <Text>Tasks can only be mapped to write-in items, denoted via</Text>
                  <EvolveIcon icon="WriteInItem" size="sm" color="inherit" />
                </Flex>
              }
            >
              <EvolveIcon icon="Info" size="sm" color="cyan.5" />
            </AgGridStyleTooltip>
          </Flex>
        }
      />
    </AlertingPopover>
  );
};
