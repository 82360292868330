import { DateInput } from '@mantine/dates';
import type { CustomDateProps } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

export const CustomDateComponent = ({ date, onDateChange }: CustomDateProps) => (
  <DateInput
    placeholder="mm/dd/yyyy"
    popoverProps={{
      withinPortal: true,
      classNames: {
        // Necessary for AG Grid to not close the filter
        // when we click on the popover
        dropdown: 'ag-custom-component-popup',
      },
    }}
    value={date}
    onChange={onDateChange}
    icon={<EvolveIcon color="inherit" icon="CalendarBoard" />}
  />
);
