import { useCallback } from 'react';

import { useNavigate, To, NavigateOptions } from 'react-router-dom';

import type { LocationState } from './types';
import { useEvolveLocation } from './useEvolveLocation';

export const calculateNewState = ({
  state,
  clearHistory,
  to,
  from,
}: {
  to: To;
  state: LocationState;
} & (
  | {
      from: string;
      clearHistory?: boolean;
    }
  | {
      from?: string;
      clearHistory: true;
    }
)): LocationState => {
  if (clearHistory) {
    return {
      ...state,
      from: undefined,
    };
  }
  if (state?.from?.[0]?.url === window.location.pathname || to === window.location.pathname) {
    return state;
  }
  return {
    ...state,
    from: [
      {
        title: from,
        // window.location.pathname starts with `/` but typescript doesn't know that
        url: window.location.pathname as any,
        index: state?.from?.length ?? 0,
      },
      ...(state?.from ?? []),
    ],
  };
};

/** Use in place of `useNavigation` */
export const useEvolveNavigate = (pageTitle: string, clearHistory = false) => {
  const { state } = useEvolveLocation();
  const navigate = useNavigate();
  return useCallback(
    (to: To, options?: Omit<NavigateOptions, 'state'>) => {
      navigate(to, {
        ...options,
        state: calculateNewState({
          to,
          state,
          from: pageTitle,
          clearHistory,
        }),
      });
    },
    [clearHistory, navigate, pageTitle, state],
  );
};
