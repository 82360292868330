import { useCallback, useState, useEffect, useMemo } from 'react';

import { Checkbox, Flex, Text } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import type { SelectedFacility } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { PageBreadcrumb } from 'components/Mantine/Navigation/PageBreadcrumb';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { INCLUDE_DELETED_OPTIONS } from 'graphql/types';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { DepartmentId } from 'hooks/projectsAndFacilities/types';
import { DEPARTMENT_USER_INCLUDED_ADMIN_OPTIONS } from 'modules/Shop/ShopProperties/AdminTab/constants/constants';

import { DepartmentUser, DepartmentUserId, getMembersColDef } from './columnDefs';
import { InviteUsersModal } from './InviteUsersModal';

type Props = {
  shopDepartmentId: DepartmentId;
  selectedFacility: SelectedFacility;
};

export const ShopMembersPage = ({ shopDepartmentId: departmentId, selectedFacility }: Props) => {
  const [showDeleted, setShowDeleted] = useState(false);
  const [selectedRows, setSelectedRows] = useState<DepartmentUserId[]>([]);
  const [usersToDelete, setUsersToDelete] = useState<DepartmentUserId[]>([]);

  const { fetchPage, setDefaultOpts, searchHandler } = useWrappedPaginatedGet<DepartmentUser>('admin/departmentUser', {
    lazy: true,
  });
  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          departmentId,
          includeDeleted: showDeleted ? INCLUDE_DELETED_OPTIONS.YES : undefined,
          includeAdmin: DEPARTMENT_USER_INCLUDED_ADMIN_OPTIONS.YES,
        },
      },
    });
  }, [setDefaultOpts, departmentId, showDeleted]);

  const { apiCall: deleteDepartmentUsers, loading: deleting } = useWrappedPatch<
    unknown,
    {
      departmentUserIds: DepartmentUserId[];
    }
  >('admin/departmentUser/multidelete');

  const onDeleteSingleUser = useCallback((departmentUser: DepartmentUser) => {
    setUsersToDelete([departmentUser.departmentUserId]);
  }, []);

  const colDef = useMemo(() => getMembersColDef({ deleteUser: onDeleteSingleUser }), [onDeleteSingleUser]);
  const { agGridProps, refreshGrid } = useServerSideGrid({
    tableName: 'shop-members',
    fetchPage,
    colDef,
    rowId: 'departmentUserId',
  });

  const onConfirmDeleteDepartmentUsers = () => {
    deleteDepartmentUsers({
      departmentUserIds: usersToDelete,
    }).then(() => {
      setUsersToDelete([]);
      setSelectedRows([]);
      agGridProps.ref.current?.api.deselectAll();
      refreshGrid();
    });
  };

  return (
    <>
      <PageBreadcrumb title="Shop Members" />
      <Flex style={{ width: '100%' }} mb="md" justify="space-between" align="center">
        <Flex align="center" gap="sm">
          <Text c="dimmed">{selectedRows.length} items selected</Text>
          <WrappedSelect
            disabled={selectedRows.length === 0}
            value=""
            placeholder="Action"
            data={['Delete']}
            onChange={(selected) => {
              if (selected === 'Delete') {
                setUsersToDelete(selectedRows);
              }
            }}
          />
        </Flex>
        <Flex align="center" gap="sm">
          <Checkbox
            label="Show deleted users"
            checked={showDeleted}
            onChange={(e) => setShowDeleted(e.currentTarget.checked)}
          />
          <TextInputDebounced
            icon={<EvolveIcon icon="Search" color="inherit" />}
            onChange={searchHandler}
            placeholder="Search..."
          />
          <InviteUsersModal
            pageType="shop"
            refresh={refreshGrid}
            departmentId={departmentId}
            companyId={selectedFacility.companyId}
          />
        </Flex>
      </Flex>
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<DepartmentUser>
          {...agGridProps}
          rowSelection="multiple"
          rowMultiSelectWithClick
          onSelectionChanged={({ api }) => {
            setSelectedRows(api.getSelectedRows().map((r) => r.departmentUserId));
          }}
          noRowsOverlayComponent={() => 'No members found.'}
        />
      </div>
      <ConfirmationModal
        onConfirm={onConfirmDeleteDepartmentUsers}
        loading={deleting}
        onClose={() => {
          setUsersToDelete([]);
        }}
        opened={usersToDelete.length > 0}
        buttonColor="red"
        description={`Are you sure you want to delete ${usersToDelete.length === 1 ? 'this user' : 'these users'}?`}
      />
    </>
  );
};
