import { ReactNode } from 'react';

import { Box, Flex, Space } from '@mantine/core';

import { DeviceSize } from 'constants/globalConstants';
import useDeviceSize from 'hooks/useDeviceSize';

import MobileErrorBackground from '../assets/images/backgrounds/ErrorPage-bg-mobile.png';
import TabletErrorBackground from '../assets/images/backgrounds/ErrorPage-bg-tablet.png';
import DesktopErrorBackground from '../assets/images/backgrounds/ErrorPage-bg.png';

export const FullPageError = ({
  children,
  backgroundSrc,
  align = backgroundSrc ? 'center' : 'left',
}: {
  children: ReactNode;
  align?: 'left' | 'center' | 'right';
  backgroundSrc?: string;
}) => {
  const { deviceSize } = useDeviceSize();

  return (
    <Box
      style={{
        height: '100vh',
        position: deviceSize === 'DESKTOP' ? 'inherit' : 'fixed',
        left: 0,
        right: 0,
        overflowY: 'hidden',
      }}
    >
      <PageNotFoundBackground deviceSize={deviceSize} backgroundSrc={backgroundSrc} />
      <Flex
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
        justify="space-around"
      >
        {align === 'right' && <Space />}
        <Flex direction="column" justify="center" align="center" gap="md">
          {children}
        </Flex>
        {align === 'left' && <Space />}
      </Flex>
    </Box>
  );
};

const PageNotFoundBackground = ({ deviceSize, backgroundSrc }: { deviceSize: DeviceSize; backgroundSrc?: string }) => {
  if (backgroundSrc) return <img src={backgroundSrc} alt="Error page" height="100%" />;
  if (deviceSize === 'DESKTOP') return <img src={DesktopErrorBackground} alt="404 Page Not Found" height="100%" />;

  if (deviceSize === 'TABLET')
    return <img src={TabletErrorBackground} alt="404 Page Not Found" height="100%" width="100%" />;

  if (deviceSize === 'MOBILE') return <img src={MobileErrorBackground} alt="404 Page Not Found" height="100%" />;

  return <p>Loading...</p>;
};
