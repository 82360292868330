import { forwardRef, useMemo, useState } from 'react';

import { Box, MantineColor, Text } from '@mantine/core';
import type { ColDef } from 'ag-grid-community';

import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { WRITE_IN_ASSEMBLY } from 'modules/Field/WorkRequests/WorkRequest/constants';

import { canEditWorkOrder, canEditWorkRequest } from '../../WorkOrdersPage/common';
import { DeleteItemsModal } from './DeleteItemsModal';
import { InheritFromBopModal } from './InheritFromBopModal';
import type { WorkOrderPageProps } from './WorkOrderSelectedWrapper';
import type { WorkRequestPageProps } from './WorkRequestSelectedWrapper';

const multiSelectOptions = ['Inherit BOP', 'Delete'] as const;
type ItemMultiSelectOption = typeof multiSelectOptions[number];

type DataOpt = {
  color?: MantineColor;
} & (
  | {
      disabled?: boolean;
      disabledReason?: string;
    }
  | {
      disabled: true;
      disabledReason: string;
    }
);

const optsMap: Partial<Record<ItemMultiSelectOption, (context: WorkRequestPageProps | WorkOrderPageProps) => DataOpt>> =
  {
    'Inherit BOP': (context) => {
      const canEditTasks =
        ('workOrder' in context
          ? canEditWorkOrder(context.workOrder.workOrderStatusTypeName, 'editTasks')
          : canEditWorkRequest(context.workRequest.workRequestStatusName, 'editTasks')) &&
        context.selectedItem.type === 'FACILITY';
      const disabled =
        !canEditTasks ||
        ('workOrder' in context
          ? context.selectedWorkOrderItems.some((item) => item.workOrderItemTypeId !== WRITE_IN_ASSEMBLY)
          : context.selectedWorkRequestItems.some((item) => item.workRequestItemTypeId !== WRITE_IN_ASSEMBLY));
      return {
        disabled,
      };
    },
    Delete: (context) => {
      const disabled =
        'workOrder' in context
          ? !canEditWorkOrder(context.workOrder.workOrderStatusTypeName, 'editItems')
          : !canEditWorkRequest(context.workRequest.workRequestStatusName, 'editItems');
      return {
        disabled,
        color: 'red',
      };
    },
  };

const SelectItem = forwardRef<
  HTMLDivElement,
  {
    value: ItemMultiSelectOption;
    label: ItemMultiSelectOption;
  } & DataOpt
>(({ label, color, disabled, disabledReason, ...props }, ref) => (
  <div ref={ref} {...props}>
    <Text c={disabled ? 'dimmed' : color}>
      {label}
      {disabled && disabledReason ? (
        <>
          <br />({disabledReason})
        </>
      ) : (
        ''
      )}
    </Text>
  </div>
));

export const ItemsMultiSelectActions = ({ ...props }: WorkRequestPageProps | WorkOrderPageProps) => {
  const {
    serverSideGridProps: { refreshGrid, agGridProps },
  } = props;
  const numSelected =
    'selectedWorkOrderItems' in props ? props.selectedWorkOrderItems.length : props.selectedWorkRequestItems.length;
  const [value, onChange] = useState<ItemMultiSelectOption | null>(null);

  const shouldShow = useMemo(
    () => agGridProps.columnDefs?.some((c: ColDef) => c.checkboxSelection && !c.hide),
    [agGridProps.columnDefs],
  );
  if (!shouldShow) return null;

  return (
    <>
      <Box pos="relative">
        <WrappedSelect
          classNames={{ input: 'select-as-button' }}
          styles={{
            input: { maxWidth: 120 },
            dropdown: {
              minWidth: 120,
              left: '0 !important',
            },
          }}
          placeholder={numSelected > 0 ? `Action (${numSelected})` : 'Action'}
          itemComponent={SelectItem}
          disabled={numSelected === 0}
          data={multiSelectOptions.map((opt) => ({
            label: opt,
            value: opt,
            ...optsMap[opt]?.(props),
          }))}
          size="xs"
          value={value}
          onChange={onChange}
        />
      </Box>
      <InheritFromBopModal
        opened={value === 'Inherit BOP'}
        onClose={() => onChange(null)}
        {...('selectedWorkOrderItems' in props
          ? { workOrderItems: props.selectedWorkOrderItems }
          : { workRequestItems: props.selectedWorkRequestItems })}
      />
      <DeleteItemsModal
        opened={value === 'Delete'}
        onClose={() => onChange(null)}
        refresh={() => {
          agGridProps.ref.current?.api?.deselectAll();
          refreshGrid();
        }}
        {...('selectedWorkOrderItems' in props
          ? { workOrderItems: props.selectedWorkOrderItems }
          : { workRequestItems: props.selectedWorkRequestItems })}
      />
    </>
  );
};
