import { ReactNode } from 'react';

import { NoContentFallback } from 'components';
import {
  FacilityRequired,
  type SelectedFacility,
} from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { Custom } from 'components/Icons';
import { isNil } from 'helpers/isNotNil';
import type { DepartmentId } from 'hooks/projectsAndFacilities/types';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';

type Props = {
  selectedFacility: SelectedFacility;
  children: (shopDepartmentId: DepartmentId) => ReactNode;
};

const ShopDepartmentRequiredWrapped = ({ selectedFacility, children }: Props) => {
  const shopDepartmentId = selectedFacility.departments?.find(
    (d) => d.departmentType.departmentTypeId === DEPARTMENT_TYPE.SHOP,
  )?.departmentId;

  if (isNil(shopDepartmentId)) {
    return <NoContentFallback icon={Custom.Info} message="This facility does not have a Shop department." />;
  }

  return children(shopDepartmentId);
};

export const ShopDepartmentRequired = ({
  children,
}: {
  children: (shopDepartmentId: DepartmentId, selectedFacility: SelectedFacility) => ReactNode;
}) => (
  <FacilityRequired>
    {(selectedFacility) => (
      <ShopDepartmentRequiredWrapped selectedFacility={selectedFacility}>
        {(departmentId) => children(departmentId, selectedFacility)}
      </ShopDepartmentRequiredWrapped>
    )}
  </FacilityRequired>
);
