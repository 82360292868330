import { useCallback, useRef } from 'react';

import { ObservableQuery, gql, useApolloClient } from '@apollo/client';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { BOM_ITEM_LINE_TYPES } from 'constants/globalConstants';
import { billOfMaterialItem } from 'graphql/queries';
import { BillOfMaterialItem, MutationAddBillOfMaterialsWriteInItemBodyInput } from 'graphql/types';
import useBillOfMaterialsWriteInItem from 'hooks-api/mutations/useAddBillOfMaterialsWriteInItem';
import useCache from 'hooks/useCache';
import { useProject } from 'modules/Field/LocationsAndWorkPhases/contexts/ProjectContext';
import useCreateManufaturerIfDoesntExists from 'modules/Materials/BillOfMaterialsById/BOMCatalogTable/Decisions/hooks/useCreateManufacturerIfDoesnExits';

import { useGetGenericManufacturer } from '../hooks/useGetGenericManufacturer';
import { AddBOMItemStrategy, Manufacturer, NewItem } from './AddBOMItemStrategy';
import { PartItemStrategy } from './PartItemStrategy';
import { WriteInItemStrategy } from './WriteInItemStrategy';

const getExecuteBOMItemsQuery = (observableQuery: ObservableQuery, parentLineItemId: string) => {
  const billOfMaterialItems = observableQuery
    .getLastResult()
    ?.data?.billOfMaterialItem?.map(({ lineItemId }: BillOfMaterialItem) => lineItemId);
  const shouldFetchParentQuery = billOfMaterialItems.includes(parentLineItemId);

  if (shouldFetchParentQuery) return true;

  return false;
};

const getBOMItemsCurrentLevelQuery = (
  observableQuery: ObservableQuery,
  parentLineItemId: string,
  currentProjectId: string,
) => {
  const { parentLineItemIds, projectId } = observableQuery.variables?.query ?? {};
  const shouldFetchCurrentQueryIfChild = parentLineItemIds && parentLineItemIds === parentLineItemId;

  const shouldFetchRootBOM = !parentLineItemId && projectId === currentProjectId;
  if (shouldFetchCurrentQueryIfChild || shouldFetchRootBOM) return true;

  return false;
};

type useAddStrategyProps = {
  methods: UseFormReturn<FieldValues, any>;
};

const useAddStrategy = ({ methods }: useAddStrategyProps) => {
  const { id: urlId } = useParams();
  const { itemSelectedId: id } = useProject();
  const { selectedItem } = useFacilitiesProjects();
  const { deleteFromCacheByQuery } = useCache();

  const client = useApolloClient();
  const strategy = useRef<AddBOMItemStrategy | null>(null);
  const genericManufacturer = useGetGenericManufacturer();
  const createManufacturerIfDoesntExists = useCreateManufaturerIfDoesntExists();
  const [addBillOfMaterialsWriteInItem] = useBillOfMaterialsWriteInItem({});
  const currentProjectId = selectedItem?.type === 'FACILITY' ? urlId : id;

  const addItemRow = useCallback(
    (item: NewItem, manufacturer: Manufacturer) => {
      if (item.lineItemTypeId === BOM_ITEM_LINE_TYPES.write) strategy.current = new WriteInItemStrategy(methods);
      if (item.lineItemTypeId === BOM_ITEM_LINE_TYPES.part) strategy.current = new PartItemStrategy(methods);
      if (!strategy.current) throw Error('Must select an strategy');
      return strategy.current.addItemRow(item, manufacturer);
    },
    [methods],
  );

  const onAddItem = useCallback(
    (item: NewItem) => addItemRow(item, genericManufacturer),
    [addItemRow, genericManufacturer],
  );

  const onCreateBOMItem = async (data: FieldValues) => {
    const errorMessage = 'There was an error trying to create the item.';
    const { manufacturer } = data;
    const manufacturerId = await createManufacturerIfDoesntExists(manufacturer);
    if (!manufacturerId) throw new Error(errorMessage);
    const items = strategy.current?.createBOMItem(data, manufacturerId);
    if (!items || items.length < 0) throw new Error(errorMessage);
    const mutationInputItem: MutationAddBillOfMaterialsWriteInItemBodyInput = {
      projectId: currentProjectId,
      items,
    };

    const response = await addBillOfMaterialsWriteInItem({ variables: { body: mutationInputItem } });
    const itemAdded = (response?.data?.addBillOfMaterialsWriteInItem ?? [])[0];

    if (itemAdded) {
      const ern = `ern:moab:bomitem:${itemAdded.lineItemId}`;
      deleteFromCacheByQuery('billOfMaterialLineItemByLBS', { ern });
    }
  };

  const refetchParentBOMItems = async (data: FieldValues) =>
    client.refetchQueries({
      include: [gql(billOfMaterialItem)],
      onQueryUpdated(observableQuery: ObservableQuery) {
        if (!currentProjectId) throw new Error('There was an error getting items.');
        return getExecuteBOMItemsQuery(observableQuery, data?.parentLineItemId);
      },
    });

  const refetchCurrenBOMItemsLevel = async (data: FieldValues) =>
    client.refetchQueries({
      include: [gql(billOfMaterialItem)],
      onQueryUpdated(observableQuery: ObservableQuery) {
        if (!currentProjectId) throw new Error('There was an error getting items.');
        return getBOMItemsCurrentLevelQuery(observableQuery, data?.parentLineItemId, currentProjectId);
      },
    });

  return {
    onAddItem,
    onCreateBOMItem,
    refetchParentBOMItems,
    refetchCurrenBOMItemsLevel,
  };
};

export default useAddStrategy;
