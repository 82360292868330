import { useState } from 'react';

import { ActionIcon } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { useWrappedDelete } from 'hooks-api/useWrappedApiCall';
import { WRITE_IN_ASSEMBLY } from 'modules/Field/WorkRequests/WorkRequest/constants';
import type { WorkRequestItemTypeId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { canEditWorkOrder, canEditWorkRequest } from '../../WorkOrdersPage/common';
import type { BillOfProcessId, WorkOrderItemTypeId } from './types';
import { useWorkRequestOrderItems } from './WorkRequestOrderItemsContext';

export const DeleteBOPModal = ({
  billOfProcessId,
  itemTypeId,
  refresh,
}: {
  billOfProcessId: BillOfProcessId;
  itemTypeId: WorkRequestItemTypeId | WorkOrderItemTypeId;
  refresh: () => void;
}) => {
  const context = useWorkRequestOrderItems();
  const [opened, setOpened] = useState(false);
  const { apiCall, loading } = useWrappedDelete<unknown>(`shop/billOfProcess/${billOfProcessId}`);
  const canEdit =
    itemTypeId === WRITE_IN_ASSEMBLY &&
    context.selectedItem.type === 'FACILITY' &&
    ('workOrder' in context
      ? canEditWorkOrder(context.workOrder.workOrderStatusTypeName, 'editTasks')
      : canEditWorkRequest(context.workRequest.workRequestStatusName, 'editTasks'));
  if (!canEdit) return null;

  const onClose = () => setOpened(false);
  const onConfirm = () => apiCall().then(refresh).then(onClose);

  return (
    <>
      <ActionIcon onClick={() => setOpened(true)} className="show-on-row-hover" variant="transparent">
        <EvolveIcon icon="Delete" color="inherit" />
      </ActionIcon>
      <ConfirmationModal
        title="Confirm BOP deletion"
        opened={opened}
        onClose={onClose}
        buttonColor="red"
        onConfirm={onConfirm}
        loading={loading}
      >
        Are you sure you want to delete all tasks in this bill of process?
      </ConfirmationModal>
    </>
  );
};
