import React, { useCallback, useMemo } from 'react';

import { Stack, Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import { FilterButtonWithPopover } from 'components/Buttons';
import ExportButton from 'components/Buttons/ExportButton';
import { Columns, useItemsDataGridPro } from 'components/ItemsDatagridPro';
import PopoverContent from 'modules/Materials/commons/FilterPopoverContent/PopoverContent';
import HideChipsButton from 'modules/Materials/commons/HideChipsButton';
import LocationChipsArea from 'modules/Materials/commons/LocationsChipsArea';
import { useAssignationMode } from 'modules/Materials/features/assignation-mode';

import { useTakeOff } from '../context/TakeOffContext';
import { FIELDS_COLUMNS_CSV, HEADERS_NAME } from '../TakeOffPadTab/hooks/TableHeaders/constants';
import TabCounterItems from '../TakeOffPadTab/TabCounterItems';
import { HeaderProvider } from './HeaderContext';
import { requestTakeoffPadCSV, requestSourcePadCSV } from './helpers';
import SelectAction from './SelectAction/SelectAction';

const TakeOffHeader = () => {
  const {
    selectedPadItems,
    padSelected,
    disabledDropdown,
    projectId,
    sourcePadSortingKeyword,
    takeOffPadSortingKeyword,
    setAssignModeOn,
  } = useTakeOff();
  const { AssignationModeComponents } = useAssignationMode(useTakeOff, setAssignModeOn);
  const { exportColumns: exportColumnsBase } = useItemsDataGridPro({ updateColumns: false });

  const exportColumns = useMemo(() => {
    const columnsExportMapped = exportColumnsBase.map(({ field }) => ({
      name: FIELDS_COLUMNS_CSV[field],
      displayname: HEADERS_NAME(padSelected?.isSourcePad)[field],
    }));

    return columnsExportMapped;
  }, [exportColumnsBase, padSelected?.isSourcePad]);

  const csvCall = useCallback(() => {
    const itemList = selectedPadItems?.map((e) => e.lineItemId);
    if (padSelected?.isSourcePad) {
      const bodyContent = {
        sourcePadId: padSelected.takeoffPadId,
        sourcePadName: padSelected.takeoffPadName,
        lineItemIds: itemList?.length ? itemList : null,
        orderBy: sourcePadSortingKeyword.current,
        projectId,
        exportColumns,
      };
      return requestSourcePadCSV(bodyContent);
    }
    const bodyContent = {
      takeoffPadId: padSelected?.takeoffPadId,
      lineItemIds: itemList?.length ? itemList : null,
      orderBy: takeOffPadSortingKeyword.current,
      exportColumns,
    };
    return requestTakeoffPadCSV(bodyContent);
  }, [padSelected, projectId, selectedPadItems, sourcePadSortingKeyword, takeOffPadSortingKeyword, exportColumns]);

  return (
    <HeaderProvider>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginRight: '4px 0 16px 0' }}
      >
        <Box sx={{ width: '100%' }}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              minWidth: '100%',
            }}
          >
            <Typography variant="h5">Takeoff</Typography>
          </Stack>
          <Stack direction="row" sx={{ alignItems: 'space-between', width: '100%' }}>
            <Grid container item alignItems="center" wrap="nowrap">
              <Box sx={{ display: 'flex', height: 45, alignItems: 'center', justifyContent: 'center' }}>
                <TabCounterItems isSelected sx={{ marginLeft: 0 }} />
                <SelectAction
                  disabled={!selectedPadItems?.length || disabledDropdown}
                  isSourcePad={padSelected?.isSourcePad}
                  isTakeoff
                />
                {AssignationModeComponents.ChipSelected}
              </Box>
            </Grid>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <HideChipsButton contextHook={useTakeOff} />
              <FilterButtonWithPopover>
                <PopoverContent contextHook={useTakeOff} padSelected={padSelected} />
              </FilterButtonWithPopover>
              {AssignationModeComponents.Button}
              <Columns />
              <ExportButton context={useTakeOff} action={csvCall} />
            </Stack>
          </Stack>
          <LocationChipsArea contextHook={useTakeOff} />
        </Box>
      </Stack>
    </HeaderProvider>
  );
};
export default TakeOffHeader;
