import { ActionIcon, Avatar, FileButton, Flex, LoadingOverlay, Modal, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { CombinedUser, useUser } from 'app/UserContext';
import { ModalButtons } from 'components/Mantine/ModalButtons';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { useSelectImage } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddTasks/useDocumentImage';
import type { DocumentId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';
import { User } from 'types/types-api';

import { useProfilePictureContext } from '../ProfilePictureContext';

type Props = {
  user: CombinedUser;
  opened: boolean;
  onClose: () => void;
};

type FormValues = {
  firstName?: string | null;
  lastName?: string | null;
  workPhoneNumber?: string | null;
  personalPhoneNumber?: string | null;
};

type UpdateUserBody = FormValues & {
  userPhotoId?: DocumentId;
};

const EditProfileModal = ({ opened, onClose, user }: Props) => {
  const { refreshUser } = useUser();
  const { image: profilePicture, onImageSelected: setProfilePicture, previewUrl } = useSelectImage();

  const {
    uploadProfilePicture,
    uploading,
    updateProfilePicture,
    profilePicture: existingProfilePicture,
  } = useProfilePictureContext();

  const { apiCall, loading } = useWrappedPatch<User, UpdateUserBody>(`admin/user/${user.userId}`);

  const saving = uploading || loading;

  const form = useForm<FormValues>({
    initialValues: {
      firstName: user.userFirstName ?? '',
      lastName: user.userLastName ?? '',
      personalPhoneNumber: user.personalPhoneNumber ?? '',
      workPhoneNumber: user.workPhoneNumber ?? '',
    },
  });

  const submitEnabled = form.isDirty() || isNotNil(profilePicture);

  const onSubmit = async (values: typeof form.values) => {
    const { firstName, lastName, workPhoneNumber, personalPhoneNumber } = values;
    const userPhotoId = profilePicture ? await uploadProfilePicture(profilePicture) : undefined;
    apiCall({
      firstName,
      lastName,
      workPhoneNumber,
      personalPhoneNumber,
      userPhotoId,
    })
      .then((res) => {
        notifications.show({
          title: 'Successfully updated',
          message: 'Profile information changed successfully',
          color: 'green',
        });
        if (res.userPhotoId) {
          updateProfilePicture(res.userPhotoId);
        }
        onClose();
      })
      .finally(() => refreshUser());
  };

  return (
    <Modal opened={opened} onClose={onClose} closeOnClickOutside={!form.isDirty()} title="Edit profile" centered>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Flex direction="column" gap="md">
          <Flex direction="column" align="center" gap="sm">
            <FileButton accept="image/png,image/jpg,image/jpeg" onChange={setProfilePicture}>
              {(props) => (
                <ActionIcon disabled={saving} {...props} size={84} variant="subtle" radius="100%">
                  <Avatar src={previewUrl ?? existingProfilePicture} size="xl" radius="100%" />
                  <LoadingOverlay visible={!!previewUrl && saving} />
                </ActionIcon>
              )}
            </FileButton>
            <Text c="dimmed" fz="sm" mb="sm" align="center">
              Select the image you want to upload. Images should not be more than 8MB in size. Only .jpg, .jpeg, .png
              files are accepted.
            </Text>
          </Flex>
          <TextInput label="First name" disabled={saving} maxLength={64} {...form.getInputProps('firstName')} />
          <TextInput label="Last name" disabled={saving} maxLength={64} {...form.getInputProps('lastName')} />
          <TextInput
            label="Work number"
            disabled={saving}
            maxLength={18}
            type="tel"
            {...form.getInputProps('workPhoneNumber')}
          />
          <TextInput
            label="Personal number"
            disabled={saving}
            maxLength={18}
            type="tel"
            {...form.getInputProps('personalPhoneNumber')}
          />
          <ModalButtons
            onClose={onClose}
            confirmationText="Save changes"
            loading={saving}
            disabled={!form.isValid() || !submitEnabled}
            type="submit"
          />
        </Flex>
      </form>
    </Modal>
  );
};

export default EditProfileModal;
