import { useEffect, useMemo } from 'react';

import { useUser } from 'app/UserContext';
import { LOCAL_STORAGE_CONSTANTS, LOCAL_STORAGE_KEY, LOCAL_STORAGE_TYPE } from 'constants/globalConstants';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { strongIsEqual as isEqual } from 'helpers/strongEntries';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { EvolveURL } from './useEvolveApi';
import { GetOpts, useWrappedGet } from './useWrappedApiCall';

/**
 * Returns a response cached in local storage,
 * and will update the local storage if the data retrieved from the network
 * is different than the cache.
 */
export const useWrappedGetWithLocalStorage = <
  K extends LOCAL_STORAGE_KEY,
  V extends LOCAL_STORAGE_TYPE[K] = LOCAL_STORAGE_TYPE[K],
  DataType = any,
>(
  localStorageKey: typeof LOCAL_STORAGE_CONSTANTS[K],
  initialValue: V,
  url: EvolveURL,
  opts?: GetOpts<DataType>,
) => {
  const { user } = useUser();
  const [stored, setStored] = useLocalStorage<K>(localStorageKey, initialValue);
  const { data: fromNetwork, apiCall, ...rest } = useWrappedGet<V>(url, { lazy: true, ...opts });

  useEffect(() => {
    // Many calls don't work without a user,
    // so once we have a user, make the call.
    if (isNotNil(user?.userId)) {
      apiCall();
    }
  }, [apiCall, user?.userId]);

  useEffect(() => {
    if (!isNil(fromNetwork) && !isEqual(stored, fromNetwork)) {
      setStored(fromNetwork);
    }
  }, [fromNetwork, setStored, stored]);
  return useMemo(
    () => ({
      ...rest,
      apiCall,
      data: stored,
    }),
    [rest, apiCall, stored],
  );
};
