import { ReactNode, createContext, useCallback, useEffect, useMemo } from 'react';

import { useUser } from 'app/UserContext';
import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import type { CompanyId, UserId } from 'types/types-api';

declare const unitOfMeasureIdSymbol: unique symbol;
export type UnitOfMeasureId = string & { [unitOfMeasureIdSymbol]: never };

declare const unitOfMeasureTypeIdSymbol: unique symbol;
type UnitOfMeasureTypeId = string & { [unitOfMeasureTypeIdSymbol]: never };

export type UnitOfMeasureType = {
  unitOfMeasureTypeId: UnitOfMeasureTypeId;
  unitOfMeasureTypeName: string;
};

export type UnitOfMeasure = {
  companyId: CompanyId;
  createdBy: UserId;
  createdOn: string; // timestamp
  modifiedBy: UserId | null;
  modifiedOn: string | null; // timestamp
  unitOfMeasureCode: string;
  unitOfMeasureId: UnitOfMeasureId;
  unitOfMeasureName: string;
  unitOfMeasureTypeId: UnitOfMeasureTypeId;
  updatedOn: string | null; // timestamp?
};

export type NewUnitOfMeasureBody = Pick<
  UnitOfMeasure,
  'unitOfMeasureName' | 'unitOfMeasureCode' | 'unitOfMeasureTypeId' | 'companyId'
>;

type UnitOfMeasureContextType = {
  uoms: UnitOfMeasure[] | undefined;
  uomTypes: UnitOfMeasureType[] | undefined;
  loading: boolean;
  addNewUOM: (newUom: NewUnitOfMeasureBody) => Promise<UnitOfMeasure>;
};

const UnitOfMeasureContext = createContext<UnitOfMeasureContextType | undefined>(undefined);

export const UnitOfMeasureProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const { apiCall: addNewUOMApiCall } = useWrappedPost<UnitOfMeasure, NewUnitOfMeasureBody>('moab/uom');
  const { data: uomTypes, loading: loadingUOMTypes } = useWrappedGet<UnitOfMeasureType[]>('moab/uomType');
  const {
    data: uoms,
    setDefaultOpts,
    loading: loadingUOMs,
    apiCall: refetchUOMs,
  } = useWrappedGet<UnitOfMeasure[]>('moab/uom', { lazy: true });
  const companyId = user?.companyId;
  useEffect(() => {
    if (isNotNil(companyId)) {
      setDefaultOpts({ defaultConfig: { params: { companyId } } });
    }
  }, [setDefaultOpts, companyId]);

  const addNewUOM = useCallback<UnitOfMeasureContextType['addNewUOM']>(
    async (values) => addNewUOMApiCall(values).finally(() => refetchUOMs()),
    [addNewUOMApiCall, refetchUOMs],
  );

  const value = useMemo<UnitOfMeasureContextType>(
    () => ({
      uoms,
      uomTypes,
      loading: loadingUOMTypes || loadingUOMs,
      addNewUOM,
    }),
    [uoms, uomTypes, loadingUOMTypes, loadingUOMs, addNewUOM],
  );
  return <UnitOfMeasureContext.Provider value={value}>{children}</UnitOfMeasureContext.Provider>;
};

export const useUnitOfMeasure = () => useGeneralContext(UnitOfMeasureContext, 'UnitOfMeasure');
