import React, { ReactNode } from 'react';

import { Modal, Text } from '@mantine/core';

import type { WithRequired } from 'helpers/helper-types';
import { isNotNil } from 'helpers/isNotNil';

import { ModalButtonProps, ModalButtons } from './ModalButtons';

type Props = React.ComponentProps<typeof Modal> &
  WithRequired<Omit<ModalButtonProps, 'dividerMargin'>, 'onConfirm'> & {
    title?: string;
  } & (
    | { description: string; children?: never }
    | {
        description?: never;
        children: ReactNode;
      }
  );

export const ConfirmationModal = ({
  title = 'Confirm',
  description,
  children,
  opened,
  cancellationText = 'Cancel',
  confirmationText = 'Confirm',
  buttonColor,
  loading,
  disabled,
  onClose,
  onConfirm,
  ...props
}: Props) => (
  <Modal opened={opened} centered closeOnClickOutside={false} title={title} onClose={onClose} {...props}>
    <div style={{ overflowX: 'hidden' }}>
      {isNotNil(description) && <Text>{description}</Text>}
      {children}
    </div>
    <ModalButtons
      dividerMargin="sm"
      confirmationText={confirmationText}
      cancellationText={cancellationText}
      buttonColor={buttonColor}
      loading={loading || !opened}
      disabled={disabled}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  </Modal>
);
