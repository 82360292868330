import { useState } from 'react';

import { ActionIcon, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { User } from 'types/types-api';

export const companyMembersBulkActions = ['Add Licenses', 'Reset Password', 'Delete'] as const;
export type CompanyMembersBulkAction = typeof companyMembersBulkActions[number];

type Props = {
  user: User;
  actionSingleUser: (user: User, selectedAction: CompanyMembersBulkAction) => void;
};

export const CompanyMemberMenu = ({ user, actionSingleUser }: Props) => {
  const [opened, setOpened] = useState(false);
  const { user: myUser } = useUser();
  const { apiCall: resendInvitation, loading } = useWrappedPatch(`admin/user/${user.userId}/resendInvitation`);
  return (
    <Menu position="bottom-end" withinPortal opened={opened} onChange={setOpened}>
      <Menu.Target>
        <ActionIcon variant="transparent">
          <EvolveIcon icon="MoreOptsVert" color="inherit" className={opened ? undefined : 'show-on-row-hover'} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {user.inviteStatus === 'Pending' && (
          <Menu.Item
            disabled={loading}
            onClick={() => {
              resendInvitation({}).then(() => {
                notifications.show({
                  title: 'Successfully sent',
                  message: `Invitation resent to ${user.userEmail}`,
                  color: 'green',
                });
              });
            }}
          >
            Resend Invitation
          </Menu.Item>
        )}
        {companyMembersBulkActions.map((action) => (
          <Menu.Item
            key={action}
            color={action === 'Delete' ? 'red' : undefined}
            disabled={
              (action === 'Delete' && user.userId === myUser?.userId) ||
              (action === 'Reset Password' && user.inviteStatus === 'Pending')
            }
            onClick={() => actionSingleUser(user, action)}
          >
            {action}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
