import { useState } from 'react';

import { ActionIcon, Box, Menu } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import type { TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { SelectableTask } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddTasks/SelectableTask';

type Props = {
  task: TaskType;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

export const TaskWithMenu = ({ task, onClickEdit, onClickDelete }: Props) => {
  const [hover, setHover] = useState(false);
  const [opened, setOpened] = useState(false);
  return (
    <Box
      p={0}
      style={{ display: 'inline-block', marginRight: 12 }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <SelectableTask
        task={task}
        rightComponent={
          <Menu opened={opened} onChange={setOpened} position="bottom-end">
            <Menu.Target>
              <ActionIcon
                onClick={(e) => {
                  setOpened((o) => !o);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                variant="transparent"
                m={2}
                size="sm"
                style={{ display: !hover && !opened ? 'none' : undefined }}
              >
                <EvolveIcon icon="MoreOptsVert" color="inherit" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={(e) => {
                  onClickEdit();
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                color="red"
                onClick={(e) => {
                  onClickDelete();
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        }
      />
    </Box>
  );
};
