import { useEffect, useState } from 'react';

import type { MultiSelectProps } from '@mantine/core';

import { isNotNil } from 'helpers/isNotNil';

import { SearchableSelectorProps } from './searchableTypes';
import { WrappedMultiSelect } from './TypeSafeSelect';
import { useSearchableData } from './useSearchableData';

type Props<T, TKey extends keyof T> = SearchableSelectorProps<T, TKey> &
  Omit<MultiSelectProps, 'value' | 'data' | 'onChange'> & {
    disableSearch?: boolean;
    getGroup?: (data: T) => string | undefined;
    nowrap?: boolean;
  };

/**
 * Used for MutliSelects whose "search" functinality
 * involves fetching data from the backend.
 */
export const SearchableMultiSelect = <T, TKey extends keyof T>({
  paginatedGet,
  getItemLabel,
  idKey,
  value,
  onChange,
  excludeIds,
  setIsDirty,
  fetchPageOpts,
  searchKey,
  searchValue,
  useLike,
  getGroup,
  preselectedOptions = [],
  disableOption,
  disableSearch = false,
  placeholder,
  nothingFound = 'No results found',
  ...multiSelectProps
}: Props<T, TKey>) => {
  const [opened, setOpened] = useState(false);
  const { options, setIsLazy, loading, setSearchPhrase } = useSearchableData({
    paginatedGet,
    fetchPageOpts,
    searchKey,
    useLike,
    lazy: true,
  });
  useEffect(() => {
    if (opened) {
      setIsLazy(disableSearch);
    } else {
      setIsLazy(true);
    }
  }, [disableSearch, opened, setIsLazy]);
  const [selectedOptions, setSelectedOptions] = useState<T[]>(preselectedOptions);

  useEffect(() => {
    if (isNotNil(searchValue)) {
      setSearchPhrase(searchValue);
    }
  }, [searchValue, setSearchPhrase]);

  useEffect(() => setSelectedOptions((o) => value ?? o), [value]);

  return (
    <WrappedMultiSelect
      placeholder={placeholder || 'Type to search...'}
      nothingFound={disableSearch ? undefined : nothingFound}
      searchable
      searchValue={searchValue}
      onSearchChange={setSearchPhrase}
      clearSearchOnChange={false}
      clearSearchOnBlur
      withinPortal
      filter={(_, selected) => !selected}
      {...multiSelectProps}
      onDropdownOpen={(...e) => {
        setOpened(true);
        multiSelectProps?.onDropdownOpen?.(...e);
      }}
      onDropdownClose={(...e) => {
        setOpened(false);
        multiSelectProps?.onDropdownClose?.(...e);
      }}
      onChange={(v) => {
        const ids = v as T[TKey][];
        const stillSelected = selectedOptions.filter((t) => ids.includes(t[idKey]));
        const additionalSelected = options.filter(
          (o) => ids.includes(o[idKey]) && !stillSelected.some((t) => t[idKey] === o[idKey]),
        );
        const newSelectedOptions = [...stillSelected, ...additionalSelected];
        setIsDirty?.(true);
        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions);
      }}
      value={selectedOptions.map((t) => t[idKey] as string)}
      data={[
        ...selectedOptions.map((t) => ({ label: getItemLabel(t), value: t[idKey] as string, group: getGroup?.(t) })),
        ...options
          .filter((t) => !excludeIds?.includes(t[idKey]) && !selectedOptions.some((st) => st[idKey] === t[idKey]))
          .map((t) => ({
            label: getItemLabel(t),
            value: t[idKey] as string,
            disabled: disableOption?.(t),
            group: getGroup?.(t),
          })),
        ...(loading ? [{ value: 'loading', label: 'Loading...', disabled: true }] : []),
      ]}
    />
  );
};
