import { useCallback, useState } from 'react';

import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import type { WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';
import { useTasks } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/useTasks';

import type { WorkOrder } from '../../../WorkOrdersPage/types';

export type UpdateInProgressTaskBody = {
  tasks: {
    taskId: TaskId;
    isSaveData: boolean;
  }[];
};

type ReturnType = {
  tasks: WorkCellTask[];
  fetchInProgressTasks: () => Promise<void>;
  updateInProgressTasks: (tasks: UpdateInProgressTaskBody['tasks']) => Promise<void>;
  loading: boolean;
};

export const useInProgressTasks = (workOrder: WorkOrder): ReturnType => {
  const { taskStatusTypes } = useTasks();
  const { fetchPage: fetchTasks } = useWrappedPaginatedGet<WorkCellTask>('shop/workCellTask', { lazy: true });
  const [allTasks, setAllTasks] = useState<WorkCellTask[]>([]);
  const [loading, setLoading] = useState(false);
  const { apiCall: updateTasks } = useWrappedPatch<any, UpdateInProgressTaskBody>('shop/task/multiple');
  const updateInProgressTasks = useCallback<ReturnType['updateInProgressTasks']>(
    async (tasks) => {
      await updateTasks({ tasks });
    },
    [updateTasks],
  );

  const fetchInProgressTasks = useCallback(async () => {
    const inProgressTaskStatusTypeIds = taskStatusTypes
      ?.filter((t) => t.taskStatusTypeName === 'Started' || t.taskStatusTypeName === 'Paused')
      .map((t) => t.taskStatusTypeId);
    if (isNil(inProgressTaskStatusTypeIds)) return;
    setAllTasks([]);
    setLoading(true);
    getAllDataFromFetcher(fetchTasks, {
      params: {
        orderBy: 'createdOn:asc',
        workRequestId: workOrder.workRequest.workRequestId,
        taskStatusTypeIds: inProgressTaskStatusTypeIds.join(','),
      },
    })
      .then(setAllTasks)
      .finally(() => setLoading(false));
  }, [fetchTasks, taskStatusTypes, workOrder.workRequest.workRequestId]);

  return {
    fetchInProgressTasks,
    tasks: allTasks,
    updateInProgressTasks,
    loading,
  };
};
