import { ReactNode } from 'react';

import { Box, Card, Flex, Overlay, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import { DocumentImage } from '../AddTasks/useDocumentImage';
import type { Part } from './types';

export const CatalogPartCard = ({
  part,
  disabled,
  rightComponent,
}: {
  part: Part;
  disabled?: boolean;
  rightComponent: ReactNode;
}) => (
  <Flex
    justify="space-between"
    pr="md"
    pos="relative"
    style={{
      width: '100%',
      height: 80,
      maxHeight: 80,
    }}
  >
    {disabled && <Overlay opacity={0.5} color="#fff" />}
    <Flex gap="sm">
      <Card p={0} withBorder style={{ width: 80, height: 80, minWidth: 80, minHeight: 80 }}>
        <Flex style={{ height: '100%', width: '100%' }} align="center" justify="center">
          <DocumentImage
            storagePath={part.partImages?.find((img) => img.isDefault)?.document.storagePath}
            alt={part.partName}
            fallback={<EvolveIcon size="xl" icon={part.hasAssembly ? 'Assembly' : 'Part'} />}
          >
            <Box style={{ position: 'absolute', right: 0, bottom: 0 }}>
              <EvolveIcon className="shadow" size="lg" icon={part.hasAssembly ? 'Assembly' : 'Part'} />
            </Box>
          </DocumentImage>
        </Flex>
      </Card>
      <Flex direction="column" gap={5}>
        <Text
          title={part.partName}
          fw={500}
          lh={1.25}
          style={{
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            // @ts-ignore This field exists, promise
            WebkitBoxOrient: 'vertical',
          }}
        >
          {part.partName}
        </Text>
        <Text fz="sm" lh={1}>
          Catalog # {part.manufacturerCatalogNumber}
        </Text>
        <Text fz="sm" lh={1}>
          Manufacturer: {part.manufacturer?.manufacturerName}
        </Text>
      </Flex>
    </Flex>
    <Flex align="flex-end" style={{ height: 80 }}>
      {rightComponent}
    </Flex>
  </Flex>
);
