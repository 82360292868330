import { useState } from 'react';

import { Flex, Modal, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { ModalButtons } from 'components/Mantine/ModalButtons';
import { isNotNil } from 'helpers/isNotNil';
import { WorkCell } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

type EditableFields = Pick<WorkCell, 'workCellName' | 'workCellDescription'>;

type Props = {
  opened: boolean;
  onClose: () => void;
  workCell?: WorkCell;
  onSubmit: (values: EditableFields) => Promise<void>;
};

const EditWorkCellForm = ({ workCell, onSubmit: parentOnSubmit, onClose }: Props) => {
  const [saving, setSaving] = useState(false);
  const form = useForm<EditableFields>({
    initialValues: {
      workCellName: workCell?.workCellName ?? '',
      workCellDescription: workCell?.workCellDescription ?? '',
    },
    validate: {
      workCellName: (v) => !v,
    },
  });
  const onSubmit = (values: typeof form.values) => {
    setSaving(true);
    parentOnSubmit(values).finally(() => setSaving(false));
  };
  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Flex direction="column" gap="md">
        <TextInput
          label="Work Cell Name"
          disabled={saving}
          required
          autoFocus
          {...form.getInputProps('workCellName')}
        />
        <Textarea label="Description" disabled={saving} {...form.getInputProps('workCellDescription')} />
        <ModalButtons
          loading={saving}
          onClose={onClose}
          confirmationText={isNotNil(workCell) ? 'Save' : 'Create'}
          disabled={!form.isValid()}
          type="submit"
        />
      </Flex>
    </form>
  );
};

export const CreateEditWorkCellModal = (props: Props) => {
  const { workCell, opened, onClose } = props;
  return (
    <Modal
      title={`${isNotNil(workCell) ? 'Edit' : 'Create'} work cell`}
      centered
      closeOnClickOutside={false}
      opened={opened}
      onClose={onClose}
    >
      <EditWorkCellForm {...props} />
    </Modal>
  );
};
