import { useState } from 'react';

import { Text, Title } from '@mantine/core';
import { Navigate } from 'react-router-dom';

import ResetPasswordBackground from '../assets/images/backgrounds/reset-password-bg.png';
import { FullPageError } from './FullPageError';
import { HeaderBar } from './Layout/HeaderBar/HeaderBar';
import { ResetPasswordModal } from './Layout/HeaderBar/ProfileDropdown/ResetPasswordModal/ResetPasswordModal';
import { useModule } from './Modules/ModuleContext';
import { useUser } from './UserContext';

export const ResetPasswordLandingPage = ({ noLicense }: { noLicense?: boolean }) => {
  const { user, loading } = useUser();
  const [passwordReset, setPasswordReset] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { data: navModules } = useModule();

  if (navModules.length > 0 && noLicense) return <Navigate to="/" />;

  if ((!loading && !user) || passwordReset) return <Navigate to="/auth/login" />;

  return (
    <FullPageError backgroundSrc={ResetPasswordBackground}>
      <HeaderBar noLicenses />
      {noLicense ? (
        <>
          <Title>{`You don't have a license.`}</Title>
          <Text ta="center">
            Hi there, it seems you do not have a license. Please request one from your company admin to gain access.
          </Text>
        </>
      ) : (
        <Text fz="xl">
          <Text c="indigo.9" style={{ cursor: 'pointer' }} span onClick={() => setOpenModal(true)}>
            Click here
          </Text>{' '}
          to reset your password.
        </Text>
      )}
      <ResetPasswordModal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        onComplete={() => setPasswordReset(true)}
      />
    </FullPageError>
  );
};
