import { ReactNode, createContext, useEffect, useMemo } from 'react';

import { useUser } from 'app/UserContext';
import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import type { CompanyId } from 'types/types-api';

declare const manufacturerIdSymbol: unique symbol;
export type ManufacturerId = string & { [manufacturerIdSymbol]: never };

export type Manufacturer = {
  companyId: CompanyId | null;
  homePageUrl: string | null;
  manufacturerId: ManufacturerId;
  manufacturerName: string;
};

type ManufacturersContextType = {
  manufacturers: Manufacturer[] | undefined;
  loading: boolean;
};

const ManufacturersContext = createContext<ManufacturersContextType | undefined>(undefined);

export const ManufacturersProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const {
    data: manufacturers,
    setDefaultOpts,
    loading,
  } = useWrappedGet<Manufacturer[]>('moab/mfg', {
    lazy: true,
  });
  const companyId = user?.companyId;
  useEffect(() => {
    if (isNotNil(companyId)) {
      setDefaultOpts({ defaultConfig: { params: { companyId } } });
    }
  }, [setDefaultOpts, companyId]);

  const value = useMemo<ManufacturersContextType>(
    () => ({
      manufacturers,
      loading,
    }),
    [loading, manufacturers],
  );
  return <ManufacturersContext.Provider value={value}>{children}</ManufacturersContext.Provider>;
};

export const useManufacturers = () => useGeneralContext(ManufacturersContext, 'Manufacturers');
