import type { ColDef, ITooltipParams, ValueFormatterParams } from 'ag-grid-community';

import { CellRenderer } from 'components/Mantine/CellRenderer';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { isNil } from 'helpers/isNotNil';
import { DrawingsPopover } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/DrawingsPopover';
import { EditTaskMenu } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/EditTaskMenu';
import type { WorkOrderItem } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/types';
import { useWorkRequestOrderItems } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/WorkRequestOrderItemsContext';

import { CellStatusBadge } from '../../WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';
import { getDaysRemaining } from '../../WorkRequestsList/WorkRequestsPage/common';
import { WRITE_IN_ASSEMBLY } from '../constants';
import type { Task, WorkRequestItem } from './types';

export const getBillOfProcessColDef =
  () =>
  (workRequestOrderItem: WorkRequestItem | WorkOrderItem): ColDef<Task>[] =>
    [
      {
        field: 'taskName',
        tooltipField: 'taskName',
        sortable: false,
        minWidth: 100,
        flex: 1,
      },
      {
        headerName: 'Predecessor',
        field: 'taskPredecessorIds',
        tooltipValueGetter: ({ value }: ITooltipParams<Task, Task['taskPredecessorIds']>) =>
          value?.map((v) => v.taskName).join(', ') ?? '',
        valueFormatter: ({ value }: ValueFormatterParams<Task, Task['taskPredecessorIds']>) =>
          value?.map((v) => v.taskName).join(', ') ?? '',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'taskStatusTypeName',
        headerName: 'Status',
        cellRenderer: CellRenderer<Task>(({ data }) => <StatusCellRenderer task={data} />),
        width: 120,
      },
      {
        field: 'workCellName',
        headerName: 'Work Cell',
        enableCellChangeFlash: false,
      },
      {
        ...lockedColDef('right'),
        width: 48,
        cellRenderer: CellRenderer<Task>(({ data }) => <DrawingsPopover taskId={data.taskId} />, {
          show: ({ data }) => data.hasDrawingRegisterDocuments ?? false,
        }),
      },
      {
        ...lockedColDef('right'),
        width: 60,
        cellRenderer: CellRenderer<Task>(
          ({ node, api }) => (
            <EditTaskMenu
              task={node.data}
              onUpdate={(d) => node.updateData(d)}
              refetch={() => api.refreshServerSide({ purge: true })}
            />
          ),
          {
            show:
              ('workRequestItemTypeId' in workRequestOrderItem
                ? workRequestOrderItem.workRequestItemTypeId
                : workRequestOrderItem.workOrderItemTypeId) === WRITE_IN_ASSEMBLY,
          },
        ),
      },
    ];

const StatusCellRenderer = ({ task }: { task: Task | null | undefined }) => {
  const context = useWorkRequestOrderItems();
  if (isNil(task)) return '';
  const { needBy } = 'workOrder' in context ? context.workOrder.workRequest : context.workRequest;
  const completedTimestamp = task.time.events.find((e) => e.description === 'Completed')?.timesStamp;
  return (
    <CellStatusBadge
      status={task.taskStatusTypeName}
      daysRemaining={getDaysRemaining(task.taskStatusTypeName, needBy, completedTimestamp)}
    />
  );
};
