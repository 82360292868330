import { Alert } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNotNil } from 'helpers/isNotNil';

const loginErrorCodes = [
  'UserNotVerifiedFoundException',
  'LimitExceededException',
  'NotAuthorizedException',
  'UserNotFoundException',
  'EmailNotVerified',
  'InvitationExpired',
  'EmailAlreadyExists',
  // Catch-all, frontend only
  'UnknownException',
] as const;
export type LoginErrorCode = typeof loginErrorCodes[number];
export const isLoginErrorCode = (errorCode: string): errorCode is LoginErrorCode =>
  loginErrorCodes.includes(errorCode as LoginErrorCode);

const loginErrorCopy: Record<LoginErrorCode, string> = {
  EmailNotVerified: 'Your email address has not been verified!',
  InvitationExpired: 'Sorry, this invitation has expired.',
  LimitExceededException: 'Too many attemps, try again later.',
  NotAuthorizedException: 'Invalid email or password.',
  UserNotFoundException: 'No account found for this email.',
  UnknownException: 'Something went wrong. Please try again later.',
  EmailAlreadyExists: 'TODO',
  UserNotVerifiedFoundException: '',
};

type Props = {
  errorCode: LoginErrorCode | undefined | null;
  onClose?: () => void;
};

export const LoginErrorAlert = ({ errorCode, onClose }: Props) =>
  errorCode ? (
    <Alert
      variant="light"
      color="red"
      withCloseButton={isNotNil(onClose)}
      icon={<EvolveIcon icon="Info" color="red" />}
      onClose={onClose}
    >
      {errorCode in loginErrorCopy ? loginErrorCopy[errorCode] : loginErrorCopy.UnknownException}
    </Alert>
  ) : null;
