import { Fragment } from 'react';

import { Divider, Flex, MantineProvider } from '@mantine/core';

import { useModule } from 'app/Modules/ModuleContext';
import { isNotNil } from 'helpers/isNotNil';
import useSetupModule from 'hooks/useSetupModule';

import { NavItem } from './NavItem';

export const NavBarContent = () => {
  const { data } = useModule();
  const { currentModule } = useSetupModule();

  const navigationModuleSets = data.find((n) => n.navigationModuleCode === currentModule())?.navigationModuleSets ?? [];
  return (
    <MantineProvider theme={{ colorScheme: 'dark' }} inherit>
      <Flex direction="column" gap="xs" mt="xs">
        {navigationModuleSets.map(({ navigationSet, isLastInGroup, navigationModuleSetId }) => (
          <Fragment key={navigationModuleSetId}>
            {isNotNil(navigationSet) && (
              <NavItem
                item={
                  navigationSet.isMenu
                    ? navigationSet
                    : navigationSet.navigationSetItems?.[0].navigationItem ?? navigationSet
                }
              />
            )}
            {isLastInGroup && <Divider color="gray.7" />}
          </Fragment>
        ))}
      </Flex>
    </MantineProvider>
  );
};
