/* eslint-disable prefer-promise-reject-errors */
import { useCallback } from 'react';

import { Auth } from 'aws-amplify';

import { assignUserDetailsToAmplifyUser, CombinedUser } from 'app/UserContext';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import { setLocalStorage } from 'hooks/useLocalStorage';
import type { User } from 'types/types-api';

import { useFetchProviderStatus } from './restCalls';

const { STAY_SIGNED_IN } = LOCAL_STORAGE_CONSTANTS;

export const useSignIn = () => {
  const { fetchProviderStatus } = useFetchProviderStatus();
  const { apiCall } = useWrappedGet<User>(`admin/user/username/`, { lazy: true });

  const checkForLatestEulaAgreement = useCallback(
    async (email: string) => {
      const res = await fetchProviderStatus(email);
      if ('acceptedLatestEula' in res) {
        const { acceptedLatestEula, userName } = res;
        return { acceptedLatestEula, userName };
      }
      return { acceptedLatestEula: null, userName: null };
    },
    [fetchProviderStatus],
  );

  const signIn = useCallback(
    async ({
      email,
      password,
      keepEnabled,
    }: {
      email: string;
      password: string;
      keepEnabled: boolean;
    }): Promise<CombinedUser> => {
      const provider = await fetchProviderStatus(email);
      const { userStatus } = provider;

      if (userStatus === 'UNCONFIRMED')
        return Promise.reject({
          code: 'UserNotVerifiedFoundException',
          message: 'The email address is not verified',
        });

      if (['EMAIL_NOT_FOUND', 'USER_NOT_FOUND'].includes(userStatus))
        return Promise.reject({
          code: 'UserNotFoundException',
          message: 'The email address does not exist',
        });

      if (userStatus === 'FORCE_CHANGE_PASSWORD')
        return Promise.reject({
          code: 'UserNotFoundException',
          message: 'Username/client id combination not found.',
        });

      // TODO: if user has to complete the invitation link first

      const amplifyResult = await Auth.signIn(email, password);

      const res = await apiCall({ url: `admin/user/username/${amplifyResult.username}` });

      assignUserDetailsToAmplifyUser(amplifyResult, res);

      setLocalStorage(STAY_SIGNED_IN, keepEnabled);

      return amplifyResult;
    },
    [apiCall, fetchProviderStatus],
  );

  return { signIn, checkForLatestEulaAgreement };
};
