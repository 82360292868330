import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';

import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import { useLocalStorage } from 'hooks/useLocalStorage';

import type { ShopConfiguration } from './types';
import { useSelectedProjectFacility } from './useSelectedProjectFacility';

type ShopConfigurationsContextType = {
  shopConfigurations?: ShopConfiguration[];
  activeShopConfiguration?: ShopConfiguration;
  loading: boolean;
  refresh: () => Promise<ShopConfiguration[]>;
};

const ShopConfigurationContext = React.createContext<ShopConfigurationsContextType | undefined>(undefined);

export const ShopConfigurationsProvider = ({ children }: { children: ReactNode }) => {
  const { selectedItem } = useSelectedProjectFacility();
  const [activeShopConfiguration, setActiveShopConfiguration] = useLocalStorage<'ACTIVE_SHOP_CONFIGURATION'>(
    'ACTIVE_SHOP_CONFIGURATION',
    undefined,
  );
  const {
    data: shopConfigurations,
    loading,
    apiCall,
  } = useWrappedGet<ShopConfiguration[]>(`shop/configuration/${selectedItem?.id}`, { lazy: true });

  useEffect(() => {
    if (selectedItem?.type === 'FACILITY') {
      apiCall({ url: `shop/configuration/${selectedItem.id}` });
    }
  }, [selectedItem?.type, selectedItem?.id, apiCall]);

  useEffect(() => {
    const activeConfig = shopConfigurations?.find((c) => c.isActive);
    if (isNotNil(activeConfig) && activeConfig.facilityId === selectedItem?.id) {
      if (activeConfig.shopConfigurationId !== activeShopConfiguration?.shopConfigurationId) {
        setActiveShopConfiguration(activeConfig);
      }
    } else {
      setActiveShopConfiguration(undefined);
    }
  }, [activeShopConfiguration?.shopConfigurationId, selectedItem?.id, setActiveShopConfiguration, shopConfigurations]);

  const refresh = useCallback(() => apiCall(), [apiCall]);

  const value = useMemo<ShopConfigurationsContextType>(
    () => ({
      ...(!loading && activeShopConfiguration?.facilityId === selectedItem?.id
        ? {
            shopConfigurations,
            activeShopConfiguration,
          }
        : {}),
      refresh,
      loading,
    }),
    [activeShopConfiguration, loading, refresh, selectedItem?.id, shopConfigurations],
  );

  return <ShopConfigurationContext.Provider value={value}>{children}</ShopConfigurationContext.Provider>;
};

export const useShopConfigurations = () => useGeneralContext(ShopConfigurationContext, 'ShopConfiguration');
