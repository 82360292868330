import { useState } from 'react';

import { ActionIcon, Box, Flex } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import MobileNavBar from '../../NavBar/MobileNavBar';
import { EvolveLogoLink } from '../components/EvolveLogoLink';
import { HeaderSearch } from '../components/HeaderSearchAutocomplete/HeaderSearch';
import { SearchInputContainer } from '../DesktopAndTabletHeader';
import { ProfileDropdown } from '../ProfileDropdown/ProfileDropdown';

export const MobileHeader = () => {
  const [showSearch, setShowSearch] = useState(false);

  return (
    <>
      <Flex align="center" justify="space-between" style={{ width: '100%' }}>
        {/* Left side of header */}
        <Flex align="center" gap="xs">
          <MobileNavBar />
          {/* <MobileModuleSelector /> */}
        </Flex>

        <EvolveLogoLink />

        {/* Right side of header */}
        <Flex align="center" gap="xs">
          <ActionIcon
            color={showSearch ? 'white' : undefined}
            size="lg"
            radius="xl"
            variant="filled"
            onClick={() => setShowSearch((o) => !o)}
          >
            <EvolveIcon icon="Search" color={showSearch ? 'inherit' : undefined} />
          </ActionIcon>
          <ProfileDropdown />
        </Flex>
      </Flex>
      {showSearch && (
        <SearchInputContainer>
          <Box style={{ width: '100%' }}>
            <HeaderSearch />
          </Box>
        </SearchInputContainer>
      )}
    </>
  );
};
