import { useEffect, useState } from 'react';

import { ActionIcon, Drawer, Flex, Loader, Popover, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
// import { ForgeViewer } from 'components/Autodesk';
import ForgeViewerDocument from 'components/Autodesk/ForgeViewerDocument';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { TriggerOnVisible } from 'hooks/useOnScreen';
import type { TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { useDocumentTypes } from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes/useDocumentTypes';

import type { Document, TaskDocument } from './SecondaryPane/WorkRequestOrderDetail/Attachments/types';
import { useDocumentsCache } from './SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';

type Props = {
  taskId: TaskId;
};

/** Only these extension types are openable in Forge Viewer. */
const forgeViewerExtensions = ['.pdf'];

const DrawingDocument = ({
  taskDocument,
  onClick,
}: {
  taskDocument: TaskDocument;
  onClick: (document: Document) => void;
}) => {
  const { attachmentToDocumentMap } = useDocumentsCache();
  if (isNil(taskDocument.documentId)) return null;
  const document = attachmentToDocumentMap[taskDocument.documentId];
  const isLink = isNotNil(document) && forgeViewerExtensions.includes(document?.extension.toLocaleLowerCase());
  return (
    <Flex
      gap="xs"
      c={isLink ? 'primary' : undefined}
      className={isLink ? 'attachment-link' : ''}
      onClick={isNotNil(document) && isLink ? () => onClick(document) : undefined}
      align="center"
    >
      <EvolveIcon icon="DrawingDocument" color="inherit" />
      {isNotNil(document) ? <Text fz="sm">{document.documentName}</Text> : <Loader variant="dots" size="sm" />}
    </Flex>
  );
};

export const DrawingsList = ({ taskId, setDocumentOpen }: Props & { setDocumentOpen: (open: boolean) => void }) => {
  const [viewingDocument, setViewingDocument] = useState<Document | null>(null);
  useEffect(() => setDocumentOpen(isNotNil(viewingDocument)), [setDocumentOpen, viewingDocument]);
  const { drawingModelCNCIds } = useDocumentTypes();
  const { requestDocumentDetails } = useDocumentsCache();
  const {
    data: documents,
    fetchNextPage,
    loading,
  } = useWrappedPaginatedGet<TaskDocument>('shop/taskDocument', {
    defaultConfig: {
      params: {
        taskId,
        documentTypeIds: drawingModelCNCIds,
        includeExternalUrl: false,
      },
    },
  });
  useEffect(() => {
    requestDocumentDetails(documents.map((d) => d.documentId).filter(isNotNil));
  }, [documents, requestDocumentDetails]);

  return (
    <>
      <Flex direction="column" gap="xs" style={{ minWidth: 300 }}>
        {documents.map((d) => (
          <DrawingDocument key={d.taskDocumentId} taskDocument={d} onClick={setViewingDocument} />
        ))}
      </Flex>
      <Drawer
        title="Viewer"
        size="xl"
        opened={isNotNil(viewingDocument)}
        onClose={() => setViewingDocument(null)}
        styles={{ body: { height: 'calc(100% - 4rem)' } }}
        // Done temporarily to appear above the page Header bar
        // TODO: Remove once the header has a more reasonable zIndex
        zIndex={10000}
      >
        {isNotNil(viewingDocument) && <ForgeViewerDocument document={viewingDocument} />}
      </Drawer>
      <TriggerOnVisible onVisible={fetchNextPage} loading={loading} loaderProps={{ m: 0, size: 'sm' }} />
    </>
  );
};

export const DrawingsPopover = (props: Props) => {
  const [documentOpen, setDocumentOpen] = useState(false);
  return (
    <Popover withinPortal position="bottom-end" shadow="md" offset={-10} closeOnClickOutside={!documentOpen}>
      <Popover.Target>
        <ActionIcon variant="subtle" size="sm" color="primary">
          <EvolveIcon icon="DrawingDocument" color="inherit" />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown style={{ maxHeight: 500, overflowY: 'auto' }}>
        <DrawingsList setDocumentOpen={setDocumentOpen} {...props} />
      </Popover.Dropdown>
    </Popover>
  );
};
