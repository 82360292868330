import { useState } from 'react';

import { Button, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

import useQuery from 'hooks/useQuery';

import { useResendSignUp } from './auth/useResendSignUp';
import { AuthFormWrapper } from './components/AuthFormWrapper';
import type { LoginErrorCode } from './components/LoginErrorAlert';

export const VerifyEmailPrompt = () => {
  const [loading, setLoading] = useState(false);
  const [verificationResent, setVerificationResent] = useState(false);
  const resendSignUp = useResendSignUp();
  const query = useQuery();
  const isUnverified = query.get('isUnverified');
  const email = query.get('email');
  const [errorCode, setErrorCode] = useState<LoginErrorCode | undefined>(isUnverified ? 'EmailNotVerified' : undefined);

  const handleResendEmail = async () => {
    if (email) {
      try {
        setLoading(true);
        await resendSignUp(email);
        setVerificationResent(true);
      } catch ({ code }: any) {
        setErrorCode(code);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <AuthFormWrapper
      title="Verify Email Address"
      onSubmit={handleResendEmail}
      errorCode={errorCode}
      onCloseError={() => setErrorCode(undefined)}
      successMessage={verificationResent ? `An email verification link has been sent to ${email}!` : undefined}
      onCloseSuccess={() => setVerificationResent(false)}
    >
      <Text>You must verify your email address to login to your account.</Text>
      <Button type="submit" fullWidth color="indigo.9" loading={loading}>
        Resend email
      </Button>
      <Text ta="center">
        Entered the wrong email address?
        <Link className="link" to="/auth/register">
          Register with a different email.
        </Link>
      </Text>
    </AuthFormWrapper>
  );
};
