import { useEffect, useState } from 'react';

import { ActionIcon, Divider, Flex, Loader, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { TriggerOnVisible } from 'hooks/useOnScreen';

import type { PartCatalogId, PartCategory, PartCategoryId } from './types';

type Props = {
  disabled: boolean;
  selectedItem: SelectedItem;
  partCatalogId: PartCatalogId;
  selectedCategoryId: PartCategoryId | null;
  setSelectedCategoryId: (partCategoryId: PartCategoryId) => void;
};

const Category = ({
  partCategory,
  hidden = false,
  depth = 0,
  ...props
}: {
  partCategory: PartCategory;
  hidden?: boolean;
  depth?: number;
} & Props) => {
  const zeroDepth = depth === 0;
  const { partCatalogId, selectedItem, selectedCategoryId, setSelectedCategoryId, disabled } = props;
  const [expanded, setExpanded] = useState(zeroDepth);
  const [hasEverBeenExpanded, setHasEverBeenExpanded] = useState(expanded);
  const {
    data: subCategories,
    fetchNextPage,
    loading,
  } = useWrappedPaginatedGet<PartCategory>('moab/partCategory', {
    defaultConfig: {
      params: {
        parentPartCategoryId: partCategory.partCategoryId,
        partCatalogId,
        orderBy: 'name:asc',
        companyId: selectedItem.companyId,
      },
    },
  });

  const selected = selectedCategoryId === partCategory.partCategoryId;
  const expandDisabled = subCategories.length === 0 || zeroDepth;

  return (
    <>
      <Flex
        style={{
          display: hidden && !selected ? 'none' : 'flex',
          userSelect: 'none',
        }}
        bg={selected ? `gray.${disabled ? '3' : '2'}` : undefined}
        align="center"
        gap="xs"
        py={3}
        onClick={!zeroDepth && !disabled ? () => setSelectedCategoryId(partCategory.partCategoryId) : undefined}
      >
        <ActionIcon
          ml={8 * depth}
          size="sm"
          disabled={!loading && expandDisabled}
          loading={loading}
          variant={expandDisabled ? 'transparent' : 'subtle'}
          onClick={(e) => {
            if (loading || disabled) return;
            setExpanded(!expanded);
            setHasEverBeenExpanded(true);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <EvolveIcon
            size="xs"
            color="inherit"
            // eslint-disable-next-line no-nested-ternary
            icon={subCategories.length === 0 ? null : expanded ? 'ArrowDown' : 'ArrowRight'}
          />
        </ActionIcon>
        <Text c={zeroDepth ? 'dimmed' : undefined} fw={selected ? 500 : undefined}>
          {partCategory.partCategoryName}
        </Text>
        {selected && <EvolveIcon color="green.5" icon="CheckCircle" size="sm" />}
      </Flex>
      <Divider hidden={hidden && !selected} />
      {hasEverBeenExpanded && (
        <>
          {subCategories.map((c) => (
            <Category
              hidden={!expanded || hidden}
              key={c.partCategoryId}
              partCategory={c}
              depth={depth + 1}
              {...props}
            />
          ))}
          {expanded && <TriggerOnVisible onVisible={fetchNextPage} loading={loading} loaderProps={{ size: 'sm' }} />}
        </>
      )}
    </>
  );
};

export const CatalogCategoryNav = (props: Props) => {
  const { partCatalogId, selectedItem } = props;
  const {
    data: categories,
    setDefaultOpts,
    loading,
  } = useWrappedPaginatedGet<PartCategory>('moab/partCategory', { lazy: true });

  useEffect(() => {
    setDefaultOpts({
      lazy: false,
      defaultConfig: {
        params: {
          parentPartCategoryId: '',
          partCatalogId,
          orderBy: 'name:asc',
          companyId: selectedItem.companyId,
        },
      },
    });
  }, [partCatalogId, selectedItem.companyId, setDefaultOpts]);

  return (
    <Flex direction="column" fz="sm">
      {loading ? (
        <Loader variant="dots" size="sm" my="sm" />
      ) : (
        categories.map((c) => <Category key={c.partCategoryId} partCategory={c} {...props} />)
      )}
    </Flex>
  );
};
