import { useEffect, useState } from 'react';

import { Button, Flex, Modal, Text, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { ModalButtons } from 'components/Mantine/ModalButtons';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import type { WorkRequestStatusName } from 'constants/badgeMappingStatus';
import { Facility } from 'graphql/types';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { type FacilityId } from 'hooks/projectsAndFacilities/useFacilities';

import type { WorkRequest } from './types';

const SmallDetail = ({ title, value }: { title: string; value: string }) => (
  <Flex direction="column" style={{ width: '100%' }}>
    <Text fz="sm" c="dimmed">
      {title}
    </Text>
    <Text>{value}</Text>
  </Flex>
);

export const TransferWorkRequestModal = ({
  workRequest,
  updateStatus,
  disabled,
}: {
  workRequest: WorkRequest;
  disabled: boolean;
  updateStatus: (newStatus: WorkRequestStatusName, description?: string) => void;
}) => {
  const { apiCall: updateWorkRequest, loading } = useWrappedPatch<WorkRequest, { facilityId: FacilityId }>(
    `shop/workRequest/${workRequest.workRequestId}`,
  );

  const [facilities, setFacilities] = useState<Facility[]>([]);
  const { fetchPage, loading: loadingFacilities } = useWrappedPaginatedGet<Facility>('admin/facility/all', {
    lazy: true,
  });
  useEffect(() => {
    getAllDataFromFetcher(fetchPage).then(setFacilities);
  }, [fetchPage]);

  const [opened, setOpened] = useState(false);
  const form = useForm<{
    facilityId: FacilityId;
    notes?: string;
  }>({
    validate: {
      facilityId: isNil,
    },
  });

  const onSubmit = (values: typeof form.values) => {
    updateWorkRequest({ facilityId: values.facilityId }).then(() => {
      updateStatus('Transferred', values.notes);
      setOpened(false);
    });
  };

  return (
    <>
      <Button
        onClick={() => {
          form.reset();
          setOpened(true);
        }}
        size="sm"
        variant="subtle"
        disabled={disabled}
      >
        Transfer
      </Button>
      <Modal
        title={`Transfer ${workRequest.workRequestIdentifier}`}
        opened={opened}
        onClose={() => setOpened(false)}
        closeOnClickOutside={false}
        centered
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Flex gap="md" direction="column">
            <Flex gap="lg">
              <SmallDetail title="Project" value={workRequest.projectName} />
              <SmallDetail title="Current Facility" value={workRequest.facilityName} />
            </Flex>
            <WrappedSelect
              label="New Facility"
              placeholder="Please select"
              required
              disabled={loading}
              withinPortal
              data={facilities
                .filter((f) => f.facilityId !== workRequest.facilityId)
                .map((f) => ({
                  label: f.facilityName,
                  value: f.facilityId,
                }))}
              {...form.getInputProps('facilityId')}
              nothingFound={loadingFacilities ? 'Loading...' : undefined}
            />

            <Textarea disabled={loading} placeholder="Notes" maxLength={1000} {...form.getInputProps('notes')} />
            <ModalButtons
              onClose={() => setOpened(false)}
              confirmationText="Transfer"
              loading={loading}
              disabled={!form.isValid()}
              type="submit"
            />
          </Flex>
        </form>
      </Modal>
    </>
  );
};
