import { ReactNode, useState } from 'react';

import { ColorSwatch, Flex, PasswordInput, Text } from '@mantine/core';

import { useMantineColor } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { lowercaseRegEx, numberRegEx, specialCharacterRegEx, uppercaseRegEx } from 'constants/regEx';

const regexes = [lowercaseRegEx, numberRegEx, specialCharacterRegEx, uppercaseRegEx] as const;

type Props = {
  onChange: (password: string) => void;
  setPasswordIsValid: (isValid: boolean) => void;
  loading?: boolean;
};

const MarkRequirementText = ({ hasPassed, children }: { hasPassed: boolean; children: ReactNode }) => {
  const { mantineColor } = useMantineColor(hasPassed ? 'green.5' : 'gray.3');
  return (
    <Flex align="center" gap="sm" fz="sm">
      <ColorSwatch color={mantineColor ?? 'gray'} size={8} withShadow={false} />
      {children}
    </Flex>
  );
};

const PasswordRequirements = ({ password = '' }: { password: string }) => (
  <Flex p="xs" direction="column" gap="xs">
    <Text c="dimmed" fz="sm">
      PASSWORD MUST
    </Text>
    <Flex direction="column" ml={2}>
      <MarkRequirementText hasPassed={password.length > 7}>Be a minimum of 8 characters</MarkRequirementText>
      <MarkRequirementText hasPassed={specialCharacterRegEx.test(password)}>
        Include at least 1 special character
      </MarkRequirementText>
      <MarkRequirementText hasPassed={numberRegEx.test(password)}>Include at least 1 number</MarkRequirementText>
      <MarkRequirementText hasPassed={uppercaseRegEx.test(password)}>
        Include at least 1 uppercase letter
      </MarkRequirementText>
      <MarkRequirementText hasPassed={lowercaseRegEx.test(password)}>
        Include at least 1 lowercase letter
      </MarkRequirementText>
    </Flex>
  </Flex>
);

const passwordIsValid = (password: string, confirmPassword: string) =>
  password.length > 7 && regexes.filter((r) => !r.test(password)).length === 0 && password === confirmPassword;

export const NewPasswordInput = ({ onChange, loading, setPasswordIsValid }: Props) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showRequirements, setShowRequirements] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState<string>();

  const validatePasswordsMatch = () => {
    if (!newPassword || !confirmPassword) {
      setPasswordMismatchError(undefined);
    }
    if (newPassword !== confirmPassword) {
      setPasswordMismatchError('Passwords do not match.');
      setPasswordIsValid(false);
    }
  };

  return (
    <>
      <AgGridStyleTooltip
        opened={showRequirements}
        withArrow
        position="right"
        withinPortal
        label={<PasswordRequirements password={newPassword} />}
      >
        <PasswordInput
          label="New password"
          value={newPassword}
          onChange={(e) => {
            const password = e.currentTarget.value.trim();
            setPasswordMismatchError(undefined);
            setNewPassword(password);
            onChange(password);
            setPasswordIsValid(passwordIsValid(password, confirmPassword));
          }}
          onFocus={() => setShowRequirements(true)}
          onBlur={() => {
            setShowRequirements(false);
            validatePasswordsMatch();
          }}
          error={!!passwordMismatchError || (!passwordIsValid && !showRequirements && newPassword.length > 0)}
          disabled={loading}
        />
      </AgGridStyleTooltip>
      <PasswordInput
        label="Confirm password"
        value={confirmPassword}
        onChange={(e) => {
          const password = e.currentTarget.value.trim();
          setPasswordMismatchError(undefined);
          setConfirmPassword(password);
          setPasswordIsValid(passwordIsValid(newPassword, password));
        }}
        onBlur={validatePasswordsMatch}
        error={passwordMismatchError}
        disabled={loading}
      />
    </>
  );
};
