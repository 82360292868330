import { useEffect, useState } from 'react';

import { Box, MantineSize, MultiSelect, MultiSelectProps, Select, SelectItem, SelectProps } from '@mantine/core';

import { isNotNil } from 'helpers/isNotNil';

type TypeSafeData<T> =
  | readonly T[]
  | readonly (Omit<SelectItem, 'value' | 'disabled'> &
      (
        | {
            value: T;
            disabled?: false;
          }
        | {
            value: string;
            // If the option is always disabled, it can't be selected.
            // Therefore, `value` doesn't have to be of type `T`
            disabled: true;
          }
      ))[];

export type TypeSafeSelectProps<T = string> = {
  value: T | null | undefined;
  onChange: (value: T | null) => void;
  data: TypeSafeData<T>;
};

export const WrappedSelect = <T extends string>(
  props: Omit<SelectProps, keyof TypeSafeSelectProps<T>> & TypeSafeSelectProps<T>,
) => <Select {...props} />;

type TypeSafeMultiSelectProps<T> = {
  value: T[] | undefined;
  onChange: (value: T[]) => void;
  data: TypeSafeData<T>;
};

// TODO: There has to be a way to get this from Mantine theme,
// but I just can't find it
const sizeToRemMap: Record<MantineSize, string> = {
  xs: '1.875rem',
  sm: '2.25rem',
  md: '2.625rem',
  lg: '3.125rem',
  xl: '3.75rem',
} as const;

export const WrappedMultiSelect = <T extends string>({
  onChange,
  value,
  /** Default size of Mantine Select is `sm` */
  size = 'sm',
  /** If `true`, will overflow the Select instead of wrapping @default true */
  nowrap = true,
  ...props
}: Omit<MultiSelectProps, keyof TypeSafeMultiSelectProps<T>> &
  TypeSafeMultiSelectProps<T> & {
    nowrap?: boolean;
  }) => {
  const [values, setValues] = useState(value ?? []);
  useEffect(() => {
    if (isNotNil(value)) setValues(value);
  }, [value]);
  const [opened, setOpened] = useState(false);
  return (
    <MultiSelect
      withinPortal
      inputContainer={
        nowrap
          ? (children) => (
              <Box
                pos="relative"
                style={{
                  maxHeight: size in sizeToRemMap ? sizeToRemMap[size] : `calc(${size})`,
                  // Same zIndex as Dropdown. Would love to pull this programatically
                  zIndex: opened ? 300 : undefined,
                  overflowY: 'visible',
                }}
              >
                {children}
              </Box>
            )
          : undefined
      }
      size={size}
      // valueComponent={({ index, label, onRemove }) => {
      //   const displayLabel = index === 0 || opened;
      //   if (index < 2 || opened) {
      //     return (
      //       <Badge
      //         tt="none"
      //         radius="sm"
      //         size={size}
      //         color="gray"
      //         className="mantine-MultiSelect-defaultValueRemove mantine-CloseButton-root"
      //         c={displayLabel ? 'dark' : undefined}
      //         fw={500}
      //         title={displayLabel ? label : undefined}
      //         rightSection={
      //           displayLabel ? (
      //             <ActionIcon size="xs" variant="transparent" onClick={onRemove}>
      //               <EvolveIcon icon="Close" size="xs" color="gray.8" />
      //             </ActionIcon>
      //           ) : null
      //         }
      //         style={{
      //           userSelect: 'none',
      //           paddingRight: displayLabel ? 0 : undefined,
      //           minWidth: '2.5rem',
      //           maxWidth: 'calc(100% - 0.625rem)',
      //         }}
      //       >
      //         {displayLabel ? label : `+${values.length - 1}`}
      //       </Badge>
      //     );
      //   }
      //   return null;
      // }}
      {...props}
      onFocus={(...e) => {
        setOpened(true);
        props.onFocus?.(...e);
      }}
      onBlur={(...e) => {
        setOpened(false);
        props.onBlur?.(...e);
      }}
      value={values}
      onChange={(v) => onChange((v ?? []) as T[])}
      styles={
        nowrap
          ? {
              values: !opened
                ? {
                    overflowX: 'scroll',
                    scrollbarWidth: 'none',
                    overflowWrap: 'unset',
                    flexWrap: 'nowrap',
                    marginRight: '5px !important',
                    // gap: 8,
                  }
                : {
                    paddingTop: 3,
                    paddingBottom: 3,
                    // gap: '6px 8px',
                  },
              ...props.styles,
            }
          : props.styles
      }
    />
  );
};
