import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { statusColors } from 'styles/theme/colors';

type Props = {
  icon: any;
  message: string;
};
const NoContentFallback = ({ icon: Icon, message }: Props) => (
  <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <Icon style={{ fill: statusColors.draft }} />
    <Typography variant="h6" sx={{ mt: 1, color: 'rgba(0, 0, 0, 0.5)' }}>
      {message}
    </Typography>
  </Box>
);

export default NoContentFallback;
