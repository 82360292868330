import { Button, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import useDeviceSize from 'hooks/useDeviceSize';

import { FullPageError } from './FullPageError';

export const PageNotFound = () => {
  const { deviceSize } = useDeviceSize();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const goToHelpCenter = () => (window.location.href = 'https://evolvemep.com/contact-support');

  return (
    <FullPageError>
      <Title fz={100}>404</Title>
      <Text align="center" style={{ width: deviceSize === 'DESKTOP' ? '480px' : 3 / 5 }}>
        {"Uh oh, we can't seem to find the page you're looking for. Try going back to the previous page, or "}
        <Text c="indigo.9" onClick={goToHelpCenter} style={{ cursor: 'pointer' }} span>
          contact support.
        </Text>
      </Text>
      <Button mt="xs" variant="filled" onClick={goBack} color="dark">
        Go back
      </Button>
    </FullPageError>
  );
};
