import { useCallback, useState } from 'react';

import { useLazyQuery, gql } from '@apollo/client';

import { partCategoryCloudSearch as PART_CATEGORIES_CLOUD_SEARCH } from 'graphql/queries';
import { stringifyCloudQuery } from 'helpers/stringFunctions';
import type {
  PartCatalogId,
  PartCategoryId,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';
import type { CompanyId } from 'types/types-api';

const DEFAULT_QUERY_PARAMS = {
  parser: 'structured',
  sort: 'category_depth asc',
  queryOptions: encodeURIComponent(
    JSON.stringify({
      fields: ['category_id', 'category_name_for_searching', 'category_path', 'catalog_id', 'company_id'],
    }),
  ),
};

const PART_CATEGORY_CLOUD_SEARCH_LIMIT = 3;

export type CatalogSearchResults = {
  catalogId: PartCatalogId;
  categoryDepth: string; // actually a number as string, like '1'
  categoryId: PartCategoryId;
  categoryName: string;
  categoryPath: string; // categoryName path (ie. /Categories/ThisCategory)
  companyId: CompanyId;
};

const usePartCategoriesCloudSearchAPI = (catalogId: PartCatalogId | '' = '') => {
  const [data, setData] = useState<CatalogSearchResults[]>([]);

  const [fetchPartCategories, { loading }] = useLazyQuery<{
    partCategoryCloudSearch: CatalogSearchResults[];
  }>(gql(PART_CATEGORIES_CLOUD_SEARCH), {
    fetchPolicy: 'no-cache',
  });

  const searchPartCategories = useCallback(
    async (queryString: string) => {
      const res = await fetchPartCategories({
        variables: {
          query: {
            query: stringifyCloudQuery(queryString, catalogId),
            skip: 0,
            take: PART_CATEGORY_CLOUD_SEARCH_LIMIT,
            ...DEFAULT_QUERY_PARAMS,
          },
        },
      });
      setData(res.data?.partCategoryCloudSearch ?? []);
    },
    [catalogId, fetchPartCategories],
  );

  return { data, loading, searchPartCategories };
};

export default usePartCategoriesCloudSearchAPI;
