import { useMemo, useState } from 'react';

import { Button, Divider, Flex, Select, SelectItem, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import type { SelectedProject } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { isNotNil } from 'helpers/isNotNil';
import { strongFromEntries } from 'helpers/strongEntries';
import type { DepartmentType } from 'hooks/projectsAndFacilities/types';
import { useFacilities } from 'hooks/projectsAndFacilities/useFacilities';
import { projectUnitTypes, ProjectUpdate, useProjects } from 'hooks/projectsAndFacilities/useProjects';

import type { ProjDepartments } from './types';

type Props = {
  selectedProject: SelectedProject;
};

export const ProjectPropertiesForm = ({ selectedProject }: Props) => {
  const { projects, updateProject, loading, projTypes, depTypes } = useProjects();
  const projectProps = projects.find((f) => f.projectId === selectedProject.id);

  const { facilities } = useFacilities();
  const allFacilityDepartments: (SelectItem & { type: DepartmentType })[] = facilities
    .flatMap((f) => f.departments ?? [])
    .map((dep) => ({ value: dep.departmentId, label: dep.departmentName, type: dep.departmentType }));

  const [saving, setSaving] = useState(false);

  const depTypeMap = useMemo(
    () => strongFromEntries(depTypes?.map((dt) => [dt.departmentTypeName, dt.departmentTypeId]) ?? []),
    [depTypes],
  );

  const form = useForm<ProjDepartments & Omit<ProjectUpdate, 'departmentIds'>>({
    initialValues: {
      projectName: projectProps?.projectName,
      projectIdentifier: projectProps?.projectIdentifier,
      projectTypeId: projectProps?.projectType?.projectTypeId,
      shopDep:
        projectProps?.departments?.find((dep) => dep.departmentType.departmentTypeName === 'Shop')?.departmentId ??
        null,
      procurementDep:
        projectProps?.departments?.find((dep) => dep.departmentType.departmentTypeName === 'Procurement')
          ?.departmentId ?? null,
      designDep:
        projectProps?.departments?.find((dep) => dep.departmentType.departmentTypeName === 'Design')?.departmentId ??
        null,
      units: projectProps?.units ?? null,
      addressLine1: projectProps?.address?.addressLine1,
      addressLine2: projectProps?.address?.addressLine2,
      city: projectProps?.address?.city,
      state: projectProps?.address?.state,
      zip: projectProps?.address?.zip,
      country: projectProps?.address?.country,
    },
    validate: {
      projectName: (n) => !n,
      projectIdentifier: (i) => !i,
    },
  });

  const onSubmit = ({ procurementDep, designDep, shopDep, ...values }: typeof form.values) => {
    setSaving(true);
    updateProject(
      selectedProject.id,
      {
        ...values,
        departmentIds: [procurementDep, designDep, shopDep].filter(isNotNil),
      },
      // Overwrite the entire Project Properties object
      true,
    )
      .then(({ projectName, projectIdentifier, projectTypeId, departments, units, address }) => {
        const newValues = {
          projectName,
          projectIdentifier,
          projectTypeId,
          units,
          shopDep: departments?.find((dep) => dep.departmentTypeId === depTypeMap.Shop)?.departmentId ?? null,
          procurementDep:
            departments?.find((dep) => dep.departmentTypeId === depTypeMap.Procurement)?.departmentId ?? null,
          designDep: departments?.find((dep) => dep.departmentTypeId === depTypeMap.Design)?.departmentId ?? null,
          ...address,
        };
        form.setValues(newValues);
        form.resetDirty(newValues);
      })
      .finally(() => setSaving(false));
  };

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)} style={{ height: '100%', overflowY: 'auto' }}>
        <Flex direction="column">
          <Flex gap="xl" style={{ height: '100%', width: '100%' }}>
            <Flex direction="column" gap="xs" style={{ maxWidth: '600px', width: '50%' }}>
              <TextInput
                label="Project Title"
                maxLength={64}
                required
                {...form.getInputProps('projectName')}
                disabled={saving}
              />
              <TextInput
                label="Project Id"
                maxLength={30}
                required
                {...form.getInputProps('projectIdentifier')}
                disabled={saving}
              />
              <Select
                data={projTypes?.map((type) => ({ value: type.projectTypeId, label: type.projectTypeName })) ?? []}
                label="Project Type"
                clearable
                {...form.getInputProps('projectTypeId')}
                disabled={saving}
              />
              <Select
                data={allFacilityDepartments.filter((dep) => dep.type.departmentTypeName === 'Shop')}
                label="Shop Department"
                clearable
                {...form.getInputProps('shopDep')}
                disabled={saving}
              />
              <Select
                data={projectUnitTypes}
                label="Units"
                clearable
                {...form.getInputProps('units')}
                disabled={saving}
              />
            </Flex>

            <Divider orientation="vertical" mb={0} />

            <Flex direction="column" justify="space-between" style={{ maxWidth: '400px', width: '50%' }}>
              <Flex direction="column" gap="xs" style={{ width: '100%' }}>
                <TextInput
                  label="Address Line 1"
                  maxLength={64}
                  {...form.getInputProps('addressLine1')}
                  disabled={saving}
                />
                <TextInput
                  label="Address Line 2"
                  maxLength={64}
                  {...form.getInputProps('addressLine2')}
                  disabled={saving}
                />
                <TextInput label="City" maxLength={64} {...form.getInputProps('city')} disabled={saving} />
                <Flex direction="row" gap="md" style={{ width: '100%' }}>
                  <TextInput
                    label="State"
                    maxLength={64}
                    style={{ width: '100%' }}
                    {...form.getInputProps('state')}
                    disabled={saving}
                  />
                  <TextInput
                    label="Zip/Postal Code"
                    maxLength={64}
                    style={{ width: '100%' }}
                    {...form.getInputProps('zip')}
                    disabled={saving}
                  />
                </Flex>
                <TextInput label="Country" maxLength={64} {...form.getInputProps('country')} disabled={saving} />
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="row" gap="sm" mt="xl">
            <Button
              variant="subtle"
              color="gray"
              c="dark"
              onClick={() => form.reset()}
              disabled={saving || !form.isDirty()}
            >
              Discard changes
            </Button>
            <Button loading={saving} disabled={!form.isValid() || !form.isDirty() || loading} type="submit">
              Save changes
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
};
