import { OptionsFilterQuerySchema, OptionsItem } from './types';

export const onUpdateQueryCallback = (query: OptionsFilterQuerySchema, prev: OptionsFilterQuerySchema) => {
  const merge = { ...prev, ...query };
  const values = Object.entries(merge);
  const newQuery = values.reduce((out, current) => {
    const filter: any = { ...out };
    const [key, value] = current;
    if (value) filter[key] = value;
    return filter;
  }, {});
  return newQuery;
};

export const onlyActiveCallback = (options: OptionsItem[], query: OptionsFilterQuerySchema) => {
  const merge = options.reduce((out: OptionsItem[], current) => {
    const list = [...out];
    const exist = query[current.key];
    if (exist) list.push(current);
    return list;
  }, []);
  return merge;
};
