import { Routes, Route } from 'react-router-dom';

import { PageNotFound } from 'app/PageNotFound';
import { MaterialsConfigContextProvider } from 'hooks-api/useMaterialsConfig';
import {
  ProjectInterfaceNavContent,
  FacilitiesInterfaceNavContent,
  ProjectPropertiesInterfaceNavContent,
  ProjectMembersInterfaceNavContent,
} from 'modules/Admin/AdminNavInterfaces';
import { AssemblyEditorNavContent, DataImportInterfaceNavContent } from 'modules/Materials/MaterialNavInterfaces';
import { ProviderMaterialBase } from 'modules/Materials/MaterialProviderRoutes';

import { ShopConfigProvider } from './providers/ShopConfigProvider';
import {
  ShopMembersInterfaceNavContent,
  Dashboard,
  WorkRequestsNavContent,
  WorkCellsAndMachinesInterfaceNavContent,
  WorkRequestNavContent,
  ShopPropertiesInterfaceNavContent,
  WorkCellsInterfaceNavContent,
  CatalogSetupInterfaceNavContent,
  WorkOrdersNavContent,
  WorkCellQueueNavContent,
  TaskViewerNavContent,
  DrawingRegisterInterfaceNavContent,
  WorkOrderItemsNavContent,
  CustomAssemblyNavContent,
  CustomAssemblyByIdNavContent,
  BillOfMaterialsInterfaceNavContent,
  BillOfMaterialsByIdInterfaceNavContent,
  InsightsNavContent,
} from './ShopNavInterfaces';

const ShopRoutes = () => (
  <ShopConfigProvider>
    <MaterialsConfigContextProvider>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="insights" element={<InsightsNavContent />} />
        <Route path="facilities" element={<FacilitiesInterfaceNavContent />} />
        <Route path="shop-members" element={<ShopMembersInterfaceNavContent />} />
        <Route path="shop-properties" element={<ShopPropertiesInterfaceNavContent />} />
        <Route path="projects" element={<ProjectInterfaceNavContent />} />
        <Route path="members/project-members" element={<ProjectMembersInterfaceNavContent />} />
        <Route path="properties/project-properties" element={<ProjectPropertiesInterfaceNavContent />} />
        <Route path="shop-setup/work-cell-setup" element={<WorkCellsAndMachinesInterfaceNavContent />} />
        <Route path="work-requests" element={<WorkRequestsNavContent />} />
        <Route path="work-requests/:id" element={<WorkRequestNavContent />} />
        <Route path="work-orders" element={<WorkOrdersNavContent />} />
        <Route path="work-orders/:id" element={<WorkOrderItemsNavContent />} />
        <Route path="catalog-setup" element={<CatalogSetupInterfaceNavContent />} />
        <Route path="data-import" element={<DataImportInterfaceNavContent />} />
        <Route path="work-cell-queue" element={<WorkCellQueueNavContent />} />
        <Route path="task-viewer/:id" element={<TaskViewerNavContent />} />
        <Route path="drawing-register" element={<DrawingRegisterInterfaceNavContent />} />
        <Route path="custom-assembly" element={<CustomAssemblyNavContent />} />
        <Route path="custom-assembly/:id" element={<CustomAssemblyByIdNavContent />} />
        <Route
          path="bill-of-materials"
          element={
            <ProviderMaterialBase>
              <BillOfMaterialsInterfaceNavContent />
            </ProviderMaterialBase>
          }
        />
        <Route
          path="bill-of-materials/:id"
          element={
            <ProviderMaterialBase>
              <BillOfMaterialsByIdInterfaceNavContent />
            </ProviderMaterialBase>
          }
        />
        <Route path="work-cells" element={<WorkCellsInterfaceNavContent />} />
        <Route
          path="assembly-editor"
          element={
            <ProviderMaterialBase>
              <AssemblyEditorNavContent />
            </ProviderMaterialBase>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </MaterialsConfigContextProvider>
  </ShopConfigProvider>
);

export default ShopRoutes;
