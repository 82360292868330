import { useState, useCallback, useMemo, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { EModuleType, MODULE_URL_MAPPING, MODULE_LABEL_MAPPING } from 'types/module';

const { CURRENT_MODULE, CURRENT_PAGE } = LOCAL_STORAGE_CONSTANTS;

const useSetupModule = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const persistedModule = localStorage.getItem(CURRENT_MODULE) as EModuleType;
  const [module, setModule] = useState<EModuleType | null>(persistedModule);
  const moduleNavigationName = useMemo(
    () =>
      pathname
        .substring(1, pathname.indexOf('/', 1) !== -1 ? pathname.indexOf('/', 1) : pathname.length)
        .toLocaleUpperCase() as EModuleType | '',
    [pathname],
  );
  const pageNavigationName = useMemo(
    () => pathname.split('/')[2] ?? moduleNavigationName,
    [pathname, moduleNavigationName],
  );

  useEffect(() => {
    if (moduleNavigationName && MODULE_LABEL_MAPPING[moduleNavigationName]) {
      setModule(moduleNavigationName);
      localStorage.setItem(CURRENT_MODULE, moduleNavigationName);
    }
  }, [moduleNavigationName]);

  useEffect(() => {
    localStorage.setItem(CURRENT_PAGE, pageNavigationName);
  }, [pageNavigationName]);

  const setSelectedModule = useCallback(
    (selectedModule: EModuleType) => {
      navigate(`/${MODULE_URL_MAPPING[selectedModule]}`);
    },
    [navigate],
  );

  const resetModule = () => {
    setModule(null);
    localStorage.removeItem(CURRENT_MODULE);
  };

  const currentModule = () => localStorage.getItem(CURRENT_MODULE) as EModuleType;
  const currentPage = () => localStorage.getItem(CURRENT_PAGE);

  return {
    module,
    setSelectedModule,
    resetModule,
    currentModule,
    currentPage,
  };
};

export default useSetupModule;
