import { useState } from 'react';

import { ActionIcon, FileButton, Flex, Menu, Overlay, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { compactRowHeight } from 'helpers/ag-grid/baseColumnDef';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import type { WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type { WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import { AddLinkModal } from './AddLinkModal';
import { FILE_TYPE_ACCEPT, useUploadDocuments } from './useUploadDocuments';

type Props = {
  section: 'Work Order' | 'Work Request' | 'Tasks';
  agGridProps: AgGridReactProps;
  title?: string;
} & (
  | { active?: false }
  | ({ active: true } & (
      | {
          section: 'Work Order';
          workOrder: WorkOrder;
          refreshWOdocs: () => void;
        }
      | {
          section: 'Work Request';
          workRequest: WorkRequest;
          refreshWRdocs: () => void;
        }
    ))
);

const baseGridProps: AgGridReactProps = {
  domLayout: 'autoHeight',
  rowHeight: compactRowHeight,
  headerHeight: 0,
  defaultColDef: {
    resizable: false,
    suppressMovable: true,
    sortable: false,
    contextMenuItems: [],
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
  },
};

export const AttachmentSection = ({ ...props }: Props) => {
  const [addingLink, setAddingLink] = useState(false);
  const { title, section, agGridProps, active } = props;
  const { uploadDocuments, saving } = useUploadDocuments();
  const onUpload = async (files: File[]) => {
    if (!props.active) return;
    const { projectName, projectId } = props.section === 'Work Order' ? props.workOrder.workRequest : props.workRequest;
    const project = { projectId, projectName };
    if (props.section === 'Work Order') {
      await uploadDocuments(files, { project, workOrder: props.workOrder }).finally(() => props.refreshWOdocs());
    } else if (props.section === 'Work Request') {
      await uploadDocuments(files, { project, workRequest: props.workRequest }).finally(() => props.refreshWRdocs());
    }
  };

  return (
    <div className="ag-theme-quartz small-table">
      <Dropzone
        activateOnKeyboard={false}
        activateOnClick={false}
        loading={saving}
        disabled={!active}
        onDrop={onUpload}
        accept={FILE_TYPE_ACCEPT}
        multiple
        styles={{
          root: {
            border: 'none',
            borderRadius: 10,
            cursor: 'initial',
            backgroundColor: 'inherit !important',
            overflow: 'hidden',
          },
          inner: { pointerEvents: 'all' },
        }}
        px={0}
        py="xs"
      >
        <Dropzone.Accept>
          <Overlay bg="rgba(229,219,255,0.75)">
            <Flex gap="sm" align="center" justify="center" style={{ height: '100%' }}>
              <EvolveIcon icon="Upload" dark />
              <Text fw={500}>Drag to upload</Text>
            </Flex>
          </Overlay>
        </Dropzone.Accept>
        <Dropzone.Reject>
          <Overlay bg="rgba(200,120,120,0.75)">
            <Flex gap="sm" align="center" justify="center" style={{ height: '100%' }}>
              <EvolveIcon icon="Close" dark />
              <Text fw={500}>Cannot accept files</Text>
            </Flex>
          </Overlay>
        </Dropzone.Reject>

        <Flex direction="column" mb={3}>
          <Flex gap="sm" align="center">
            <Text align="center" fw={500}>
              {title ?? section}
            </Text>
            {active && (
              <FileButton onChange={onUpload} accept={FILE_TYPE_ACCEPT.join(',')} multiple>
                {(fileButtonProps) => (
                  <Menu position="bottom-start" withinPortal zIndex={10002}>
                    <Menu.Target>
                      <ActionIcon variant="filled" size="xs" color="primary">
                        <EvolveIcon icon="Add" color="inherit" size="xs" />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item icon={<EvolveIcon icon="Attachment" color="inherit" />} {...fileButtonProps}>
                        Attach files
                      </Menu.Item>

                      <Menu.Item
                        onClick={() => setAddingLink(true)}
                        icon={<EvolveIcon icon="ExternalURL" color="inherit" />}
                      >
                        Add web link
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                )}
              </FileButton>
            )}
          </Flex>
          {active && (
            <Text size="xs" c="dimmed" mt={2}>
              Drag and drop or add attachments
            </Text>
          )}
        </Flex>
        <AgGridReact
          {...agGridProps}
          {...baseGridProps}
          noRowsOverlayComponent={() => <NoRowsOverlay label="No attachments found." align="flex-start" />}
        />
        {active && (
          <AddLinkModal
            opened={addingLink}
            onClose={() => setAddingLink(false)}
            {...('workRequest' in props
              ? {
                  refresh: () => props.refreshWRdocs(),
                  workRequest: props.workRequest,
                }
              : {
                  refresh: () => props.refreshWOdocs(),
                  workOrder: props.workOrder,
                })}
          />
        )}
      </Dropzone>
    </div>
  );
};
