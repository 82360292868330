import { useEffect } from 'react';

import { LoadingOverlay } from '@mantine/core';
import { Navigate } from 'react-router-dom';

import { useUser } from 'app/UserContext';
import getEnvVars from 'envVars';
import { isNotNil } from 'helpers/isNotNil';

import { Layout } from './Layout/Layout';
import { MessageAlertBannerProvider } from './Layout/MessageAlertBanner';

export const PrivateWrapper = () => {
  const { user, loading } = useUser();

  useEffect(() => {
    if (isNotNil(user)) {
      getEnvVars().then((env) => {
        // @ts-expect-error function is defined globally
        if (env.NULLSTONE_ENV === 'production') window.initializePendo(user);
      });
    }
  }, [user]);

  if (loading) return <LoadingOverlay visible />;

  if (!user) return <Navigate to="/auth/login" />;

  return (
    <MessageAlertBannerProvider>
      <Layout />
    </MessageAlertBannerProvider>
  );
};
