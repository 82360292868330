import { ComponentProps, useEffect, useMemo, useState } from 'react';

import { Loader, Tabs } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { AgGridReact } from 'ag-grid-react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import type { SelectedProject } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import { ProjectId, useProjects } from 'hooks/projectsAndFacilities/useProjects';
import { InviteUsersModal } from 'modules/Shop/ShopMembers/ShopMembersMantine/InviteUsersModal';
import type { UserId } from 'types/types-api';

import { getProjectAdminsColDefs } from './colDefs';
import { ProjectPropertiesForm } from './ProjectPropertiesForm';
import type { ProjectUser } from './types';

type Props = {
  selectedProject: SelectedProject;
};

const modalOptions = ['Remove admin'] as const;

const basePageHeaderTabs: Omit<Partial<ComponentProps<typeof BasePageHeader>>, 'title'> = {
  topLeftComponent: (
    <Tabs.List>
      <Tabs.Tab value="info">Info</Tabs.Tab>
      <Tabs.Tab value="admins">Admins</Tabs.Tab>
    </Tabs.List>
  ),
} as const;

const ProjectPropertiesPageWrapped = ({ selectedProject }: Props) => {
  const [selectedTab, setSelectedTab] = useState<'info' | 'admins'>('info');

  const [saving, setSaving] = useState(false);
  const [selectedRows, setSelectedRows] = useState<ProjectUser[]>([]);
  const [modalOpened, setModalOpened] = useState<typeof modalOptions[number] | null>(null);
  const { fetchPage, setDefaultOpts, searchHandler } = useWrappedPaginatedGet<ProjectUser>('admin/projectUser', {
    lazy: true,
  });
  const colDef = useMemo(() => getProjectAdminsColDefs(), []);
  const { agGridProps, refreshGrid, filterIsSet } = useServerSideGrid({
    tableName: 'project-admins',
    fetchPage,
    colDef,
    rowId: 'projectUserId',
  });
  useEffect(() => {
    if (isNotNil(selectedProject)) {
      setDefaultOpts({
        lazy: true,
        defaultConfig: {
          params: {
            projectId: selectedProject.id,
            isAdmin: true,
          },
        },
      });
    }
  }, [selectedProject, setDefaultOpts]);

  const { apiCall: removeProjectAdmin } = useWrappedPost<
    unknown,
    {
      userId: UserId;
      projectId: ProjectId;
      isAdmin: false;
    }
  >('admin/projectUser/:projectUserId');

  const onConfirmRemoveProjectAdmins = () => {
    setSaving(true);
    Promise.allSettled(
      selectedRows.map(({ userId }) =>
        removeProjectAdmin(
          {
            userId,
            isAdmin: false,
            projectId: selectedProject.id,
          },
          {
            url: `admin/projectUser`,
          },
        ).then(() => {
          notifications.show({
            title: 'Successfully removed admin',
            message: `User${selectedRows.length === 1 ? '' : 's'} removed as project admin${
              selectedRows.length === 1 ? '' : 's'
            }.`,
            color: 'green',
          });
        }),
      ),
    ).then(() => {
      setSaving(false);
      setSelectedRows([]);
      setModalOpened(null);
      agGridProps.ref.current?.api.deselectAll();
      refreshGrid();
    });
  };

  return (
    <>
      <Tabs
        value={selectedTab}
        onTabChange={(v: typeof selectedTab) => setSelectedTab(v)}
        keepMounted={false}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        styles={{
          tabsList: { flexWrap: 'nowrap' },
          tab: {
            width: 110,
            fontWeight: 600,
          },
          tabLabel: { fontWeight: 600 },
        }}
      >
        {selectedTab === 'info' ? (
          <BasePageHeader title="Project Properties" {...basePageHeaderTabs} />
        ) : (
          <BasePageHeader
            title="Project Properties"
            {...basePageHeaderTabs}
            gridRef={agGridProps.ref}
            filterIsSet={filterIsSet}
            topRightComponent={
              <InviteUsersModal
                pageType="project"
                companyId={selectedProject.companyId}
                projectId={selectedProject.id}
                refresh={refreshGrid}
                isAdmin
              />
            }
            bottomLeftComponent={
              <WrappedSelect
                disabled={selectedRows.length === 0}
                value={modalOpened}
                placeholder={selectedRows.length > 0 ? `Action (${selectedRows.length})` : 'Action'}
                data={modalOptions}
                onChange={setModalOpened}
              />
            }
            bottomRightComponent={
              <TextInputDebounced
                icon={<EvolveIcon icon="Search" color="inherit" />}
                onChange={searchHandler}
                placeholder="Search..."
              />
            }
          />
        )}
        <Tabs.Panel value="info">
          <ProjectPropertiesForm selectedProject={selectedProject} />
        </Tabs.Panel>
        <Tabs.Panel value="admins" style={{ flex: '1 1 auto' }}>
          <div className="ag-theme-quartz" style={{ height: '100%' }}>
            <AgGridReact<ProjectUser>
              {...agGridProps}
              rowSelection="multiple"
              rowMultiSelectWithClick
              noRowsOverlayComponent={() => <NoRowsOverlay label="No members found." gridRef={agGridProps.ref} />}
              onSelectionChanged={({ api }) => setSelectedRows(api.getSelectedRows())}
            />
          </div>
        </Tabs.Panel>
      </Tabs>
      <ConfirmationModal
        onConfirm={onConfirmRemoveProjectAdmins}
        loading={saving}
        onClose={() => setModalOpened(null)}
        opened={modalOpened === 'Remove admin'}
        buttonColor="red"
        description={`Are you sure you want to remove ${
          selectedRows.length === 1
            ? `${selectedRows[0].user.userEmail} as an admin for this project`
            : `${selectedRows.length} users as admins for this project`
        }?`}
      />
    </>
  );
};

export const ProjectPropertiesPage = (props: ComponentProps<typeof ProjectPropertiesPageWrapped>) => {
  const { projects, projTypes, depTypes } = useProjects();
  if (projects.length === 0 || isNil(projTypes) || isNil(depTypes)) return <Loader m="lg" />;
  return <ProjectPropertiesPageWrapped {...props} />;
};
