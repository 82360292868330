import { ReactNode } from 'react';

import { NoContentFallback } from 'components';
import { Custom } from 'components/Icons';
import {
  useSelectedProjectFacility,
  useSelectedFacilityShop,
} from 'hooks/projectsAndFacilities/useSelectedProjectFacility';

const ShopDepartmentFallback = ({ children }: { children: ReactNode }) => {
  const { selectedItem } = useSelectedProjectFacility();
  const shopDepartmentId = useSelectedFacilityShop();

  if (selectedItem?.type === 'FACILITY' && !shopDepartmentId) {
    return <NoContentFallback icon={Custom.Info} message="This facility does not have a Shop department." />;
  }

  return children;
};

export default ShopDepartmentFallback;
