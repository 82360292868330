import React from 'react';

import { Routes, Route } from 'react-router-dom';

import { PageNotFound } from 'app/PageNotFound';
import { ProjectInterfaceNavContent, FacilitiesInterfaceNavContent } from 'modules/Admin/AdminNavInterfaces';

import { Dashboard } from './ElectricalNavInterfaces';

const ElectricalRoutes = () => (
  <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path="projects" element={<ProjectInterfaceNavContent />} />
    <Route path="facilities" element={<FacilitiesInterfaceNavContent />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default ElectricalRoutes;
