/* eslint-disable no-console */
import { useEffect } from 'react';

import { Loader } from '@mantine/core';
import parse from 'html-react-parser';

import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { UserNameIdSymbol } from 'types/types-api';

type Props = {
  userName: UserNameIdSymbol | undefined;
  onEulaAccepted: () => void;
  onClose: () => void;
};

export const EULAModal = ({ userName, onEulaAccepted, onClose }: Props) => {
  const { apiCall: acceptEula, loading: saving } = useWrappedPatch('admin/user/username/:userName/acceptEula');
  const { data, apiCall, loading } = useWrappedGet<{ content: string }>('admin/eula/latest', { lazy: true });
  useEffect(() => {
    if (isNotNil(userName)) {
      apiCall();
    }
  }, [apiCall, userName]);

  const onSubmit = async () => {
    await acceptEula(
      {},
      {
        url: `admin/user/username/${userName}/acceptEula`,
      },
    );
    onEulaAccepted();
  };

  return (
    <ConfirmationModal
      opened={isNotNil(userName)}
      onClose={onClose}
      onConfirm={onSubmit}
      disabled={loading}
      loading={saving}
      confirmationText="Accept"
      cancellationText="Decline"
      title="End-User License Agreement"
      size="xl"
    >
      {isNotNil(data) ? parse(data.content) : <Loader />}
    </ConfirmationModal>
  );
};
