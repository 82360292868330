import React from 'react';

import { Space } from '@mantine/core';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PageBreadcrumb } from 'components/Mantine/Navigation/PageBreadcrumb';
import MiniSpinner from 'components/MiniSpinner';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';

import { useTaskViewerContext } from '../../TaskViewerContext';

const Header = ({ viewerRenderComplete, currentPage }) => {
  const { taskProperty, taskStatusTypeName, taskName, loadingTask, needBy } = useTaskViewerContext();

  return (
    <Stack direction="row" sx={{ mt: 0.5 }} alignItems="center">
      <PageBreadcrumb
        title={taskName}
        // noHistory
        parentPage={{
          title: 'Work Cell Queue',
          url: '/work-cell-queue',
        }}
        pageHasViewer
        viewerRenderComplete={viewerRenderComplete}
        currentPage={currentPage}
      />
      <Space mx="sm" />
      {!!taskStatusTypeName && <CellStatusBadge status={taskStatusTypeName} needBy={needBy} />}
      {loadingTask ? (
        <MiniSpinner sx={{ width: 50 }} size="16px" height="24px" />
      ) : (
        <Typography variant="subtitle1" style={{ marginLeft: 30 }}>
          {taskProperty?.workRequestItemName}
        </Typography>
      )}
    </Stack>
  );
};

export default Header;
