import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Checkbox, Flex } from '@mantine/core';
import type { KeyCreatorParams, ValueFormatterParams } from 'ag-grid-community';
import type { CustomFilterProps } from 'ag-grid-react';

import { strongIsEqual as isEqual } from 'helpers/strongEntries';

type Props<Opt, Val> = Omit<CustomFilterProps<Opt, Val>, 'model' | 'onModelChange'> & {
  model:
    | {
        filterType: 'set';
        values: Val[];
      }
    | undefined;
  onModelChange: (newModel: Props<Opt, Val>['model']) => void;
  values: Opt[];
  valueFormatter: (params: Omit<ValueFormatterParams<any, Opt>, 'node' | 'data'>) => string | null | undefined;
  keyCreator: (params: Omit<KeyCreatorParams<any, Opt>, 'node' | 'data'>) => Val;
  buttons?: ('apply' | 'reset')[];
  suppressSorting?: boolean;
};

/**
 * A Set Filter where a value can be an object, not just a string.
 * Expected to be used in tandem with `filterStatusConverter`
 *
 * Use via `filter: 'customValueSetFilter'` on `ColDef`
 */
export const CustomValueSetFilterComponent = <Opt, Val>({
  values,
  model,
  onModelChange,
  buttons,
  valueFormatter,
  keyCreator,
  suppressSorting,
  ...props
}: Props<Opt, Val>) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [selected, setSelected] = useState<Val[]>(model?.values ?? []);

  useEffect(() => {
    setSelected((s) => model?.values ?? s);
  }, [model?.values]);

  const onChange = useCallback((value: Val) => {
    setSelected((existingSelected) => {
      const index = existingSelected.findIndex((v) => isEqual(v, value));
      if (index >= 0) {
        return [...existingSelected.slice(0, index), ...existingSelected.slice(index + 1)];
      }
      return [...existingSelected, value];
    });
  }, []);

  const applyModel = useCallback(
    (newValues: typeof selected) => {
      onModelChange(
        newValues.length > 0 && newValues.length < values.length
          ? {
              filterType: 'set',
              values: newValues,
            }
          : // If no values (or all values) are selected, the model can just be `undefined`
            undefined,
      );
    },
    [onModelChange, values.length],
  );

  useEffect(() => {
    // If there is no `apply` button,
    // the model needs to be applied any time `selected` changes
    if (!buttons?.includes('apply')) {
      // If nothing (or everything) is selected,
      // we only want to update the model if the model isn't already `undefined`
      if (!((selected.length === 0 || selected.length === values.length) && model === undefined)) {
        applyModel(selected);
      }
    }
  }, [applyModel, buttons, model, selected, values.length]);

  const resetModel = useCallback(() => {
    setSelected([]);
    if (model) setTimeout(() => onModelChange(undefined));
  }, [model, onModelChange]);

  // The list of visible options the user can select from
  const options = useMemo(() => {
    const opts = values
      .map((value) => ({
        value: keyCreator({ value, ...props }),
        label: valueFormatter({ value, ...props }) ?? '',
      }))
      .filter(({ label }) => !searchPhrase || label.toLocaleLowerCase().includes(searchPhrase.toLocaleLowerCase()));
    return suppressSorting ? opts : opts.sort((a, b) => a.label.localeCompare(b.label));
  }, [keyCreator, props, searchPhrase, suppressSorting, valueFormatter, values]);
  // The list of selected options, filtered down to only ones that are visible
  const selectedFiltered = useMemo(
    () => selected.filter((v) => options.some(({ value }) => isEqual(v, value))),
    [options, selected],
  );

  return (
    <>
      <Box p="sm" pb={0}>
        <Flex direction="column" gap="md">
          <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
            <input
              placeholder="Search..."
              value={searchPhrase}
              onChange={(e) => setSearchPhrase(e.currentTarget.value)}
              type="text"
              className="ag-input-field-input ag-text-field-input"
              style={{ paddingLeft: 26, minWidth: 180 }}
            />
          </div>
          <Flex
            direction="column"
            gap={8}
            style={{
              overflowY: 'auto',
              height: 144,
            }}
          >
            <Checkbox
              label="(Select all)"
              indeterminate={selectedFiltered.length > 0 && selectedFiltered.length !== options.length}
              checked={selectedFiltered.length > 0}
              size="xs"
              styles={{ label: { fontSize: 14 } }}
              onChange={() => {
                // If something is selected, this Checkbox deselects whatever is selected
                if (selectedFiltered.length > 0) {
                  options.forEach(({ value }) => {
                    if (selectedFiltered.some((n) => isEqual(n, value))) {
                      onChange(value);
                    }
                  });
                }
                // If nothing is selected, this Checkbox selects everything
                else {
                  setSelected((s) => [...s, ...options.map(({ value }) => value)]);
                }
              }}
            />
            {options.map(({ label, value }) => (
              <Checkbox
                key={label}
                label={label}
                checked={selectedFiltered.some((n) => isEqual(n, value))}
                onChange={() => onChange(value)}
                size="xs"
                styles={{ label: { fontSize: 14 } }}
              />
            ))}
          </Flex>
        </Flex>
      </Box>
      <div className="ag-filter-apply-panel">
        {buttons?.includes('reset') && (
          <button
            className="ag-button ag-standard-button ag-filter-apply-panel-button"
            type="button"
            onClick={resetModel}
          >
            Reset
          </button>
        )}
        {buttons?.includes('apply') && (
          <button
            className="ag-button ag-standard-button ag-filter-apply-panel-button"
            type="button"
            onClick={() => applyModel(selected)}
          >
            Apply
          </button>
        )}
      </div>
    </>
  );
};
