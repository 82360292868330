import { useState } from 'react';

import { ActionIcon, Box, BoxProps, Divider, Flex, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import useBreakpoint from 'hooks/useBreakpoint';
import useSetupModule from 'hooks/useSetupModule';
import { MODULE_LABEL_MAPPING } from 'types/module';

import { EvolveLogoLink } from './components/EvolveLogoLink';
import { FacilitiesProjectsBreadcrumb } from './components/FacilitiesProjectsBreadcrumb';
import { HeaderSearch } from './components/HeaderSearchAutocomplete/HeaderSearch';
import { MailButton } from './components/MailButton';
import { ModuleIcon, ModuleSelectPopover } from './components/ModuleSelectPopover';
import { HelpDeskPopover } from './HelpDeskDropdown/HelpDeskDropdown';
import { ProfileDropdown } from './ProfileDropdown/ProfileDropdown';

export const DesktopAndTabletHeader = ({ noLicenses }: { noLicenses?: boolean }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const { isTablet } = useBreakpoint();

  return (
    <>
      <Flex align="center" justify="space-between" style={{ width: '100%' }}>
        {/* Left side of bar */}
        {!noLicenses && (
          <Flex align="center" gap="xl">
            {!isTablet && (
              <>
                <Box ml={8}>
                  <EvolveLogoLink />
                </Box>
                <Divider orientation="vertical" color="gray.7" />
              </>
            )}
            <ActiveModuleIconLabel />
            <Divider orientation="vertical" color="gray.7" />
            <FacilitiesProjectsBreadcrumb data-testid="header-breadcrumbs" />
          </Flex>
        )}

        {(isTablet || noLicenses) && <EvolveLogoLink />}

        {/* Right side of bar */}
        <Flex align="center" gap="sm">
          {!noLicenses && (
            <>
              {isTablet ? (
                <ActionIcon
                  color={searchOpen ? 'white' : undefined}
                  size="lg"
                  radius="xl"
                  variant="filled"
                  onClick={() => setSearchOpen((o) => !o)}
                >
                  <EvolveIcon icon="Search" color={searchOpen ? 'inherit' : undefined} />
                </ActionIcon>
              ) : (
                <Box style={{ width: '21rem' }}>
                  <HeaderSearch />
                </Box>
              )}
            </>
          )}
          <HelpDeskPopover />
          <MailButton />
          <ProfileDropdown />
          {!noLicenses && (
            <>
              <Divider orientation="vertical" mx="sm" color="gray.7" />
              <ModuleSelectPopover />
            </>
          )}
        </Flex>
      </Flex>
      {searchOpen && isTablet && (
        <SearchInputContainer>
          <Box style={{ width: 394 }}>
            <HeaderSearch />
          </Box>
        </SearchInputContainer>
      )}
    </>
  );
};

const ActiveModuleIconLabel = () => {
  const { currentModule } = useSetupModule();

  return (
    <Flex align="center">
      <ModuleIcon moduleType={currentModule()} size={24} />
      <Text ml={8} mr={4} style={{ userSelect: 'none' }}>
        {MODULE_LABEL_MAPPING[currentModule()]}
      </Text>
    </Flex>
  );
};

export const SearchInputContainer = ({ children, ...props }: BoxProps) => (
  <Box
    px="xs"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      backgroundColor: '#424242',
      position: 'absolute',
      height: '64px',
      top: '64px',
      left: 0,
      right: 0,
      zIndex: 10000,
    }}
    {...props}
  >
    {children}
  </Box>
);
