import React from 'react';

import { Routes, Route } from 'react-router-dom';

import { PageNotFound } from 'app/PageNotFound';
import { MaterialsConfigContextProvider } from 'hooks-api/useMaterialsConfig';
import {
  ProjectInterfaceNavContent,
  FacilitiesInterfaceNavContent,
  ProjectMembersInterfaceNavContent,
  ProjectPropertiesInterfaceNavContent,
} from 'modules/Admin/AdminNavInterfaces';
import {
  BillOfMaterialsByIdInterfaceNavContent,
  BillOfMaterialsInterfaceNavContent,
  TakeOffContent,
} from 'modules/Materials/MaterialNavInterfaces';
import { InsightsNavContent } from 'modules/Shop/ShopNavInterfaces';

import {
  WorkBreakDownStructureInterfaceNavContent,
  Dashboard,
  WorkPackagesInterfaceNavContent,
  LocationsAndWorkPhasesInterfaceNavContent,
  WorkRequestNavContent,
  FieldMembersInterfaceNavContent,
  WorkRequestsNavContent,
  DrawingRegisterInterfaceNavContent,
} from './FieldNavInterfaces';
import { ProjectProvider } from './LocationsAndWorkPhases/contexts/ProjectContext';

const FieldRoutes = () => (
  <ProjectProvider>
    <MaterialsConfigContextProvider>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="insights" element={<InsightsNavContent />} />
        <Route path="facilities" element={<FacilitiesInterfaceNavContent />} />
        <Route path="company/field-members" element={<FieldMembersInterfaceNavContent />} />
        <Route path="projects" element={<ProjectInterfaceNavContent />} />
        <Route path="members/project-members" element={<ProjectMembersInterfaceNavContent />} />
        <Route path="properties/project-properties" element={<ProjectPropertiesInterfaceNavContent />} />
        <Route path="project-setup/locations-and-work-phases" element={<LocationsAndWorkPhasesInterfaceNavContent />} />
        <Route path="project-setup/work-packages" element={<WorkPackagesInterfaceNavContent />} />
        <Route path="project-setup/work-breakdown-structures" element={<WorkBreakDownStructureInterfaceNavContent />} />
        {/* <Route path="work-requests" element={<WorkRequestsNavContent />} /> */}
        <Route path="bill-of-materials" element={<BillOfMaterialsInterfaceNavContent />} />
        <Route path="bill-of-materials/:id" element={<BillOfMaterialsByIdInterfaceNavContent />} />
        <Route path="takeoff" element={<TakeOffContent />} />
        <Route path="work-requests" element={<WorkRequestsNavContent />} />
        <Route path="work-requests/:id" element={<WorkRequestNavContent />} />
        <Route path="drawing-register" element={<DrawingRegisterInterfaceNavContent />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </MaterialsConfigContextProvider>
  </ProjectProvider>
);

export default FieldRoutes;
