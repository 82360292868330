import { forwardRef, useCallback, useMemo } from 'react';

import { useMantineTheme, MantineColor, Box, BoxProps, MantineSize } from '@mantine/core';

import { isNil } from 'helpers/isNotNil';

import { iconMap } from './icons';

export type EvolveIconName = keyof typeof iconMap;

const iconSizing: Record<MantineSize, number> = {
  xs: 12,
  sm: 18,
  md: 24,
  lg: 32,
  xl: 40,
} as const;

type Props = {
  /**
   * If the passed in icon name is null,
   * nothing will be rendered
   */
  icon: EvolveIconName | null;
  className?: string;
  inline?: boolean;
  boxProps?: BoxProps;
} & (
  | {
      size?: MantineSize;
      width?: never;
      height?: never;
    }
  | {
      size?: never;
      width?: number;
      height?: number;
    }
) &
  (
    | {
        dark?: boolean;
        color?: never;
      }
    | {
        dark?: never;
        color?: MantineColor;
      }
  );

/**
 * The string annotation for a Mantine color is 'color.index',
 * where index is between 0-9. For example, `red.7`
 *
 * When used inside a Mantine component, ie
 *
 *     <Typography color="red.7">Hello<Typography>
 *
 * the color is automatically applied. For non-Mantine components,
 * however, we need to pull the color out of the theme.
 * I'm not convinced this is the best way to do it... but it works.
 *
 * **FIXME:** We *really* should be using `parseThemeColor` instead,
 * but it's only available in Mantine 7+
 *
 *     const { value: mantineColor } = parseThemeColor(color);
 */
export const useMantineColor = (
  /** Optionally pass a color in to convert it. */
  defaultColor?: MantineColor,
) => {
  const theme = useMantineTheme();
  const convertMantineColor = useCallback(
    (color?: MantineColor) => {
      if (!color || color === 'inherit') return color;
      const [a, b] = color.split('.') ?? ['', '9'];
      const index = parseInt(b, 10);
      const mantineColor = a;
      const mantineShade = Number.isNaN(index) ? 9 : index;
      const parsedColor = mantineColor in theme.colors ? theme.colors[mantineColor][mantineShade] : color;
      return parsedColor;
    },
    [theme.colors],
  );

  const mantineColor = useMemo(() => convertMantineColor(defaultColor), [convertMantineColor, defaultColor]);

  return useMemo(
    () => ({
      mantineColor,
      /** Used to convert colors on the fly. */
      convertMantineColor,
    }),
    [convertMantineColor, mantineColor],
  );
};

export const EvolveIcon = forwardRef<HTMLDivElement, Props>(
  ({ icon, boxProps, inline = false, color = '#8A8A8A', dark, size = 'md', width, height, ...props }, ref) => {
    const sizing = {
      width: width ?? iconSizing[size],
      height: height ?? width ?? iconSizing[size],
    };
    const { mantineColor } = useMantineColor(color);
    if (isNil(icon)) return null;
    const Icon = iconMap[icon];
    return (
      <Box
        display={inline ? 'inline-flex' : 'flex'}
        style={{
          ...sizing,
          alignItems: 'center',
          justifyContent: 'center',
          verticalAlign: inline ? 'top' : undefined,
          ...boxProps?.style,
        }}
        {...boxProps}
        ref={ref}
      >
        <Icon color={mantineColor ?? (dark ? '#333' : color)} {...sizing} {...props} />
      </Box>
    );
  },
);
