import { useEffect, useState } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useUser } from 'app/UserContext';
import { getDocumentPresignedURL as GET_DOCUMENT_PRESIGNED_URL } from 'graphql/mutations';
import { documentById as DOCUMENT_BY_ID } from 'graphql/queries';

export default (documentId: string) => {
  const { user } = useUser();

  const [fetchDocument] = useLazyQuery(gql(DOCUMENT_BY_ID));
  const [getPresignedURL] = useMutation(gql(GET_DOCUMENT_PRESIGNED_URL));
  const [document, setDocument] = useState(null);
  const fetchFunction = async (id: string) => {
    if (!id) return null;
    const documentRes = await fetchDocument({
      variables: { params: { documentId: id } },
    });

    if (!documentRes?.data?.documentById) return null;

    const presignedURLResponse = await getPresignedURL({
      variables: {
        body: {
          expirationHours: 24,
          objectKey: `${documentRes?.data?.documentById.storagePath}/${documentRes.data.documentById.documentName}`,
          requestedBy: user?.userId,
          verb: 'GET',
        },
      },
    });
    const documentResponse = presignedURLResponse.data?.getDocumentPresignedURL?.preSignedURL;
    setDocument(documentResponse);
    return documentResponse;
  };
  useEffect(() => {
    if (documentId) fetchFunction(documentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);
  return { document, fetchFunction };
};
