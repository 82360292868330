import { ActionIcon } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useWrappedDelete } from 'hooks-api/useWrappedApiCall';
import type { Entitlement } from 'types/types-api';

type Props = {
  entitlement: Entitlement;
  refresh: () => void;
};

export const DeleteEntitlement = ({ entitlement, refresh }: Props) => {
  const { apiCall: deleteEntitlement, loading } = useWrappedDelete(`admin/entitlement/${entitlement.entitlementId}`);
  const onDelete = () => {
    deleteEntitlement().then(() => {
      notifications.show({
        title: 'Successfully removed',
        message: `${entitlement.productPool.label} license removed`,
        color: 'green',
      });
      refresh();
    });
  };
  return (
    <ActionIcon loading={loading} size="sm" onClick={onDelete}>
      <EvolveIcon className="show-on-row-hover" icon="Delete" color="inherit" />
    </ActionIcon>
  );
};
