import { useState } from 'react';

import { ActionIcon, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { Project, useProjects } from 'hooks/projectsAndFacilities/useProjects';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';

import { EditProjectLink } from './EditProjectLink';

type Props = {
  project: Project;
};

export const ModifyProjectMenu = ({ project }: Props) => {
  const { selectProject } = useSelectedProjectFacility();
  const { deleteProject, mutationRunning } = useProjects();
  const [deleting, setDeleting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onConfirm = () => {
    setDeleting(true);
    deleteProject(project.projectId)
      .then(() => {
        notifications.show({
          title: 'Successfully deleted',
          message: `Deleted ${project.projectName}`,
          color: 'green',
        });
        setModalOpen(false);
      })
      .finally(() => setDeleting(false));
  };

  return (
    <>
      <Menu withArrow position="bottom-end" disabled={mutationRunning} withinPortal>
        <Menu.Target>
          <ActionIcon variant="transparent">
            <EvolveIcon icon="MoreOptsVert" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => selectProject(project)}>Select Project</Menu.Item>
          <EditProjectLink>{(onClick) => <Menu.Item onClick={() => onClick(project)}>Edit</Menu.Item>}</EditProjectLink>
          <Menu.Item color="red" onClick={() => setModalOpen(true)}>
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <ConfirmationModal
        description={`Are you sure you want to delete ${project.projectName}?`}
        opened={modalOpen}
        onConfirm={onConfirm}
        onClose={() => setModalOpen(false)}
        loading={deleting}
        buttonColor="red"
      />
    </>
  );
};
