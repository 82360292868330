import { Fragment } from 'react';

import { Divider, Stack, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { isDateBeforeToday } from 'helpers/dateFunctions';

import WorkRequestStatus from '../WorkRequestStatus';
import { workOrder as WorkOrderType } from './WRflyoutTypes';

type props = {
  WorkRequestOrders: WorkOrderType[];
};
export const WorkOrdersDetails = ({ WorkRequestOrders }: props) => {
  const { palette } = useTheme();
  return (
    <>
      {WorkRequestOrders.map((workOrder: WorkOrderType) => {
        const hasDaysRemaining =
          workOrder?.workRequest?.needBy && isDateBeforeToday(dayjs(`${workOrder?.workRequest?.needBy}Z`));
        return (
          <Fragment key={workOrder.workOrderId}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                '& .link': {
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                    color: palette.secondary.mainDarkBackground,
                  },
                },
              }}
            >
              <Link
                to={`../work-orders/${workOrder.workOrderId}`}
                className="link"
                // state={{ workOrderId: workOrder.workOrderId, from: 'Work Requests' }}
              >
                <Typography variant="body2" color={palette.secondary.mainDarkBackground}>
                  {workOrder.workOrderName}
                </Typography>
              </Link>
              <Stack sx={{ height: '20px' }}>
                <WorkRequestStatus status={workOrder?.workOrderStatusTypeName} pastDaysRemaining={!!hasDaysRemaining} />
              </Stack>
            </Stack>
            <Divider variant="middle" sx={{ margin: '8px 0 8px 0', borderColor: palette.primary.light }} />
          </Fragment>
        );
      })}
    </>
  );
};
