import { ReactNode } from 'react';

import { useNavigate } from 'react-router-dom';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import type { Project } from 'hooks/projectsAndFacilities/useProjects';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import useSetupModule from 'hooks/useSetupModule';

type Props = {
  project?: Project;
  showSelected?: boolean;
  children: (onClick: (project: Project) => void) => ReactNode;
};

export const EditProjectLink = ({ project, showSelected = false, children }: Props) => {
  const navigate = useNavigate();
  const { currentModule } = useSetupModule();
  const { selectedItem, selectProject } = useSelectedProjectFacility();
  const module = currentModule() ?? 'admin';
  const onClick = (selectedProject: Project) => {
    selectProject(selectedProject);
    navigate(`/${module.toLocaleLowerCase()}/properties/project-properties`);
  };
  return (
    <>
      {children(onClick)}
      {showSelected && selectedItem?.id === project?.projectId && (
        <EvolveIcon icon="CheckCircle" color="green" size="sm" />
      )}
    </>
  );
};
