import { ComponentProps } from 'react';

import { Header, MantineProvider } from '@mantine/core';

import useBreakpoint from 'hooks/useBreakpoint';

import { DesktopAndTabletHeader } from './DesktopAndTabletHeader';
import { MobileHeader } from './MobileHeader/MobileHeader';

export const HeaderBar = (props: ComponentProps<typeof DesktopAndTabletHeader>) => {
  const { isMobile } = useBreakpoint();

  return (
    <Header
      height={64}
      data-cy="app-bar"
      px="xl"
      c="white"
      bg="black"
      withBorder={false}
      display="flex"
      style={{
        alignItems: 'center',
        position: 'fixed',
        // TODO: Remove once upgraded to Mantine 7
        zIndex: 200,
      }}
    >
      <MantineProvider theme={{ colorScheme: 'dark' }} inherit>
        {isMobile ? <MobileHeader /> : <DesktopAndTabletHeader {...props} />}
      </MantineProvider>
    </Header>
  );
};
