import { Flex } from '@mantine/core';
import type { ColDef } from 'ag-grid-community';
import { format, isValid, parseISO } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import { sortByStatusKey, workOrderStatusTypeNames } from 'constants/badgeMappingStatus';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { filterStatusConverter } from 'helpers/ag-grid/convertAgGridRequest';
import { isNotNil } from 'helpers/isNotNil';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';

import { AttachmentsPopover } from '../WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/AttachmentsPopover';
import { getDaysRemainingWorkOrder } from './common';
import { EditWorkOrderMenu } from './EditWorkOrderMenu';
import type { WorkOrder, WorkOrderStatusType } from './types';
import { WorkOrderActionButtons } from './WorkOrderActionButtons';

export const getWorkOrderColDef = (
  workOrderStatuses: WorkOrderStatusType[],
  setSelectedWorkOrder: (workOrder: WorkOrder | undefined) => void,
  showOpen: boolean,
): ColDef<WorkOrder>[] => [
  {
    ...lockedColDef(),
    cellRenderer: 'agGroupCellRenderer',
    width: 48,
  },
  {
    field: 'workOrderName',
    headerName: 'Work Order Id',
    cellRenderer: CellRenderer<WorkOrder>(({ value, data, node }) => (
      <EvolveLink
        to={`${data.workOrderId}`}
        from="Work Orders"
        onClick={(event) => {
          const isShortcutKeyPressed = event.ctrlKey || event.shiftKey || event.metaKey;
          // If a shortcut key is pressed, we can assume they're opening in a new tab,
          // so we shouldn't update the current tab by calling setSelectedWorkOrder
          if (!isShortcutKeyPressed) {
            setSelectedWorkOrder(node.data);
          }
        }}
      >
        <Flex align="center" gap="xs">
          <EvolveIcon icon="WorkOrder" />
          {value}
        </Flex>
      </EvolveLink>
    )),
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'workRequest.workRequestName',
    headerName: 'Work Order Name',
    flex: 1,
    minWidth: 220,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'workRequest.projectName',
    headerName: 'Project Name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    field: 'workOrderStatusTypeId',
    // Set so that the field rerenders if either of these fields changes,
    // the cell will rerender
    valueGetter: ({ data }) => `${data?.workOrderStatusTypeName}_${data?.workRequest.needBy}`,
    headerName: 'Status',
    minWidth: 120,
    width: 120,
    cellRenderer: CellRenderer<WorkOrder>(({ data }) => (
      <CellStatusBadge status={data.workOrderStatusTypeName} daysRemaining={getDaysRemainingWorkOrder(data)} />
    )),
    sortable: false,
    filter: showOpen ? 'customValueSetFilter' : false,
    filterParams: filterStatusConverter(
      workOrderStatuses.sort(sortByStatusKey(workOrderStatusTypeNames, 'workOrderStatusTypeName')),
      'workOrderStatusTypeId',
      'workOrderStatusTypeName',
    ),
  },
  {
    field: 'workRequest.needBy',
    headerName: 'Need By',
    width: 150,
    valueFormatter: (p) => {
      const date = parseISO(`${p.value}Z`);
      return isValid(date) ? format(date, 'MM/dd/yyyy') : '';
    },
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: ['inRange'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    headerName: showOpen ? 'Days Remaining' : 'Days Early/Late',
    headerComponent: () =>
      showOpen ? (
        'Days Remaining'
      ) : (
        <>
          <span>Days Early/</span>
          <span style={{ color: '#E03131' }}>Late</span>
        </>
      ),
    width: 150,
    valueGetter: ({ data }) => (isNotNil(data) ? getDaysRemainingWorkOrder(data) : ''),
    cellStyle: ({ value }) => ({
      ...(value < 0 ? { color: '#E03131' } : {}),
    }),
    valueFormatter: ({ value }) => (showOpen ? value : Math.abs(value)),
    sortable: false,
  },
  {
    field: 'workOrderDescription',
    tooltipField: 'workOrderDescription',
    headerName: 'Comments',
    sortable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      maxNumConditions: 1,
      buttons: ['reset'],
    },
  },
  {
    ...lockedColDef('right'),
    width: 80,
    cellRenderer: CellRenderer<WorkOrder>(({ data }) => <AttachmentsPopover workOrder={data} />, {
      flexProps: { justify: 'flex-end' },
    }),
  },
  {
    ...lockedColDef('right'),
    width: 150,
    cellRenderer: CellRenderer<WorkOrder>(
      ({ data, node }) => <WorkOrderActionButtons workOrder={data} onUpdate={(w) => node.updateData(w)} />,
      { flexProps: { justify: 'flex-end' } },
    ),
  },
  {
    ...lockedColDef('right'),
    width: 50,
    cellRenderer: CellRenderer<WorkOrder>(({ data, node, api }) => (
      <EditWorkOrderMenu
        startEditingWorkOrder={() => node.setSelected(true, true)}
        workOrder={data}
        refresh={() => api.refreshServerSide({ purge: true })}
      />
    )),
  },
];
