import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Loader, LoaderProps } from '@mantine/core';

export default function useOnScreen(ref: MutableRefObject<any>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), []);

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
}

export const TriggerOnVisible = ({
  loading = false,
  loaderProps,
  onVisible,
}: {
  loading?: boolean;
  loaderProps?: LoaderProps;
  onVisible: () => void;
}) => {
  const ref = useRef(null);
  const onScreen = useOnScreen(ref);
  useEffect(() => {
    if (onScreen && !loading) onVisible();
  }, [loading, onScreen, onVisible]);
  return <Box ref={ref}>{loading && <Loader variant="dots" m="xs" {...loaderProps} />}</Box>;
};
