import React from 'react';

import { Stack, IconButton } from '@mui/material';

import { IconStyled } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';

import { tooltipNames } from './TooltipNames';

const itemCounter = (config) => {
  const countActiveItems = (array = []) => array.filter((item) => !item.isDisabled)?.length;
  if (!config) return 0;
  if (config.staticList) return countActiveItems(config.staticList);
  if (config.dynamicList) return countActiveItems(config.dynamicList);
  return 0;
};

const IconsController = ({ onClick, sectionSelected, config, fixed, iconsContainerSx = {} }) => (
  <IconsContainer fixed={fixed} countItems={itemCounter(config)} sx={iconsContainerSx}>
    {config?.staticList &&
      config?.staticList.map((item) => (
        <BoxIcon
          isDisabled={item.isDisabled}
          key={item.section}
          icon={item.icon}
          isActive={item.section === sectionSelected}
          custom={item.custom}
          onClick={() => onClick(item.section)}
          data-testid={item.testId || `flyoutBtn-${item.section}`}
          tooltipTitle={tooltipNames[item.section]}
        />
      ))}
    {config?.dynamicList?.sections &&
      config?.dynamicList?.sections.map((item) => (
        <BoxIcon
          key={item.section}
          icon={item.icon}
          isActive={item.section === sectionSelected}
          onClick={() => onClick(item.section)}
          data-testid={item.testId || `flyoutBtn-static-${item.section}`}
          tooltipTitle={tooltipNames[item.section]}
        />
      ))}
  </IconsContainer>
);

export default IconsController;

const BoxIcon = ({ icon: Icon, isActive, custom = false, isDisabled, tooltipTitle, ...restProps }) => (
  <IconWrapper isActive={isActive}>
    <NavItemTooltip title={tooltipTitle}>
      <IconButton
        sx={{
          width: '100%',
          height: '100%',
          display: isDisabled ? 'none' : '',
          color: isActive ? 'white' : 'surface.darkSurface.disabled',
        }}
        disabled={isDisabled}
        {...restProps}
      >
        {custom ? (
          <IconStyled icon={Icon} color={isActive ? 'white' : 'surface.darkSurface.disabled'} />
        ) : (
          <Icon sx={{ fill: isActive ? 'white' : 'surface.darkSurface.disabled' }} color="disabled" />
        )}
      </IconButton>
    </NavItemTooltip>
  </IconWrapper>
);

const IconWrapper = ({ isActive, ...rest }) => (
  <Stack
    sx={{
      backgroundColor: isActive ? 'secondary.main' : '',
      path: {
        fill: isActive ? 'white' : '',
      },
      '&:hover': {
        backgroundColor: 'secondary.main',
        path: {
          fill: 'white',
        },
      },
      borderRadius: 2,
      justifyContent: 'center',
      height: '40px',
      alignItems: 'center',
    }}
    {...rest}
  />
);

const IconsContainer = ({ fixed, countItems = 0, sx, ...rest }) => (
  <Stack
    sx={{
      height: countItems * 40,
      width: 40,
      backgroundColor: 'other.navbarBackground',
      position: fixed ? 'fixed' : 'absolute',
      bottom: 4,
      right: 4,
      borderRadius: 2,
      zIndex: 2,
      ...sx,
    }}
    {...rest}
  />
);
