import React from 'react';

import { Tabs, useMantineTheme } from '@mantine/core';

import { useMantineColor } from 'assets/icons/EvolveIcon';

export type ShowOpen = 'open' | 'closed';

export type OpenClosedTabsProps = {
  showOpen: ShowOpen;
  setShowOpen: React.Dispatch<React.SetStateAction<ShowOpen>>;
};

export const OpenClosedTabs = ({ showOpen, setShowOpen }: OpenClosedTabsProps) => {
  const theme = useMantineTheme();
  const { mantineColor: activeTabColor } = useMantineColor(theme.primaryColor);
  return (
    <Tabs
      value={showOpen}
      onTabChange={(e) => {
        setShowOpen((o) => (e ?? o) as typeof showOpen);
      }}
      styles={{
        tabsList: { flexWrap: 'nowrap' },
        tab: {
          width: 110,
          fontWeight: 600,
        },
        tabLabel: { fontWeight: 600 },
      }}
    >
      <Tabs.List>
        <Tabs.Tab
          value="open"
          style={{
            color: showOpen === 'open' ? activeTabColor : theme.colors.gray[6],
          }}
        >
          Open
        </Tabs.Tab>
        <Tabs.Tab
          value="closed"
          style={{
            color: showOpen === 'closed' ? activeTabColor : theme.colors.gray[6],
          }}
        >
          Closed
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};
