import { useEffect, useState } from 'react';

import type { AdditionalRequestConfig } from 'hooks-api/useEvolveApi';
import type { PageFetcher } from 'hooks-api/useWrappedApiCall';

const getAllDataFromFetcherImpl = async <T>(
  pageFetcher: PageFetcher<T>,
  config?: AdditionalRequestConfig<T>,
  existingData: T[] = [],
): Promise<T[]> => {
  const { entireCount, data } = await pageFetcher(
    { skip: existingData.length, take: 200, throwOnMoreRecent: true },
    config,
  );
  const allData = [...existingData, ...data];
  if (entireCount > allData.length) {
    return getAllDataFromFetcherImpl(pageFetcher, config, allData);
  }
  return allData;
};

/**
 * Used to get ALL the data from a particular paginated endpoint
 * and store it in a single array.
 *
 * Generally, this indicates a bad practice, and the design
 * should be reworked to avoid this when possible.
 */
export const getAllDataFromFetcher = async <T>(
  pageFetcher: PageFetcher<T>,
  config?: AdditionalRequestConfig<T>,
): Promise<T[]> => getAllDataFromFetcherImpl(pageFetcher, config, []);

export const useGetAllDataFromFetcher = <T>(
  pageFetcher: PageFetcher<T>,
  initialConfig?: AdditionalRequestConfig<T>,
  shouldWait = false,
) => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(!shouldWait);
  const [config, setConfig] = useState(initialConfig);
  const [wait, setWait] = useState(shouldWait);
  useEffect(() => {
    if (wait) return;
    setLoading(true);
    setData([]);
    getAllDataFromFetcher(pageFetcher, config)
      .then(setData)
      .finally(() => setLoading(false));
  }, [config, pageFetcher, wait]);
  return [data, loading, { setConfig, setWait }] as const;
};
