import { useMemo } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import {
  addBillOfMaterialLineItemByLBS as CREATE_LBS,
  patchBillOfMaterialLineItemByLBS as PATCH_LBS,
  patchBulkBillOfMaterialLineItemByLBS as PATCH_BULK_LBS,
  patchBulk2BillOfMaterialLineItemByLBS as PATCH_BULK_LBS_2,
} from 'graphql/mutations';
import { billOfMaterialLineItemByLBS as RETRIEVE_LBS } from 'graphql/queries';
import { BulkAmount, BulkLBSResponseInput } from 'graphql/types';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

interface IBody {
  ern: string;
  locationId: string | null;
  workPhaseId: string | null;
  fetchPolicy: string;
  includeZeroAmounts: boolean;
}

const useLBSAPI = (
  ern = 'ern:moab:takeoffpaditem:',
  locationId: string | null = null,
  workPhaseId: string | null = null,
  includeZeroAmounts = true,
  fetchPolicy = 'cache-and-network',
) => {
  const { handleResponse } = useGraphqlResponseHandler();

  const body = useMemo((): Partial<IBody> => {
    const object: Partial<IBody> = {};

    if (ern) object.ern = ern;
    if (locationId) object.locationId = locationId;
    if (workPhaseId) object.workPhaseId = workPhaseId;

    object.includeZeroAmounts = includeZeroAmounts;

    return object;
  }, [ern, locationId, workPhaseId, includeZeroAmounts]);

  const [fetchRetrieveLBS, { data, loading, called }] = useLazyQuery(gql(RETRIEVE_LBS), {
    fetchPolicy: fetchPolicy as any,
    variables: { query: body },
  });

  const [fetchCreateLBSMutation, { loading: loadingCreteLBS }] = useMutation(gql(CREATE_LBS), {
    refetchQueries: [{ query: gql(RETRIEVE_LBS), variables: { query: body } }],
  });

  const [fetchPatchLBSMutation, { loading: loadingPatchLBS }] = useMutation(gql(PATCH_LBS), {
    refetchQueries: [{ query: gql(RETRIEVE_LBS), variables: { query: body } }],
  });

  const [fetchPatchBulkLBSMutation, { loading: loadingPatchBulkLBS }] = useMutation(gql(PATCH_BULK_LBS));
  const [fetchPatchBulk2LBSMutation, { loading: loadingPatchBulk2LBS }] = useMutation(gql(PATCH_BULK_LBS_2));

  const handleFetchRetrieveBS = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    locationId: string | null,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    workPhaseId: string | null,
    onCompleted: (response: any) => void = () => {},
  ) => {
    const query: { [key: string]: string } = {};

    if (locationId) query.locationId = locationId;
    if (workPhaseId) query.workPhaseId = workPhaseId;

    return fetchRetrieveLBS({
      variables: { query: { ern, ...query } },
      onCompleted,
    });
  };

  const handleFetchCreateLBS = (amount: number, onCompleted: (response: any) => void = () => {}) =>
    fetchCreateLBSMutation({ variables: { body: { ...body, amount } }, onCompleted });

  const handleFetchPatchLBS = (amount: number, onCompleted: (response: any) => void = () => {}) => {
    handleResponse(
      fetchPatchLBSMutation,
      { variables: { body: { ...body, amount, operation: 'Set' } } },
      { successMessage: '', errorMessage: '' },
      onCompleted,
      () => {
        if (amount > 0) handleFetchCreateLBS(amount, onCompleted);
      },
    );
  };

  const handleFetchPatchBulkLBS = async (lbs: BulkLBSResponseInput[]) =>
    fetchPatchBulkLBSMutation({ variables: { body: { ern: body.ern, lbs, operation: 'Set' } } });

  const handleFetchPatchBulk2LBS = async (amounts: BulkAmount[], customVariables: Record<string, string>) =>
    fetchPatchBulk2LBSMutation({
      variables: { body: { bulk: [{ ern: body.ern, amounts }] } },
      ...customVariables,
    });

  return {
    fetchRetrieveLBS,
    data: data?.billOfMaterialLineItemByLBS || {},
    loading,
    handleFetchCreateLBS,
    loadingCreteLBS,
    handleFetchPatchLBS,
    loadingPatchLBS,
    handleFetchPatchBulkLBS,
    loadingPatchBulkLBS,
    handleFetchPatchBulk2LBS,
    loadingPatchBulk2LBS,
    called,
    handleFetchRetrieveBS,
  };
};

export default useLBSAPI;
