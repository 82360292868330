import { useState } from 'react';

import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

import { isNil } from 'helpers/isNotNil';
import useSetupModule from 'hooks/useSetupModule';

import { CancelWorkOrderModal } from '../WorkOrder/WorkOrderItemsPage/CancelWorkOrder/CancelWorkOrderModal';
import { canEditWorkOrder } from './common';
import type { WorkOrder } from './types';
import { useWorkOrders } from './useWorkOrders';

type Props = {
  workOrder: WorkOrder;
  /**
   * Callback after an update completes.
   * @param workOrder the updated `WorkOrder`
   * */
  onUpdate: (workOrder: WorkOrder) => void;
  /**
   * Whether or not these Actions are inline in a table.
   * @default true
   * */
  inline?: boolean;
};

export const WorkOrderActionButtons = ({ workOrder, onUpdate, inline = true }: Props) => {
  const navigate = useNavigate();
  const { currentModule } = useSetupModule();
  const module = (currentModule() ?? 'shop').toLocaleLowerCase();

  const [updating, setUpdating] = useState(false);
  const { workOrderStatuses, updateWorkOrderStatus, mutationRunning } = useWorkOrders();

  if (workOrder.workOrderStatusTypeName === 'Completed' || isNil(workOrderStatuses)) return null;

  const canRelease = canEditWorkOrder(workOrder.workOrderStatusTypeName, 'release');
  if (!canRelease) {
    // Cancel is only available on the WO page, not the inline table listing
    if (inline) return null;
    return <CancelWorkOrderModal workOrder={workOrder} onUpdate={onUpdate} disabled={updating} />;
  }

  const onSubmit = () => {
    const releaseStatus = workOrderStatuses.find(
      (s) => s.workOrderStatusTypeName === 'Not Started',
    )?.workOrderStatusTypeId;
    if (isNil(releaseStatus)) {
      notifications.show({
        title: 'Something went wrong',
        message: `Could not find 'Not Started' status.`,
        color: 'red',
      });
      return;
    }
    setUpdating(true);
    updateWorkOrderStatus(workOrder.workOrderId, releaseStatus)
      .then((updatedWorkOrder) => {
        const { stepFunctionProcessingMessage } = updatedWorkOrder;
        if (isNil(stepFunctionProcessingMessage)) {
          notifications.show({
            color: 'green',
            title: 'Successfully updated',
            message: `Released work order ${workOrder.workOrderName}`,
          });
        } else {
          notifications.show({
            color: 'red',
            message: stepFunctionProcessingMessage,
          });
        }
        return updatedWorkOrder;
      })
      .then(onUpdate)
      .then(() => navigate(`/${module}/work-orders`))
      .finally(() => setUpdating(false));
  };

  return (
    <Button
      loading={updating}
      compact={inline}
      size="sm"
      onClick={onSubmit}
      disabled={mutationRunning && !updating}
      variant={inline ? 'outline' : undefined}
    >
      Release
    </Button>
  );
};
