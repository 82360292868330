import React from 'react';

import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useCatalogPartContext } from 'modules/Materials/CatalogSetup/Providers/CatalogPartProvider';

import PartAdditionalImages from './PartAdditionalImage/PartAdditionalImage';
import PartAdditionalImageLoader from './PartAdditionalImageLoader/PartAdditionalImageLoader';
import PartDefaultImage from './PartDefaultImage/PartDefaultImage';
import { usePartImageContext } from './PartImageContext';
import PartImageErrorMessage from './PartImageErrorMessage';

const PartImage = () => {
  const {
    watch,
    control,
    setValue,
    getValues,
    formState: { isSubmitting },
    disabled,
  } = useFormContext();

  const { setError } = useCatalogPartContext();
  const {
    error,
    partDefaultImage,
    uploadingProgress,
    uploadingPartDefaultImage,
    uploadingPartAdditionalImages,
    partAdditionalImages,
  } = usePartImageContext();

  return (
    <Stack direction="column" margin="16px 24px">
      <PartDefaultImage
        control={control}
        setValue={setValue}
        getValues={getValues}
        isSubmitting={isSubmitting || disabled}
        partDefaultImage={partDefaultImage}
        uploadingProgress={uploadingProgress}
        uploadingPartDefaultImage={uploadingPartDefaultImage}
        setError={setError}
        partAdditionalImages={partAdditionalImages}
      />

      <Stack direction="row" gap={1}>
        <PartAdditionalImages
          control={control}
          setValue={setValue}
          watch={watch}
          getValues={getValues}
          isSubmitting={isSubmitting || disabled}
          partAdditionalImages={partAdditionalImages}
          setError={setError}
        />
      </Stack>

      <Typography
        fontWeight="400"
        color="rgba(0, 0, 0, 0.5)"
        fontSize={12}
        width={169}
        height={113}
        sx={{ lineHeight: '24px', letterSpacing: '0.4px', marginTop: '10px' }}
      >
        Select the image you want to upload. Images should not be more than 500KB in size. Only GIF, PNG, JPEG, HEIC
        files are accepted.
      </Typography>

      <PartAdditionalImageLoader
        uploadingProgress={uploadingProgress}
        uploadingPartAdditionalImages={uploadingPartAdditionalImages}
      />

      <PartImageErrorMessage message={error} />
    </Stack>
  );
};
export default PartImage;
