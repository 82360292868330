import './kanban.css';
import { useEffect, useState } from 'react';

import { ActionIcon, Avatar, Badge, Button, Card, Flex, Loader, Popover, Space, Text } from '@mantine/core';
import { format } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import { SearchableMultiSelect } from 'components/Mantine/SearchableMultiSelect';
import { WrappedMultiSelect } from 'components/Mantine/TypeSafeSelect';
import type { WorkOrderStatusTypeName } from 'constants/badgeMappingStatus';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { Project } from 'hooks/projectsAndFacilities/useProjects';
import { TriggerOnVisible } from 'hooks/useOnScreen';
import type { Task } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';
import { WorkCellTaskLink } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/columnDefs';
import type { WorkCell } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';
import { useTasks } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/useTasks';
import { ShopKanbanCard } from 'modules/Shop/WorkOrders/WorkOrdersPage/KanbanCard';
import { WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import type { WorkCellTaskKanban } from './types';
import type { WorkCellsProps } from './WorkCellsPage';

const TaskPredecssorPopover = ({ task }: { task: WorkCellTaskKanban }) => {
  const { taskStatusTypes } = useTasks();
  const { data, setDefaultOpts, loading } = useWrappedGet<Task>(`shop/task/${task.taskId}`, { lazy: true });
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    if (opened && isNil(data)) {
      setDefaultOpts({
        lazy: false,
      });
    }
  }, [data, opened, setDefaultOpts]);
  return (
    <Popover opened={opened} onChange={setOpened} withinPortal withArrow position="top" shadow="sm">
      <AgGridStyleTooltip
        openDelay={0}
        label="This task has an incomplete predecessor. Click to see the list of predecessors."
        style={{ maxWidth: 280 }}
        ta="center"
        withArrow
        opened={opened ? false : undefined}
      >
        <Popover.Target>
          <Badge
            size="lg"
            px={4}
            radius="sm"
            color="gray.4"
            c="gray"
            variant="outline"
            onClick={(e) => {
              setOpened((o) => !o);
              e.stopPropagation();
            }}
          >
            <EvolveIcon icon="Blocked" size="sm" />
          </Badge>
        </Popover.Target>
      </AgGridStyleTooltip>
      <Popover.Dropdown style={{ minWidth: 250 }} onClick={(e) => e.stopPropagation()}>
        <ActionIcon onClick={() => setOpened(false)} style={{ position: 'absolute', right: 5, top: 5 }}>
          <EvolveIcon icon="Close" size="sm" color="dark" />
        </ActionIcon>
        <Text fw={500} mb="xs">
          Predecessors
        </Text>
        <Flex
          direction="column"
          gap="xs"
          pr="xs"
          style={{
            maxHeight: 200,
            overflowY: 'auto',
          }}
        >
          {data?.taskPredecessorIds.map((p) => (
            <Flex key={p.taskId} justify="space-between" align="center" gap="xl">
              <Text
                fz="sm"
                title={p.taskName}
                style={{
                  maxWidth: 150,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {p.taskName}
              </Text>
              <CellStatusBadge
                status={
                  taskStatusTypes?.find((t) => t.taskStatusTypeId === p.taskStatusTypeId)?.taskStatusTypeName ??
                  'Not Started'
                }
              />
            </Flex>
          ))}
        </Flex>
        {loading && <Loader variant="dots" my={4} />}
      </Popover.Dropdown>
    </Popover>
  );
};

const TaskKanbanCard = ({ task }: { task: WorkCellTaskKanban }) => (
  <ShopKanbanCard
    currentPage="Work Cells"
    cardDestination={`/shop/task-viewer/${task.taskId}`}
    {...task}
    status={task.taskStatusTypeName}
    includeStatusBadge={task.isEnabled}
    cardProps={{
      style: {
        cursor: 'pointer',
      },
      opacity: task.isEnabled ? 1 : 0.6,
    }}
    footer={!task.isEnabled ? <TaskPredecssorPopover task={task} /> : null}
  >
    <EvolveLink className="kanban-work-order-link" to={`/shop/work-orders/${task.workOrderId}`} from="Work Cells">
      <Text display="inline" lineClamp={2} fw={500}>
        {task.workRequestName}
      </Text>
    </EvolveLink>
    <Space />
    <WorkCellTaskLink
      task={task}
      from="Work Cells"
      linkProps={{
        className: task.isEnabled ? 'kanban-work-order-link' : undefined,
      }}
      hideTooltip
    >
      <Text c="dimmed" lineClamp={2} size="sm">
        {(task.taskStatusTypeName === 'Started' || task.taskStatusTypeName === 'Paused') && (
          <AgGridStyleTooltip
            openDelay={100}
            label={task.taskStatusTypeName === 'Started' ? 'Task is running' : 'Task is paused'}
            withArrow
          >
            <EvolveIcon
              boxProps={{ mt: 1, mr: 3, ml: -3 }}
              icon={task.taskStatusTypeName === 'Started' ? 'Play' : 'Pause'}
              size="sm"
              color="inherit"
              inline
            />
          </AgGridStyleTooltip>
        )}
        {task.taskName}
      </Text>
    </WorkCellTaskLink>
  </ShopKanbanCard>
);

const titleHeight = 30;

const NumberAndCollapseIndicator = ({
  collapsed,
  setCollapsed,
  entireCount,
  rotate,
}: {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  entireCount: number | undefined;
  rotate?: boolean;
}) => (
  <Flex
    justify="center"
    align="center"
    ml={rotate ? -titleHeight : undefined}
    style={{ width: titleHeight, height: titleHeight, transform: rotate ? 'rotate(-90deg)' : undefined }}
  >
    <AgGridStyleTooltip label={collapsed ? 'Expand' : 'Collapse'} withArrow>
      <ActionIcon className="hover-title--on" size="sm" variant="light" onClick={() => setCollapsed(!collapsed)}>
        <EvolveIcon size="sm" icon={collapsed ? 'ExpandPane' : 'CollapsePane'} color="inherit" />
      </ActionIcon>
    </AgGridStyleTooltip>
    <Avatar className="hover-title--off" radius="100%" size="sm">
      {isNotNil(entireCount) ? (
        <Text fz="sm" c={(entireCount ?? 0) > 0 ? 'dark' : undefined}>
          {entireCount}
        </Text>
      ) : (
        <Loader size="xs" />
      )}
    </Avatar>
  </Flex>
);

const WorkCellKanbanLane = ({
  workCell: { workCellId, workCellName },
  selectedWorkOrders,
  selectedProjects,
}: {
  workCell: Pick<WorkCell, 'workCellId' | 'workCellName'>;
  selectedWorkOrders: WorkOrder[];
  selectedProjects: Project[];
}) => {
  const {
    data: tasks,
    loading,
    fetchNextPage,
    entireCount,
    setDefaultOpts,
  } = useWrappedPaginatedGet<WorkCellTaskKanban>(`shop/workCell/${workCellId}/kanban`, { lazy: true });

  const [collapsed, setCollapsed] = useState(true);
  const cardWidth = collapsed ? titleHeight : 320;

  useEffect(() => {
    setCollapsed(true);
    setDefaultOpts({
      defaultConfig: {
        params:
          selectedWorkOrders.length > 0
            ? {
                workOrderIds: selectedWorkOrders.map((w) => w.workOrderId).join(','),
              }
            : {
                projectIds: selectedProjects.map((p) => p.projectId).join(','),
              },
      },
    });
  }, [selectedProjects, selectedWorkOrders, setDefaultOpts]);

  useEffect(() => {
    if (!loading && isNotNil(entireCount)) {
      setCollapsed(entireCount === 0);
    }
  }, [entireCount, loading]);

  return (
    <Flex direction="column" style={{ height: '100%', width: cardWidth }}>
      <Flex mb={4} style={{ maxWidth: cardWidth }}>
        <Flex
          className="hover-title"
          align="center"
          justify="space-between"
          gap="sm"
          onClick={collapsed ? () => setCollapsed(!collapsed) : undefined}
          style={{
            height: titleHeight,
            width: collapsed ? undefined : '100%',
            transformOrigin: 'left bottom',
            transform: collapsed ? 'rotate(90deg)' : undefined,
          }}
        >
          {collapsed && (
            <NumberAndCollapseIndicator
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              entireCount={entireCount}
              rotate
            />
          )}
          <Text
            fw={500}
            c={collapsed && (entireCount ?? 0) === 0 ? 'dimmed' : undefined}
            title={workCellName}
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              userSelect: 'none',
            }}
          >
            {workCellName}
          </Text>
          {!collapsed && (
            <NumberAndCollapseIndicator collapsed={collapsed} setCollapsed={setCollapsed} entireCount={entireCount} />
          )}
        </Flex>
      </Flex>
      {!collapsed && (
        <Card
          withBorder
          display="flex"
          radius="md"
          bg="gray.0"
          p="xs"
          pb={0}
          style={{
            width: cardWidth,
            flexDirection: 'column',
            flex: '1 0 0',
          }}
        >
          <Flex direction="column" style={{ flex: '1 1 auto', overflowY: 'auto', scrollbarWidth: 'none' }}>
            {tasks.map((task) => (
              <TaskKanbanCard key={task.taskId} task={task} />
            ))}
            <Flex justify="center">
              <TriggerOnVisible loading={loading} onVisible={fetchNextPage} />
            </Flex>
          </Flex>
        </Card>
      )}
    </Flex>
  );
};

const workOrderStatusesToDisplay: WorkOrderStatusTypeName[] = ['Not Started', 'In Progress'] as const;

export const WorkCellsKanbanView = (props: WorkCellsProps) => {
  const { selectedFacility, workCellsList, workOrderStatuses } = props;
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const { workCells, loading, refetch } = workCellsList;

  const [selectedWorkOrders, setSelectedWorkOrders] = useState<WorkOrder[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);

  const {
    data: projects,
    setDefaultOpts: setDefaultOptsProjects,
    loading: loadingProjects,
    apiCall: refreshProjects,
  } = useWrappedGet<Project[]>('shop/project/projectsForActiveTasks', { lazy: true });
  useEffect(() => {
    setDefaultOptsProjects({
      lazy: false,
      defaultConfig: {
        params: {
          facilityId: selectedFacility.id,
        },
      },
    });
  }, [selectedFacility.id, setDefaultOptsProjects]);

  const { setDefaultOpts, ...getWorkOrders } = useWrappedPaginatedGet<WorkOrder>('shop/workOrder', {
    lazy: true,
  });
  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          facilityId: selectedFacility.id,
          hasShopTask: true,
          projectIds: selectedProjects.map((p) => p.projectId).join(','),
          workOrderStatusIds: workOrderStatuses
            .filter((s) => workOrderStatusesToDisplay.includes(s.workOrderStatusTypeName))
            .map((s) => s.workOrderStatusTypeId)
            .join(','),
        },
      },
    });
  }, [setDefaultOpts, selectedFacility.id, selectedProjects, workOrderStatuses]);

  const filterIsSet = selectedWorkOrders.length > 0 || selectedProjects.length > 0;
  return (
    <>
      <BasePageHeader
        title="Work Cells"
        viewsToShow={['list', 'kanban']}
        topLeftComponent={
          <>
            <ActionIcon
              size="lg"
              variant="outline"
              onClick={() => {
                refetch();
                refreshProjects();
                setLastRefresh(new Date());
              }}
              disabled={loading}
            >
              <EvolveIcon icon="Refresh" color="inherit" />
            </ActionIcon>
            <Text c="dimmed" fz="sm">
              Last refresh: {format(lastRefresh, 'p')}
            </Text>
          </>
        }
        topRightComponent={
          <>
            <Button
              variant="subtle"
              onClick={() => {
                if (filterIsSet) {
                  setSelectedProjects([]);
                  setSelectedWorkOrders([]);
                }
              }}
              compact
              leftIcon={<EvolveIcon icon="ClearFilters" size="sm" color="inherit" />}
              fz="xs"
              style={{
                cursor: filterIsSet ? 'pointer' : 'initial',
                opacity: filterIsSet ? 1 : 0,
                transition: '.1s ease-out',
              }}
            >
              Clear filters
            </Button>
            <WrappedMultiSelect
              placeholder="Search for projects..."
              data={
                projects?.map((p) => ({
                  label: p.projectName,
                  value: p.projectId,
                })) ?? []
              }
              nothingFound={loadingProjects ? 'Loading...' : undefined}
              value={selectedProjects.map((p) => p.projectId)}
              onChange={(ids) => setSelectedProjects((ps) => projects?.filter((p) => ids.includes(p.projectId)) ?? ps)}
              style={{ minWidth: 250, maxWidth: 300 }}
              searchable
              clearable
              onBlur={() => {
                if (selectedProjects.length > 0) {
                  setSelectedWorkOrders((wos) => {
                    const newWos = wos.filter((wo) =>
                      selectedProjects.some((p) => wo.workRequest.projectId === p.projectId),
                    );
                    return newWos.length < wos.length ? newWos : wos;
                  });
                }
              }}
            />
            <SearchableMultiSelect
              placeholder="Search for work orders..."
              paginatedGet={getWorkOrders}
              getItemLabel={(w) => w.workOrderName}
              idKey="workOrderId"
              searchKey="workOrderName"
              value={selectedWorkOrders}
              onChange={setSelectedWorkOrders}
              style={{ minWidth: 250, maxWidth: 300 }}
              nothingFound={
                selectedProjects.length > 0
                  ? `No results in selected project${selectedProjects.length === 1 ? '' : 's'}`
                  : undefined
              }
              getGroup={() =>
                selectedProjects.length > 0
                  ? `In selected project${selectedProjects.length === 1 ? '' : 's'}`
                  : undefined
              }
              clearable
            />
          </>
        }
      />
      <Flex gap="xl" pb={4} style={{ height: '100%', overflowX: 'auto' }}>
        {!loading ? (
          workCells?.map((w) => (
            <WorkCellKanbanLane
              key={w.workCellId}
              workCell={w}
              selectedWorkOrders={selectedWorkOrders}
              selectedProjects={selectedProjects}
            />
          ))
        ) : (
          <Flex align="center" justify="center" style={{ flex: '1 0 auto' }}>
            <Loader variant="bars" m="xl" />
          </Flex>
        )}
      </Flex>
    </>
  );
};
