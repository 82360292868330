import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { UTCFORMATDAYJS } from 'constants/globalConstants';

import { isNil } from './isNotNil';

dayjs.extend(utc);

// return a date with the format yyyy-mm-dd
export const formatToBackendDateString = (date: Date) => date.toISOString().slice(0, 10);

export const getNextDays = (days = 0) => {
  const today = new Date();
  return today.setDate(today.getDate() + days);
};

export const TODAY = getNextDays();
export const YESTERDAY = getNextDays(-1);
export const TOMORROW = getNextDays(1);

export const addInitialZero = (value: string) => (Number(value) < 10 ? `0${value}` : value);

export const getParsedDate = (timestamp: string) => {
  if (!timestamp) return null;
  return dayjs(`${timestamp}Z`).local().format('MM/DD/YYYY');
};

export const getDatePartFromDateTime = (datetime: string) => {
  if (!datetime) return null;
  return dayjs(`${datetime}Z`).utc().format('MM/DD/YYYY');
};

export const getDaysBetween = (firstDate: Date, secondDate: Date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  return Math.round((firstDate.getTime() - secondDate.getTime()) / oneDay);
};

export const getParsedDateMinifiedYear = (value: string) => {
  const date = new Date(value);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${addInitialZero(month.toString())}/${addInitialZero(day.toString())}/${date
    .getFullYear()
    .toString()
    .slice(-2)}`;
};

export const getFormattedDateTime = (date: string, hour12 = false, separator = '  ', appendToDate = 'Z') => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12,
    timeZone,
  };
  const dateObj = new Date(`${date}${appendToDate}`);
  return new Intl.DateTimeFormat('en', options).format(dateObj).replaceAll(',', separator);
};

export const isPastDate = (needBy: string) =>
  new Date(new Date(needBy).toDateString()) < new Date(new Date().toDateString());

export const addCurrentHours = (date: string) => {
  const currentDate = new Date();
  const currentTime = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
  const dateTimeString = `${getParsedDate(date)} ${currentTime}`;
  const dateTime = new Date(dateTimeString);
  return Boolean(date) === false ? null : format(dateTime, 'EEE LLL d yyyy H:mm:ss xxxx');
};

export const formatLocalTime = (date: string) => new Date(date?.includes('Z') ? date : `${date}Z`);

export const getMonthDayYearFromUtcDate = (paramDate: string) => {
  if (Number.isNaN(Date.parse(paramDate))) return '';

  const utcDate = paramDate?.includes('Z') ? paramDate : `${paramDate}Z`;

  const date = new Date(utcDate);
  const year = date.getFullYear()?.toString()?.slice(2, 4);

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  return `${month}/${day}/${year}`;
};

export const formateTime = (date: string) => {
  const localTime = date?.includes('Z') ? new Date(date) : new Date(`${date}Z`);
  let hours = localTime?.getHours();
  const minutes = String(localTime?.getMinutes()).padStart(2, '0');
  const AmOrPm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;
  const finalTime = `${hours}:${minutes}${AmOrPm}`;
  return finalTime;
};

export const convertSecondsToHHMMSS = (seconds: number) => {
  if (seconds < 0 || !seconds) return '00:00:00';

  const HH = `${Math.floor(seconds / 3600)}`.padStart(2, '0');
  const MM = `${Math.floor(seconds / 60) % 60}`.padStart(2, '0');
  const SS = `${Math.floor(seconds) % 60}`.padStart(2, '0');
  return [HH, MM, SS].join(':');
};

export const formatLocalTimeToUTC = (date: Dayjs) => {
  if (!date?.local) return date;
  return date.local().endOf('day').utc().format(UTCFORMATDAYJS);
};

export const getTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone;
};

export const formatTo = (date: number | Date, pattern = 'MM/DD/YYYY') => dayjs(date).format(pattern);

export const isDateBeforeToday = (date: Dayjs) => {
  if (!date.isValid()) return false;
  return date.isBefore(dayjs(), 'day');
};

export const getDateFromString = (date: string | undefined | null) => {
  if (isNil(date)) return null;
  const dayJsDate = dayjs(`${date}Z`);
  if (!dayJsDate.isValid()) return null;
  return dayJsDate.toDate();
};
