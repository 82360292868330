import { useCallback, useEffect, useMemo } from 'react';

import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { useUsersInfo } from 'hooks/useUsersInfo';
import type { WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';
import type { WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import type { DocumentId, TaskDocument, WorkOrderDocument, WorkRequestDocument } from './types';
import { useDocumentsCache } from './useDocumentsCache';

type Props =
  | {
      workRequest: WorkRequest;
      workOrder?: never;
    }
  | {
      workOrder: WorkOrder;
      workRequest?: never;
    };

const isValidDocumentId = (documentId: DocumentId | null | undefined): documentId is DocumentId =>
  isNotNil(documentId) && documentId !== '00000000-0000-0000-0000-000000000000';

export const useDocuments = ({ workRequest, workOrder }: Props) => {
  const { commonTypeId } = useDocumentTypes();
  const { getUsersData } = useUsersInfo();
  const { requestDocumentDetails } = useDocumentsCache();
  const {
    fetchPage: fetchWorkRequestDocsApiCall,
    setDefaultOpts: setWorkRequestDocsDefaultOpts,
    entireCount: totalWorkRequestDocs,
    loading: loadingWorkRequestDocs,
  } = useWrappedPaginatedGet<WorkRequestDocument>('shop/workRequestDocument', { lazy: true });
  const {
    fetchPage: fetchWorkOrderDocsApiCall,
    setDefaultOpts: setWorkOrderDocsDefaultOpts,
    entireCount: totalWorkOrderDocs,
    loading: loadingWorkOrderDocs,
  } = useWrappedPaginatedGet<WorkOrderDocument>('shop/workOrderDocument', {
    lazy: true,
  });
  const {
    fetchPage: fetchTaskDocsApiCall,
    setDefaultOpts: setTaskDocsDefaultOpts,
    entireCount: totalTaskDocs,
    loading: loadingTaskDocs,
  } = useWrappedPaginatedGet<TaskDocument>('shop/taskDocument', {
    lazy: true,
  });

  const workRequestId = workOrder ? workOrder.workRequest.workRequestId : workRequest.workRequestId;
  const workOrderId = workOrder?.workOrderId;

  useEffect(() => {
    setWorkRequestDocsDefaultOpts({
      defaultConfig: { params: { workRequestId, documentTypeIds: commonTypeId } },
      lazy: true,
    });
  }, [commonTypeId, setWorkRequestDocsDefaultOpts, workRequestId]);

  useEffect(() => {
    setWorkOrderDocsDefaultOpts({
      defaultConfig: { params: { workOrderIds: workOrderId, documentTypeIds: commonTypeId } },
      lazy: true,
    });
  }, [commonTypeId, setWorkOrderDocsDefaultOpts, workOrderId]);

  useEffect(() => {
    setTaskDocsDefaultOpts({
      defaultConfig: { params: { workOrderId, documentTypeIds: commonTypeId } },
      lazy: true,
    });
  }, [commonTypeId, setTaskDocsDefaultOpts, workOrderId]);

  const fetchWorkRequestDocs = useCallback(
    (...args: Parameters<typeof fetchWorkRequestDocsApiCall>) =>
      fetchWorkRequestDocsApiCall(...args).then((res) => {
        getUsersData(res.data.map((doc) => doc.createdBy));
        requestDocumentDetails(res.data.map((d) => d.documentId).filter(isValidDocumentId));
        return res;
      }),
    [fetchWorkRequestDocsApiCall, getUsersData, requestDocumentDetails],
  );
  const fetchWorkOrderDocs = useCallback(
    (...args: Parameters<typeof fetchWorkOrderDocsApiCall>) =>
      fetchWorkOrderDocsApiCall(...args).then((res) => {
        getUsersData(res.data.map((doc) => doc.createdBy));
        requestDocumentDetails(res.data.map((d) => d.documentId).filter(isValidDocumentId));
        return res;
      }),
    [fetchWorkOrderDocsApiCall, getUsersData, requestDocumentDetails],
  );
  const fetchTaskDocs = useCallback(
    (...args: Parameters<typeof fetchTaskDocsApiCall>) =>
      fetchTaskDocsApiCall(...args).then((res) => {
        getUsersData(res.data.map((doc) => doc.createdBy));
        requestDocumentDetails(res.data.map((d) => d.documentId).filter(isValidDocumentId));
        return res;
      }),
    [fetchTaskDocsApiCall, getUsersData, requestDocumentDetails],
  );

  return useMemo(
    () =>
      ({
        fetchWorkRequestDocs,
        fetchWorkOrderDocs,
        fetchTaskDocs,
        totalDocs: (totalWorkRequestDocs ?? 0) + (totalWorkOrderDocs ?? 0) + (totalTaskDocs ?? 0),
        loadingAttachments: loadingWorkRequestDocs || loadingWorkOrderDocs || loadingTaskDocs,
      } as const),
    [
      fetchTaskDocs,
      fetchWorkOrderDocs,
      fetchWorkRequestDocs,
      loadingTaskDocs,
      loadingWorkOrderDocs,
      loadingWorkRequestDocs,
      totalTaskDocs,
      totalWorkOrderDocs,
      totalWorkRequestDocs,
    ],
  );
};
