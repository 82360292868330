import { forwardRef, ReactNode, useState } from 'react';

import { ActionIcon, Button, Flex, Overlay, Popover, Portal } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import './jiggle.css';

type Props = {
  opened: boolean;
  onOpen?: () => void;
  onSubmit: () => void;
  onClose: () => void;
  isDirty: boolean;
  disabled?: boolean;
  loading?: boolean;
  width?: number;
  target?: ReactNode;
  confirmButtonText?: string;
  suppressJiggle?: boolean;
  children: ReactNode;
};

export const HiddenTarget = forwardRef<HTMLDivElement>((props, ref) => <div ref={ref} {...props} />);

export const AlertingPopover = ({
  opened,
  onOpen,
  onClose,
  onSubmit,
  isDirty,
  disabled,
  loading,
  confirmButtonText = 'Save',
  suppressJiggle = false,
  width = 300,
  target,
  children,
}: Props) => {
  const [jiggling, setJiggling] = useState(false);
  return (
    <>
      {opened && (
        // When the user needs to take an action (because the form is dirty)
        // prevent them from clicking anywhere else on the screen
        <Portal>
          <Overlay fixed opacity={0} />
        </Portal>
      )}
      <Popover
        width={width}
        opened={opened}
        position="bottom-end"
        shadow="md"
        onClose={() => {
          if (isDirty) {
            if (!suppressJiggle) {
              setJiggling(true);
            }
          } else {
            onClose();
          }
        }}
        onOpen={() => {
          setJiggling(false);
          onOpen?.();
        }}
        withinPortal
      >
        <Popover.Target>{target ?? <HiddenTarget />}</Popover.Target>
        <Popover.Dropdown className={jiggling ? 'jiggle' : ''} onAnimationEnd={() => setJiggling(false)}>
          <ActionIcon onClick={onClose} style={{ position: 'absolute', right: 5, top: 5 }}>
            <EvolveIcon icon="Close" size="sm" color="dark" />
          </ActionIcon>
          {children}
          <Flex gap="sm" mt="sm">
            <Button fullWidth onClick={onClose} disabled={loading} variant="outline">
              Cancel
            </Button>
            <Button fullWidth loading={loading} type="submit" onClick={onSubmit} disabled={disabled}>
              {confirmButtonText}
            </Button>
          </Flex>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};
