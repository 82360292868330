import { useState } from 'react';

import { Button, Flex, NumberInput } from '@mantine/core';

import type { WorkRequestId } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import type { WorkOrderId } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import { CatalogPartCard } from './CatalogPartCard';
import type { Part, PartId } from './types';

type AddCatalogItemBodyBase = {
  quantity: number;
  partId: PartId;
};

export type AddCatalogItemToWorkOrderBody = AddCatalogItemBodyBase & {
  workOrderId: WorkOrderId;
};

export type AddCatalogItemToWorkRequestBody = AddCatalogItemBodyBase & {
  workRequestId: WorkRequestId;
};

type Props = {
  part: Part;
  savingItems: boolean;
  multiplePartsMap: Record<PartId, number>;
  updateMultiPartsMap: (partId: PartId, qty?: number) => void;
  addSingleCatalogItem: (part: Part) => Promise<void>;
};

export const AddCatalogPartCard = ({
  part,
  savingItems,
  multiplePartsMap,
  updateMultiPartsMap,
  addSingleCatalogItem,
}: Props) => {
  const [saving, setSaving] = useState(false);

  const onSubmit = () => {
    setSaving(true);
    addSingleCatalogItem(part).finally(() => setSaving(false));
  };

  return (
    <CatalogPartCard
      part={part}
      rightComponent={
        <Flex gap="md" align="flex-end">
          <NumberInput
            style={{ width: 90 }}
            max={9999}
            min={0}
            startValue={1}
            placeholder="Qty"
            disabled={saving || savingItems}
            value={multiplePartsMap[part.partId] || ''}
            onChange={(qty) => {
              updateMultiPartsMap(part.partId, qty || undefined);
            }}
          />
          <Button
            loading={saving}
            onClick={onSubmit}
            disabled={!multiplePartsMap[part.partId] || (savingItems && !saving)}
            variant="outline"
          >
            Add
          </Button>
        </Flex>
      }
    />
  );
};
