import React, { useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAudioRecording } from 'hooks/AudioRecording/useAudioRecording';

import { ROUNDED_BORDER_STYLE } from '../constants/constants';
import useCachedUserPhotos from '../hooks/useCachedUserPhotos';
import { useTaskViewerContext } from '../TaskViewerContext';
import TaskTypeEvent from './TaskTypeEvents';

const getStartedEvents = (events = []) => events?.filter((event) => event.description !== 'Not Started');

// eslint-disable-next-line max-lines-per-function
const ProductionTimeContent = () => {
  const {
    timeData,
    unityQuantity,
    unitTime: { totalUnitTime },
    taskStatus,
  } = useTaskViewerContext();

  const startedEvents = useMemo(() => getStartedEvents(timeData.events), [timeData.events]);

  const { cachedUserPhotos } = useCachedUserPhotos(startedEvents);

  const { completedTime } = useAudioRecording();

  return (
    <Stack
      sx={{
        ...ROUNDED_BORDER_STYLE,
        mt: 1,
        px: 2,
        pt: 0.5,
        pb: 1,
        minHeight: 260,
        '& tr > td:nth-of-type(4)': {
          width: '62px',
        },
      }}
    >
      <table>
        <thead>
          <tr>
            <td />
            <TableHead>Action</TableHead>
            <TableHead>Date</TableHead>
            <TableHead>Time</TableHead>
          </tr>
        </thead>
        <tbody>
          {startedEvents?.map((row) => (
            <TaskTypeEvent key={row.timesStamp} row={row} userData={cachedUserPhotos[row.userId] || {}} />
          ))}
        </tbody>
      </table>
      <Stack gap="8px" sx={{ mr: 1, mt: 1 }}>
        <TableResult
          label="Total time"
          value={taskStatus === 'Completed' || taskStatus === 'Paused' ? completedTime(timeData?.time) : ''}
        />

        <>
          <TableResult label="Unit quantity" value={unityQuantity} />
          <TableResult label="Unit time" value={taskStatus === 'Completed' ? completedTime(totalUnitTime) : ''} />
        </>
      </Stack>
    </Stack>
  );
};

export default ProductionTimeContent;

const TableHead = ({ children }) => (
  <td>
    <Typography variant="caption">{children}</Typography>
  </td>
);

const TableResult = ({ label, value }) => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="body2">{label}</Typography>
    <Typography variant="body2">{value}</Typography>
  </Stack>
);
