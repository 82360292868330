import { Badge, BadgeProps, MantineSize } from '@mantine/core';

import { StatusKey, statusToColorMap } from 'constants/badgeMappingStatus';

type Props = {
  size?: MantineSize;
  status: StatusKey;
  daysRemaining?: number;
} & Partial<BadgeProps>;

export const CellStatusBadge = ({ status, size, daysRemaining = 0, ...props }: Props) => {
  const { label, color } = statusToColorMap[status];
  const finalColor = daysRemaining < 0 ? statusToColorMap.Late.color : color;
  return (
    <Badge
      tt="none"
      size={size}
      radius="sm"
      key={label}
      color={finalColor}
      c="white"
      variant="filled"
      onClick={(e) => e.stopPropagation()}
      style={{
        cursor: 'initial',
        ...props.style,
      }}
      {...props}
    >
      {label}
    </Badge>
  );
};
