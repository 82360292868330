import type { ColDef, IServerSideGetRowsRequest, KeyCreatorParams, ValueFormatterParams } from 'ag-grid-community';
import { format, parseISO } from 'date-fns';

import { StatusKey, statusToColorMap } from 'constants/badgeMappingStatus';
import { isNotNil } from 'helpers/isNotNil';
import { groupBy, strongEntries, strongFromEntries } from 'helpers/strongEntries';
import type { AdditionalRequestConfig } from 'hooks-api/useEvolveApi';
import type { PageFetcher } from 'hooks-api/useWrappedApiCall';

// TODO: Get rid of these after simplifying the backend API
const filterKeyMapping: Record<string, string> = {
  // User
  userEmail: 'email',
  userName: 'name',
  // WorkRequest
  workRequestStatusName: 'statusName',
  workRequestStatusId: 'workRequestStatusIds',
  // WorkOrder
  'workRequest.needBy': 'needBy',
  workOrderStatusTypeId: 'workOrderStatusIds',
  'workRequest.projectName': 'projectName',
  'workRequest.facilityName': 'facilityName',
  'workRequest.workRequestName': 'workRequestName',
  'workRequest.workRequestDescription': 'workRequestDescription',
  // WorkCells
  taskStatusTypeId: 'taskStatusTypeIds',
  workCellId: 'workCellIds',
} as const;

const orderKeyMapping: Record<string, string> = {
  // User
  userName: 'name',
  // DepartmentUser
  'user.userEmail': 'email',
  // WorkOrder
  'workRequest.needBy': 'needBy',
  'workRequest.projectName': 'projectName',
  'workRequest.facilityName': 'facilityName',
  'workRequest.workRequestName': 'workRequestName',
} as const;

const convertDate = (date: Date, timeFormat: '00:00:00' | '23:59:59') => `${format(date, 'yyyy-MM-dd')}T${timeFormat}`;

// AG Grid's FilterModel type is super vague and unhelpful
// This is a reverse-engineered Type for their built-in model,
// with our own custom filterTypes added.
export type HandledFilterTypes = 'set' | 'text' | 'date' | 'custom';
export type HandledFilterModel<T = any> = {
  filterType: HandledFilterTypes;
} & (
  | {
      filterType: 'set';
      values: T[];
    }
  | {
      filterType: 'text';
      filter: string;
    }
  | {
      filterType: 'date';
      dateFrom: string;
      dateTo: string;
    }
  | {
      filterType: 'custom';
      value: T;
      filterKey?: string;
    }
);

// Unfortunately, FilterModel isn't typed any stronger than `any`,
// but I'm doing this anyway in case some day it gets typed
const convertFilterToEvolveApi = <S extends HandledFilterTypes>([key, filterModel]: [
  string | number,
  HandledFilterModel<S>,
]): [string, string] | [] => {
  const mappedKey = ('filterKey' in filterModel ? filterModel.filterKey : filterKeyMapping[key]) ?? `${key}`;
  if (filterModel.filterType === 'set') {
    if (filterModel.values.length > 0) {
      return [
        mappedKey,
        filterModel.values
          // filterModel may be an array of arrays, but we want
          // a flat array to send to the backend.
          .flatMap((a: any) => a)
          .map(encodeURIComponent)
          .join(','),
      ];
    }
    return [];
  }
  if (filterModel.filterType === 'text') {
    return [mappedKey, `like:${filterModel.filter}`];
  }
  if (filterModel.filterType === 'date') {
    const startDate = parseISO(filterModel.dateFrom.split(' ')[0]);
    const endDate = parseISO(filterModel.dateTo.split(' ')[0]);
    return [mappedKey, `start:${convertDate(startDate, '00:00:00')}end:${convertDate(endDate, '23:59:59')}`];
  }
  if (filterModel.filterType === 'custom') {
    return [mappedKey, filterModel.value];
  }
  // @ts-ignore The type system believes the above are the only possible values of filterType, but there are filterTypes AG Grid has that we don't handle.
  // eslint-disable-next-line no-console
  console.warn(`Unrecognized filterModel.filterType '${filterModel.filterType}', ignoring`);
  return [];
};

export const convertAgGridRequestToParams = <TData = any>(
  request: IServerSideGetRowsRequest,
  additionalConfig?: AdditionalRequestConfig<TData>,
): Parameters<PageFetcher<TData>> => {
  const skip = request.startRow ?? 0;
  const mappedParams = strongFromEntries(
    strongEntries(request.filterModel ?? {})
      .map(convertFilterToEvolveApi)
      .filter((n): n is [string, string] => n.length > 0),
  );
  return [
    {
      skip,
      take: isNotNil(request.endRow) ? request.endRow - skip : undefined,
      orderBy: request.sortModel.map(({ colId, sort }) => `${orderKeyMapping[colId] ?? colId}:${sort}`).join(','),
    },
    {
      ...additionalConfig,
      params: {
        ...mappedParams,
        ...additionalConfig?.params,
      },
    },
  ] as const;
};

/**
 * Used to generate a selectable set of Statuses for filtering in AG Grid.
 *
 * For a given set of status types (each with an ID and Name),
 * finds the UI labels for each type and groups by label.
 * This way, if you select a label that covers multiple status type IDs,
 * all of those IDs will be selected, instead of just one.
 *
 * For example, `taskStatusTypeNames` "Paused" and "Started"
 * both have the label "In Progress" from `statusToColorMap`
 * so we want to group them together.
 */
export const filterStatusConverter = <T, IdKey extends string & keyof T, NameKey extends string & keyof T>(
  statusTypes: T[NameKey] extends StatusKey ? (T[IdKey] extends string ? T[] : never) : never,
  /** `T[IdKey]` must be of type `string` */
  statusIdKey: IdKey,
  /** `T[NameKey]` must be of type `StatusKey` */
  statusNameKey: NameKey,
  opts: {
    /**
     * The statuses to display.
     * If left empty, will display all statsuses from `statusTypes`
     */
    filterableStatuses?: StatusKey[];
  } = {},
): ColDef['filterParams'] => {
  /** A map of `statusToColorMap.label` to all `statusTypeIds` in `statusTypes` that have that label */
  type Value = { label: string; ids: T[IdKey][] };
  const values: Value[] = strongEntries(
    groupBy(
      statusTypes
        .filter((s) => opts?.filterableStatuses?.includes(s[statusNameKey] as StatusKey) ?? true)
        .map((t) => ({
          // This is a safe cast - the `extends StatusKey` check on the func def verifies that
          // but I'm not sure how to tell TypeScript otherwise that T[NameKey] will always be a StatusKey
          statusKey: statusTypes.find((s) => s[statusIdKey] === t[statusIdKey])?.[statusNameKey] as
            | StatusKey
            | undefined,
          id: t[statusIdKey],
        }))
        .filter((t): t is { statusKey: StatusKey; id: T[IdKey] } => isNotNil(t.statusKey))
        .map(({ statusKey, id }) => ({
          label: statusToColorMap[statusKey].label,
          id,
        })),
      (t) => t.label,
    ),
  ).map(([label, groupedStatusTypes]) => ({
    label,
    ids: groupedStatusTypes.map((a) => a.id),
  }));
  /** Returns what should be displayed to the user */
  const valueFormatter = ({ value }: ValueFormatterParams<T, Value>) => value?.label ?? '';
  /** Returns what should be sent to the backend for filtering (in this case, a list of statusTypeIds) */
  const keyCreator = ({ value }: KeyCreatorParams<T, Value>) => value?.ids ?? [];
  return {
    values,
    valueFormatter,
    keyCreator,
    suppressSorting: true,
    buttons: ['apply', 'reset'],
  };
};

export const customSetFilterParams = <T, IdKey extends string & keyof T, NameKey extends string & keyof T>(
  values: T[IdKey] extends string ? T[] : never,
  /** `T[IdKey]` must be of type `string` */
  idKey: IdKey,
  labelKey: NameKey,
): ColDef['filterParams'] => ({
  values,
  /** Returns what should be displayed to the user */
  valueFormatter: ({ value }: ValueFormatterParams<any, T>) => value?.[labelKey] ?? null,
  /** Returns what should be sent to the backend for filtering (in this case, a list of statusTypeIds) */
  keyCreator: ({ value }: KeyCreatorParams<any, T>) => value?.[idKey],
  suppressSorting: true,
  buttons: ['apply', 'reset'],
});
