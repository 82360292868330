import './attachments.css';
import { useCallback, useMemo } from 'react';

import { Flex } from '@mantine/core';

import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNotNil } from 'helpers/isNotNil';
import type { WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import { useWorkRequests } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';
import type { WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';
import { useWorkOrders } from 'modules/Shop/WorkOrders/WorkOrdersPage/useWorkOrders';

import { AttachmentSection } from './AttachmentSection';
import { getDocumentColumnDef } from './columnDefs';
import type { TaskDocument, WorkOrderDocument, WorkRequestDocument } from './types';
import { useDocuments } from './useDocuments';

type Props = {
  readOnly?: boolean;
} & (
  | {
      workRequest: WorkRequest;
      workRequestUpdated?: (updatedWR: WorkRequest) => void;
      workOrder?: never;
      workOrderUpdated?: never;
    }
  | {
      workOrder: WorkOrder;
      workOrderUpdated?: (updatedWO: WorkOrder) => void;
      workRequest?: never;
      workRequestUpdated?: never;
    }
);

export const AttachmentsList = ({ readOnly = false, workRequestUpdated, workOrderUpdated, ...props }: Props) => {
  const { getWorkOrder } = useWorkOrders();
  const { getWorkRequest } = useWorkRequests();
  const { workRequest, workOrder } = props;
  const { fetchWorkRequestDocs, fetchWorkOrderDocs, fetchTaskDocs } = useDocuments(props);

  const wrIsCompleted =
    workOrder?.workOrderStatusTypeName === 'Completed' || workRequest?.workRequestStatusName === 'Completed';

  const refreshParent = useCallback(() => {
    if (isNotNil(workOrder)) {
      getWorkOrder(workOrder.workOrderId).then(workOrderUpdated);
    } else if (isNotNil(workRequest)) {
      getWorkRequest(workRequest.workRequestId).then(workRequestUpdated);
    }
  }, [getWorkOrder, getWorkRequest, workOrder, workOrderUpdated, workRequest, workRequestUpdated]);

  const wrColDef = useMemo(
    () => getDocumentColumnDef<WorkRequestDocument>(wrIsCompleted || readOnly || isNotNil(workOrder), refreshParent),
    [readOnly, workOrder, wrIsCompleted, refreshParent],
  );
  const { agGridProps: wrDocsAgGridProps, refreshGrid: refreshWRdocs } = useServerSideGrid({
    tableName: 'work-request-attachments',
    fetchPage: fetchWorkRequestDocs,
    colDef: wrColDef,
    rowId: 'workRequestDocumentId',
  });
  const woColDef = useMemo(
    () => getDocumentColumnDef<WorkOrderDocument>(wrIsCompleted || readOnly, refreshParent),
    [readOnly, wrIsCompleted, refreshParent],
  );
  const { agGridProps: woDocsAgGridProps, refreshGrid: refreshWOdocs } = useServerSideGrid({
    tableName: 'work-order-attachments',
    fetchPage: fetchWorkOrderDocs,
    colDef: woColDef,
    rowId: 'workOrderDocumentId',
  });
  const taskColDef = useMemo(() => getDocumentColumnDef<TaskDocument>(true, refreshParent), [refreshParent]);
  const { agGridProps: taskDocsAgGridProps } = useServerSideGrid({
    tableName: 'task-attachments',
    fetchPage: fetchTaskDocs,
    colDef: taskColDef,
    rowId: 'taskDocumentId',
  });

  return (
    <Flex direction="column">
      {/* Work Request view doesn't show WO Attachments or Task Attachments */}
      {isNotNil(workOrder) && (
        <>
          <AttachmentSection section="Tasks" agGridProps={taskDocsAgGridProps} />
          <AttachmentSection
            section="Work Order"
            agGridProps={woDocsAgGridProps}
            active={!wrIsCompleted && !readOnly}
            refreshWOdocs={() => {
              refreshWOdocs();
              refreshParent();
            }}
            workOrder={workOrder}
          />
        </>
      )}
      <AttachmentSection
        section="Work Request"
        agGridProps={wrDocsAgGridProps}
        {...(isNotNil(workRequest)
          ? {
              active: !wrIsCompleted && !readOnly,
              refreshWRdocs: () => {
                refreshWRdocs();
                refreshParent();
              },
              workRequest,
            }
          : { active: false })}
      />
    </Flex>
  );
};
