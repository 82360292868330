import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import type { ColDef, ColumnState, FilterModel } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';

import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import type { AdditionalRequestConfig } from 'hooks-api/useEvolveApi';
import type { PageFetcher } from 'hooks-api/useWrappedApiCall';

export type ExportGridOpts<T> = {
  fileName: string;
  columnDefs: ColDef<T>[];
  fetchPage: PageFetcher<T>;
  columnState: ColumnState[] | undefined;
  filterModel: FilterModel | null;
  config?: AdditionalRequestConfig<T>;
};

type ExportGridContextType = {
  exportGrid: <T>(opts: ExportGridOpts<T>) => Promise<void>;
  exportRunning: boolean;
};

const ExportGridContext = React.createContext<ExportGridContextType | undefined>(undefined);

// Used to export from an AG Grid API. May be useful some day.
// const pageSize = 100;
// const getAllDataFromServerSideDataSource = async <T,>(
//   api: GridApi<T>,
//   datasource: IServerSideDatasource,
//   startRow = 0,
// ): Promise<T[]> =>
//   new Promise((resolve) => {
//     const endRow = startRow + pageSize;
//     datasource.getRows({
//       request: {
//         startRow,
//         endRow,
//         filterModel: api.getFilterModel(),
//         sortModel: api.getColumnState().filter((c): c is SortModelItem => !!c.sort),
//       },
//       success: (async ({ rowData, rowCount }) => {
//         if (endRow >= (rowCount ?? 0)) {
//           resolve(rowData);
//         } else {
//           resolve([...rowData, ...(await getAllDataFromServerSideDataSource(api, datasource, endRow))]);
//         }
//       }) as IServerSideGetRowsParams['success'],
//       api,
//     } as unknown as IServerSideGetRowsParams);
//   });

export const ExportGridProvider = ({ children }: { children: ReactNode }) => {
  const [exportRunning, setExportRunning] = useState(false);
  const [exportAllDataGridOptions, setExportAllDataGridOptions] = useState<
    | (Pick<AgGridReactProps, 'columnDefs' | 'rowData'> &
        Pick<ExportGridOpts<any>, 'fileName' | 'columnState' | 'filterModel'>)
    | null
  >(null);

  const exportGrid = useCallback<ExportGridContextType['exportGrid']>(
    async ({ fileName, columnDefs, fetchPage, columnState, filterModel, config }) => {
      setExportRunning(true);

      const rowData = await getAllDataFromFetcher(fetchPage, config).catch((e) => {
        setExportRunning(false);
        throw e;
      });
      setExportAllDataGridOptions({
        fileName,
        columnDefs,
        rowData,
        columnState,
        filterModel,
      });
    },
    [],
  );

  const value = useMemo(() => ({ exportGrid, exportRunning }), [exportGrid, exportRunning]);

  return (
    <ExportGridContext.Provider value={value}>
      {isNotNil(exportAllDataGridOptions) && (
        <div style={{ display: 'none' }}>
          <AgGridReact
            {...exportAllDataGridOptions}
            onGridReady={async ({ api }) => {
              try {
                api.applyColumnState({ state: exportAllDataGridOptions.columnState, applyOrder: true });
                api.setFilterModel(exportAllDataGridOptions.filterModel);
                api.exportDataAsCsv({ fileName: exportAllDataGridOptions.fileName });
              } finally {
                setExportAllDataGridOptions(null);
                setExportRunning(false);
              }
            }}
          />
        </div>
      )}
      {children}
    </ExportGridContext.Provider>
  );
};

export const useExportGrid = () => useGeneralContext(ExportGridContext, 'ExportGrid');
