import { useCallback } from 'react';

import { useWrappedGet } from 'hooks-api/useWrappedApiCall';

import { useFetchProviderStatus } from './restCalls';

export const useResendSignUp = () => {
  const { apiCall: resendInvite } = useWrappedGet('admin/authentication/sendInvitation​');
  const { fetchProviderStatus } = useFetchProviderStatus();
  const resendSignUp = useCallback(
    async (email: string) => {
      const userInfo = await fetchProviderStatus(email);
      if (!('userName' in userInfo)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          code: 'UserNotFoundException',
          message: 'The email address does not exist',
        });
      }
      return resendInvite({ params: { userName: userInfo.userName } });
    },
    [fetchProviderStatus, resendInvite],
  );

  return resendSignUp;
};
