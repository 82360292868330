import { useCallback, useEffect, useMemo, useState } from 'react';

import { Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { AgGridReact } from 'ag-grid-react';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { TextInputDebounced } from 'components/Mantine/TextInputDebounced';
import { WrappedMultiSelect, WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { extractErrorMessage } from 'helpers/extractError';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet, useWrappedPatch, useWrappedPost } from 'hooks-api/useWrappedApiCall';
import { InviteUsersModal } from 'modules/Shop/ShopMembers/ShopMembersMantine/InviteUsersModal';
import type { Entitlement, ProductPool, ProductPoolId, User, UserId } from 'types/types-api';

import { AddLicensesModal } from './AddLicensesModal';
import { getCompanyMembersColDef, getEntitlementsColDef } from './columnDefs';
import { CompanyMembersBulkAction, companyMembersBulkActions } from './CompanyMemberMenu';

export const CompanyMembersPage = () => {
  const { user } = useUser();
  const { apiCall: sendPasswordResetEmail } = useWrappedPost('admin/authentication/forgotPassword', {
    dontAlertOnError: true,
  });
  const [showNoLicense, setShowNoLicense] = useState(false);
  const [selectedProductPools, setSelectedProductPools] = useState<ProductPoolId[]>([]);
  const [singleUserAction, setSingleUserAction] = useState<User | null>(null);
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [selectedAction, setSelectedAction] = useState<CompanyMembersBulkAction | null>(null);

  const { data: productPools, setDefaultOpts: setDefaultOptsProductPools } = useWrappedPaginatedGet<ProductPool>(
    'admin/productPool',
    { lazy: true },
  );
  useEffect(() => {
    if (isNotNil(user)) {
      setDefaultOptsProductPools({
        lazy: false,
        perPage: 999,
        defaultConfig: {
          params: {
            excludeExpired: true,
            companyId: user?.companyId,
          },
        },
      });
    }
  }, [setDefaultOptsProductPools, user]);

  const { apiCall: deleteUsersApiCall, loading: deleting } = useWrappedPatch<unknown, { userIds: UserId[] }>(
    'admin/user/multidelete',
  );
  const deleteUsers = useCallback(
    async (userIds: UserId[]) => {
      await deleteUsersApiCall({ userIds }).then(() => {
        notifications.show({
          title: 'Successfully deleted',
          message: `Deleted ${userIds.length} user${userIds.length === 1 ? '' : 's'}`,
          color: 'green',
        });
      });
    },
    [deleteUsersApiCall],
  );
  const [searchPhrase, setSearchPhrase] = useState('');
  const { fetchPage, setDefaultOpts, searchHandler } = useWrappedPaginatedGet<User>('admin/user', {
    lazy: true,
  });
  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          companyId: user?.companyId,
          productPoolIds: selectedProductPools.join(','),
          hasLicense: showNoLicense ? false : undefined,
        },
      },
    });
  }, [setDefaultOpts, selectedProductPools, showNoLicense, user?.companyId]);
  const { fetchPage: fetchEntitlementPage } = useWrappedPaginatedGet<Entitlement>('admin/entitlement/multisearch');

  const actionSingleUser = useCallback((selectedUser: User, action: CompanyMembersBulkAction) => {
    setSelectedAction(action);
    setSingleUserAction(selectedUser);
  }, []);
  const usersToAction = useMemo(
    () => (singleUserAction ? [singleUserAction] : selectedRows),
    [selectedRows, singleUserAction],
  );
  useEffect(() => {
    if (isNil(selectedAction)) {
      setSingleUserAction(null);
    }
  }, [selectedAction]);

  useEffect(() => {
    if (selectedAction === 'Reset Password') {
      setSelectedAction(null);
      Promise.allSettled(
        usersToAction.map(({ userEmail: email }) =>
          sendPasswordResetEmail({}, { params: { email } }).catch((error) => {
            notifications.show({
              title: `Error (${email})`,
              // AWS errors tend to have the useful error on error.message
              message: error?.message ?? extractErrorMessage(error),
              color: 'red',
            });
            throw error;
          }),
        ),
      ).then((results) => {
        const sent = results.filter((r) => r.status === 'fulfilled').length;
        if (sent > 0) {
          notifications.show({
            title: 'Successfully sent',
            message: `${sent} password reset email${sent === 1 ? '' : 's'} sent`,
            color: 'green',
          });
        }
      });
    }
  }, [selectedAction, sendPasswordResetEmail, usersToAction]);

  const colDef = useMemo(
    () =>
      getCompanyMembersColDef({
        hasDetailTable: true,
        actionSingleUser,
        filterOnStatus: true,
      }),
    [actionSingleUser],
  );
  const entitlementsColDef = useMemo(() => getEntitlementsColDef(), []);
  const { agGridProps, expandDetailGrid, refreshDetailGrid, refreshGrid, filterIsSet } = useServerSideGrid({
    tableName: 'company-members',
    fetchPage,
    colDef,
    rowId: 'userId',
    detailTables: [
      {
        title: 'Licenses',
        colDef: entitlementsColDef,
        hideHeader: true,
        fetchPage: fetchEntitlementPage,
        icon: null,
        autoExpand: true,
        configMapper: (d) => ({ params: { userId: d.userId } }),
      },
    ],
  });

  const clearSelection = useCallback(() => {
    agGridProps.ref.current?.api?.deselectAll();
  }, [agGridProps.ref]);

  const defaultColDef = useMemo<typeof agGridProps['defaultColDef']>(
    () => ({
      ...agGridProps.defaultColDef,
      cellClassRules: {
        'disabled-row': (r) => r.data?.inviteStatus === 'Deleted',
      },
    }),
    [agGridProps.defaultColDef],
  );
  const deleteDisabled = useMemo(
    () => selectedRows.some((r) => r.userId === user?.userId),
    [selectedRows, user?.userId],
  );

  if (isNil(user)) return <Loader m="lg" />;

  return (
    <>
      <BasePageHeader
        title="Company Members"
        filterIsSet={filterIsSet || !!searchPhrase || showNoLicense || selectedProductPools.length > 0}
        onFilterClear={() => {
          setSearchPhrase('');
          searchHandler('');
          setShowNoLicense(false);
          setSelectedProductPools([]);
        }}
        gridRef={agGridProps.ref}
        noHistory
        topRightComponent={<InviteUsersModal pageType="company" refresh={refreshGrid} companyId={user.companyId} />}
        bottomLeftComponent={
          <WrappedSelect
            disabled={selectedRows.length === 0}
            value={selectedAction}
            placeholder={selectedRows.length > 0 ? `Action (${selectedRows.length})` : 'Action'}
            data={companyMembersBulkActions.map((action) => ({
              value: action,
              label: action === 'Delete' && deleteDisabled ? `${action} (cannot delete self)` : action,
              disabled: action === 'Delete' && deleteDisabled,
            }))}
            onChange={setSelectedAction}
          />
        }
        bottomRightComponent={
          <>
            <TextInputDebounced
              icon={<EvolveIcon icon="Search" color="inherit" />}
              placeholder="Search..."
              onChange={searchHandler}
              value={searchPhrase}
              onImmediateChange={setSearchPhrase}
            />
            <WrappedMultiSelect<'No Licenses' | ProductPoolId>
              value={showNoLicense ? ['No Licenses'] : selectedProductPools}
              placeholder="Filter by license..."
              data={[
                {
                  label: 'No Licenses',
                  value: 'No Licenses' as const,
                },
                ...(productPools ?? []).map((p) => ({
                  label: p.product.productName,
                  value: p.productPoolId,
                  group: 'Licenses',
                })),
              ]}
              onChange={(selected) => {
                if (showNoLicense || !selected.includes('No Licenses')) {
                  setShowNoLicense(false);
                  setSelectedProductPools(selected.filter((s): s is ProductPoolId => s !== 'No Licenses'));
                } else if (selected.includes('No Licenses')) {
                  setShowNoLicense(true);
                  setSelectedProductPools([]);
                }
              }}
              styles={{ item: { marginTop: 1, marginBottom: 1 } }}
              clearable
              disableSelectedItemFiltering
              style={{ width: 250 }}
            />
          </>
        }
      />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<User>
          {...agGridProps}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          rowMultiSelectWithClick
          onSelectionChanged={({ api }) => {
            setSelectedRows(api.getSelectedRows());
          }}
          noRowsOverlayComponent={() => <NoRowsOverlay label="No members found." gridRef={agGridProps.ref} />}
        />
      </div>
      <AddLicensesModal
        userIds={usersToAction.map((r) => r.userId)}
        opened={selectedAction === 'Add Licenses'}
        onClose={() => setSelectedAction(null)}
        refresh={() => {
          usersToAction.forEach((r) => {
            refreshDetailGrid(r.userId, 'Licenses');
            expandDetailGrid(r.userId, 'Licenses');
          });
          clearSelection();
        }}
      />
      <ConfirmationModal
        title="Confirm delete users"
        opened={selectedAction === 'Delete'}
        onClose={() => setSelectedAction(null)}
        loading={deleting}
        confirmationText="Delete"
        buttonColor="red"
        onConfirm={() => {
          deleteUsers(usersToAction.map((r) => r.userId)).then(() => {
            clearSelection();
            refreshGrid();
            setSelectedAction(null);
          });
        }}
      >
        Are you sure you want to delete{' '}
        {usersToAction.length === 1 ? usersToAction[0].userEmail : `${usersToAction.length} users`}?
      </ConfirmationModal>
    </>
  );
};
