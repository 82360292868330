import { useMemo } from 'react';

import { Avatar, Box, Flex, Loader, Text } from '@mantine/core';

import { useUser } from 'app/UserContext';

import { useProfilePictureContext } from './ProfilePictureContext';

const formatPhoneNumber = (phoneNumber: string | null | undefined) => {
  if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) return phoneNumber;
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
};

export const ProfileInformation = () => {
  const { user, loading } = useUser();
  const { profilePicture } = useProfilePictureContext();
  const { userFirstName, userLastName, personalPhoneNumber, userEmail } = user ?? {};
  const name = useMemo(() => {
    if (userFirstName && userLastName) {
      return `${userFirstName} ${userLastName}`;
    }
    return userEmail;
  }, [userEmail, userFirstName, userLastName]);
  const phoneNumber = useMemo(() => formatPhoneNumber(personalPhoneNumber), [personalPhoneNumber]);

  if (loading) return <Loader />;
  return (
    <Box style={{ maxWidth: 276 }}>
      <Flex justify="center" mb={2}>
        <Avatar alt="profile" src={profilePicture} size="xl" radius="100%" />
      </Flex>
      <Text
        align="center"
        c="white"
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {name}
      </Text>
      <Text c="dimmed" align="center">
        {phoneNumber}
      </Text>
    </Box>
  );
};
