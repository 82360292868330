import { NoContentFallback } from 'components';
import { Custom } from 'components/Icons';

import useCheckIfShopDepartmentExists from './useCheckIfShopDeparmentExists';

const NoShopDptOverlay = ({ children }) => {
  if (!useCheckIfShopDepartmentExists()) {
    return <NoContentFallback icon={Custom.Info} message="This facility does not have a Shop department." />;
  }

  return children;
};

export default NoShopDptOverlay;
