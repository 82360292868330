import { isNil } from './isNotNil';

const getStringsAtLeaf = (obj: any): string | null => {
  if (isNil(obj)) {
    return null;
  }
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return obj.flatMap(getStringsAtLeaf).join(' ');
    }
    return Object.values(obj).flatMap(getStringsAtLeaf).join(' ');
  }
  return `${obj}`;
};

export const extractErrorMessage = (error: any): string => {
  try {
    const parsedError = getStringsAtLeaf(error?.response?.data?.errors);
    // Using || instead of ?? to skip over any empty strings
    const errorString =
      parsedError ||
      error?.reponse?.data?.message ||
      error?.response?.data?.title ||
      getStringsAtLeaf(error?.response?.data) ||
      error?.toString() ||
      error?.response ||
      error;
    return errorString ? `${errorString}` : 'An unknown error occurred.';
  } catch {
    return `${error}`;
  }
};
