import { ActionIcon, Menu } from '@mantine/core';
import type { ColDef } from 'ag-grid-community';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { CustomSelectComponent } from 'helpers/ag-grid/CustomSelectComponent';
import { isNotNil } from 'helpers/isNotNil';
import { IncludeDeletedOptions, type User } from 'types/types-api';

const fallback = '--';

declare const departmentUserIdSymbol: unique symbol;
export type DepartmentUserId = string & { [departmentUserIdSymbol]: never };

export type DepartmentUser = {
  departmentUserId: DepartmentUserId;
  isDeleted: boolean;
  isAdmin: boolean;
  user: User;
};

declare const projectUserIdSymbol: unique symbol;
export type ProjectUserId = string & { [projectUserIdSymbol]: never };

export type ProjectUser = {
  projectUserId: ProjectUserId;
  isDeleted?: boolean;
  user: User;
};

export const getMembersColDef = <T extends DepartmentUser | ProjectUser>({
  deleteUser,
}: {
  deleteUser: (user: T) => void;
}) =>
  [
    {
      ...lockedColDef(),
      width: 56,
      checkboxSelection: ({ data }) => !data?.isDeleted,
    },
    {
      headerName: 'User',
      valueGetter: ({ data }) => {
        const nameParts = [data?.user.userFirstName, data?.user.userLastName].filter(isNotNil);
        if (nameParts.length > 0) {
          return nameParts.join(' ');
        }
        return fallback;
      },
    },
    {
      headerName: 'Email',
      field: 'user.userEmail',
      minWidth: 200,
      valueFormatter: ({ value }) => value ?? fallback,
      flex: 1,
    },
    {
      headerName: 'Office Phone',
      field: 'user.workPhoneNumber',
      valueFormatter: ({ value }) => value ?? fallback,
      sortable: false,
    },
    {
      headerName: 'Mobile Phone',
      field: 'user.personalPhoneNumber',
      valueFormatter: ({ value }) => value ?? fallback,
      sortable: false,
    },
    {
      headerName: 'Status',
      cellStyle: ({ data }) => ({
        // eslint-disable-next-line no-nested-ternary
        color: data?.user.isDeleted ? 'red' : data?.user.inviteStatus === 'Active' ? 'teal' : 'orange',
      }),
      valueFormatter: ({ data }) => (data?.user?.isDeleted ? 'Deleted' : data?.user?.inviteStatus),
      filter: CustomSelectComponent({
        label: 'User status',
        defaultValue: IncludeDeletedOptions.NO,
        data: [
          {
            label: 'Active users',
            value: IncludeDeletedOptions.NO,
          },
          {
            label: 'Deleted users',
            value: IncludeDeletedOptions.ONLY_DELETED,
          },
          {
            label: 'All users',
            value: IncludeDeletedOptions.YES,
          },
        ],
        filterKey: 'includeDeleted',
      }),
    },
    {
      lockPosition: 'right',
      resizable: false,
      sortable: false,
      width: 56,
      suppressColumnsToolPanel: true,
      cellRenderer: CellRenderer<T>(
        ({ data }) => (
          <Menu position="bottom-end" withinPortal>
            <Menu.Target>
              <ActionIcon>
                <EvolveIcon icon="MoreOptsVert" color="inherit" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                color="red"
                onClick={(e) => {
                  deleteUser(data);
                  e.stopPropagation();
                }}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
        {
          show: ({ data }) => !data.isDeleted,
        },
      ),
    },
  ] as ColDef<T>[];
