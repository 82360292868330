// Copyright ©️ 2022 eVolve MEP, LLC
import React, { useEffect } from 'react';

import { InputForesiteField } from '@evolvemep/foresite-components-ui-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';

import OverflowTip from 'components/Tooltip/OverflowTip';
import { requiredRule } from 'constants/inputFieldRules';

import FacilityNameSelect from '../../WorkRequest/components/FacilityNameSelect';
import NeedByDateField from './NeedByDateField';

export const WorkRequestCreateForm = ({
  facilities,
  isAdding,
  showProjectInfo = true,
  shouldUnregisterFields = true,
  formDescriptionSx = {},
  facilityIdSx = {},
  selectedItem,
}) => {
  const { control, setValue, watch } = useFormContext();

  const projectId = watch('ProjectId');

  useEffect(() => {
    if (selectedItem?.type === 'PROJECT' && selectedItem?.id) setValue('ProjectId', selectedItem.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  if (!projectId) return <></>;

  return (
    <Box>
      <Typography sx={{ fontSize: '16px', color: 'surface.lightSurface.primary', ...formDescriptionSx }}>
        Please fill out the following Work Request information
      </Typography>
      {showProjectInfo && (
        <Stack mt={0} direction="row" gap={2}>
          <InformationField label="Project Name" value={selectedItem.name ?? selectedItem.label} />
          <InformationField label="Project Id" value={selectedItem.identifier} />
        </Stack>
      )}
      <Stack mt={3} direction="row" gap={2}>
        <FacilityNameSelect
          control={control}
          options={facilities}
          name="FacilityId"
          disabled={isAdding}
          sx={{ width: '420px', ...facilityIdSx, flex: 1 }}
        />
        <NeedByDateField disabled={isAdding} />
      </Stack>
      <WorkRequestNameField disabled={isAdding} shouldUnregister={shouldUnregisterFields} />
      <WorkRequestDescriptionField disabled={isAdding} shouldUnregister={shouldUnregisterFields} />
    </Box>
  );
};

const WorkRequestNameField = ({ ...rest }) => (
  <TextField
    autoComplete="off"
    name="WorkRequestName"
    label="Work Request Name*"
    rules={requiredRule('WorkRequestName', '*Required')}
    maxLength={64}
    data-cy="work-request-name"
    {...rest}
  />
);

const WorkRequestDescriptionField = ({ ...rest }) => (
  <TextField
    multiline
    rows={6}
    name="WorkRequestDescription"
    label="Description"
    maxLength={1000}
    data-cy="work-request-description"
    {...rest}
  />
);

const TextField = ({ ...rest }) => {
  const { control } = useFormContext();
  return (
    <InputForesiteField
      inputProps={{ style: { backgroundColor: 'transparent' } }}
      fullWidth
      size="small"
      color="secondary"
      control={control}
      {...rest}
    />
  );
};

const InformationField = ({ label, value }) => (
  <Box sx={{ flexDirection: 'column', width: '100%', maxWidth: '210px' }}>
    <Typography sx={{ fontSize: '12px', height: '23px', color: 'surface.lightSurface.secondary' }}>{label}</Typography>
    <OverflowTip title={value} offset={{ x: 0, y: -8 }}>
      <Typography
        color="primary"
        sx={{
          fontSize: '16px',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {value}
      </Typography>
    </OverflowTip>
  </Box>
);
