import './attachments.css';
import { ReactNode, useState } from 'react';

import { Flex, Loader } from '@mantine/core';
import type { ColDef } from 'ag-grid-community';
import { format, parseISO } from 'date-fns';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { CellRenderer } from 'components/Mantine/CellRenderer';
import { TextWithRef } from 'components/Mantine/TextWithRef';
import { withHttp } from 'helpers/common';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useUsersInfo } from 'hooks/useUsersInfo';

import { DeleteAttachment } from './DeleteAttachment';
import type { DocumentSkeleton, RenderableDocument } from './types';
import { useDocumentsCache } from './useDocumentsCache';

const LinkFollower = ({ attachment, children }: { attachment: DocumentSkeleton; children: ReactNode }) => {
  const { user } = useUser();
  const { getPresignedUrl, attachmentToDocumentMap } = useDocumentsCache();
  const { externalUrl } = attachment;
  const [presigning, setPresigning] = useState(false);
  return (
    <Flex
      onClick={() => {
        if (typeof externalUrl === 'string') {
          window.open(withHttp(externalUrl), '_blank');
        } else {
          const document = attachmentToDocumentMap[attachment.documentId];
          if (presigning || isNil(document) || isNil(user)) return;
          setPresigning(true);
          getPresignedUrl({
            objectKey: document.storagePath,
            expirationHours: 24,
            requestedBy: user.userId,
            verb: 'GET',
          })
            .then(({ preSignedURL }) => {
              window.open(preSignedURL, '_blank');
            })
            .finally(() => setPresigning(false));
        }
      }}
      align="center"
      gap="sm"
      className="attachment-link"
      style={{ height: '100%', width: '100%' }}
    >
      {children}
      {presigning ? (
        <Loader size="xs" />
      ) : (
        <EvolveIcon
          className="show-on-row-hover"
          color="inherit"
          size="sm"
          icon={attachment.externalUrl ? null : 'Download'}
        />
      )}
    </Flex>
  );
};

const FileNameRenderer = ({ attachment }: { attachment: DocumentSkeleton }) => {
  const { attachmentToDocumentMap, loadingDocuments } = useDocumentsCache();
  const { externalUrl, documentId } = attachment;
  // I'm not sure why this type narrows,
  // but `if (externalUrl)` doesn't
  if (typeof externalUrl === 'string') {
    return (
      <AgGridStyleTooltip label={externalUrl}>
        <TextWithRef>{externalUrl}</TextWithRef>
      </AgGridStyleTooltip>
    );
  }
  const document = documentId in attachmentToDocumentMap ? attachmentToDocumentMap[documentId] : undefined;
  if (isNotNil(document)) {
    return document.documentNameFormatted;
  }
  if (loadingDocuments) {
    return <Loader size="sm" variant="dots" />;
  }
  return '--';
};

const DateUploadedRenderer = ({
  attachment: { documentId, createdBy, createdOn },
}: {
  attachment: DocumentSkeleton;
}) => {
  const { attachmentToDocumentMap } = useDocumentsCache();
  const { cachedUserData } = useUsersInfo();
  const document = isNotNil(documentId) ? attachmentToDocumentMap[documentId] : null;
  const uploadedOn = document?.updatedOn ?? document?.createdOn ?? createdOn;
  const uploadedBy = document?.updatedBy ?? document?.createdBy ?? createdBy;
  const createdOnDate = parseISO(`${uploadedOn}Z`);
  const tooltip = `Uploaded${
    uploadedBy in cachedUserData ? ` by ${cachedUserData[uploadedBy].completeName}` : ''
  } at ${format(createdOnDate, 'h:mmaaa')}`;
  return (
    <AgGridStyleTooltip
      label={tooltip}
      withArrow
      withinPortal
      openDelay={400}
      // To appear on top of the Drawer
      zIndex={100001}
    >
      <TextWithRef>{format(createdOnDate, 'MM/dd/yyyy')}</TextWithRef>
    </AgGridStyleTooltip>
  );
};

export const getDocumentColumnDef = <T extends RenderableDocument>(locked: boolean, onUpdate: () => void) =>
  [
    {
      width: 40,
      cellRenderer: CellRenderer<T>(
        ({ data }) => <EvolveIcon dark size="sm" icon={isNotNil(data.externalUrl) ? 'ExternalURL' : 'Attachment'} />,
        { flexProps: { justify: 'center' } },
      ),
    },
    {
      flex: 1,
      cellRenderer: CellRenderer<T>(({ data }) => (
        <LinkFollower attachment={data}>
          <FileNameRenderer attachment={data} />
        </LinkFollower>
      )),
    },
    {
      field: 'createdOn',
      width: 100,
      cellRenderer: CellRenderer<T>(({ data }) => <DateUploadedRenderer attachment={data} />),
      cellStyle: {
        paddingLeft: 10,
      },
    },
    {
      lockPosition: 'right',
      width: 40,
      cellRenderer: CellRenderer<T>(
        ({ data, api }) => (
          <DeleteAttachment
            document={data}
            refresh={() => {
              onUpdate();
              api.refreshServerSide({ purge: true });
            }}
          />
        ),
        { show: !locked },
      ),
    },
  ] as ColDef<T>[];
