import { useEffect, useState } from 'react';

import { Loader } from '@mantine/core';
import { parseISO, isAfter } from 'date-fns';
import { Navigate } from 'react-router-dom';

import useQuery from 'hooks/useQuery';

import { useConfirmUser, isInvitationType, useFetchProviderStatus, UserFromProvider } from './auth/restCalls';
import type { LoginErrorCode } from './components/LoginErrorAlert';
import { Join } from './Join/Join';

export const AcceptInvitation = () => {
  const { confirm, confirming } = useConfirmUser();
  const { fetchProviderStatus } = useFetchProviderStatus();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserFromProvider>();

  const queryParams = useQuery();
  const userId = queryParams.get('userId');
  const inviteSourceName = queryParams.get('name');
  const type = queryParams.get('type');
  const id = queryParams.get('id');
  const code = queryParams.get('code');
  const expirationDateStr = queryParams.get('expiration');

  const [errorCode, setErrorCode] = useState<LoginErrorCode>();

  useEffect(() => {
    if (expirationDateStr) {
      const expirationDate = parseISO(expirationDateStr);
      const hasExpired = isAfter(new Date(), expirationDate);
      if (hasExpired) {
        setLoading(false);
        setErrorCode('InvitationExpired');
        return;
      }
    }

    (async () => {
      try {
        if (isInvitationType(type) && !!id && !!code) {
          await confirm(id, type, {
            confirmationCode: code,
          }).catch((e) => {
            // eslint-disable-next-line no-console
            console.error('Could not accept invite directly, user is likely not confirmed.', e);
          });
        }
        if (userId) {
          const userResponse = await fetchProviderStatus(userId);
          if ('userName' in userResponse) {
            setUser(userResponse);
          } else {
            setErrorCode('UserNotFoundException');
          }
        }
      } catch (error: any) {
        setErrorCode(error.code ?? 'UnknownException');
      } finally {
        setLoading(false);
      }
    })();
  }, [code, confirm, expirationDateStr, fetchProviderStatus, id, type, userId]);

  if (errorCode === 'InvitationExpired') {
    return <Navigate to="/invitation-expired" />;
  }

  if (errorCode || !code || !userId || !id || !isInvitationType(type)) {
    return <Navigate to={`/auth/login?errorCode=${errorCode ?? 'UnknownException'}`} />;
  }

  if (loading || confirming) return <Loader />;

  return user?.userStatus === 'CONFIRMED' ? (
    <Navigate to="/auth/login" />
  ) : (
    <Join inviteSourceName={inviteSourceName} type={type} id={id} code={code} />
  );
};
