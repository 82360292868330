/* eslint-disable import/no-cycle */
import { useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import { useItemsIntegrationTableContext } from '../../../context/ItemsIntegrationTableContext';
import { useFieldContext } from '../../QuantityField/context/FieldContext';
import { QTY_LIMIT_EXCEEDED_ERROR_MESSAGE } from '../constants';

export const useValidateNumberRangeCallbacks = () => {
  const { fieldName, rangeErrorMessage, isBomLineItem } = useFieldContext();
  const { setFieldErrors } = useItemsIntegrationTableContext();
  const { clearErrors, setError } = useFormContext();
  const clearAllErrors = useCallback((fieldNameToClear?: string) => {
    clearErrors(fieldNameToClear);
    setFieldErrors((current) => {
      if (!fieldNameToClear) return current;
      return current.filter((field) => fieldNameToClear !== field);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addFieldError = useCallback(
    () =>
      setFieldErrors((current) => {
        if (current.includes(fieldName)) return current;
        return [...current, fieldName];
      }),
    [fieldName, setFieldErrors],
  );
  const setRangeError = useCallback(
    () => setError(fieldName, { type: 'rangeError', message: rangeErrorMessage }),
    [fieldName, rangeErrorMessage, setError],
  );
  const setValidateError = useCallback(
    () => setError(fieldName, { type: 'validate', message: rangeErrorMessage }),
    [fieldName, rangeErrorMessage, setError],
  );
  const setQtyLimitError = useCallback(
    () => setError(fieldName, { type: 'quantityLimitExceeded', message: QTY_LIMIT_EXCEEDED_ERROR_MESSAGE }),
    [fieldName, setError],
  );
  const handleNegativeAmount = useCallback(
    (numberValue: number) => {
      if (numberValue !== 0) {
        addFieldError();
        setRangeError();
        return rangeErrorMessage;
      }
      clearAllErrors(fieldName);
      return true;
    },
    [addFieldError, clearAllErrors, fieldName, rangeErrorMessage, setRangeError],
  );

  const addValidateError = () => {
    if (isBomLineItem) {
      addFieldError();
      setValidateError();
      return rangeErrorMessage;
    }
    return true;
  };

  const addRangeError = () => {
    addFieldError();
    setRangeError();
    return rangeErrorMessage;
  };

  return { clearAllErrors, addValidateError, addRangeError, addFieldError, handleNegativeAmount, setQtyLimitError };
};
