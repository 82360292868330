import React from 'react';

import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

type Props = {
  label: string;
} & (
  | {
      onClick: () => void;
    }
  | {
      onClick?: never;
      url: string;
      linkState: any;
      companyId: string;
    }
);

const StyledLink = ({ label, ...props }: Props) => {
  const navigate = useNavigate();

  return (
    <Link
      onClick={
        props.onClick ??
        (() => {
          const { url, linkState, companyId } = props;
          navigate(url, { state: { linkState, companyId } });
        })
      }
      sx={{
        color: 'secondary.main',
        cursor: 'pointer',
        textDecorationColor: 'transparent',
      }}
    >
      {label}
    </Link>
  );
};

export default StyledLink;
