/* eslint-disable max-lines-per-function */
import React from 'react';

import { SxProps } from '@mui/material';
import BreadcrumsMUI from '@mui/material/Breadcrumbs';

import { slugify } from 'helpers/stringFunctions';

import { formatBreadcrumbs } from './BreadcrumbsHelpers';
import { BreadcrumbsItemType, CollapseModeType } from './BreadcrumbTypes';
import BreadcrumbsItem from './components/BreadcrumbsItem';

interface BreadcrumbsProps {
  breadcrumbs: Array<BreadcrumbsItemType>;
  darkMode: boolean;
  separator?: React.ReactNode;
  sx?: SxProps;
  lastLabelSx?: SxProps;
  labelSx?: SxProps;
  maxItems?: number;
  collapseMode?: CollapseModeType;
  itemsAfterCollapse?: number;
  itemsBeforeCollapse?: number;
}

const Breadcrumbs = ({
  breadcrumbs = [],
  separator,
  darkMode,
  sx,
  labelSx,
  lastLabelSx,
  maxItems,
  collapseMode = 'default',
  itemsAfterCollapse = 1,
  itemsBeforeCollapse = 1,
  ...props
}: BreadcrumbsProps) => {
  const isSeparatorComponent = typeof separator === 'function';
  const separatorProps = { darkMode };

  const breadcrumbsBuilder = () => {
    const breadcrumbsList = formatBreadcrumbs({
      breadcrumbs,
      maxItems,
      collapseMode,
      itemsBeforeCollapse,
    });

    return breadcrumbsList.map((breadcrumbItem, index) => {
      const isLastItem = index === breadcrumbsList.length - 1;
      return (
        <BreadcrumbsItem
          key={slugify(breadcrumbItem.label)}
          {...breadcrumbItem}
          isLastItem={isLastItem}
          darkMode={darkMode}
          labelSx={labelSx}
          lastLabelSx={lastLabelSx}
        />
      );
    });
  };

  return (
    <BreadcrumsMUI
      separator={isSeparatorComponent ? React.cloneElement(separator, separatorProps) : separator}
      aria-label="breadcrumb"
      sx={{
        color: darkMode ? 'surface.darkSurface.secondary' : 'surface.lightSurface.secondary',
        ...sx,
      }}
      {...(maxItems && collapseMode === 'default' ? { maxItems, itemsBeforeCollapse, itemsAfterCollapse } : {})}
      {...props}
    >
      {breadcrumbsBuilder()}
    </BreadcrumsMUI>
  );
};

export default Breadcrumbs;
