import { ColDef } from 'ag-grid-community';

import { CellRenderer } from 'components/Mantine/CellRenderer';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';

import { InsightsDetailsWorkOrder, InsightsDetailsWorkRequest, InsightsStatusBadgeType } from './types';

export const getWorkOrderColumnDefs = (): ColDef<InsightsDetailsWorkOrder>[] => [
  {
    field: 'workOrderId',
  },
  {
    field: 'projectName',
  },
  {
    field: 'facilityName',
  },
  {
    field: 'workOrderName',
  },
  {
    field: 'status',
    cellRenderer: CellRenderer<InsightsDetailsWorkOrder & InsightsStatusBadgeType>(({ data, value }) => (
      <CellStatusBadge
        status={value}
        daysRemaining={data.status !== 'Completed' ? data.daysRemaining : data.daysEarly ?? -data.daysLate}
      />
    )),
  },
  {
    field: 'needBy',
  },
  {
    field: 'workOrderDescription',
    headerName: 'Comments',
    tooltipField: 'workOrderDescription',
  },
  {
    field: 'daysRemaining',
  },
  {
    field: 'dateApproved',
  },
  {
    field: 'dateInProgress',
  },
  {
    field: 'dateCompleted',
  },
  {
    field: 'daysEarly',
  },
  {
    field: 'daysLate',
  },
  {
    field: 'manufacturingLeadTimeDays',
  },
  {
    field: 'productionLeadTimeDays',
  },
];

export const getWorkRequestColumnDefs = (): ColDef<InsightsDetailsWorkRequest>[] => [
  {
    field: 'workRequestId',
  },
  {
    field: 'projectName',
  },
  {
    field: 'facilityName',
  },
  {
    field: 'workRequestName',
  },
  {
    field: 'status',
    cellRenderer: CellRenderer<InsightsDetailsWorkRequest & InsightsStatusBadgeType>(({ data, value }) => (
      <CellStatusBadge
        status={value}
        daysRemaining={data.status !== 'Completed' ? data.daysRemaining : data.daysEarly ?? -data.daysLate}
      />
    )),
  },
  {
    field: 'needBy',
  },
  {
    field: 'workRequestDescription',
    headerName: 'Comments',
    tooltipField: 'workRequestDescription',
  },
  {
    field: 'daysRemaining',
  },
  {
    field: 'dateLastSpoolPublished',
    headerName: 'Date Revit Published',
  },
  {
    field: 'submittedBy',
    tooltipField: 'submittedBy',
  },
  {
    field: 'dateSubmitted',
  },
  {
    field: 'dateApproved',
  },
  {
    field: 'dateInProgress',
  },
  {
    field: 'dateCompleted',
  },
  {
    field: 'daysEarly',
  },
  {
    field: 'daysLate',
  },
  {
    field: 'orderLeadTimeDays',
  },
];
