import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { useUser } from 'app/UserContext';
import useGeneralContext from 'helpers/useGeneralContext';
import type { DocumentId } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/types';
import { useDocumentsCache } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';
import { useUploadDocuments } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useUploadDocuments';

type ProfilePictureContextType = {
  /** Presigned URL */
  profilePicture: string | null;
  uploading: boolean;
  uploadProfilePicture: (file: File) => Promise<DocumentId | undefined>;
  updateProfilePicture: (documentId: DocumentId) => Promise<void>;
};

const ProfilePictureContext = React.createContext<ProfilePictureContextType | undefined>(undefined);

export const ProfilePictureProvider = ({ children }: { children: ReactNode }) => {
  const { uploadDocument, saving } = useUploadDocuments();
  const { getPresignedUrlForDocument } = useDocumentsCache();
  const { user, setUpdateUserListener } = useUser();
  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  useEffect(() => {
    if (user?.userPhotoId && user?.userId) {
      getPresignedUrlForDocument(user.userPhotoId, {
        expirationHours: 24,
        requestedBy: user.userId,
        verb: 'GET',
      }).then((r) => setProfilePicture(r?.preSignedURL ?? null));
    }
  }, [getPresignedUrlForDocument, user?.userPhotoId, user?.userId]);

  const updateProfilePicture = useCallback<ProfilePictureContextType['updateProfilePicture']>(
    async (userPhotoId) => {
      if (!user) return;

      const resp = await getPresignedUrlForDocument(userPhotoId, {
        expirationHours: 24,
        requestedBy: user.userId,
        verb: 'GET',
      });
      if (resp) {
        const { preSignedURL } = resp;
        setProfilePicture(preSignedURL);
        setUpdateUserListener({ user, getPresignedURL: preSignedURL });
      }
    },
    [getPresignedUrlForDocument, setUpdateUserListener, user],
  );

  const uploadProfilePicture = useCallback<ProfilePictureContextType['uploadProfilePicture']>(
    async (imgFile: File) => {
      if (!user) return undefined;
      return uploadDocument(imgFile, { user });
    },
    [uploadDocument, user],
  );

  const value = useMemo(
    () => ({
      profilePicture,
      uploading: saving,
      uploadProfilePicture,
      updateProfilePicture,
    }),
    [profilePicture, saving, updateProfilePicture, uploadProfilePicture],
  );

  return <ProfilePictureContext.Provider value={value}>{children}</ProfilePictureContext.Provider>;
};

export const useProfilePictureContext = () => useGeneralContext(ProfilePictureContext, 'ProfilePicture');
