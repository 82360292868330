import { ColorSwatch } from '@mantine/core';

import { useMantineColor } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { statusToColorMap } from 'constants/badgeMappingStatus';
import type { WorkRequestItemStatusTypeId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { workOrderStatusMap, type WorkOrderItemStatusTypeId } from './types';

export const StatusPoint = ({ status }: { status: WorkRequestItemStatusTypeId | WorkOrderItemStatusTypeId }) => {
  const statusName = workOrderStatusMap[status];
  const { color, label } = statusToColorMap[statusName];
  const { mantineColor } = useMantineColor(color);
  return (
    <AgGridStyleTooltip withinPortal withArrow label={label} openDelay={0}>
      <ColorSwatch size={8} color={mantineColor ?? color} withShadow={false} />
    </AgGridStyleTooltip>
  );
};
