import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';

import { Customers } from './customers/Customers';
import EvolveMembers from './evolveMembers';

export const Dashboard = () => (
  <ComponentPaneLayout>
    <div>Management dashboard</div>
  </ComponentPaneLayout>
);

export const EvolveMembersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <EvolveMembers />
  </ComponentPaneLayout>
);

export const CustomersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <Customers />
  </ComponentPaneLayout>
);
