import React, { useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormProvider, useForm } from 'react-hook-form';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { Modal } from 'components';
import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { updateProject as UPDATE_PROJECT } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';

import ProjectIdentifierForm from './ProjectIdentifierForm';

const ProjectIdentifierModal = ({ open, onClose = () => {}, openWorkRequestModal = () => {} }) => {
  const { selectedItem, setSelectedItem } = useFacilitiesProjects();
  const [idsAlreadyAdded, setIdsAlreadyAdded] = useState([]);
  const [updateProject, { loading }] = useMutation(gql(UPDATE_PROJECT));
  const { handleResponse } = useGraphqlResponseHandler();

  const methods = useForm({ mode: 'all' });

  const {
    trigger,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = methods;

  const successCallback = (newIdentifier) => {
    setSelectedItem({ ...selectedItem, identifier: newIdentifier?.projectIdentifier });
    onClose();
    openWorkRequestModal();
  };

  const submit = async (inputData) => {
    await handleResponse(
      updateProject,
      {
        variables: {
          params: { projectId: selectedItem?.id },
          body: { projectIdentifier: inputData.projectIdentifier },
        },
      },
      {
        successMessage: 'Project Id successfully added',
        errorMessage: GENERIC_MUTATION_ERROR,
      },
      () => successCallback(inputData),
      () => {
        setIdsAlreadyAdded([...idsAlreadyAdded, inputData.projectIdentifier]);
        trigger('projectIdentifier');
      },
    );
  };

  return (
    <Modal
      open={open}
      title="Add project identifier"
      onClose={onClose}
      footer={
        <ModalFooter
          onCancel={onClose}
          onSubmit={handleSubmit(submit)}
          disable={!isValid || isSubmitting}
          loading={loading}
        />
      }
    >
      <Stack>
        <ModalInfo selectedItem={selectedItem} />
        <FormProvider {...methods}>
          <ProjectIdentifierForm disable={isSubmitting} idValidation={idsAlreadyAdded} />
        </FormProvider>
      </Stack>
    </Modal>
  );
};

export default ProjectIdentifierModal;

const ModalInfo = ({ selectedItem }) => (
  <Stack>
    <Typography sx={{ fontSize: '12px', opacity: 0.5, marginLeft: '24px' }}>Project Name</Typography>
    <Typography sx={{ margin: '-10px 0 0 16px' }}>{selectedItem?.label}</Typography>
    <Typography mt={5} fontWeight="bold">
      Please assign a project id to the project
    </Typography>
  </Stack>
);

const ModalFooter = ({ onSubmit, onCancel, disable, loading }) => {
  const { palette } = useTheme();
  return (
    <Stack direction="row">
      {loading && <CircularProgress />}
      <Button sx={{ marginRight: '8px', fontWeight: 'bold' }} onClick={() => onCancel(false)} disabled={loading}>
        Cancel
      </Button>
      <Button
        sx={{
          fontWeight: 'bold',
          backgroundColor: disable ? palette.action.disabled : palette.secondary.main,
        }}
        variant="contained"
        color="secondary"
        onClick={onSubmit}
        disabled={disable}
      >
        Done
      </Button>
    </Stack>
  );
};
