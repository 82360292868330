import './headerSearch.css';
import { useState } from 'react';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { isNotNil } from 'helpers/isNotNil';
import { FacilityId, useFacilities } from 'hooks/projectsAndFacilities/useFacilities';
import { ProjectId, useProjects } from 'hooks/projectsAndFacilities/useProjects';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';

export const HeaderSearch = () => {
  const { selectProject, selectFacility } = useSelectedProjectFacility();
  const { projects } = useProjects();
  const { facilities } = useFacilities();
  const [selected, setSelected] = useState<ProjectId | FacilityId | null>(null);

  const onChange = (id: ProjectId | FacilityId | null) => {
    if (!id) return;
    setSelected(id);
    const project = projects.find((p) => p.projectId === id);
    if (isNotNil(project)) {
      selectProject(project);
      return;
    }
    const facility = facilities.find((f) => f.facilityId === id);
    if (isNotNil(facility)) {
      selectFacility(facility);
    }
  };

  return (
    <WrappedSelect
      className="header-search"
      searchable
      value={selected}
      placeholder="Open Facilities or Projects..."
      onChange={onChange}
      onBlur={() => setSelected(null)}
      icon={<EvolveIcon icon="Search" />}
      maxDropdownHeight={600}
      autoComplete="off"
      data={[
        ...facilities
          .sort((a, b) => a.facilityName.localeCompare(b.facilityName))
          .map((f) => ({
            value: f.facilityId,
            label: f.facilityName,
            group: 'Facilities',
          })),
        ...(facilities.length === 0
          ? [
              {
                value: 'no-facility',
                label: 'No facilities found',
                group: 'Facilities',
                disabled: true,
              } as const,
            ]
          : []),
        ...projects
          .sort((a, b) => a.projectName.localeCompare(b.projectName))
          .map((p) => ({
            value: p.projectId,
            label: p.projectName,
            group: 'Projects',
          })),
        ...(projects.length === 0
          ? [
              {
                value: 'no-project',
                label: 'No projects found',
                group: 'Projects',
                disabled: true,
              } as const,
            ]
          : []),
      ]}
    />
  );
};
