import React from 'react';

import { createSvgIcon } from '@mui/material';

import { ReactComponent as DefaultImage } from '../backgrounds/default-image.svg';
import { ReactComponent as AccessTimeSvg } from './access-time.svg';
import { ReactComponent as AccountCircleSvg } from './account-circle-icon.svg';
import { ReactComponent as AccountCircle } from './account-circle.svg';
import { ReactComponent as AddSvg } from './add-alt.svg';
import { ReactComponent as AddBoxOutlinedSvg } from './add-box-outlined.svg';
import { ReactComponent as AddBox } from './add-box.svg';
import { ReactComponent as AddBoxAlt } from './add-box1.svg';
import { ReactComponent as AddCircleOutlineSvg } from './add-circle-outlined.svg';
import { ReactComponent as AddCircleSvg } from './add-circle.svg';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as AddBoxSvg } from './addbox.svg';
import { ReactComponent as Alert } from './alert.svg';
import { ReactComponent as Apps } from './apps.svg';
import { ReactComponent as ArchivedActive } from './archived-active.svg';
import { ReactComponent as ArchivedInactive } from './archived-inactive.svg';
import { ReactComponent as Archived } from './archived.svg';
import { ReactComponent as ArrowBackAlt } from './arrow-back-alt.svg';
import { ReactComponent as ArrowDropDownSvg } from './arrow-down-alt.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowDownwardSvg } from './arrow-downward.svg';
import { ReactComponent as ArrowDropDown } from './arrow-drop-down.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowRightSvg } from './arrowright.svg';
import { ReactComponent as AssemblyIcon } from './assembly.svg';
import { ReactComponent as AttachFile } from './attach-file.svg';
import { ReactComponent as AutodeskIcon } from './autodesk-icon.svg';
import { ReactComponent as Bim360Icon } from './bim360-icon.svg';
import { ReactComponent as CalendarBoard } from './calendar-board.svg';
import { ReactComponent as CalendarTodayOutlined } from './calendar-today.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Camera } from './camera.svg';
import { ReactComponent as Cancel } from './cancel.svg';
import { ReactComponent as CanvasFitView } from './canvas-fit-view.svg';
import { ReactComponent as CanvasHorizontalLayout } from './canvas-horizontal-layout.svg';
import { ReactComponent as CanvasLock } from './canvas-lock.svg';
import { ReactComponent as CanvasUnLock } from './canvas-unlock.svg';
import { ReactComponent as CanvasVerticalLayout } from './canvas-vertical-layout.svg';
import { ReactComponent as CanvasZoomIn } from './canvas-zoom-in.svg';
import { ReactComponent as CanvasZoomOut } from './canvas-zoom-out.svg';
import { ReactComponent as CatalogToolAdd } from './catalog-tool-add.svg';
import { ReactComponent as CheckBoxOutlineBlankSvg } from './check-box-outline.svg';
import { ReactComponent as CheckBoxSvg } from './check-box.svg';
import { ReactComponent as CheckCircleSvg } from './check-circle-icon.svg';
import { ReactComponent as CheckCircleOutlinePrimary } from './check-circle-outline-primary.svg';
import { ReactComponent as CheckCircleOutline } from './check-circle-outlined.svg';
import { ReactComponent as CheckCircle } from './check-circle.svg';
import { ReactComponent as Clear } from './clear.svg';
import { ReactComponent as CloseAlt } from './close-alt.svg';
import { ReactComponent as CloseSvg } from './close-icon.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as CloudUpload } from './cloud-upload.svg';
import { ReactComponent as CNC } from './cnc.svg';
import { ReactComponent as Complete } from './complete.svg';
import { ReactComponent as ContentCopySvg } from './content-copy.svg';
import { ReactComponent as ContentCutSvg } from './content-cut.svg';
import { ReactComponent as CopyFiles } from './copy-files.svg';
import { ReactComponent as CopyLink } from './copy-link.svg';
import { ReactComponent as Cursor } from './cursor.svg';
import { ReactComponent as CustomAssembly } from './custom_assembly.svg';
import { ReactComponent as DateRange } from './date-range.svg';
import { ReactComponent as DecisionIcon } from './decision-icon.svg';
import { ReactComponent as DeleteOutlineActive } from './delete-outline-active.svg';
import { ReactComponent as DeleteOutlineDisabled } from './delete-outline-disabled.svg';
import { ReactComponent as DeleteOutline } from './delete-outline.svg';
import { ReactComponent as DeleteSvg } from './delete.svg';
import { ReactComponent as DeveloperBoard } from './developer-board.svg';
import { ReactComponent as DisableByDefaultSvg } from './disable-by-default.svg';
import { ReactComponent as DockSvg } from './dock-icon.svg';
import { ReactComponent as Dock } from './dock.svg';
import { ReactComponent as DoubleCheck } from './double-check.svg';
import { ReactComponent as Download2 } from './download-2.svg';
import { ReactComponent as DownloadSvg } from './download-icon.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as DragIndicatorAlt } from './drag-indicator-alt.svg';
import { ReactComponent as DragIndicatorSvg } from './drag-indicator-icon.svg';
import { ReactComponent as DragIndicator } from './drag-indicator.svg';
import { ReactComponent as Drag } from './drag.svg';
import { ReactComponent as DrawingDocument } from './drawing-document.svg';
import { ReactComponent as SmallDrawingDocument } from './drawing-document_sm.svg';
import { ReactComponent as DrawingTypeCategory } from './drawing-type-category.svg';
import { ReactComponent as DrawingType } from './drawing-type.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Ellipsis } from './ellipsis.svg';
import { ReactComponent as Empty } from './empty.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as EvolveForesiteIcon } from './evolve-foresite-icon.svg';
import { ReactComponent as ExpandLessSvg } from './expand-less-icon.svg';
import { ReactComponent as ExpandMoreSvg } from './expand-more-icon.svg';
import { ReactComponent as ExpandMore } from './expand-more.svg';
import { ReactComponent as Export } from './export.svg';
import { ReactComponent as FiberManualRecordSvg } from './fiber-manual-record.svg';
import { ReactComponent as FileDownloadOutlinedSvg } from './file-download-outline.svg';
import { ReactComponent as Folder } from './filled-folder.svg';
import { ReactComponent as FilterListSvg } from './filter-list.svg';
import { ReactComponent as FlagSvg } from './flag-icon.svg';
import { ReactComponent as Flag } from './flag.svg';
import { ReactComponent as Floor } from './floor.svg';
import { ReactComponent as FolderOpen } from './folder-open.svg';
import { ReactComponent as FilledFolder } from './folder.svg';
import { ReactComponent as SmallFloor } from './folder_sm.svg';
import { ReactComponent as Gimlet } from './gimlet.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as History } from './history.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Hourglass } from './hourglass.svg';
import { ReactComponent as Import } from './import.svg';
import { ReactComponent as InfoIcon } from './info-icon.svg';
import { ReactComponent as InfoRounded } from './info-rounded.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as InfoOutline } from './info_outline.svg';
import { ReactComponent as Insights } from './insights.svg';
import { ReactComponent as KeyboardArrowDownAlt } from './keyboard-arrow-down-alt.svg';
import { ReactComponent as KeyboardArrowDown } from './keyboard-arrow-down.svg';
import { ReactComponent as KeyboardArrowDownSvg } from './keyboard-arrow-drop-down.svg';
import { ReactComponent as KeyboardArrowLeftSvg } from './keyboard-arrow-left-icon.svg';
import { ReactComponent as KeyboardArrowLeft } from './keyboard-arrow-left.svg';
import { ReactComponent as KeyboardArrowRightSvg } from './keyboard-arrow-right-icon.svg';
import { ReactComponent as KeyboardArrowRight } from './keyboard-arrow-right.svg';
import { ReactComponent as KeyboardArrowRight1 } from './keyboard-arrow-right1.svg';
import { ReactComponent as KeyboardArrowUpAlt } from './keyboard-arrow-up-alt.svg';
import { ReactComponent as KeyboardArrowUp } from './keyboard-arrow-up.svg';
import { ReactComponent as Link } from './link.svg';
import { ReactComponent as LocationMachine } from './location-machine.svg';
import { ReactComponent as LocationOn } from './location-marker.svg';
import { ReactComponent as LocationSystem } from './location-system.svg';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as LocationWorkPhase } from './locationWorkPhase.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as MajIcon } from './maj-file.svg';
import { ReactComponent as ManageIssues } from './manage-issues.svg';
import { ReactComponent as ManWithShovel } from './manWithShovel.svg';
import { ReactComponent as MenuBook } from './menu-book.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as MobileRestricted } from './mobile-restricted.svg';
import { ReactComponent as ModeComment } from './mode-comment.svg';
import { ReactComponent as Model } from './model.svg';
import { ReactComponent as MoreHorizSvg } from './more-horiz.svg';
import { ReactComponent as MoreVertSvg } from './more-vert-alt.svg';
import { ReactComponent as MoreVert } from './more-vert.svg';
import { ReactComponent as More } from './more.svg';
import { ReactComponent as NavigateNextSvg } from './navigate-next.svg';
import { ReactComponent as NewformaIcon } from './newforma-icon.svg';
import { ReactComponent as NoSearchContent } from './no-search-content.svg';
import { ReactComponent as NotSupported } from './not-supported.svg';
import { ReactComponent as Orbit } from './orbit.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as PauseArrow } from './pauseArrow.svg';
import { ReactComponent as PCItem } from './pc-item.svg';
import { ReactComponent as PlangridIcon } from './plangrid-icon.svg';
import { ReactComponent as Plans } from './plans.svg';
import { ReactComponent as PlayArrow } from './play.svg';
import { ReactComponent as Preview } from './preview.svg';
import { ReactComponent as PreviewText } from './previewText.svg';
import { ReactComponent as ProcoreIcon } from './procore-icon.svg';
import { ReactComponent as Publish } from './publish.svg';
import { ReactComponent as RadioButtonChecked } from './radio-button-checked.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as RemoveSvg } from './remove.svg';
import { ReactComponent as Repair } from './repair.svg';
import { ReactComponent as RequisitionsFilled } from './requisitions-filled.svg';
import { ReactComponent as Restore } from './restore.svg';
import { ReactComponent as RoundedAdd } from './rounded-add.svg';
import { ReactComponent as RoundedArrowLeft } from './rounded-arrow-left.svg';
import { ReactComponent as SandWatch } from './sand-watch.svg';
import { ReactComponent as SandWatchGreen } from './sandwatchGreen.svg';
import { ReactComponent as Save } from './save.svg';
import { ReactComponent as SearchSvg } from './search-alt.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as SharepointIcon } from './sharepoint-icon.svg';
import { ReactComponent as SmartDisplay } from './smart-display.svg';
import { ReactComponent as Spools } from './spools.svg';
import { ReactComponent as StatusSmall } from './status-small.svg';
import { ReactComponent as StopFilled } from './stop-filled.svg';
import { ReactComponent as Stop } from './stop.svg';
import { ReactComponent as SwapHorizontalActive } from './swap-horiz-active.svg';
import { ReactComponent as SwapHorizontalInactive } from './swap-horiz-inactive.svg';
import { ReactComponent as Sync } from './sync.svg';
import { ReactComponent as TableChart } from './table-chart.svg';
import { ReactComponent as Takeoff } from './takeoff.svg';
import { ReactComponent as Target } from './target.svg';
import { ReactComponent as PartThumbnailOutlined } from './thumbnail-part-outlined.svg';
import { ReactComponent as PartThumbnail } from './thumbnail-part.svg';
import { ReactComponent as Toolbox } from './toolbox.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as UpdateBg } from './update-bg.svg';
import { ReactComponent as Update } from './update.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Vector } from './vector.svg';
import { ReactComponent as Vector1 } from './vector1.svg';
import { ReactComponent as ViewColumnSvg } from './view-column-alt.svg';
import { ReactComponent as ViewColumn } from './view-column.svg';
import { ReactComponent as VisibilityOffSvg } from './visibility-off.svg';
import { ReactComponent as VisibilitySvg } from './visibility.svg';
import { ReactComponent as WindowsIcon } from './windows-icon.svg';
import { ReactComponent as WorkflowActive } from './workflow-active.svg';
import { ReactComponent as WorkflowInactive } from './workflow-inactive.svg';
import { ReactComponent as WriteInItem } from './write-in-item.svg';
import { ReactComponent as Zone } from './zone.svg';
import { ReactComponent as ZoomIn } from './zoom_in.svg';
import { ReactComponent as ZoomOut } from './zoom_out.svg';

const AddCircle = createSvgIcon(<AddCircleSvg />, 'AddCircle');
const AddCircleOutline = createSvgIcon(<AddCircleOutlineSvg />, 'AddCircleOutlineOutlined');
const AddBoxIcon = createSvgIcon(<AddBoxSvg />, 'AddBox');
const AddBoxOutlinedIcon = createSvgIcon(<AddBoxOutlinedSvg />, 'AddBoxOutlined');
const ArrowDownward = createSvgIcon(<ArrowDownwardSvg />, 'ArrowDownward');
const ArrowRightIcon = createSvgIcon(<ArrowRightSvg />, 'ArrowRight');
const MoreVerAlt = createSvgIcon(<MoreVertSvg />, 'MoreVert');
const MoreHoriz = createSvgIcon(<MoreHorizSvg />, 'MoreHoriz');
const ArrowDropDownIcon = createSvgIcon(<ArrowDropDownSvg />, 'ArrowDropDown');
const Remove = createSvgIcon(<RemoveSvg />, 'Remove');
const AddIcon = createSvgIcon(<AddSvg />, 'Add');
const KeyboardArrowDownIcon = createSvgIcon(<KeyboardArrowDownSvg />, 'KeyboardArrowDown');
const NavigateNextIcon = createSvgIcon(<NavigateNextSvg />, 'NavigateNext');
const ViewColumnIcon = createSvgIcon(<ViewColumnSvg />, 'ViewColumn');
const SearchIcon = createSvgIcon(<SearchSvg />, 'Search');
const FilterList = createSvgIcon(<FilterListSvg />, 'FilterList');
const DragIndicatorIcon = createSvgIcon(<DragIndicatorSvg />, 'DragIndicator');
const DisableByDefault = createSvgIcon(<DisableByDefaultSvg />, 'DisabledByDefault');
const DockIcon = createSvgIcon(<DockSvg />, 'Dock');
const CloseIcon = createSvgIcon(<CloseSvg />, 'Close');
const CheckBoxOutlineBlank = createSvgIcon(<CheckBoxOutlineBlankSvg />, 'CheckBoxOutlineBlank');
const CheckBox = createSvgIcon(<CheckBoxSvg />, 'CheckBox');
const CheckCircleIcon = createSvgIcon(<CheckCircleSvg />, 'CheckCircle');
const FiberManualRecord = createSvgIcon(<FiberManualRecordSvg />, 'FiberManualRecord');
const Visibility = createSvgIcon(<VisibilitySvg />, 'Visibility');
const VisibilityOff = createSvgIcon(<VisibilityOffSvg />, 'VisibilityOff');
const FlagIcon = createSvgIcon(<FlagSvg />, 'Flag');
const Delete = createSvgIcon(<DeleteSvg />, 'Delete');
const DownloadIcon = createSvgIcon(<DownloadSvg />, 'Download');
const ContentCopy = createSvgIcon(<ContentCopySvg />, 'ContentCopy');
const KeyboardArrowLeftIcon = createSvgIcon(<KeyboardArrowLeftSvg />, 'KeyboardArrowLeft');
const KeyboardArrowRightIcon = createSvgIcon(<KeyboardArrowRightSvg />, 'KeyboardArrowRight');
const ContentCut = createSvgIcon(<ContentCutSvg />, 'ContentCut');
const ExpandMoreIcon = createSvgIcon(<ExpandMoreSvg />, 'ExpandMore');
const ExpandLessIcon = createSvgIcon(<ExpandLessSvg />, 'ExpandLess');
const FileDownloadOutlined = createSvgIcon(<FileDownloadOutlinedSvg />, 'FileDownloadOutlined');
const AccessTime = createSvgIcon(<AccessTimeSvg />, 'AccessTime');
const AccountCircleIcon = createSvgIcon(<AccountCircleSvg />, 'AccountCircle');
const InfoOutlineIcon = createSvgIcon(<InfoOutline />, 'InfoOutline');
const SyncIcon = createSvgIcon(<Sync />, 'Sync');
const UpdateIcon = createSvgIcon(<UpdateBg />, 'UpdateBg');
const PartThumbnailIcon = createSvgIcon(<PartThumbnail />, 'PartThumbnail');
const WriteInItemIcon = createSvgIcon(<WriteInItem />, 'WriteInItem');

export {
  PCItem,
  AccessTime,
  ArrowDown,
  ArrowBackAlt,
  ArrowDownward,
  AddBoxIcon,
  Alert,
  DoubleCheck,
  DisableByDefault,
  ContentCopy,
  ContentCut,
  Complete,
  Export,
  Empty,
  Ellipsis,
  Floor,
  Folder,
  FilledFolder,
  SmallFloor,
  Gimlet,
  Hourglass,
  Info,
  LocationSystem,
  Location,
  LocationWorkPhase,
  MobileRestricted,
  More,
  NoSearchContent,
  Preview,
  PreviewText,
  Repair,
  Remove,
  RoundedAdd,
  SandWatch,
  SandWatchGreen,
  Target,
  Vector,
  Vector1,
  Visibility,
  VisibilityOff,
  Zone,
  CatalogToolAdd,
  PartThumbnail,
  PartThumbnailOutlined,
  PartThumbnailIcon,
  Takeoff,
  DefaultImage,
  CopyLink,
  Link,
  LocationMachine,
  DrawingDocument,
  SmallDrawingDocument,
  DrawingTypeCategory,
  DrawingType,
  CNC,
  Download,
  DownloadIcon,
  Download2,
  Model,
  Plans,
  RoundedArrowLeft,
  Camera,
  Cursor,
  CustomAssembly,
  Orbit,
  ZoomIn,
  ZoomOut,
  Drag,
  Upload,
  Trash,
  ExpandMore,
  ExpandMoreIcon,
  ExpandLessIcon,
  DragIndicator,
  DragIndicatorAlt,
  DragIndicatorIcon,
  Add,
  AddIcon,
  AccountCircle,
  AccountCircleIcon,
  Close,
  CloseAlt,
  CloseIcon,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  KeyboardArrowUp,
  KeyboardArrowUpAlt,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  KeyboardArrowDown,
  KeyboardArrowDownAlt,
  KeyboardArrowDownIcon,
  ArrowDropDownIcon,
  KeyboardArrowRight1,
  ArrowDropDown,
  CheckCircle,
  CheckCircleIcon,
  CheckCircleOutlinePrimary,
  CheckCircleOutline,
  InfoOutlineIcon,
  InfoRounded,
  ModeComment,
  ArrowRight,
  ArrowRightIcon,
  CheckBox,
  CheckBoxOutlineBlank,
  CalendarTodayOutlined,
  FilterList,
  FiberManualRecord,
  Error,
  Search,
  SearchIcon,
  Delete,
  DeleteOutline,
  DeleteOutlineActive,
  DeleteOutlineDisabled,
  Save,
  Update,
  UpdateIcon,
  SmartDisplay,
  Dock,
  DockIcon,
  InfoIcon,
  Refresh,
  AddBox,
  AddBoxOutlinedIcon,
  AddBoxAlt,
  Clear,
  Edit,
  Help,
  Mail,
  Apps,
  Archived,
  Cancel,
  Flag,
  FlagIcon,
  Menu,
  ManWithShovel,
  MenuBook,
  History,
  Restore,
  RadioButtonChecked,
  MoreVert,
  MoreHoriz,
  MoreVerAlt,
  ViewColumn,
  ViewColumnIcon,
  TableChart,
  DeveloperBoard,
  Pause,
  PauseArrow,
  PlayArrow,
  DateRange,
  AttachFile,
  LocationOn,
  CloudUpload,
  AddCircle,
  AddCircleOutline,
  NavigateNextIcon,
  FileDownloadOutlined,
  RequisitionsFilled,
  StatusSmall,
  SyncIcon,
  Stop,
  FolderOpen,
  StopFilled,
  CanvasLock,
  CanvasUnLock,
  CanvasFitView,
  CanvasZoomOut,
  CanvasZoomIn,
  CanvasVerticalLayout,
  CanvasHorizontalLayout,
  SharepointIcon,
  Bim360Icon,
  ProcoreIcon,
  PlangridIcon,
  WriteInItemIcon,
  NewformaIcon,
  AutodeskIcon,
  EvolveForesiteIcon,
  WindowsIcon,
  NotSupported,
  MajIcon,
  CalendarBoard,
  Toolbox,
  AssemblyIcon,
  Calendar,
  DecisionIcon,
  Import,
  CopyFiles,
  ManageIssues,
  Publish,
  WorkflowInactive,
  WorkflowActive,
  SwapHorizontalActive,
  SwapHorizontalInactive,
  ArchivedActive,
  ArchivedInactive,
  Spools,
  Home,
  Insights,
};
