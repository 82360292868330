import { useState } from 'react';

import { Navbar } from '@mantine/core';

import { NavBarContent } from './NavBarContent/NavBarContent';

export const DesktopNavBar = () => {
  const [open, setOpen] = useState(false);
  return (
    <Navbar
      width={{ base: open ? 260 : 64 }}
      height="calc(100vh - 64px)"
      p={8}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      bg="rgb(48,48,48)"
      withBorder
      // TODO: Remove once upgraded to Mantine 7
      zIndex={200}
      style={{
        scrollbarWidth: open ? undefined : 'none',
        borderColor: 'rgb(48,48,48)',
        position: 'fixed',
        whiteSpace: 'nowrap',
        transition: '.2s',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <NavBarContent />
    </Navbar>
  );
};

export default DesktopNavBar;
