import { ReactNode, useEffect, useState } from 'react';

import { Box, Checkbox, Divider, Flex, FlexProps, TextInput, TextInputProps } from '@mantine/core';

import { TriggerOnVisible } from 'hooks/useOnScreen';

import { AgGridStyleTooltip } from './AgGridStyleTooltip';
import { SearchableSelectorProps } from './searchableTypes';
import { useSearchableData } from './useSearchableData';

type Props<T, TKey extends keyof T> = SearchableSelectorProps<T, TKey, ReactNode> & {
  textInputProps?: Omit<TextInputProps, 'value' | 'onChange'>;
  flexListProps?: FlexProps;
  rightSideComponent?: ReactNode;
  hideCheckboxes?: boolean;
  searchDisabled?: boolean;
};

/**
 * Used for Check Lists whose "search" functinality
 * involves fetching data from the backend.
 */
export const SearchableCheckList = <T, TKey extends keyof T>({
  paginatedGet,
  getItemLabel,
  idKey,
  value,
  onChange,
  excludeIds,
  setIsDirty,
  fetchPageOpts,
  nothingFound = 'No results found.',
  searchKey,
  useLike,
  preselectedOptions = [],
  flexListProps,
  textInputProps,
  rightSideComponent,
  hideCheckboxes,
  disabled,
  disableOption,
  searchDisabled = disabled,
}: Props<T, TKey>) => {
  const { options, fetchNextPage, loading, loadingFresh, searchPhrase, setSearchPhrase } = useSearchableData({
    paginatedGet,
    fetchPageOpts,
    searchKey,
    useLike,
  });
  const [selectedOptions, setSelectedOptions] = useState<T[]>(preselectedOptions);

  useEffect(() => setSelectedOptions((o) => value ?? o), [value]);

  const selectOption = (option: T) => () => {
    const newOptions = [...selectedOptions];
    const indexOf = selectedOptions.findIndex((o) => o[idKey] === option[idKey]);
    if (indexOf >= 0) {
      newOptions.splice(indexOf, 1);
    } else {
      newOptions.push(option);
    }
    setIsDirty?.(true);
    setSelectedOptions(newOptions);
    onChange(newOptions);
  };

  const filteredSelectedOptions = selectedOptions.filter((o) => !options.some((op) => op[idKey] === o[idKey]));

  const renderOption = (o: T) => {
    const optionDisabled = disabled || disableOption?.(o);
    return (
      <Flex key={o[idKey] as string} align="center" gap="sm">
        {!hideCheckboxes && (
          <Checkbox
            onChange={selectOption(o)}
            checked={selectedOptions.some((so) => so[idKey] === o[idKey])}
            disabled={optionDisabled}
          />
        )}
        <Box
          fz="sm"
          onClick={optionDisabled ? undefined : selectOption(o)}
          style={{ cursor: optionDisabled ? undefined : 'pointer', lineHeight: 1.5, whiteSpace: 'nowrap' }}
        >
          {getItemLabel(o)}
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Flex gap="sm" mb="md" align="center" justify="space-between" style={{ flex: '0 1 auto', width: '100%' }}>
        <Flex gap="sm" align="center" style={{ width: '100%' }}>
          {!hideCheckboxes && (
            <AgGridStyleTooltip label="Deselect all" withArrow openDelay={100}>
              <Checkbox
                checked
                indeterminate
                onChange={() => {
                  setSelectedOptions([]);
                  onChange([]);
                }}
                disabled={selectedOptions.length === 0 || disabled}
              />
            </AgGridStyleTooltip>
          )}
          <TextInput
            placeholder="Search..."
            onChange={(e) => setSearchPhrase(e.target.value)}
            disabled={searchDisabled}
            {...textInputProps}
          />
        </Flex>
        {rightSideComponent}
      </Flex>
      <Flex
        direction="column"
        gap="xs"
        {...flexListProps}
        style={{ overflowY: 'auto', overflowX: 'hidden', ...flexListProps?.style }}
      >
        {!loadingFresh && (
          <>
            {filteredSelectedOptions.length === 0 && searchPhrase.length > 0 && (
              <Divider c="dimmed" label="Search results" />
            )}
            {filteredSelectedOptions.map(renderOption)}
            {filteredSelectedOptions.length > 0 && <Divider c="dimmed" label="Search results" />}
            {options.filter((o) => !excludeIds?.includes(o[idKey])).map(renderOption)}
          </>
        )}
        {!loading && options.length === 0 && (
          <Box c="dimmed" fz="sm">
            {nothingFound}
          </Box>
        )}
        <TriggerOnVisible onVisible={fetchNextPage} loading={loading} />
      </Flex>
    </>
  );
};
