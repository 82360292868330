import { useState } from 'react';

import { ActionIcon, Anchor, Divider, Flex, Popover, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

const helpDeskItems: {
  label: string;
  href?: string;
  onClick?: () => void;
}[] = [
  {
    label: 'Cookie Settings',
    onClick: () => {
      // @ts-expect-error
      // eslint-disable-next-line no-underscore-dangle
      window._hsp?.push(['showBanner']);
    },
  },
  { href: 'https://evolvemep.com/legal/privacy-policy', label: 'Privacy Policy' },
  { href: 'https://www.evolvemep.com/contact-support/', label: 'Contact Support' },
  {
    href: 'https://evolvemep.com/legal/eula',
    label: 'End-User License Agreement',
  },
  { href: 'https://help-foresite.evolvemep.com/', label: 'Knowledge Base' },
  { href: 'https://evolveforesite.featureupvote.com/', label: 'Submit Idea' },
];

export const HelpDeskPopover = () => {
  const [opened, setOpened] = useState(false);
  return (
    <Popover opened={opened} onChange={setOpened} position="bottom-end">
      <Popover.Target>
        <ActionIcon onClick={() => setOpened((o) => !o)} variant="filled" size="lg" radius="xl">
          <EvolveIcon icon="Help" size="sm" />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown mt="sm" p="md">
        <Flex direction="column" align="center" gap="sm">
          <Text>Help Desk</Text>
          <Divider style={{ width: '100%' }} />
          {helpDeskItems.map(({ label, ...props }) => (
            <Anchor
              key={label}
              {...props}
              size="sm"
              target="_blank"
              onClick={() => {
                props.onClick?.();
                setOpened(false);
              }}
            >
              {label}
            </Anchor>
          ))}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
