import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { Box, Flex, Loader, Overlay, Text } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { isNil, isNotNil } from 'helpers/isNotNil';
import usePartCategoriesCloudSearchAPI, { CatalogSearchResults } from 'hooks-api/usePartCategoriesCloudSearchAPI';
import usePartsCloudSearchAPI, { PartSearchResult } from 'hooks-api/usePartsCloudSearchAPI';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { useLocalStorage } from 'hooks/useLocalStorage';

import type { WorkRequestOrderProps } from '../../WorkRequestOrderItemsContext';
import { CatalogBreadcrumb, NavPartCategory } from './CatalogBreadcrumb';
import { CatalogSelect } from './CatalogSelect';
import { CategoryCard } from './CategoryCard';
import type { PartCategory, PartId, PartCategoryId, PartListingProps } from './types';

const MIN_SEARCH_LENGTH = 2 as const;

type Props =
  | {
      selectedItem: SelectedItem;
      refresh: () => void;
      rightSideComponent?: ReactNode;
      children: (props: PartListingProps) => ReactNode;
      disabled?: boolean;
    } & WorkRequestOrderProps;

export const Catalog = ({ rightSideComponent, disabled, children, ...props }: Props) => {
  const { selectedItem } = props;
  const [selectedCatalogId, setSelectedCatalogId] = useLocalStorage<'SELECTED_CATALOG_ID'>(
    LOCAL_STORAGE_CONSTANTS.SELECTED_CATALOG_ID,
    null,
  );
  const [selectedCategory, setSelectedCategory] = useState<NavPartCategory | null>(null);
  const [categoryHistory, setCategoryHistory] = useState<NavPartCategory[]>([]);

  const [searchPhrase, setSearchPhrase] = useDebouncedState('', 350);
  const [selectedSearchItem, setSelectedSearchItem] = useState<CatalogSearchResults | PartSearchResult | null>();
  const partFromSearch = isNotNil(selectedSearchItem) && 'partId' in selectedSearchItem ? selectedSearchItem : null;
  const categoryFromSearch =
    isNotNil(selectedSearchItem) && 'categoryId' in selectedSearchItem ? selectedSearchItem : null;

  const {
    data: categorySearchResult,
    searchPartCategories,
    loading: loadingSearchPartCategories,
  } = usePartCategoriesCloudSearchAPI(selectedCatalogId ?? undefined);
  const {
    data: partsSearchResult,
    searchParts,
    loading: loadingSearchParts,
  } = usePartsCloudSearchAPI(selectedCatalogId ?? undefined);

  const {
    data: categories,
    setDefaultOpts: setDefaultOptsCategory,
    loading: loadingCategories,
  } = useWrappedPaginatedGet<PartCategory>('moab/partCategory', { lazy: true });

  useEffect(() => {
    if (searchPhrase.length >= MIN_SEARCH_LENGTH) {
      searchParts(searchPhrase);
      searchPartCategories(searchPhrase);
    }
  }, [searchPartCategories, searchParts, searchPhrase]);

  useEffect(() => {
    setSelectedCategory((currentCategory) => {
      const category = isNil(currentCategory) ? categories[0] : currentCategory;
      if (isNotNil(category) && isNil(currentCategory)) {
        setCategoryHistory([category]);
      }
      return category;
    });
  }, [categories]);

  useEffect(() => {
    if (isNotNil(selectedCatalogId)) {
      setDefaultOptsCategory({
        lazy: false,
        defaultConfig: {
          params: {
            parentPartCategoryId: selectedCategory?.partCategoryId ?? '',
            partCatalogId: selectedCatalogId,
            orderBy: 'name:asc',
            companyId: selectedItem.companyId,
          },
        },
      });
    }
  }, [selectedCatalogId, selectedCategory, selectedItem.companyId, setDefaultOptsCategory]);

  const resetToFirstCategory = useCallback(() => {
    setSelectedSearchItem(null);
    setCategoryHistory(([baseCategory]) => {
      setSelectedCategory(baseCategory);
      return [baseCategory];
    });
  }, []);

  const disableCatalogNav = disabled || isNotNil(partFromSearch);

  return (
    <>
      <Flex justify="space-between" gap="sm" wrap="wrap">
        <Flex gap="sm" style={{ flex: '1 0 180px' }}>
          <CatalogSelect
            selectedCatalogId={selectedCatalogId}
            onCatalogSelected={(id) => {
              setCategoryHistory([]);
              setSelectedCatalogId(id);
              setSelectedCategory(null);
              setSelectedSearchItem(null);
            }}
            selectedItem={selectedItem}
            disabled={disableCatalogNav}
          />
          <Box pos="relative">
            <WrappedSelect<PartCategoryId | PartId>
              placeholder="Search..."
              disabled={isNil(selectedCatalogId) || disableCatalogNav}
              searchable
              filter={() => true}
              rightSection={<EvolveIcon icon={null} />}
              value={null}
              onChange={(id) => {
                if (isNotNil(categoryFromSearch)) {
                  resetToFirstCategory();
                }
                const selectedPartCategory = categorySearchResult.find((c) => c.categoryId === id);
                if (isNotNil(selectedPartCategory)) {
                  setSelectedSearchItem(selectedPartCategory);
                  const newCategory: NavPartCategory = {
                    partCategoryId: selectedPartCategory.categoryId,
                    partCategoryName: selectedPartCategory.categoryName,
                  };
                  setCategoryHistory((hist) => [hist[0], newCategory]);
                  setSelectedCategory(newCategory);
                  return;
                }
                const selectedPart = partsSearchResult.find((p) => p.partId === id);
                if (isNotNil(selectedPart)) {
                  setSelectedSearchItem(selectedPart);
                }
              }}
              nothingFound={
                searchPhrase.length < MIN_SEARCH_LENGTH || loadingSearchPartCategories || loadingSearchParts
                  ? 'Start typing...'
                  : undefined
              }
              maxDropdownHeight={400}
              styles={{
                wrapper: { minWidth: 100 },
                dropdown: { minWidth: 208 },
              }}
              onSearchChange={setSearchPhrase}
              data={
                searchPhrase.length >= MIN_SEARCH_LENGTH
                  ? [
                      ...(categorySearchResult.length === 0 || loadingSearchPartCategories
                        ? [
                            {
                              label: loadingSearchPartCategories ? 'Loading...' : 'No categories found.',
                              value: 'loadingCategories',
                              group: 'Categories',
                              disabled: true,
                            } as const,
                          ]
                        : categorySearchResult.map((c) => ({
                            label: c.categoryName,
                            value: c.categoryId,
                            group: 'Categories',
                          }))),
                      ...(partsSearchResult.length === 0 || loadingSearchParts
                        ? [
                            {
                              label: loadingSearchParts ? 'Loading...' : 'No parts found.',
                              value: 'loadingParts',
                              group: 'Parts',
                              disabled: true,
                            } as const,
                          ]
                        : partsSearchResult.map((p) => ({
                            label: p.partName,
                            value: p.partId,
                            group: 'Parts',
                          }))),
                    ]
                  : []
              }
            />
          </Box>
        </Flex>
        {rightSideComponent}
      </Flex>

      <Flex direction="column" mt="sm" style={{ flex: '1 0 0', overflowY: 'auto', position: 'relative' }}>
        <CatalogBreadcrumb
          categoryHistory={categoryHistory}
          setCategoryHistory={setCategoryHistory}
          setSelectedCategory={setSelectedCategory}
          blur={disableCatalogNav}
          categoryIsFromSearch={isNotNil(categoryFromSearch)}
          clearSearchCategory={resetToFirstCategory}
        />

        <Flex gap="md" mt="sm" wrap="wrap" pos="relative">
          {isNil(selectedCatalogId) && !loadingCategories && (
            <Text c="dimmed" ml="md" fz="sm">
              Select a catalog to get started.
            </Text>
          )}
          {disableCatalogNav && <Overlay color="#fff" blur={2} />}
          {!loadingCategories && categories.length === 0 && isNotNil(selectedCategory) && (
            <Text c="dimmed" m="md" fz="sm">
              No sub categories found.
            </Text>
          )}
          {loadingCategories && <Loader m="xs" />}
          {categories.map((c) => (
            <CategoryCard
              key={c.partCategoryId}
              category={c}
              onClick={() => {
                setSelectedCategory(c);
                setCategoryHistory((h) => [...h, c]);
              }}
            />
          ))}
        </Flex>
        {children({
          partCatalogId: selectedCatalogId,
          partCategoryId: selectedCategory?.partCategoryId,
          overridePartName: partFromSearch?.partName,
          goBack: () => setSelectedSearchItem(null),
          ...props,
        })}
      </Flex>
    </>
  );
};
