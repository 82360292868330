import { Checkbox } from '@mantine/core';
import type { GridApi } from 'ag-grid-community';

import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';

export const DeselectAllCheckbox = ({ api, enabled }: { api: GridApi; enabled: boolean }) => (
  <AgGridStyleTooltip label="Deselect all" withArrow openDelay={100}>
    <Checkbox
      checked
      indeterminate
      disabled={!enabled}
      onChange={() => api.deselectAll()}
      size="xs"
      styles={{
        input: {
          cursor: 'pointer',
        },
      }}
    />
  </AgGridStyleTooltip>
);
