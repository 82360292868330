/* eslint-disable no-console */
import { useCallback, useState } from 'react';

import { LOCAL_STORAGE_CONSTANTS, LOCAL_STORAGE_KEY, LOCAL_STORAGE_TYPE } from 'constants/globalConstants';

export const getLocalStorage = <T = unknown>(key: string, initialValue?: T): typeof initialValue => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? (JSON.parse(item) as T) : initialValue;
  } catch (error) {
    console.error(`Error [getLocalStorage], ${error}`);
    return initialValue;
  }
};

export const setLocalStorage = <T>(key: string, value: T, throwOnError = false) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error [setLocalStorage], ${error}`);
    if (throwOnError) throw error;
  }
};

export const removeLocalStorage = (key: string, throwOnError = false) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error [removeLocalStorage], ${error}`);
    if (throwOnError) throw error;
  }
};

/**
 * Type-safe local storage handler.
 * @param key The local storage key name
 * @param initialValue The value to return if no value found in storage
 */
export const useLocalStorage = <K extends LOCAL_STORAGE_KEY, V extends LOCAL_STORAGE_TYPE[K] = LOCAL_STORAGE_TYPE[K]>(
  key: typeof LOCAL_STORAGE_CONSTANTS[K],
  initialValue: V,
) => {
  const [storedValue, setStoredValue] = useState<V>(getLocalStorage<V>(key, initialValue) ?? initialValue);

  const setValue = useCallback(
    (value: V | ((value: V) => V)) => {
      setStoredValue((stored) => {
        const valueToStore = value instanceof Function ? value(stored) : value;
        setLocalStorage(key, valueToStore, true);
        return valueToStore;
      });
    },
    [key],
  );

  return [storedValue, setValue] as const;
};
