import { FC, KeyboardEvent } from 'react';

import { ControlledSelectField } from '@evolvemep/foresite-components-ui-react';
import { MenuItem, MenuItemProps, Popper, PopperProps, Stack, Typography } from '@mui/material';
import parse from 'html-react-parser';
import { Control, UseControllerProps } from 'react-hook-form';

type OptionType = {
  value: any;
  disabled?: boolean;
  icon?: any;
  label?: string;
  isActive?: boolean;
};

type RenderMenuItemComponentType = {
  disabled?: boolean;
  value?: any;
  option: OptionType;
  menuItemSx?: any;
  showActive?: boolean;
  props?: MenuItemProps;
};
interface IOptionItemContent {
  option: OptionType;
}
interface FacilityNameSelectProps {
  disabled?: boolean;
  name: string;
  control: Control;
  sx?: any;
  options: OptionType[];
}

const OptionItemContent: FC<IOptionItemContent> = ({ option }: IOptionItemContent) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
    <Typography variant="body1">{parse(option?.label || '')}</Typography>
  </Stack>
);

const renderMenuItemComponent = ({ disabled, value, option, props }: RenderMenuItemComponentType) => (
  <MenuItem disabled={disabled} value={value} color="secondary" {...props} key={value}>
    <OptionItemContent option={option} />
  </MenuItem>
);

const FacilityNameSelect = ({ options, disabled, sx, name, control }: FacilityNameSelectProps) => {
  const controllerProps: UseControllerProps = { name, control, rules: { required: '*Required' } };

  const autoCompleteProps = {
    options,
    disabled,
    disableClearable: true,
    textFieldProps: {
      label: 'Facility Name*',
      onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => e.preventDefault(),
    },
    renderOption: (props: MenuItemProps, option: OptionType) =>
      renderMenuItemComponent({
        disabled: option.disabled,
        value: option.value,
        option,
        props,
      }),
    getOptionLabel: (option: OptionType) => option.label || '',
    sx,
    isOptionEqualToValue: (option: OptionType, value: OptionType) => option?.value === value?.value,
    PopperComponent: (props: PopperProps) => {
      const { style } = props;
      return <Popper {...props} style={{ ...style, minWidth: '221px', width: 'auto' }} placement="bottom-start" />;
    },
  };

  return <ControlledSelectField ControllerProps={controllerProps} AutoCompleteProps={autoCompleteProps} />;
};

export default FacilityNameSelect;
