import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Entitlement } from 'graphql/types';
import useGeneralContext from 'helpers/useGeneralContext';
import useAssignedEntitlementSubscription from 'hooks-api/subscriptions/useAssignedEntitlementSubscription/useAssignedEntitlementSubscription';
import useUnassignedEntitlementSubscription from 'hooks-api/subscriptions/useUnassignedEntitlementSubscription/useUnassignedEntitlementSubscription';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import { EModuleType } from 'types/module';

import { useUser } from '../UserContext';
import type { NavigationModule } from './types';

type ModuleContextType = {
  data: NavigationModule[];
  loading: boolean;
  isModuleSelectOpen: boolean;
  setIsModuleSelectOpen: (open: boolean) => void;
};

const ModuleContext = React.createContext<ModuleContextType | undefined>(undefined);

export const ModuleProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const [isModuleSelectOpen, setIsModuleSelectOpen] = useState(false);
  const popperRef = useRef(null);
  const rightSideFlyoutRef = useRef(null);
  const {
    apiCall: getModules,
    data,
    loading,
  } = useWrappedGet<{ navigationModules: NavigationModule[] }>('admin/navigationmodule', {
    lazy: true,
  });

  const refetchModules = useCallback(() => getModules(), [getModules]);

  const handleEntitlementAssign = useCallback(
    (res: { data?: { assignedEntitlement: Entitlement[] } } | undefined) => {
      if (!user) return;
      const entitlements = res?.data?.assignedEntitlement ?? [];
      const isTargetedCurrentUser = entitlements.some(({ userId }) => userId === user?.userId);
      if (isTargetedCurrentUser) refetchModules();
    },
    [user, refetchModules],
  );

  const handleEntitlementUnassign = useCallback(
    (res: { data?: { unassignedEntitlement: Entitlement } } | undefined) => {
      if (!user) return;
      const entitlement = res?.data?.unassignedEntitlement;
      const isTargetedCurrentUser = entitlement?.userId === user?.userId;
      if (isTargetedCurrentUser) refetchModules();
    },
    [refetchModules, user],
  );

  useAssignedEntitlementSubscription({ onData: (res) => handleEntitlementAssign(res.data) });
  useUnassignedEntitlementSubscription({ onData: (res) => handleEntitlementUnassign(res.data) });

  useEffect(() => {
    if (user) refetchModules();
  }, [refetchModules, user]);

  const modulesObj = useMemo(
    () => ({
      module: EModuleType.ADMIN,
      data: data?.navigationModules ?? [],
      loading,
      isModuleSelectOpen,
      setIsModuleSelectOpen,
      popperRef,
      rightSideFlyoutRef,
    }),
    [loading, data, isModuleSelectOpen],
  );

  return <ModuleContext.Provider value={modulesObj}>{children}</ModuleContext.Provider>;
};

export const useModule = () => useGeneralContext<ModuleContextType | undefined>(ModuleContext, 'ModuleContext');
