import './kanban.css';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import {
  Avatar,
  Badge,
  Button,
  Card,
  Divider,
  Flex,
  Loader,
  LoadingOverlay,
  OptionalPortal,
  Text,
} from '@mantine/core';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import { WrappedMultiSelect } from 'components/Mantine/TypeSafeSelect';
import { statusToColorMap, WorkOrderStatusTypeName } from 'constants/badgeMappingStatus';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPaginatedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import type { Project } from 'hooks/projectsAndFacilities/useProjects';
import { useShopConfigurations } from 'hooks/projectsAndFacilities/useShopConfigurations';
import { TriggerOnVisible } from 'hooks/useOnScreen';

import { ShopKanbanCard } from './KanbanCard';
import type { WorkOrderId, WorkOrderKanban, WorkOrderStatusType, WorkOrderStatusTypeId } from './types';
import type { WorkOrdersPageProps } from './WorkOrdersPage';

const WorkOrderKanbanCard = ({
  workOrder,
  workOrderStatus,
  index,
  disabled,
}: {
  workOrder: WorkOrderKanban;
  workOrderStatus: WorkOrderStatusType;
  index: number;
  disabled?: boolean;
}) => (
  <Draggable index={index} draggableId={workOrder.workOrderId} isDragDisabled={disabled}>
    {(provided, snapshot) => (
      <OptionalPortal withinPortal={snapshot.isDragging}>
        <ShopKanbanCard
          currentPage="Work Orders"
          cardDestination={`/shop/work-orders/${workOrder.workOrderId}`}
          {...workOrder}
          status={workOrderStatus.workOrderStatusTypeName}
          cardProps={{
            ref: provided.innerRef,
            shadow: snapshot.isDragging ? 'lg' : 'xs',
            ...provided.draggableProps,
            ...provided.dragHandleProps,
          }}
          footer={
            !!workOrder.workOrderDescription || !!workOrder.workRequestDescription ? (
              <AgGridStyleTooltip
                openDelay={100}
                label={
                  <Flex direction="column" gap={4}>
                    {workOrder.workOrderDescription}
                    {!!workOrder.workOrderDescription && !!workOrder.workRequestDescription && <Divider />}
                    {workOrder.workRequestDescription}
                  </Flex>
                }
                style={{ maxWidth: 300 }}
                withArrow
              >
                <Badge
                  size="lg"
                  px={4}
                  radius="sm"
                  color="gray.4"
                  c="gray"
                  variant="outline"
                  style={{ cursor: 'initial' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <EvolveIcon icon="Comment" size="sm" />
                </Badge>
              </AgGridStyleTooltip>
            ) : null
          }
        >
          <EvolveLink className="kanban-work-order-link" to={`${workOrder.workOrderId}`} from="Work Orders">
            <Text fw={500} display="inline" lineClamp={3} title={workOrder.workRequestName}>
              {workOrder.workRequestName}
            </Text>
          </EvolveLink>
        </ShopKanbanCard>
      </OptionalPortal>
    )}
  </Draggable>
);

const WorkOrdersKanbanLane = ({
  selectedFacility,
  selectedProjects,
  workOrderStatus,
  dragging,
  setDragging,
  reorderWorkOrder,
  mutationRunning,
}: Omit<WorkOrdersPageProps, 'workOrderStatuses'> & {
  selectedProjects: Project[];
  workOrderStatus: WorkOrderStatusType;
  setDragging: (status: WorkOrderStatusTypeId | null) => void;
  dragging: WorkOrderStatusTypeId | null;
  reorderWorkOrder: (workOrderId: WorkOrderId, after?: WorkOrderId) => Promise<unknown>;
  mutationRunning: boolean;
}) => {
  const {
    data: workOrdersFromNetwork,
    fetchNextPage,
    loading,
    entireCount,
    refetch,
    setDefaultOpts,
  } = useWrappedPaginatedGet<WorkOrderKanban>('shop/workOrder/kanban', { lazy: true });
  const [workOrders, setWorkOrders] = useState(workOrdersFromNetwork);
  useEffect(() => {
    setWorkOrders([]);
    setDefaultOpts({
      defaultConfig: {
        params: {
          facilityId: selectedFacility.id,
          projectIds: selectedProjects.map((p) => p.projectId).join(','),
          workOrderStatusTypeId: workOrderStatus.workOrderStatusTypeId,
        },
      },
    });
  }, [selectedFacility.id, selectedProjects, setDefaultOpts, workOrderStatus.workOrderStatusTypeId]);

  useEffect(() => {
    if (workOrdersFromNetwork.length > 0) {
      setWorkOrders(workOrdersFromNetwork);
    }
  }, [workOrdersFromNetwork]);

  const [saving, setSaving] = useState(false);
  const onDragEnd: OnDragEndResponder = ({ source, destination, draggableId: workOrderId }) => {
    setDragging(null);
    if (isNil(destination) || source.index === destination.index) return;
    setSaving(true);
    const after = workOrders[source.index > destination.index ? destination.index - 1 : destination.index]?.workOrderId;
    // Don't await - go ahead and reorder the list locally while the backend is running
    reorderWorkOrder(workOrderId as WorkOrderId, after)
      .then(() => {
        refetch();
      })
      .finally(() => setSaving(false));

    setWorkOrders((wos) => {
      const workOrdersCopy = [...wos];
      const [moved] = workOrdersCopy.splice(source.index, 1);
      return [...workOrdersCopy.slice(0, destination.index), moved, ...workOrdersCopy.slice(destination.index)];
    });
  };

  return (
    <AgGridStyleTooltip
      hidden={dragging !== workOrderStatus.workOrderStatusTypeId}
      openDelay={0}
      label="Drag card within column to set priority"
      position="top"
    >
      <Flex direction="column" style={{ height: '100%' }}>
        <Flex justify="space-between" mb={4}>
          <Text fw={500}>{statusToColorMap[workOrderStatus.workOrderStatusTypeName].label}</Text>
          <Avatar radius="100%" size="sm">
            {isNotNil(entireCount) ? <Text fz="sm">{entireCount}</Text> : <Loader size="xs" />}
          </Avatar>
        </Flex>
        <DragDropContext onDragStart={() => setDragging(workOrderStatus.workOrderStatusTypeId)} onDragEnd={onDragEnd}>
          <Card
            withBorder
            display="flex"
            radius="md"
            bg="gray.0"
            p="xs"
            pb={0}
            style={{
              width: 320,
              flexDirection: 'column',
              flex: '1 0 0',
            }}
          >
            <Card.Section p="xs" bg={statusToColorMap[workOrderStatus.workOrderStatusTypeName].color} />
            <Droppable droppableId={workOrderStatus.workOrderStatusTypeId}>
              {(provided) => (
                <Flex
                  direction="column"
                  mt="sm"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ flex: '1 1 auto', overflowY: 'auto', scrollbarWidth: 'none' }}
                >
                  {workOrders.map((wo, index) => (
                    <WorkOrderKanbanCard
                      key={wo.workOrderId}
                      workOrder={wo}
                      workOrderStatus={workOrderStatus}
                      index={index}
                      disabled={saving || mutationRunning || loading || isNotNil(dragging)}
                    />
                  ))}
                  <Flex justify="center">
                    <TriggerOnVisible loading={loading} onVisible={fetchNextPage} />
                  </Flex>
                  {
                    // Weird typing issue with provided.placeholder
                    // I think it's due to mismatched React versions
                    provided.placeholder as ReactNode
                  }
                </Flex>
              )}
            </Droppable>
            <LoadingOverlay
              visible={isNotNil(dragging) && dragging !== workOrderStatus.workOrderStatusTypeId}
              loaderProps={{ style: { display: 'none' } }}
              transitionDuration={150}
              exitTransitionDuration={150}
            />
            <LoadingOverlay
              visible={saving}
              loaderProps={{ variant: 'bars' }}
              transitionDuration={150}
              exitTransitionDuration={150}
            />
          </Card>
        </DragDropContext>
      </Flex>
    </AgGridStyleTooltip>
  );
};

const swimLanes: WorkOrderStatusTypeName[] = ['Draft', 'Not Started', 'In Progress', 'Blocked'];

export const WorkOrdersKanbanView = ({ workOrderStatuses, ...props }: WorkOrdersPageProps) => {
  const { selectedFacility } = props;
  const [dragging, setDragging] = useState<WorkOrderStatusTypeId | null>(null);
  const { activeShopConfiguration } = useShopConfigurations();
  const {
    data: projects,
    setDefaultOpts,
    loading,
  } = useWrappedGet<Project[]>('shop/project/projectsForActiveWorkOrders', { lazy: true });
  useEffect(() => {
    setDefaultOpts({
      lazy: false,
      defaultConfig: {
        params: {
          facilityId: selectedFacility.id,
        },
      },
    });
  }, [selectedFacility.id, setDefaultOpts]);

  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const filterIsSet = selectedProjects.length > 0;

  const { apiCall: reOrderWorkOrderApiCall, loading: mutationRunning } = useWrappedPatch(
    'shop/workOrder/:workOrderId/prioritize',
  );
  const reorderWorkOrder = useCallback(
    async (workOrderId: WorkOrderId, after?: WorkOrderId) =>
      reOrderWorkOrderApiCall(
        {},
        {
          url: `shop/workOrder/${workOrderId}/prioritize`,
          params: { after },
        },
      ),
    [reOrderWorkOrderApiCall],
  );

  if (isNil(activeShopConfiguration)) return <Loader m="xs" />;
  return (
    <>
      <BasePageHeader
        title="Work Orders"
        viewsToShow={['list', 'kanban', 'calendar']}
        topRightComponent={
          <>
            <Button
              variant="subtle"
              onClick={() => filterIsSet && setSelectedProjects([])}
              compact
              leftIcon={<EvolveIcon icon="ClearFilters" size="sm" color="inherit" />}
              fz="xs"
              style={{
                cursor: filterIsSet ? 'pointer' : 'initial',
                opacity: filterIsSet ? 1 : 0,
                transition: '.1s ease-out',
              }}
            >
              Clear filters
            </Button>
            <WrappedMultiSelect
              placeholder="Search for projects..."
              data={
                projects?.map((p) => ({
                  label: p.projectName,
                  value: p.projectId,
                })) ?? []
              }
              nothingFound={loading ? 'Loading...' : undefined}
              value={selectedProjects.map((p) => p.projectId)}
              onChange={(ids) => setSelectedProjects((ps) => projects?.filter((p) => ids.includes(p.projectId)) ?? ps)}
              style={{ width: 300 }}
              searchable
              clearable
            />
          </>
        }
      />
      <Flex gap="xl" pb={4} style={{ height: '100%', overflowX: 'auto' }}>
        {swimLanes
          .map((s) => workOrderStatuses.find((wos) => wos.workOrderStatusTypeName === s))
          .filter(isNotNil)
          .map((workOrderStatus) => (
            <WorkOrdersKanbanLane
              key={workOrderStatus.workOrderStatusTypeId}
              workOrderStatus={workOrderStatus}
              dragging={dragging}
              setDragging={setDragging}
              selectedProjects={selectedProjects}
              mutationRunning={mutationRunning}
              reorderWorkOrder={reorderWorkOrder}
              {...props}
            />
          ))}
      </Flex>
    </>
  );
};
