import { useCallback, useState } from 'react';

import { Button, Divider, Flex, Modal, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';
import { TriggerOnVisible } from 'hooks/useOnScreen';
import type { WorkRequestItem } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { Catalog } from './SecondaryPane/AddItems/Catalog';
import { CatalogPartCard } from './SecondaryPane/AddItems/CatalogPartCard';
import type { Part, PartId, PartListingProps } from './SecondaryPane/AddItems/types';
import { useCatalogCategoryParts } from './SecondaryPane/AddItems/useCatalogCategoryParts';
import type { BillOfProcessId, WorkOrderItem } from './types';
import { useWorkRequestOrderItems } from './WorkRequestOrderItemsContext';

const PartListings = ({
  overridePartName,
  goBack,
  onPartSelected,
  disabled,
  ...props
}: PartListingProps & {
  disabled: boolean;
  onPartSelected: (part: Part) => Promise<void>;
}) => {
  const [saving, setSaving] = useState<PartId>();
  const { parts, loadingParts, fetchMoreParts } = useCatalogCategoryParts({
    ...props,
    overridePartName,
  });
  return (
    <>
      <Flex align="center" justify="space-between" gap="sm" bg="white" mb="xs">
        {isNotNil(overridePartName) && (
          <Button
            size="xs"
            compact
            onClick={goBack}
            disabled={disabled}
            variant="outline"
            leftIcon={<EvolveIcon size="xs" icon="ArrowLeft" color="inherit" />}
            color="gray.8"
          >
            Go back
          </Button>
        )}
        <Divider
          w="100%"
          c="dimmed"
          my="md"
          label={
            loadingParts
              ? 'Loading parts...'
              : `${parts?.length ?? 0} part${parts?.length === 1 ? '' : 's'} ${
                  isNil(overridePartName) ? 'in category' : 'from search'
                }`
          }
        />
      </Flex>
      <Flex direction="column" gap="2rem">
        {parts.map((part) => {
          const hasBop = part.hasAssembly && part.hasBillOfProcess;
          const isSaving = saving === part.partId;
          const isDisabled = disabled || (isNotNil(saving) && saving !== part.partId) || !hasBop;
          return (
            <CatalogPartCard
              key={part.partId}
              part={part}
              disabled={!hasBop}
              rightComponent={
                <Button
                  variant="outline"
                  disabled={isDisabled && !isSaving}
                  loading={isSaving}
                  onClick={() => {
                    setSaving(part.partId);
                    onPartSelected(part).finally(() => setSaving(undefined));
                  }}
                >
                  {hasBop ? 'Inherit BOP' : 'No BOP to Inherit'}
                </Button>
              }
            />
          );
        })}
        {parts?.length > 0 && <TriggerOnVisible onVisible={fetchMoreParts} loading={loadingParts} />}
      </Flex>
    </>
  );
};

type Props = {
  opened: boolean;
  onClose: () => void;
} & ({ workRequestItems: WorkRequestItem[] } | { workOrderItems: WorkOrderItem[] });

export const InheritFromBopModal = ({ opened, onClose, ...props }: Props) => {
  const context = useWorkRequestOrderItems();
  const { apiCall, loading } = useWrappedPost<
    unknown,
    {
      billOfProcessIds: BillOfProcessId[];
      partId: PartId;
    }
  >('shop/billOfProcess/addAssemblyBillOfProcessToWriteInItem');
  const selectedItems = 'workRequestItems' in props ? props.workRequestItems : props.workOrderItems;
  const refresh = useCallback(() => {
    selectedItems.forEach(({ billOfProcessId }) => {
      context.serverSideGridProps.refreshDetailGrid(billOfProcessId, 'Bill of Process');
      context.serverSideGridProps.expandDetailGrid(billOfProcessId, 'Bill of Process');
    });
  }, [context.serverSideGridProps, selectedItems]);
  const inheritFromBom = useCallback(
    async ({ partId }: Part) => {
      const billOfProcessIds = ('workRequestItems' in props ? props.workRequestItems : props.workOrderItems).map(
        (i) => i.billOfProcessId,
      );
      await apiCall({ billOfProcessIds, partId }).then(() => {
        onClose();
        refresh();
      });
    },
    [apiCall, onClose, props, refresh],
  );

  return (
    <Modal
      size="xl"
      centered
      opened={opened}
      onClose={onClose}
      title="Inherit bill of process"
      closeOnClickOutside={!loading}
      styles={{
        body: { maxHeight: 800, display: 'flex' },
      }}
    >
      <Flex direction="column" gap="md" pb="md" style={{ flex: '1 1 auto', height: 600 }}>
        <Text>
          {/* eslint-disable-next-line no-nested-ternary */}
          {selectedItems.length === 1
            ? 'workRequestItemName' in selectedItems[0]
              ? selectedItems[0].workRequestItemName
              : selectedItems[0].workOrderItemName
            : `${selectedItems.length} selected items`}
        </Text>
        <Text fz="sm">
          Choose a catalog item to inherit the same bill of process for the selected custom item
          {selectedItems.length === 1 ? '' : 's'}.
        </Text>
        <Catalog {...context} disabled={loading} refresh={refresh}>
          {(listingProps) => <PartListings disabled={loading} onPartSelected={inheritFromBom} {...listingProps} />}
        </Catalog>
        <Divider />
        <Flex justify="flex-end">
          <Button onClick={onClose} variant="subtle" color="gray" c="dark" disabled={loading}>
            Cancel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
