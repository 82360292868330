import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { Waypoint } from 'react-waypoint';

import { documents as DOCUMENTS } from 'graphql/queries';
import {
  Document,
  DocumentsQuery,
  DocumentsQueryVariables,
  FolderDocument,
  TaskDocument,
  WorkOrderDocument,
  WorkRequestDocument,
} from 'graphql/types';
import { clone, removeDuplicates } from 'helpers/arrayFunctions';
import { objIsEmpty } from 'helpers/objectFunctions';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { useUsersInfo } from 'hooks/useUsersInfo';
import type { UserId } from 'types/types-api';

import useCache from './useCache';

export type VariableType = { [key: string]: string | number | boolean | any };
export type ResponseKey = 'taskDocument' | 'workOrderDocuments' | 'workRequestDocuments';

export type CombinedDocumentType = {
  documentId: string;
  documentName?: string | null;
  documentIdentifier?: string | null;
  documentTypeId?: string | null;
  storagePath?: string | null;
  extension?: string | null;
  encodedUrn?: string | null;
  documentStatusTypeId?: string | null;
  isMappedFromShop?: boolean | null;
  folderDocumentResponse?: Array<FolderDocument | null> | null;
  documentNameFormatted?: string | null;
  createdOn?: string | null;
  createdBy?: string | null;
  taskDocumentId?: string | null;
  taskId?: string | null;
  externalUrl?: string | null;
  username: string;
  workOrderDocumentId?: string;
  workOrderId?: string;
  updatedOn?: string | null;
  workRequestDocumentId?: string;
  workRequestId?: string;
};

type UsePaginatedDocumentsType = {
  documents: CombinedDocumentType[];
  loading: boolean;
  handleFetchMore: () => void;
  handleFetchDocuments: (variables: VariableType) => void;
  paginationWaypoint: () => React.ReactNode;
};

const MAX_IDS_ALLOWED_BY_BACKEND = 50;

interface ICachedDocumentDetails {
  [key: string]: Document;
}

const getUniqueDocumentIds = (documents: TaskDocument[] = [], cachedDocuments: ICachedDocumentDetails) => {
  const documentIds = documents?.reduce((acc: string[], document: any) => {
    if (document.externalUrl || cachedDocuments[document.documentId as string]) return acc;
    return [...acc, document.documentId];
  }, []);

  return removeDuplicates(documentIds)?.join(',') ?? [];
};

const usePaginatedDocuments = (QUERY: string, responseKey: ResponseKey, take = 50): UsePaginatedDocumentsType => {
  const [cachedDocumentDetails, setCachedDocumentDetails] = useState<ICachedDocumentDetails>({});
  const [variables, setVariables] = useState<VariableType>({});
  const refDocuments = useRef<Document[]>();
  const { deleteFromCacheByQuery } = useCache();

  const { getUsersData, cachedUserData } = useUsersInfo();
  const [{ lazyLoad, paginationHandler }, { loading, data }] = useLazyPaginatedQuery(gql(QUERY));
  const [detailedDocumentsQuery, { loading: loadingDetailed }] = useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(
    gql(DOCUMENTS),
    { fetchPolicy: 'cache-and-network' },
  );

  const VARIABLES = useMemo(
    (): { [key: string]: string | number; take: number } => ({ ...variables, take }),
    [variables, take],
  );

  const documents = useMemo((): CombinedDocumentType[] => {
    if (!data || objIsEmpty(cachedUserData)) return [];

    const docs =
      data[responseKey]
        ?.map((document: TaskDocument | WorkRequestDocument | WorkOrderDocument) => {
          if (!cachedDocumentDetails[document.documentId as string] && !document.externalUrl) return null;

          return {
            ...document,
            ...(cachedDocumentDetails[document.documentId as string] || {}),
            username: cachedUserData[document?.createdBy as UserId]?.completeName,
          };
        })
        .filter((doc: CombinedDocumentType) => Boolean(doc)) ?? [];

    refDocuments.current = docs.length;
    return docs;
  }, [data, cachedDocumentDetails, cachedUserData, responseKey]);

  const handleGetNewDetailedDocuments = async (d: any) => {
    const documentIds = getUniqueDocumentIds(d[responseKey], cachedDocumentDetails);
    if (!documentIds?.length) return;

    const result = await detailedDocumentsQuery({ variables: { query: { documentIds } } });
    if (!result?.data?.documents?.length) return;

    const outputObject: ICachedDocumentDetails = {};

    (result?.data?.documents as Document[]).forEach((document) => {
      outputObject[document.documentId] = document;
    });

    setCachedDocumentDetails((prev) => ({ ...prev, ...outputObject }));
  };

  const handleLoadUserData = (d: any) => {
    const uniqueUserIds = removeDuplicates(
      d[responseKey]?.map(
        (document: TaskDocument | WorkRequestDocument | WorkOrderDocument) => document?.createdBy,
      ) as string[],
    );

    getUsersData(uniqueUserIds);
  };

  const onFetchMore = useCallback(
    (skip: number) => {
      if (paginationHandler) paginationHandler(skip, VARIABLES);
    },
    [paginationHandler, VARIABLES],
  );

  const handleFetchMore = useCallback(() => {
    if (!data) return;
    onFetchMore(data[responseKey]?.length);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, onFetchMore]);

  useEffect(() => {
    // Check the variables contains more that "take" property, for avoiding the first useless call
    const clonedVars = clone(VARIABLES);
    delete clonedVars.take;

    if (lazyLoad && !objIsEmpty(clonedVars)) lazyLoad(VARIABLES);
  }, [lazyLoad, VARIABLES]);

  useEffect(() => {
    if (!data) return;
    handleLoadUserData(data);
    handleGetNewDetailedDocuments(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(
    () => () => {
      if (Number(refDocuments.current || 0) >= MAX_IDS_ALLOWED_BY_BACKEND)
        deleteFromCacheByQuery(responseKey, variables);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleFetchDocuments = (vars: VariableType) => setVariables(vars);
  const paginationWaypoint = () =>
    documents?.length > 0 ? (
      <Waypoint key={documents.length} bottomOffset="-20%" onEnter={() => handleFetchMore()} />
    ) : null;

  return {
    documents,
    loading: Boolean(loading || loadingDetailed),
    handleFetchDocuments,
    handleFetchMore,
    paginationWaypoint,
  };
};

export default usePaginatedDocuments;
