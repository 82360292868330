import { useCallback, useMemo } from 'react';

import { useWrappedGet, useWrappedPatch } from 'hooks-api/useWrappedApiCall';
import { UserId, UserNameIdSymbol } from 'types/types-api';

export type UserFromProvider = {
  acceptedLatestEula: boolean;
  netsuiteId: string | null;
  personalPhoneNumber: string | null;
  userEmail: string;
  userFirstName: string | null;
  userId: UserId;
  userLastName: null;
  userName: UserNameIdSymbol;
  userStatus: 'CONFIRMED' | 'UNCONFIRMED' | 'FORCE_CHANGE_PASSWORD';
  workPhoneNumber: null;
};

export const useFetchProviderStatus = () => {
  const { apiCall, loading } = useWrappedGet<
    | UserFromProvider
    | {
        userStatus: 'USER_NOT_FOUND' | 'EMAIL_NOT_FOUND';
      }
  >(`admin/user/:email/providerStatus`, { lazy: true });
  const fetchProviderStatus = useCallback(
    async (email: string) => apiCall({ url: `admin/user/${email}/providerStatus` }),
    [apiCall],
  );
  return useMemo(() => ({ fetchProviderStatus, loading }), [fetchProviderStatus, loading]);
};

const invitationTypes = ['project', 'department', 'company'] as const;
export type InvitationType = typeof invitationTypes[number];
export const isInvitationType = (type: string | undefined | null): type is InvitationType =>
  invitationTypes.includes(type as InvitationType);

const resourceUrlMap: Record<InvitationType, string> = {
  company: 'user',
  department: 'departmentUser',
  project: 'projectUser',
};

export const useConfirmUser = (throwError = false) => {
  const { apiCall, loading } = useWrappedPatch<
    unknown,
    {
      confirmationCode: string;
      password?: string;
    }
  >('admin/:resource/:id/confirmation', { dontAlertOnError: !throwError });
  const confirm = useCallback(
    (
      id: string,
      type: InvitationType,
      body: {
        confirmationCode: string;
        password?: string;
      },
    ) => apiCall(body, { url: `admin/${resourceUrlMap[type]}/${id}/confirmation` }),
    [apiCall],
  );
  return useMemo(() => ({ confirm, confirming: loading }), [confirm, loading]);
};
