import { Routes, Route, Navigate } from 'react-router-dom';

import { PageNotFound } from 'app/PageNotFound';

import {
  ProjectInterfaceNavContent,
  CompanyMembersInterfaceNavContent,
  ProjectMembersInterfaceNavContent,
  CompanyPropertiesInterfaceNavContent,
  ProjectPropertiesInterfaceNavContent,
  FacilitiesInterfaceNavContent,
  LicensesInterfaceNavContent,
} from './AdminNavInterfaces';

export const AdminRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="members/company-members" />} />
    <Route path="facilities" element={<FacilitiesInterfaceNavContent />} />
    <Route path="projects" element={<ProjectInterfaceNavContent />} />
    <Route path="members/company-members" element={<CompanyMembersInterfaceNavContent />} />
    <Route path="members/project-members" element={<ProjectMembersInterfaceNavContent />} />
    <Route path="properties/company-properties" element={<CompanyPropertiesInterfaceNavContent />} />
    <Route path="properties/project-properties" element={<ProjectPropertiesInterfaceNavContent />} />
    <Route path="licenses" element={<LicensesInterfaceNavContent />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
