import { useEffect, useMemo } from 'react';

import { Box, LoadingOverlay } from '@mantine/core';
import { Outlet, Navigate } from 'react-router';

import { useModule } from 'app/Modules/ModuleContext';
import { PRODUCT_IDS } from 'constants/globalConstants';
import { isNil } from 'helpers/isNotNil';
import { useEntitlementGet } from 'hooks-api/useEntitlementGet';
import useSetupModule from 'hooks/useSetupModule';
import { EModuleType } from 'types/module';

import { HeaderBar } from './HeaderBar/HeaderBar';
import MessageAlertBanner from './MessageAlertBanner/MessageAlertBanner';
import { DesktopNavBar } from './NavBar/DesktopNavBar';

const electricalOrMechanicalProducts: string[] = [PRODUCT_IDS.ELECTRICAL_LICENSE, PRODUCT_IDS.MECHANICAL_LICENSE];

export const Layout = () => {
  const { setSelectedModule, module, currentPage } = useSetupModule();
  const { data: entitlements, loading: loadingEntitlements } = useEntitlementGet();
  const { data: navModules, loading: loadingModules } = useModule();

  const moduleIsEnabled = useMemo(
    () => navModules.some(({ navigationModuleCode }) => navigationModuleCode === module),
    [navModules, module],
  );

  const electricalOrMechanicalLicense = useMemo(
    () => entitlements?.data.some((e) => electricalOrMechanicalProducts.includes(e.productPool.productId)),
    [entitlements],
  );

  useEffect(() => {
    if (navModules?.length && !module) setSelectedModule(navModules[0]?.navigationModuleCode);
  }, [navModules, setSelectedModule, module]);

  if (loadingModules) return <LoadingOverlay visible />;
  if ((isNil(navModules) || navModules.length === 0) && loadingEntitlements) return <LoadingOverlay visible />;

  // All users should have access to the Messages page,
  // even if they have access to no modules or licenses.
  if (currentPage() !== EModuleType.MESSAGE) {
    if (navModules?.length === 0 && electricalOrMechanicalLicense) {
      return <Navigate to="reset-password-landing" />;
    }
    if (!moduleIsEnabled) {
      if (navModules?.length === 0) {
        return <Navigate to="/no-licenses" />;
      }
      setSelectedModule(navModules[0]?.navigationModuleCode);
    }
  }

  return (
    <>
      <HeaderBar />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: 'calc(100% - 64px)',
        }}
      >
        <DesktopNavBar />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: 'calc(100vh - 64px)',
            width: 'calc(100% - 64px)',
            marginTop: 64,
            marginLeft: 64,
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <MessageAlertBanner />
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
