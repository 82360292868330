import { useCallback, useRef, useState } from 'react';

import { Button, Divider, Flex } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { strongValues } from 'helpers/strongEntries';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';
import useOnScreen, { TriggerOnVisible } from 'hooks/useOnScreen';

import {
  AddCatalogItemToWorkOrderBody,
  AddCatalogItemToWorkRequestBody,
  AddCatalogPartCard,
} from './AddCatalogPartCard';
import type { Part, PartId, PartListingProps } from './types';
import { useCatalogCategoryParts } from './useCatalogCategoryParts';

export const PartCategoryPartAdder = ({ overridePartName, goBack, ...props }: PartListingProps) => {
  const dividerRef = useRef<HTMLDivElement>(null);
  const partListRef = useRef<HTMLDivElement>(null);
  const onScreenRef = useRef<HTMLDivElement>(null);
  const partsOnScreen = useOnScreen(partListRef);
  const dividerMarkerOnScreen = useOnScreen(onScreenRef);
  const [multiplePartsMap, setMultiplePartsMap] = useState<Record<PartId, number>>({});
  const reset = useCallback(() => setMultiplePartsMap({}), []);
  const { parts, loadingParts, fetchMoreParts } = useCatalogCategoryParts({
    ...props,
    overridePartName,
    reset,
  });
  const [savingMultiple, setSavingMultiple] = useState(false);

  const { apiCall: addCatalogItemToWorkOrder, loading: savingWorkOrderItems } = useWrappedPost<
    unknown,
    AddCatalogItemToWorkOrderBody
  >('shop/workOrderItem/fromCatalog');

  const { apiCall: addCatalogItemToWorkRequest, loading: savingWorkRequestItems } = useWrappedPost<
    unknown,
    AddCatalogItemToWorkRequestBody
  >('shop/workRequestItem/fromCatalog');

  const updateMultiPartsMap = (partId: PartId, qty?: number) => {
    setMultiplePartsMap((map) => ({
      ...map,
      [partId]: qty,
    }));
  };

  const addSingleCatalogItem = async ({ partId, partName }: Part) => {
    const quantity = multiplePartsMap[partId];
    if (isNil(quantity) || quantity <= 0) return;
    if ('workOrder' in props) {
      await addCatalogItemToWorkOrder({
        workOrderId: props.workOrder.workOrderId,
        quantity,
        partId,
      });
    } else {
      await addCatalogItemToWorkRequest({
        workRequestId: props.workRequest.workRequestId,
        quantity,
        partId,
      });
    }
    notifications.show({
      title: 'Successfully added',
      message: `Added ${partName} to ${'workOrder' in props ? 'work order' : 'work request'}`,
      color: 'green',
    });
    updateMultiPartsMap(partId);
    props.refresh();
  };

  const addAllCatalogItems = async () => {
    const items = Object.entries(multiplePartsMap)
      .filter(([, qty]) => !!qty && qty > 0)
      .map(([partId, quantity]) => ({
        partId: partId as PartId,
        quantity,
      }));
    setSavingMultiple(true);
    Promise.all(
      items.map((item) =>
        'workOrder' in props
          ? addCatalogItemToWorkOrder({
              ...item,
              workOrderId: props.workOrder.workOrderId,
            })
          : addCatalogItemToWorkRequest({
              ...item,
              workRequestId: props.workRequest.workRequestId,
            }),
      ),
    )
      .then(() => {
        props.refresh();
        setMultiplePartsMap({});
        notifications.show({
          title: 'Successfully added',
          message: `Added ${items.length} item${items.length === 1 ? '' : 's'} to ${
            'workOrder' in props ? 'work order' : 'work request'
          }`,
          color: 'green',
        });
      })
      .finally(() => setSavingMultiple(false));
  };

  const lockDivider = !dividerMarkerOnScreen && partsOnScreen;

  return (
    <>
      <div ref={onScreenRef} style={{ marginBottom: 2, width: '100%' }} />
      <Flex
        ref={dividerRef}
        align="center"
        justify="space-between"
        gap="sm"
        bg="white"
        style={{
          width: onScreenRef.current?.clientWidth || '100%',
          position: lockDivider ? 'fixed' : undefined,
          zIndex: 1,
        }}
      >
        {isNotNil(overridePartName) && (
          <Button
            size="xs"
            compact
            onClick={goBack}
            variant="outline"
            leftIcon={<EvolveIcon size="xs" icon="ArrowLeft" color="inherit" />}
            color="gray.8"
          >
            Go back
          </Button>
        )}
        <Divider
          w="100%"
          c="dimmed"
          my="md"
          label={
            loadingParts
              ? 'Loading parts...'
              : `${parts?.length ?? 0} part${parts?.length === 1 ? '' : 's'} ${
                  isNil(overridePartName) ? 'in category' : 'from search'
                }`
          }
        />
        {isNil(overridePartName) && (
          <Button
            size="xs"
            mr="xs"
            compact
            loading={savingMultiple}
            disabled={
              !strongValues(multiplePartsMap).some((v) => !!v) ||
              ((savingWorkOrderItems || savingWorkRequestItems) && !savingMultiple)
            }
            onClick={addAllCatalogItems}
            variant="outline"
            leftIcon={<EvolveIcon size="xs" icon="Add" color="inherit" />}
          >
            Add all
          </Button>
        )}
      </Flex>
      <Flex
        ref={partListRef}
        direction="column"
        gap="2rem"
        mt={lockDivider ? dividerRef.current?.clientHeight : undefined}
      >
        {parts?.map((p) => (
          <AddCatalogPartCard
            key={p.partId}
            part={p}
            multiplePartsMap={multiplePartsMap}
            updateMultiPartsMap={updateMultiPartsMap}
            addSingleCatalogItem={addSingleCatalogItem}
            savingItems={savingWorkOrderItems || savingWorkRequestItems || savingMultiple}
          />
        ))}
        {parts?.length > 0 && <TriggerOnVisible onVisible={fetchMoreParts} loading={loadingParts} />}
      </Flex>
    </>
  );
};
