import { useMemo } from 'react';

import type { Task } from 'typings/task';

const MAX_DEEP_ALLOW = 20;

const deleteTaskIdsRepeat = (taskId: string[]) => [...new Set(taskId)];

export const getPredecessorBlacklist = (tasks: Task[], tasksByAnalyze: string[], allowPredecessorOld: boolean) => {
  const tasksGetPredecessor = (taskIdByAnalyze: string) => (task: Task) =>
    task?.taskId !== taskIdByAnalyze &&
    task?.taskPredecessorIds?.some((taskPredecessor) => taskPredecessor.taskId === taskIdByAnalyze);

  const findByTaskBlacklist = (taskIdByAnalyze: string, passTaskResult = [] as string[], deep = 0): string[] => {
    const blacklistByTaskId = tasks?.filter(tasksGetPredecessor(taskIdByAnalyze))?.map((task) => task?.taskId);

    if (deep < MAX_DEEP_ALLOW) {
      const filterRemoveLoopRecursive = (taskId: string) => !passTaskResult.includes(taskId);
      return deleteTaskIdsRepeat([
        ...passTaskResult,
        ...blacklistByTaskId
          .filter(filterRemoveLoopRecursive)
          .flatMap((taskId) => findByTaskBlacklist(taskId, [...passTaskResult, ...blacklistByTaskId], deep + 1)),
      ]);
    }

    return deleteTaskIdsRepeat([...passTaskResult, ...blacklistByTaskId]);
  };
  return tasksByAnalyze.reduce((blacklist, taskId) => {
    const findBlacklist = findByTaskBlacklist(taskId);
    const removeBlacklistOldPredecessorIds = () => {
      const taskOld = tasks.find((task) => task.taskId === taskId);
      const predecessorOld = taskOld?.taskPredecessorIds?.map((taskPredecessorId) => taskPredecessorId.taskId);
      return findBlacklist.filter((bl) => !predecessorOld?.some((predecessor) => predecessor === bl));
    };

    return {
      ...blacklist,
      [taskId]: allowPredecessorOld ? removeBlacklistOldPredecessorIds() : findBlacklist,
    };
  }, {} as Record<string, string[]>);
};

export const useTaskPredecessorBlacklist = (tasks: Task[], tasksByAnalyze: string[], allowPredecessorOld: boolean) =>
  useMemo(
    () => getPredecessorBlacklist(tasks, tasksByAnalyze, allowPredecessorOld),
    [allowPredecessorOld, tasks, tasksByAnalyze],
  );
