/* eslint-disable no-console */
import { Auth } from 'aws-amplify';

import blobDownloader from 'helpers/blobDownloader';
import { getBaseApiUrl } from 'hooks-api/useEvolveApi';

export const requestTakeoffPadCSV = async (body: any) => {
  const amplifyRefreshedUser = await Auth.currentAuthenticatedUser();
  const idToken = `Bearer ${amplifyRefreshedUser.signInUserSession.idToken.jwtToken}`;
  const headers = {
    Authorization: idToken,
    'Content-type': 'application/json',
  };
  let fileNameHeader;
  try {
    const baseUrl = await getBaseApiUrl();

    const blobValue = await fetch(`${baseUrl}/moab/takeoffpaditem/csv`, {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
    }).then((response) => {
      fileNameHeader = response.headers.get('filename');
      return response.blob();
    });
    blobDownloader(blobValue, fileNameHeader);
  } catch (error) {
    console.log(error);
  }
  return '';
};
export const requestSourcePadCSV = async (body: any) => {
  const amplifyRefreshedUser = await Auth.currentAuthenticatedUser();
  const idToken = `Bearer ${amplifyRefreshedUser.signInUserSession.idToken.jwtToken}`;
  const headers = {
    Authorization: idToken,
    'Content-type': 'application/json',
  };
  let fileNameHeader;
  try {
    const baseUrl = await getBaseApiUrl();

    const blobValue = await fetch(`${baseUrl}/moab/sourcepaditem/csv`, {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
    }).then((response) => {
      fileNameHeader = response.headers.get('filename');
      return response.blob();
    });
    blobDownloader(blobValue, fileNameHeader);
  } catch (error) {
    console.log(error);
  }
  return '';
};
