import { ComponentProps, useEffect, useMemo, useState } from 'react';

import { Loader } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';

import { SelectedFacility } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ChangeTableViewProvider, useChangeTableView } from 'components/Mantine/ChangeTableView/useChangeTableView';
import type { ShowOpen } from 'components/Mantine/OpenClosedTab';
import type { TaskStatusTypeName } from 'constants/badgeMappingStatus';
import 'helpers/ag-grid/ag-nested-grid-theme.css';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { ShopConfiguration } from 'hooks/projectsAndFacilities/types';
import { useShopConfigurations } from 'hooks/projectsAndFacilities/useShopConfigurations';
import { TaskStatusType, WorkCellTask } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';
import { useTasks } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/useTasks';
import type { WorkOrderStatusType } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';
import { useWorkOrders } from 'modules/Shop/WorkOrders/WorkOrdersPage/useWorkOrders';

import { getWorkCellsColDef } from './columnDefs';
import { useWorkCellsList } from './useWorkCellsList';
import { WorkCellsKanbanView } from './WorkCellsKanbanView';

export type WorkCellsProps = {
  selectedFacility: SelectedFacility;
  taskStatusTypes: TaskStatusType[];
  activeShopConfiguration: ShopConfiguration;
  workOrderStatuses: WorkOrderStatusType[];
  workCellsList: ReturnType<typeof useWorkCellsList>;
};

const statusesByGroup: Record<ShowOpen, TaskStatusTypeName[]> = {
  open: ['Not Started', 'Paused', 'Started'],
  closed: ['Completed'],
};

const WorkCellsPageTable = ({
  selectedFacility,
  taskStatusTypes,
  activeShopConfiguration,
  workCellsList,
}: WorkCellsProps) => {
  const [showOpen, setShowOpen] = useState<ShowOpen>('open');
  const possibleStatuses = useMemo(
    () => taskStatusTypes.filter((s) => statusesByGroup[showOpen].includes(s.taskStatusTypeName)),
    [showOpen, taskStatusTypes],
  );
  const workCellsColDef = useMemo(
    () => getWorkCellsColDef(possibleStatuses, workCellsList.workCells ?? [], showOpen === 'open'),
    [possibleStatuses, showOpen, workCellsList.workCells],
  );
  const { fetchPage, setDefaultOpts } = useWrappedPaginatedGet<WorkCellTask>('shop/workCellTask', {
    lazy: true,
  });
  const { agGridProps, filterIsSet } = useServerSideGrid({
    tableName: 'work-cells',
    colDef: workCellsColDef,
    fetchPage,
    rowId: 'workCellTaskId',
  });

  useEffect(() => {
    // The default `taskStatusTypeIds` change when a user toggles the Open/Closed tabs,
    // so we use this effect to change the behavior of fetchPage.
    // Once fetchPage updates, the datasource will be recreated as part of a later useEffect
    // which will cause the entire Grid to reload.
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          facilityIds: selectedFacility.id,
          // Order by needBy if not ordering by anything else
          orderBy: 'needBy:asc',
          taskStatusTypeIds: possibleStatuses.map((s) => s.taskStatusTypeId).join(','),
          shopConfigurationId: activeShopConfiguration.shopConfigurationId,
        },
      },
    });
  }, [activeShopConfiguration.shopConfigurationId, possibleStatuses, selectedFacility.id, setDefaultOpts]);

  const defaultColDef = useMemo<typeof agGridProps['defaultColDef']>(
    () => ({
      ...agGridProps.defaultColDef,
      cellClassRules: {
        'disabled-row': (r) => !r.data?.isEnabled && isNotNil(r.colDef.field ?? r.colDef.headerName),
      },
    }),
    [agGridProps.defaultColDef],
  );

  return (
    <>
      <BasePageHeader
        title="Work Cells"
        showOpen={showOpen}
        gridRef={agGridProps.ref}
        filterIsSet={filterIsSet}
        setShowOpen={setShowOpen}
        viewsToShow={['list', 'kanban']}
      />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<WorkCellTask>
          {...agGridProps}
          defaultColDef={defaultColDef}
          headerHeight={32}
          rowHeight={32}
          noRowsOverlayComponent={() => (
            <NoRowsOverlay
              label={`This shop configuration has no ${showOpen} tasks.`}
              icon="WorkCell"
              gridRef={agGridProps.ref}
            />
          )}
        />
      </div>
    </>
  );
};

const WorkCellsPageWrapped = ({ selectedFacility }: { selectedFacility: SelectedFacility }) => {
  const { workOrderStatuses } = useWorkOrders();
  const { selectedView } = useChangeTableView();
  const { taskStatusTypes } = useTasks();
  const { activeShopConfiguration } = useShopConfigurations();
  const workCellsList = useWorkCellsList(selectedFacility.id);
  if (
    isNil(taskStatusTypes) ||
    isNil(activeShopConfiguration) ||
    isNil(workOrderStatuses) ||
    isNil(workCellsList.workCells)
  ) {
    return <Loader m="sm" />;
  }
  return (
    <>
      {selectedView === 'list' && (
        <WorkCellsPageTable
          selectedFacility={selectedFacility}
          activeShopConfiguration={activeShopConfiguration}
          taskStatusTypes={taskStatusTypes}
          workOrderStatuses={workOrderStatuses}
          workCellsList={workCellsList}
        />
      )}
      {selectedView === 'kanban' && (
        <WorkCellsKanbanView
          selectedFacility={selectedFacility}
          activeShopConfiguration={activeShopConfiguration}
          taskStatusTypes={taskStatusTypes}
          workOrderStatuses={workOrderStatuses}
          workCellsList={workCellsList}
        />
      )}
    </>
  );
};

export const WorkCellsPage = (props: ComponentProps<typeof WorkCellsPageWrapped>) => (
  <ChangeTableViewProvider name="Work Cells">
    <WorkCellsPageWrapped {...props} />
  </ChangeTableViewProvider>
);
