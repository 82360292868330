import {
  FilterOptionType,
  FilterType,
  addFilterOption,
  sortColumnsOptions,
  combineFilterColumns,
} from 'components/Popover/FilterPopover/helpers/filterPopoverHelpers';
import { removeDuplicates } from 'helpers/arrayFunctions';

interface WorkRequestType {
  facilityName: string;
  facilityId: string;
  workRequestId: string;
  workRequestIdentifier: string;
  workRequestName: string;
  workRequestStatusId: string;
  workRequestStatusName: string;
  projectId: string;
  projectName: string;
}

interface WorkRequestFiltersType {
  needBy: string;
  workRequestStatusIds: string;
  workRequestIds: string;
  projectIds: string;
  facilityIds: string;
}
// keyof WorkRequestFiltersType not worked
type WorkRequestFiltersKeyType = 'needBy' | 'workRequestStatusIds' | 'workRequestIds' | 'projectIds' | 'facilityIds';

const addFiltersOptions = (workRequests: Array<WorkRequestType>, selectedItemType: string) =>
  workRequests.reduce<Array<FilterOptionType>>((out, current) => {
    let prev = addFilterOption('workRequestIdentifier', 'Work Request Id', out, {
      optionValue: current?.workRequestId,
      optionLabel: current?.workRequestIdentifier,
    });
    prev = addFilterOption('workRequestName', 'Work Request Name', prev, {
      optionValue: current?.workRequestId,
      optionLabel: current?.workRequestName,
    });
    if (selectedItemType === 'FACILITY') {
      prev = addFilterOption('projectName', 'Project Name', prev, {
        optionValue: current?.projectId,
        optionLabel: current?.projectName,
      });
    }
    if (selectedItemType === 'PROJECT') {
      prev = addFilterOption('facilityName', 'Facility Name', prev, {
        optionValue: current?.facilityId,
        optionLabel: current?.facilityName,
      });
    }
    prev = addFilterOption('workRequestStatusId', 'Status', prev, {
      optionValue: current?.workRequestStatusId,
      optionLabel: current?.workRequestStatusName,
    });

    return prev;
  }, []);

// Convert workRequests to columns, it's used for fill filter dropdown
export const formatWorkRequestsToColumns = (
  workRequests: Array<WorkRequestType>,
  selectedItemType: string,
  workRequestFromTable: Array<WorkRequestType>,
  columnsBackup: Array<FilterOptionType>,
) => {
  if (workRequests.length === 0) return [];

  if (columnsBackup?.length) {
    const columnsFromDataFiltered = addFiltersOptions(workRequestFromTable, selectedItemType);
    const columnsFromDataFilteredSorted = sortColumnsOptions(columnsFromDataFiltered);
    return combineFilterColumns(columnsFromDataFilteredSorted, columnsBackup);
  }

  const columns = addFiltersOptions(workRequests, selectedItemType);
  return sortColumnsOptions(columns);
};

export function getFilterNameParamByColumn(column: string): WorkRequestFiltersKeyType {
  switch (column) {
    case 'workRequestStatusId':
      return 'workRequestStatusIds';
    case 'workRequestName':
    case 'workRequestIdentifier':
      return 'workRequestIds';
    case 'projectIdentifier':
    case 'projectName':
      return 'projectIds';
    case 'facilityName':
      return 'facilityIds';
    default:
      return 'facilityIds';
  }
}

export const formatFiltersToQueryParams = (filters: Array<FilterType>) => {
  if (filters.length === 0) return {};

  return filters.reduce<WorkRequestFiltersType>((prev, current) => {
    if (current.column === 'dateFrom') {
      // eslint-disable-next-line no-param-reassign
      prev.needBy = `start:${current.values.toString()}`;
      return prev;
    }

    if (current.column === 'dateTo') {
      // eslint-disable-next-line no-param-reassign
      prev.needBy = `${prev.needBy},end:${current.values.toString()}`;
      return prev;
    }

    if (!current?.values) return prev;

    // merge two same endpoint filter name
    const filterParamName = getFilterNameParamByColumn(current.column);
    if (filterParamName && prev[filterParamName]) {
      const filtersAdded = removeDuplicates([
        ...prev[filterParamName].split(','),
        ...(typeof current.values !== 'string' ? current.values.map((val) => val.value) : []),
      ]);
      // eslint-disable-next-line no-param-reassign
      prev[filterParamName] = filtersAdded.join(',');
      return prev;
    }

    if (filterParamName) {
      return Object.assign(prev, {
        [filterParamName]: typeof current.values !== 'string' ? current.values.map((val) => val.value).join(',') : '-',
      });
    }

    return prev;
  }, {} as any);
};
