import React from 'react';

import { Routes, Route } from 'react-router-dom';

import { PageNotFound } from 'app/PageNotFound';
import {
  ProjectInterfaceNavContent,
  FacilitiesInterfaceNavContent,
  ProjectMembersInterfaceNavContent,
  ProjectPropertiesInterfaceNavContent,
  MaterialMembersInterfaceNavContent,
} from 'modules/Admin/AdminNavInterfaces';
import { WorkRequestNavContent, WorkRequestsNavContent } from 'modules/Field/FieldNavInterfaces';

import {
  Dashboard,
  AssemblyEditorNavContent,
  BillOfMaterialsInterfaceNavContent,
  CatalogSetupInterfaceNavContent,
  BillOfMaterialsByIdInterfaceNavContent,
  TakeOffContent,
  DataImportInterfaceNavContent,
} from './MaterialNavInterfaces';
import { ProviderMaterialBase } from './MaterialProviderRoutes';

const MaterialRoutes = () => (
  <ProviderMaterialBase>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="facilities" element={<FacilitiesInterfaceNavContent />} />
      <Route path="company/material-members" element={<MaterialMembersInterfaceNavContent />} />

      <Route path="projects" element={<ProjectInterfaceNavContent />} />
      <Route path="members/project-members" element={<ProjectMembersInterfaceNavContent />} />
      <Route path="properties/project-properties" element={<ProjectPropertiesInterfaceNavContent />} />
      <Route path="catalog-setup" element={<CatalogSetupInterfaceNavContent />} />
      <Route path="data-import" element={<DataImportInterfaceNavContent />} />
      <Route path="bill-of-materials" element={<BillOfMaterialsInterfaceNavContent />} />
      <Route path="bill-of-materials/:id" element={<BillOfMaterialsByIdInterfaceNavContent />} />
      <Route path="takeoff" element={<TakeOffContent />} />
      <Route path="work-requests" element={<WorkRequestsNavContent />} />
      <Route path="work-requests/:id" element={<WorkRequestNavContent />} />
      <Route path="assembly-editor" element={<AssemblyEditorNavContent />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </ProviderMaterialBase>
);

export default MaterialRoutes;
