import { v4 as uuidv4 } from 'uuid';

import { Task, TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import {
  ASSEMBLY_EDGE_TYPE_ID as assemblyEdgeTypeId,
  ASSEMBLY_NODE_PART_TYPE_ID,
  ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
  DEFAULT_ASSEMBLY_NODE_POSITION,
  EDGE_TYPE_ID_DICT,
} from 'modules/Materials/AssemblyEditor/Utils/constants';

import { Part, PartId } from './SecondaryPane/AddItems/types';
import { AssemblyEdge, AssemblyNode, NodeReferenceId } from './types';

const referenceId = () => uuidv4() as NodeReferenceId;

export const createAssemblyFromWriteInItem = (
  { partId, partName, description }: Part,
  tasks: Task[],
): {
  partId: PartId;
  edges: AssemblyEdge[];
  nodes: AssemblyNode[];
} => {
  // Arrows generated from this func go left to right
  const beginHandlePositionId = EDGE_TYPE_ID_DICT.b;
  const endHandlePositionId = EDGE_TYPE_ID_DICT.a;

  const edges: AssemblyEdge[] = [];
  const nodes: AssemblyNode[] = [];

  // Start with the Part
  const partNode: AssemblyNode = {
    partId,
    assemblyNodeName: partName,
    assemblyNodeDescription: description ?? null,
    assemblyNodeTypeId: ASSEMBLY_NODE_PART_TYPE_ID,
    referenceId: referenceId(),
    ...DEFAULT_ASSEMBLY_NODE_POSITION,
  };
  nodes.push(partNode);

  // Find every Task that is NOT a predecessor to any other task
  const tasksThatAreNotPredecessors: TaskId[] = tasks
    .filter((task) => !tasks.some((t) => t.taskPredecessorIds.some((pred) => pred.taskId === task.taskId)))
    .map((task) => task.taskId);

  // Add a node for every task
  const taskToNodeMap: Record<TaskId, AssemblyNode> = {};
  tasks.forEach((task) => {
    taskToNodeMap[task.taskId] = {
      shopTaskId: task.taskTypeId,
      assemblyNodeName: task.taskTypeName,
      assemblyNodeDescription: null,
      assemblyNodeTypeId: ASSEMBLY_NODE_SHOP_TASK_TYPE_ID,
      referenceId: referenceId(),
    };
    nodes.push(taskToNodeMap[task.taskId]);
  });

  // Then, for every task...
  tasks.forEach((task) => {
    // find its node...
    const taskNode = taskToNodeMap[task.taskId];
    // then, for every predecessor, add an Edge from the predecessor to this Task...
    task.taskPredecessorIds.forEach((t) => {
      const predecessorNode = taskToNodeMap[t.taskId];
      edges.push({
        beginNodeReferenceId: predecessorNode.referenceId,
        endNodeReferenceId: taskNode.referenceId,
        assemblyEdgeTypeId,
        beginHandlePositionId,
        endHandlePositionId,
      });
    });
    // and finally, if this Task is not a predecessor for any other task,
    // and an edge from this Task to the Part.
    if (tasksThatAreNotPredecessors.includes(task.taskId)) {
      edges.push({
        beginNodeReferenceId: taskNode.referenceId,
        endNodeReferenceId: partNode.referenceId,
        assemblyEdgeTypeId,
        beginHandlePositionId,
        endHandlePositionId,
      });
    }
  });

  return { partId, edges, nodes };
};
