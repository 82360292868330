import { useCallback } from 'react';

import type { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

export interface UserFromCognito extends CognitoUser {
  username: string;
}

const useAuth = () => {
  const getCognitoUser = useCallback(async (): Promise<UserFromCognito> => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    return cognitoUser;
  }, []);

  return { getCognitoUser };
};

export default useAuth;
