import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { formatFacility, formatProject, useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { LOCAL_STORAGE_CONSTANTS } from 'constants/globalConstants';
import { isNil, isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';
import type { CompanyId } from 'types/types-api';

import type { Department } from './types';
import type { Facility, FacilityId } from './useFacilities';
import type { Project, ProjectId } from './useProjects';

export type SelectedItem = {
  label: string;
  companyId: CompanyId;
  departments: Department[] | null;
} & (
  | {
      id: FacilityId;
      type: 'FACILITY';
    }
  | {
      id: ProjectId;
      type: 'PROJECT';
    }
);

type SelectedFacilityProjectContextType = {
  selectedItem?: SelectedItem;
  selectFacility: (facility: Facility) => void;
  selectProject: (project: Project) => void;
  clearSelectedItem: () => void;
};

const SelectedFacilityProjectContext = React.createContext<SelectedFacilityProjectContextType | undefined>(undefined);

/**
 * Runs some basic validations to make sure the selectedItem
 * (pulled from local storage usually)
 * has all the expected keys.
 * This helps prevent from corrupted or outdated local storage values.
 */
const selectedItemIsValid = (selectedItem: any): selectedItem is SelectedItem => {
  if (
    isNil(selectedItem) ||
    isNil(selectedItem.id) ||
    isNil(selectedItem.label) ||
    isNil(selectedItem.companyId) ||
    !['FACILITY', 'PROJECT'].includes(selectedItem?.type)
  ) {
    return false;
  }
  return true;
};

export const SelectedFacilityProjectProvider = ({ children }: { children: ReactNode }) => {
  // TODO: Remove once all legacy usages of this are removed
  const { setSelectedItem: setSelectedItemLegacy } = useFacilitiesProjects();

  const [selectedItem, setSelectedItem] = useState<SelectedItem | undefined>();

  const selectFacility = useCallback(
    (facility: Facility) => {
      setSelectedItem({
        type: 'FACILITY',
        label: facility.facilityName,
        id: facility.facilityId,
        companyId: facility.companyId,
        departments: facility.departments,
      });
      setSelectedItemLegacy(formatFacility(facility));
    },
    [setSelectedItemLegacy],
  );

  const selectProject = useCallback(
    (project: Project) => {
      setSelectedItem({
        type: 'PROJECT',
        label: project.projectName,
        id: project.projectId,
        companyId: project.companyId,
        departments: project.departments,
      });
      setSelectedItemLegacy(formatProject(project));
    },
    [setSelectedItemLegacy],
  );

  const clearSelectedItem = useCallback(() => {
    removeLocalStorage(LOCAL_STORAGE_CONSTANTS.SELECTED_ITEM);
    setSelectedItem(undefined);
  }, []);

  useEffect(() => {
    if (isNotNil(selectedItem)) {
      setLocalStorage(LOCAL_STORAGE_CONSTANTS.SELECTED_ITEM, selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    const selectedItemStored = getLocalStorage(LOCAL_STORAGE_CONSTANTS.SELECTED_ITEM);
    if (selectedItemIsValid(selectedItemStored) && isNil(selectedItem)) {
      setSelectedItem(selectedItemStored);
      setSelectedItemLegacy(selectedItemStored);
    }
  }, [selectedItem, setSelectedItemLegacy]);

  const value = useMemo(
    () => ({
      selectedItem,
      selectFacility,
      selectProject,
      clearSelectedItem,
    }),
    [clearSelectedItem, selectFacility, selectProject, selectedItem],
  );
  return <SelectedFacilityProjectContext.Provider value={value}>{children}</SelectedFacilityProjectContext.Provider>;
};

export const useSelectedProjectFacility = () =>
  useGeneralContext(SelectedFacilityProjectContext, 'ActiveProjectFacility');

export const useSelectedFacilityShop = () => {
  const { selectedItem } = useSelectedProjectFacility();
  if (isNil(selectedItem) || selectedItem.type === 'PROJECT') {
    return null;
  }
  return selectedItem.departments?.find((d) => d.departmentType.departmentTypeId === DEPARTMENT_TYPE.SHOP)
    ?.departmentId;
};
