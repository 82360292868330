import { useCallback } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { GENERIC_MUTATION_ERROR } from 'constants/globalConstants';
import { addWorkRequestItemDocument as ADD_WORK_REQUEST_ITEM_DOCUMENT } from 'graphql/mutations';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';

const useRegisterWRItemDocuments = () => {
  const { drawingModelCNCIds } = useDocumentTypes();
  const { enqueueSnackbar } = useSnackbar();

  const [addWorkRequestItemDocument, { loading }] = useMutation(gql(ADD_WORK_REQUEST_ITEM_DOCUMENT), {
    refetchQueries: ['TaskMultiSearch', 'TaskDocument'],
  });

  const addRequestItemDocument = useCallback(
    async (workRequestItemIds, documentIds, notification, checkboxFolderId) => {
      const body = {
        workRequestItemIds: [workRequestItemIds],
        documentIds: checkboxFolderId ? [] : documentIds,
        folderId: checkboxFolderId,
        documentTypeIds: checkboxFolderId ? drawingModelCNCIds : undefined,
      };

      await addWorkRequestItemDocument({
        variables: {
          body,
        },
        awaitRefetchQueries: true,
        onCompleted: () => notification.snackBar,
        onError: () => {
          enqueueSnackbar(GENERIC_MUTATION_ERROR, {
            preventDuplicate: true,
            variant: 'error',
          });
        },
      });
    },
    [addWorkRequestItemDocument, enqueueSnackbar, drawingModelCNCIds],
  );

  return { addRequestItemDocument, loading };
};

export default useRegisterWRItemDocuments;
