import React, { useMemo, useState } from 'react';

import { Typography, Stack, Box, useTheme } from '@mui/material';

import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { StyledEditInputImage } from 'components/FormComponents/InputImage/components/InputImageStyles';
import { Custom } from 'components/Icons';
import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import { getDaysBetween } from 'helpers/dateFunctions';
import useSetupModule from 'hooks/useSetupModule';

import { useWorkRequestsContext } from '../../WorkRequestsContext';
import WorkRequestStatus from '../WorkRequestStatus';
import EditWorkOrderName from './EditWorkOrderName';
import FlyoutAccordion from './FlyoutAccordion';
import { workOrder } from './WRflyoutTypes';

export const FlyoutWorkRequestDetails = () => {
  const { workRequestSelected, workOrderSelected } = useWorkRequestsContext();
  const { selectedItem } = useFacilitiesProjects();

  const currentItemStatus = workOrderSelected
    ? workOrderSelected.workOrderStatusTypeName
    : workRequestSelected?.workRequestStatusName;

  const daysRemaining =
    workRequestSelected?.needBy && getDaysBetween(new Date(`${workRequestSelected.needBy}Z`), new Date());

  const selectName = useMemo(() => {
    if (workOrderSelected) return workOrderSelected.workRequest.projectName;
    if (selectedItem?.type === 'PROJECT') return workRequestSelected?.facilityName;
    return workRequestSelected?.projectName;
  }, [selectedItem, workOrderSelected, workRequestSelected]);

  return (
    <Box sx={{ overflow: 'scroll', overflowX: 'hidden', height: '100%' }}>
      <Stack margin="8px 24px 0 24px" direction="row" justifyContent="space-between">
        <Stack width="328px">
          <ItemIdentifier />
          <Typography variant="subtitle2" sx={{ color: '#a7a7a7' }}>
            {selectName}
          </Typography>
        </Stack>
        <Stack sx={{ height: '8px', marginTop: '8px' }}>
          <WorkRequestStatus
            key={workRequestSelected?.workRequestId}
            status={currentItemStatus}
            pastDaysRemaining={daysRemaining < 0}
          />
        </Stack>
      </Stack>
      <FlyoutAccordion />
    </Box>
  );
};

const ItemIdentifier = () => {
  const { workRequestSelected, workOrderSelected } = useWorkRequestsContext();
  const [isHover, setHover] = useState(false);
  const [isEdit, editWOName] = useState(false);

  if (!workOrderSelected)
    return (
      <Typography variant="body1" sx={{ color: 'white' }}>
        {workRequestSelected?.workRequestIdentifier}
      </Typography>
    );
  return (
    <Box component="div" sx={{ color: 'white' }}>
      {isEdit ? (
        <EditWorkOrderName workOrderSelected={workOrderSelected} setHover={setHover} editWOName={editWOName} />
      ) : (
        <DisplayWorkOrderNameComponent
          editWOName={editWOName}
          workOrderSelected={workOrderSelected}
          isHover={isHover}
          setHover={setHover}
        />
      )}
    </Box>
  );
};

const LinkComponent = ({ workOrderSelected }: { workOrderSelected: workOrder }) => {
  const { palette } = useTheme();
  const { currentModule } = useSetupModule();
  const normalStyle = {
    color: palette.secondary.mainDarkBackground,
    textDecoration: 'none',
  };
  const hoveredStyle = { textDecoration: 'underline' };

  return (
    <EvolveLink
      style={normalStyle}
      onMouseEnter={(e) => Object.assign((e.target as HTMLAnchorElement).style, hoveredStyle)}
      onMouseLeave={(e) => Object.assign((e.target as HTMLAnchorElement).style, normalStyle)}
      to={`/${currentModule()}/work-requests/${workOrderSelected?.workRequest?.workRequestId}`}
      from="Work Requests"
    >
      {workOrderSelected?.workRequest?.workRequestIdentifier}
    </EvolveLink>
  );
};

type EditImageComponentProps = {
  editWOName: React.Dispatch<React.SetStateAction<boolean>>;
  isHover: boolean;
};

const EditImageComponent = ({ editWOName, isHover }: EditImageComponentProps) => (
  <StyledEditInputImage onClick={() => editWOName(true)} sx={{ opacity: isHover ? 1 : 0 }}>
    <Custom.Edit
      title="Click to edit work order id"
      width="13px"
      height="13px"
      style={{ marginLeft: '-17px', marginTop: '9px', marginRight: '15px' }}
    />
  </StyledEditInputImage>
);

const DisplayWorkOrderNameComponent = ({
  editWOName,
  workOrderSelected,
  isHover,
  setHover,
}: {
  editWOName: React.Dispatch<React.SetStateAction<boolean>>;
  workOrderSelected: workOrder;
  isHover: boolean;
  setHover: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <Box sx={{ display: 'inline' }}>
    <EditImageComponent editWOName={editWOName} isHover={isHover} />
    <Typography
      style={{ display: 'inline' }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      sx={{ wordBreak: 'break-word' }}
    >
      {workOrderSelected?.workOrderName} |
    </Typography>
    &nbsp;
    <LinkComponent workOrderSelected={workOrderSelected} />
  </Box>
);
