import 'helpers/ag-grid/ag-nested-grid-theme.css';
import type { ColDef } from 'ag-grid-community';
import type { AgGridReactProps } from 'ag-grid-react';
import { v4 as uuidv4 } from 'uuid';

import { CustomDateComponent } from './CustomDateComponent';
import { CustomValueSetFilterComponent } from './CustomSetFilterComponent';
import { LoadingRow } from './LoadingRow';
import { NoRowsOverlay } from './NoRowsOverlay';

export const compactRowHeight = 32 as const;

export const baseColumnDef: ColDef = {
  suppressHeaderMenuButton: true,
  enableCellChangeFlash: true,
  minWidth: 64,
  // Default width, can be overriden (or changed on resizable columns)
  width: 200,
} as const;

const components: AgGridReactProps['components'] = {
  agDateInput: CustomDateComponent,
  customValueSetFilter: CustomValueSetFilterComponent,
};

/** The bade grid definition to use for **all** grids, including detail grids. */
export const baseGridDef: AgGridReactProps = {
  noRowsOverlayComponent: () => <NoRowsOverlay label="No results found." />,
  loadingCellRenderer: LoadingRow,
  suppressRowClickSelection: true,
  alwaysMultiSort: true,
  keepDetailRows: true,
  detailRowAutoHeight: true,
  suppressCellFocus: true,
  suppressDragLeaveHidesColumns: true,
  tooltipShowDelay: 1000,
  popupParent: document.querySelector('body'),
  components,
  // TODO: DELETE on AG Grid 32
  columnMenu: 'new',
  reactiveCustomComponents: true,
  // END DELETE
} as const;

/**
 * `ColDef` for any column which is locked to the left or right side of the table.
 * ie. buttons, dropdown, menu icons, etc
 */
export const lockedColDef = <TData,>(lockPosition: 'left' | 'right' = 'left'): ColDef<TData> =>
  ({
    /** Defined to prevent the order from being randomly moved as we reload pages */
    colId: `doNotSave-${uuidv4()}`,
    minWidth: 40,
    resizable: false,
    lockPosition,
    sortable: false,
    enableCellChangeFlash: false,
    suppressColumnsToolPanel: true,
    mainMenuItems: ['autoSizeAll', 'resetColumns'],
  } as const);
