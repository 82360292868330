import { useState } from 'react';

import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AlertingPopover } from 'components/Mantine/AlertingPopover';
import { BoxWithRef } from 'components/Mantine/DivWithRef';
import { SearchableMultiSelect } from 'components/Mantine/SearchableMultiSelect';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { WorkCell } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/types';

import { useWorkCellSetup } from '../WorkCellSetupContext';

type Props = {
  taskTypes: TaskType[];
  onComplete: () => void;
};

export const MapTaskToWorkCellPopover = ({ taskTypes, onComplete }: Props) => {
  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState<WorkCell>();
  const [saving, setSaving] = useState(false);
  const {
    selectedFacility,
    draftShopConfiguration,
    mapWorkCellTaskType,
    serverSideGridProps: { refreshDetailGrid, expandDetailGrid },
  } = useWorkCellSetup();
  const { data, ...paginatedGet } = useWrappedPaginatedGet<WorkCell>('shop/workCell', {
    lazy: true,
    defaultConfig: {
      params: {
        facilityId: selectedFacility.id,
        shopConfigurationId: draftShopConfiguration?.shopConfigurationId,
      },
    },
  });

  const onSubmit = () => {
    if (isNil(selected)) return;
    setSaving(true);
    Promise.allSettled(taskTypes.map((taskType) => mapWorkCellTaskType(selected.workCellId, taskType.taskTypeId)))
      .then((x) => {
        const successful = x.filter((p) => p.status === 'fulfilled').length;
        if (successful > 0) {
          notifications.show({
            title: 'Successfully mapped',
            message: `Mapped ${successful} task${successful === 1 ? '' : 's'} to ${selected.workCellName}`,
            color: 'green',
          });
          expandDetailGrid(selected.workCellId, 'Tasks');
          refreshDetailGrid(selected.workCellId, 'Tasks');
          setSelected(undefined);
          onComplete();
          setOpened(false);
        }
      })
      .finally(() => setSaving(false));
  };

  return (
    <AlertingPopover
      opened={opened}
      onClose={() => setOpened(false)}
      isDirty={!!selected}
      onSubmit={onSubmit}
      disabled={isNil(selected)}
      loading={saving}
      target={
        <BoxWithRef>
          <Button
            onClick={() => setOpened(true)}
            variant="outline"
            leftIcon={<EvolveIcon icon="Pin" size="sm" color="inherit" />}
            disabled={taskTypes.length === 0}
          >
            Map to work cell
          </Button>
        </BoxWithRef>
      }
    >
      <SearchableMultiSelect
        label="Work Cell"
        paginatedGet={{ data, ...paginatedGet }}
        searchKey="workCellName"
        idKey="workCellId"
        getItemLabel={(d) => d.workCellName}
        maxSelectedValues={1}
        disabled={saving}
        clearable
        onChange={(s) => setSelected(s[0])}
        excludeIds={[data.find((wc) => wc.workCellName === 'Unassigned')?.workCellId].filter(isNotNil)}
      />
    </AlertingPopover>
  );
};
