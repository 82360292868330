import { useCallback, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import {
  partsCloudSearch as PARTS_COULD_SEARCH,
  partsCloudSearchCount as PARTS_CLOUD_SEARCH_COUNT,
} from 'graphql/queries';
import { DEFAULT_QUERY_PARAMS_BY_PART_NAME, DEFAULT_QUERY_PARAMS_BY_PART_ID } from 'helpers/cloudSearchParams';
import { stringifyCloudQuery } from 'helpers/stringFunctions';
import type {
  PartCatalogId,
  PartId,
} from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/AddItems/types';

const PARTS_CLOUD_SEARCH_LIMIT = 5;

export type PartSearchResult = {
  catalogNumber: null;
  hasAssembly: '0' | '1'; // appears to be boolean as a number as a string? ie. true is '1'
  manufacturerName: string;
  partCatalogId: PartCatalogId | null; // usually null, even if exists
  partCategories: null; // TODO
  partCategoryPath: null; // TODO
  partDescription: string | null;
  // partHistoryId: '08dc8702-7dc4-4fdc-81a8-baddb7719503';
  // partHistoryRefId: '5a87db60-1151-477b-a7d4-5d123005c9fd';
  partId: PartId;
  partImageDocumentId: null; // TODO
  partName: string;
  unitOfMeasureCode: string;
  version: string; // number as a string?, ie '2'
};

const usePartsCloudSearchAPI = (catalogId: PartCatalogId | '' = '', limit = 0) => {
  const [data, setData] = useState<PartSearchResult[]>([]);
  const [count, setCount] = useState(0);

  const [fetchParts, { loading }] = useLazyQuery<{
    partsCloudSearch: PartSearchResult[];
  }>(gql(PARTS_COULD_SEARCH), {
    fetchPolicy: 'no-cache',
  });

  const [fetchPartsCount] = useLazyQuery(gql(PARTS_CLOUD_SEARCH_COUNT), {
    fetchPolicy: 'no-cache',
  });

  const searchParts = useCallback(
    async (queryString: string) => {
      const res = await fetchParts({
        variables: {
          query: {
            query: stringifyCloudQuery(queryString, catalogId),
            skip: 0,
            take: limit || PARTS_CLOUD_SEARCH_LIMIT,
            ...DEFAULT_QUERY_PARAMS_BY_PART_NAME,
          },
        },
      });
      setData(res.data?.partsCloudSearch ?? []);
    },
    [catalogId, fetchParts, limit],
  );

  const getPartsCount = useCallback(
    async (queryString: string) => {
      const {
        data: { partsCloudSearchCount },
      } = await fetchPartsCount({
        variables: {
          query: {
            query: stringifyCloudQuery(queryString, catalogId),
            ...DEFAULT_QUERY_PARAMS_BY_PART_NAME,
          },
        },
      });

      const partItemsCount = partsCloudSearchCount ?? 0;
      setCount(partItemsCount);

      return partItemsCount;
    },
    [catalogId, fetchPartsCount],
  );

  const getPartById = useCallback(
    async (queryString: string, partId: PartId) => {
      const res = await fetchParts({
        variables: {
          query: {
            query: stringifyCloudQuery(queryString, partId),
            ...DEFAULT_QUERY_PARAMS_BY_PART_ID,
          },
        },
      });
      setData(res.data?.partsCloudSearch ?? []);
    },
    [fetchParts],
  );

  return { data, count, loading, searchParts, getPartsCount, getPartById };
};

export default usePartsCloudSearchAPI;
