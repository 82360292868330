import { useEffect } from 'react';

import { Loader } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';

import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import type { WorkRequestItem } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';
import { getDaysRemainingWorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/common';
import { useWorkRequests } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';
import { WorkRequestActionButtons } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/WorkRequestActionButtons';

import { getPageTitle } from './common';
import { ItemsMultiSelectActions } from './ItemsMultiSelectActions';
import { useWorkRequestItems } from './WorkRequestOrderItemsContext';

export const WorkRequestItemsTable = () => {
  const context = useWorkRequestItems();
  const { selectedItem, workRequest, workRequestUpdated, serverSideGridProps, setSelectedWorkRequestItems } = context;
  const { updateWorkRequestStatus, mutationRunning } = useWorkRequests();
  const { agGridProps, filterIsSet, refreshGrid } = serverSideGridProps;

  useEffect(() => {
    if (workRequest.workRequestStatusName === 'Submitted' && selectedItem.type === 'FACILITY') {
      updateWorkRequestStatus(workRequest.workRequestId, 'Pending', workRequest.workRequestDescription ?? '').then(
        workRequestUpdated,
      );
    }
  }, [
    selectedItem.type,
    workRequestUpdated,
    updateWorkRequestStatus,
    workRequest.workRequestDescription,
    workRequest.workRequestId,
    workRequest.workRequestStatusName,
  ]);

  return (
    <>
      <BasePageHeader
        title={getPageTitle(workRequest)}
        parentPage={{
          title: 'Work Requests',
          url: '..',
        }}
        gridRef={agGridProps.ref}
        filterIsSet={filterIsSet}
        topLeftComponent={
          <>
            <CellStatusBadge
              size="lg"
              status={workRequest.workRequestStatusName}
              daysRemaining={getDaysRemainingWorkRequest(workRequest)}
            />
            {mutationRunning && <Loader size="sm" />}
          </>
        }
        topRightComponent={
          <WorkRequestActionButtons
            workRequest={workRequest}
            onUpdate={(wr) => {
              workRequestUpdated(wr);
              refreshGrid();
            }}
            inline={false}
          />
        }
        bottomLeftComponent={<ItemsMultiSelectActions {...context} />}
      />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<WorkRequestItem>
          {...agGridProps}
          noRowsOverlayComponent={() => (
            <NoRowsOverlay label="Add an item to begin." icon="WorkRequest" gridRef={agGridProps.ref} />
          )}
          rowSelection="multiple"
          onSelectionChanged={({ api }) => setSelectedWorkRequestItems(api.getSelectedRows())}
        />
      </div>
    </>
  );
};
