import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { Anchor, Button, Divider, Flex, Modal, Text } from '@mantine/core';
import { Notifications, notifications } from '@mantine/notifications';

import { extractErrorMessage } from 'helpers/extractError';
import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';

type MantineNotificationsContextType = {
  showError: (err: any) => void;
};
const MantineNotificationsContext = React.createContext<MantineNotificationsContextType | undefined>(undefined);

const maxErrorLength = 400;
export const MantineNotificationsProvider = ({ children }: { children: ReactNode }) => {
  const [fullError, setFullError] = useState<string | null>(null);
  const showError = useCallback((err: any) => {
    const extractedError = extractErrorMessage(err);
    const errorTooLong = extractedError.length > maxErrorLength;
    notifications.show({
      id: 'error',
      title: 'Something went wrong',
      autoClose: 8000,
      message: (
        <Flex direction="column">
          {errorTooLong ? `${extractedError.substring(0, maxErrorLength)}...` : extractedError}
          {errorTooLong && (
            <Flex my={3} justify="flex-end">
              <Anchor
                onClick={() => {
                  notifications.hide('error');
                  setFullError(extractedError);
                }}
              >
                View more
              </Anchor>
            </Flex>
          )}
        </Flex>
      ),
      color: 'red',
    });
  }, []);
  return (
    <MantineNotificationsContext.Provider value={useMemo(() => ({ showError }), [showError])}>
      <Notifications zIndex={99999} autoClose={4000} />
      <Modal
        title="Error"
        centered
        size="xl"
        opened={isNotNil(fullError)}
        onClose={() => setFullError(null)}
        styles={{ body: { maxHeight: 500 } }}
      >
        <Text ff="monospace">{fullError}</Text>
        <Divider my="md" />
        <Flex justify="flex-end">
          <Button mb="md" color="gray" onClick={() => setFullError(null)}>
            Close
          </Button>
        </Flex>
      </Modal>
      {children}
    </MantineNotificationsContext.Provider>
  );
};

export const useMantineNotifications = () => useGeneralContext(MantineNotificationsContext, 'MantineNotifications');
