import 'ag-grid-community/styles/ag-grid.css';
import './mantine-styles.css';
import { ReactNode } from 'react';

import { Box, MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import MUIBox from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseManager } from 'ag-grid-enterprise';
import { SnackbarProvider } from 'notistack';

import { ExportGridProvider } from 'helpers/ag-grid/useExportGrid';
import { FacilitiesProvider } from 'hooks/projectsAndFacilities/useFacilities';
import { ProjectsProvider } from 'hooks/projectsAndFacilities/useProjects';
import { SelectedFacilityProjectProvider } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import { ShopConfigurationsProvider } from 'hooks/projectsAndFacilities/useShopConfigurations';
import { UsersInfoProvider } from 'hooks/useUsersInfo';
import { ManufacturersProvider } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useManufacturers';
import { UnitOfMeasureProvider } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';
import { WorkRequestsProvider } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';
import { DocumentTypesProvider } from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes/useDocumentTypes';
import { TasksProvider } from 'modules/Shop/Fabrication/WorkCellQueue/WorkCellQueuePage/useTasks';
import { DocumentsCacheProvider } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/Attachments/useDocumentsCache';
import { WorkOrdersProvider } from 'modules/Shop/WorkOrders/WorkOrdersPage/useWorkOrders';
import { useGenerateMantineTheme } from 'styles/theme/mantineTheme';

import { CustomThemeProvider } from '../styles';
import { AppRoutes } from './AppRoutes';
import { FacilitiesProjectsProvider } from './FacilitiesProjectsContext';
import { ProfilePictureProvider } from './Layout/HeaderBar/ProfileDropdown/ProfilePictureContext';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-062140}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Evolve_MEP}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Evolve_Shop}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Evolve_Shop}_need_to_be_licensed___{Evolve_Shop}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{21_July_2025}____[v3]_[0102]_MTc1MzA1MjQwMDAwMA==93efe8f24a7804022f221f0da9d8c96f',
);

// TODO: Remove me after converting full app to Mantine
const Notistack = ({ children }: { children: ReactNode }) => (
  <MUIBox sx={{ '& .SnackbarContent-root': { minWidth: 'unset !important' } }}>
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      maxSnack={3}
      autoHideDuration={3000}
    >
      {children}
    </SnackbarProvider>
  </MUIBox>
);

export const App = () => {
  const theme = useGenerateMantineTheme();
  return (
    <MantineProvider withCSSVariables theme={theme}>
      <ManufacturersProvider>
        <WorkRequestsProvider>
          <WorkOrdersProvider>
            <TasksProvider>
              <DocumentTypesProvider>
                <DocumentsCacheProvider>
                  <UnitOfMeasureProvider>
                    <FacilitiesProjectsProvider>
                      <SelectedFacilityProjectProvider>
                        <FacilitiesProvider>
                          <ProjectsProvider>
                            <ShopConfigurationsProvider>
                              <UsersInfoProvider>
                                <ProfilePictureProvider>
                                  <ExportGridProvider>
                                    <DatesProvider
                                      settings={{
                                        firstDayOfWeek: 0,
                                        weekendDays: [],
                                      }}
                                    >
                                      <CustomThemeProvider>
                                        <CssBaseline />
                                        <Box style={{ margin: '0 auto', minHeight: '100vh' }}>
                                          <Notistack>
                                            <AppRoutes />
                                          </Notistack>
                                        </Box>
                                      </CustomThemeProvider>
                                    </DatesProvider>
                                  </ExportGridProvider>
                                </ProfilePictureProvider>
                              </UsersInfoProvider>
                            </ShopConfigurationsProvider>
                          </ProjectsProvider>
                        </FacilitiesProvider>
                      </SelectedFacilityProjectProvider>
                    </FacilitiesProjectsProvider>
                  </UnitOfMeasureProvider>
                </DocumentsCacheProvider>
              </DocumentTypesProvider>
            </TasksProvider>
          </WorkOrdersProvider>
        </WorkRequestsProvider>
      </ManufacturersProvider>
    </MantineProvider>
  );
};
