import { useCallback, useState } from 'react';

import { Auth } from 'aws-amplify';

import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

export type ChangePasswordBody = {
  currentPassword: string;
  newPassword: string;
};

// TODO: Replace with this call once the backend call is fixed
export const useResetPasswordNew = () => {
  const { apiCall: resetPassword, ...rest } = useWrappedPost<unknown, ChangePasswordBody>(
    'admin/authentication/changePassword',
  );
  return {
    resetPassword,
    ...rest,
  };
};

export const useResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const resetPassword = useCallback(async (body: ChangePasswordBody) => {
    setLoading(true);
    try {
      const amplifyUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(amplifyUser, body.currentPassword, body.newPassword);
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    resetPassword,
    loading,
  };
};
