import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { DEPARTMENT_TYPE_IDS } from 'constants/globalConstants';
import useSetupModule from 'hooks/useSetupModule';

const useCheckIfShopDepartmentExists = () => {
  const { SHOP_TYPE_DEPARTMENT_ID } = DEPARTMENT_TYPE_IDS;
  const { selectedItem } = useFacilitiesProjects();
  const { currentModule } = useSetupModule();
  const shopDepartment = selectedItem?.departments?.find(
    (department) => department.departmentType?.departmentTypeId === SHOP_TYPE_DEPARTMENT_ID,
  );
  if (selectedItem?.type === 'PROJECT') return true;
  return !!shopDepartment && currentModule() === 'SHOP';
};

export default useCheckIfShopDepartmentExists;
