import React, { Children, ReactNode, useMemo } from 'react';

import GridLayout from 'app/Layout/GridLayout';

import ComponentPane from './ComponentPane';

type Props = {
  children: ReactNode;
  sizes?: any;
  flyoutMenu?: ReactNode;
  componentPaneProps?: React.ComponentProps<typeof ComponentPane>;
};

const ComponentPaneLayout = ({ sizes, flyoutMenu, children, componentPaneProps }: Props) => {
  const childrenPanes = useMemo(
    () =>
      Children.map(children, (child) => (
        // @ts-expect-error not sure what is supposed to be happening here
        <ComponentPane renderMenuItems={child?.props?.renderMenuItems} {...componentPaneProps}>
          {child}
        </ComponentPane>
      ))?.filter((child) => !!child.props.children),
    [children, componentPaneProps],
  );

  return (
    <>
      <GridLayout sizes={sizes}>{childrenPanes}</GridLayout>
      {flyoutMenu}
    </>
  );
};

export default ComponentPaneLayout;
