import type { MantineColor, MantineThemeOverride } from '@mantine/core';

import useSetupModule from 'hooks/useSetupModule';
import type { EModuleType } from 'types/module';

export const themePrimaryColorMap: Partial<Record<EModuleType, MantineColor>> = {
  ADMIN: 'teal',
  SHOP: 'violet',
  MATERIALS: 'orange',
  FIELD: 'green',
  MANAGEMENT: 'cyan',
  DESIGN: 'blue',
  SYNC: 'orange',
};

export const useGenerateMantineTheme = (): MantineThemeOverride => {
  const { currentModule } = useSetupModule();
  const module = currentModule();
  const primaryColor = module in themePrimaryColorMap ? themePrimaryColorMap[module] : 'gray';
  // TODO: Remove this after upgrading to Mantine v7
  document.documentElement.style.setProperty('--mantine-primary-color', `var(--mantine-color-${primaryColor}-6)`);
  for (let i = 0; i <= 9; i += 1) {
    document.documentElement.style.setProperty(
      `--mantine-primary-color-${i}`,
      `var(--mantine-color-${primaryColor}-${i})`,
    );
  }
  return {
    fontFamily: 'Roboto',
    headings: {
      fontFamily: 'Roboto',
    },
    primaryColor,
  };
};
