import { gql, useQuery } from '@apollo/client';
import { ActionIcon, Indicator } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { Custom } from 'components/Icons';
import { messagesCount as MESSAGES_COUNT } from 'graphql/queries';

export const MailButton = () => {
  const navigate = useNavigate();
  const goToMessageCenter = () => navigate('/message');

  const { data: messagesCount } = useQuery(gql(MESSAGES_COUNT));
  const count = messagesCount?.messagesCount ?? 0;

  return (
    <Indicator color="red" inline label={count > 999 ? '999+' : count} disabled={count === 0} size={16}>
      <ActionIcon variant="filled" size="lg" onClick={goToMessageCenter} radius="xl">
        <Custom.Mail />
      </ActionIcon>
    </Indicator>
  );
};
