import React, { ReactNode, useMemo } from 'react';

import { isNil } from 'helpers/isNotNil';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';

import { Custom } from '../Icons';
import { NoContentFallback } from '../NoContentFallback';

/** @deprecated in favor of `FacilityOrProjectRequired.tsx` */
const FacilityAndProjectWrapper = ({
  children,
  isFacilityRequired = false,
  isProjectRequired = false,
}: {
  children: ReactNode;
  isFacilityRequired?: boolean;
  isProjectRequired?: boolean;
}) => {
  const { selectedItem } = useSelectedProjectFacility();
  const messageSelectedItem = (() => {
    if (isFacilityRequired && isProjectRequired) return 'project or facility';
    return isFacilityRequired ? 'facility' : 'project';
  })();

  const isRequiredEntitySelected = useMemo(() => {
    if (isNil(selectedItem)) return false;

    const isFacilitySelected = selectedItem.type === 'FACILITY';
    const isProjectSelected = selectedItem.type === 'PROJECT';

    if (isFacilityRequired && isProjectRequired) {
      return isFacilitySelected || isProjectSelected;
    }

    if (isFacilityRequired) {
      return isFacilitySelected;
    }

    if (isProjectRequired) {
      return isProjectSelected;
    }

    return true;
  }, [selectedItem, isFacilityRequired, isProjectRequired]);

  if (!isRequiredEntitySelected) {
    return <NoContentFallback icon={Custom.Info} message={`Start by selecting a ${messageSelectedItem}.`} />;
  }

  return children;
};

export default FacilityAndProjectWrapper;
