import { useCallback } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { userById as USER_BY_ID } from 'graphql/queries';
import { validateUserNameOrEmail } from 'helpers/userHelpers';
import useUploadImage from 'hooks/useUploadImage';

export const useFetchTaskTypeImage = () => {
  const [, { refetch: fetchUserById }] = useLazyQuery(gql(USER_BY_ID), {
    fetchPolicy: 'cache-and-network',
  });

  const { getImage } = useUploadImage();

  const fetchTaskTypeUserData = useCallback(
    async (userId) => {
      const fetchUser = await fetchUserById({ params: { id: userId } });

      if (!fetchUser) return {};

      const user = fetchUser?.data?.userById;
      const taskTypeImageId = user?.userPhotoId;

      const presignedURL = taskTypeImageId ? await getImage(taskTypeImageId) : null;

      const userNameOrEmail = validateUserNameOrEmail(user);
      const userData = {
        username: userNameOrEmail,
        avatar: presignedURL,
      };

      return userData;
    },
    [fetchUserById, getImage],
  );

  return { fetchTaskTypeUserData };
};
