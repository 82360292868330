import { ReactNode, useLayoutEffect, useState } from 'react';

import type { BrowserHistory } from 'history';
import { Router, RouterProps } from 'react-router';

export const BrowserRouter = ({
  history,
  children,
  ...props
}: {
  history: BrowserHistory;
  children: ReactNode;
  props?: RouterProps;
}) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router {...props} location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  );
};
