import { useState } from 'react';

import { ActionIcon, Menu } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import type { WorkRequestStatusName } from 'constants/badgeMappingStatus';

import type { WorkRequest } from './types';
import { useWorkRequests } from './useWorkRequests';

const canDeleteStatuses: WorkRequestStatusName[] = ['Draft', 'Canceled', 'Rejected'];

type Props = {
  startEditingWorkRequest: () => void;
  workRequest: WorkRequest;
  refresh: () => void;
};

export const EditWorkRequestMenu = ({ startEditingWorkRequest, workRequest, refresh }: Props) => {
  const { deleteWorkRequest, mutationRunning } = useWorkRequests();
  const [deleting, setDeleting] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const onConfirm = () => {
    setDeleting(true);
    deleteWorkRequest(workRequest.workRequestId)
      .then(() => {
        notifications.show({
          title: 'Successfully deleted',
          message: `Deleted work request ${workRequest.workRequestIdentifier}`,
          color: 'green',
        });
        setModalOpen(false);
        refresh();
      })
      .finally(() => setDeleting(false));
  };

  return (
    <>
      <Menu position="bottom-start" disabled={mutationRunning} withinPortal opened={menuOpen} onChange={setMenuOpen}>
        <Menu.Target>
          <ActionIcon>
            <EvolveIcon className={menuOpen ? '' : 'show-on-row-hover'} icon="MoreOptsVert" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={startEditingWorkRequest}>View properties</Menu.Item>
          {canDeleteStatuses.includes(workRequest.workRequestStatusName) && (
            <Menu.Item color="red" onClick={() => setModalOpen(true)}>
              Delete
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <ConfirmationModal
        description={`Are you sure you want to delete ${workRequest.workRequestIdentifier}?`}
        opened={modalOpen}
        onConfirm={onConfirm}
        onClose={() => setModalOpen(false)}
        loading={deleting}
        buttonColor="red"
      />
    </>
  );
};
