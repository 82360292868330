import React, { Suspense } from 'react';

import { Flex, Loader } from '@mantine/core';
import { Route, Routes, Navigate } from 'react-router-dom';

import useDeviceSize from 'hooks/useDeviceSize';

import { AcceptInvitation } from './AcceptInvitation';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { Login } from './Login/Login';
import { ResetPassword } from './ResetPassword';
import { VerifyEmailPrompt } from './VerifyEmailPrompt';

const AuthBackground = React.lazy(() => import('./AuthBackground'));

export const AuthenticationRoutes = () => {
  const { deviceSize } = useDeviceSize();
  return (
    <Suspense fallback={<Loader />}>
      <Flex
        direction={deviceSize === 'DESKTOP' ? 'row-reverse' : 'column'}
        style={{
          backgroundColor: 'white',
        }}
      >
        <AuthBackground />
        <Flex
          direction="row"
          align="center"
          justify="center"
          style={{
            width: '100%',
            minHeight: 'calc(100vh - 120px)',
            ...(deviceSize === 'DESKTOP'
              ? {
                  width: '40%',
                  minHeight: 'calc(100vh - 425px)',
                  maxWidth: '700px',
                }
              : {}),
          }}
        >
          <Routes>
            {/* <Route path="register" element={<Registration />} /> */}
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="password-reset" element={<ResetPassword />} />
            <Route path="email-confirmation" element={<VerifyEmailPrompt />} />
            <Route path="accept" element={<AcceptInvitation />} />
            <Route path="*" element={<Navigate to="login" />} />
          </Routes>
        </Flex>
      </Flex>
    </Suspense>
  );
};
