import React from 'react';

import useGeneralContext from 'helpers/useGeneralContext';

import type { DetailTab } from './SecondaryPane/WorkRequestOrderDetailsPane';
import type { WorkOrderPageProps } from './WorkOrderSelectedWrapper';
import type { WorkRequestPageProps } from './WorkRequestSelectedWrapper';

export type WorkRequestOrderProps = Pick<WorkRequestPageProps, 'workRequest'> | Pick<WorkOrderPageProps, 'workOrder'>;
type WorkRequestOrderItemsContextType = (WorkRequestPageProps | WorkOrderPageProps) & {
  selectedTab: DetailTab;
  setSelectedTab: (newTab: DetailTab) => void;
};

// WorkRequestOrderItemsContext.Provider is implemented
// in the specific WR/WO Selected wrappers
export const WorkRequestOrderItemsContext = React.createContext<WorkRequestOrderItemsContextType | undefined>(
  undefined,
);

export const useWorkRequestOrderItems = () => useGeneralContext(WorkRequestOrderItemsContext, 'WorkRequestOrderItems');
export const useWorkOrderItems = () => {
  const context = useWorkRequestOrderItems();
  if (!('workOrder' in context)) {
    throw new Error('useWorkOrderItems expecting a WorkOrder context, WorkRequest found.');
  }
  return context;
};
export const useWorkRequestItems = () => {
  const context = useWorkRequestOrderItems();
  if (!('workRequest' in context)) {
    throw new Error('useWorkRequestItems expecting a WorkRequest context, WorkOrder found.');
  }
  return context;
};
