import { Button, Flex, Popover, Text } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import { WorkOrder } from 'modules/Shop/WorkOrders/WorkOrdersPage/types';

import { AttachmentsList } from './AttachmentsList';

type Props =
  | {
      workRequest: WorkRequest;
      workOrder?: never;
    }
  | {
      workRequest?: never;
      workOrder: WorkOrder;
    };

export const AttachmentsPopover = ({ workRequest, workOrder }: Props) => {
  const numberOfAttachments = workRequest?.numberOfAttachments ?? workOrder?.numberOfAttachments;
  if (!numberOfAttachments) return null;

  return (
    <Popover withinPortal position="bottom-end" shadow="md" width={450} offset={-10}>
      <Popover.Target>
        <Button variant="subtle" size="sm" py={2} px={4} color="primary">
          <Flex fz="sm" gap={2} align="center">
            <Text mt={2} fw={400}>
              {numberOfAttachments}
            </Text>
            <EvolveIcon icon="Attachment" color="inherit" size="sm" />
          </Flex>
        </Button>
      </Popover.Target>
      <Popover.Dropdown style={{ maxHeight: 500, overflowY: 'auto' }}>
        <AttachmentsList readOnly {...(workOrder ? { workOrder } : { workRequest })} />
      </Popover.Dropdown>
    </Popover>
  );
};
