import React, { useEffect, useState, useMemo, ReactNode, useCallback } from 'react';

import useGeneralContext from 'helpers/useGeneralContext';
import { useWrappedGet } from 'hooks-api/useWrappedApiCall';
import type { UserFromCognito } from 'hooks/useAuth';
import { User } from 'types/types-api';

// The frontend expects a slightly different contract than what the API returns
export type CombinedUser = UserFromCognito &
  Pick<
    User,
    | 'companyId'
    | 'userId'
    | 'userFirstName'
    | 'userLastName'
    | 'personalPhoneNumber'
    | 'workPhoneNumber'
    | 'isAdmin'
    | 'userTypes'
    | 'userPhotoId'
    | 'userEmail'
  > & {
    firstName?: string | null;
    lastName?: string | null;
  };

type UpdateUserType = {
  user: CombinedUser;
  getPresignedURL: string;
};

type UserContextType = {
  user: CombinedUser | null;
  setUser: (user: CombinedUser | null) => void;
  loading: boolean;
  setUpdateUserListener: (params: UpdateUserType) => void;
  updateUserListener: UpdateUserType | null;
  refreshUser: () => Promise<void>;
};

export const UserContext = React.createContext<UserContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
  getCognitoUser: () => Promise<UserFromCognito>;
};

/**
 * Assign values from the user info onto the Amplify user object.
 *
 * **FIXME:** This is a BAD practice! This is done temporarily until we can rewrite the auth.
 */
/* eslint-disable no-param-reassign */
export const assignUserDetailsToAmplifyUser = (amplifyUser: any, user: User) => {
  amplifyUser.companyId = user.companyId;
  amplifyUser.userId = user.userId;
  // Some places in the app use userFirstName, some use firstName
  amplifyUser.userFirstName = user.userFirstName;
  amplifyUser.userLastName = user.userLastName;
  amplifyUser.firstName = user.userFirstName;
  amplifyUser.lastName = user.userLastName;
  amplifyUser.personalPhoneNumber = user.personalPhoneNumber;
  amplifyUser.workPhoneNumber = user.workPhoneNumber;
  amplifyUser.isAdmin = user.isAdmin;
  amplifyUser.userTypes = user.userTypes;
  amplifyUser.userPhotoId = user.userPhotoId;
  amplifyUser.userEmail = user.userEmail;
};

export const UserProvider = ({ children, getCognitoUser }: Props) => {
  const { apiCall: getUserByName, loading: apiLoading } = useWrappedGet<User>('admin/user/username/', { lazy: true });
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<CombinedUser | null>(null);
  const [updateUserListener, setUpdateUserListener] = useState<UserContextType['updateUserListener']>(null);

  const pullUser = useCallback(async () => {
    try {
      setLoading(true);
      // This is a UserFromCognito, but we are unsafely adding new keys to it,
      // so it is being typed as any for now until we can verify what fields are actually needed/used
      const amplifyRefreshedUser: any = await getCognitoUser();
      if (!amplifyRefreshedUser) return;

      const res = await getUserByName({
        url: `admin/user/username/${amplifyRefreshedUser.username}`,
      });
      assignUserDetailsToAmplifyUser(amplifyRefreshedUser, res);
      setUser(amplifyRefreshedUser);
    } catch (error) {
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, [getCognitoUser, getUserByName]);

  useEffect(() => {
    pullUser();
  }, [pullUser]);

  const userObj = useMemo(
    () => ({
      user,
      setUser,
      loading: loading || apiLoading,
      refreshUser: pullUser,
      // These seem to only be used for user profile pics,
      // and should be revisited/rewritten
      setUpdateUserListener,
      updateUserListener,
    }),
    [user, loading, apiLoading, pullUser, updateUserListener],
  );

  return <UserContext.Provider value={userObj}>{children}</UserContext.Provider>;
};

export const useUser = () => useGeneralContext(UserContext, 'User');
