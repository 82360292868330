import { useMemo } from 'react';

import type { DeviceSize } from 'constants/globalConstants';

import useBreakPoint from './useBreakpoint';

export default function useDeviceSize() {
  const { isMobile, isAboveTablet, isDesktop } = useBreakPoint();

  const deviceSize = useMemo<DeviceSize>(() => {
    if (isAboveTablet && !isDesktop) return 'TABLET';
    if (isMobile) return 'MOBILE';
    return 'DESKTOP';
  }, [isMobile, isAboveTablet, isDesktop]);

  return { deviceSize };
}
