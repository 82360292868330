import { useEffect, useMemo, useState } from 'react';

import { Flex, Loader, Text } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';

import type { SelectedFacility } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import type { ShowOpen } from 'components/Mantine/OpenClosedTab';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { ShopConfiguration } from 'hooks/projectsAndFacilities/types';
import { useShopConfigurations } from 'hooks/projectsAndFacilities/useShopConfigurations';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useWorkCellsList } from 'modules/Shop/WorkCells/WorkCellsPage/useWorkCellsList';

import { getWorkCellTaskColDef } from './columnDefs';
import { useEmptyApiResponder } from './common';
import type { TaskStatusType, WorkCellTask } from './types';
import { useTasks } from './useTasks';

const statusesByGroup: Record<ShowOpen, WorkCellTask['taskStatusTypeName'][]> = {
  open: ['Not Started', 'Paused', 'Started'],
  closed: ['Completed'],
};

type Props = {
  taskStatusTypes: TaskStatusType[];
  selectedFacility: SelectedFacility;
  activeShopConfiguration: ShopConfiguration;
};

const WorkCellQueueTable = ({ selectedFacility, taskStatusTypes }: Props) => {
  const [showOpen, setShowOpen] = useState<ShowOpen>('open');
  const { workCells, loading: loadingWorkCells } = useWorkCellsList(selectedFacility.id);

  const [selectedWorkCell, setSelectedWorkCell] = useLocalStorage<'SELECTED_WORK_CELL'>('SELECTED_WORK_CELL', null);
  useEffect(() => {
    if (isNotNil(workCells) && !workCells.some((c) => c.workCellId === selectedWorkCell)) {
      setSelectedWorkCell(null);
    }
  }, [loadingWorkCells, selectedWorkCell, setSelectedWorkCell, workCells]);

  const possibleStatuses = useMemo(
    () => taskStatusTypes.filter((s) => statusesByGroup[showOpen].includes(s.taskStatusTypeName)),
    [showOpen, taskStatusTypes],
  );

  const { fetchPage, entireCount, loading, setDefaultOpts } = useWrappedPaginatedGet<WorkCellTask>('shop/workCellTask');
  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          facilityId: selectedFacility.id,
          workCellIds: selectedWorkCell,
          taskStatusTypeIds: possibleStatuses.map((s) => s.taskStatusTypeId).join(','),
        },
      },
    });
  }, [possibleStatuses, selectedFacility.id, selectedWorkCell, setDefaultOpts]);

  const colDef = useMemo(
    () => getWorkCellTaskColDef(possibleStatuses, showOpen === 'open'),
    [possibleStatuses, showOpen],
  );
  const emptyApiResponse = useEmptyApiResponder<WorkCellTask>(loadingWorkCells);
  const { agGridProps, filterIsSet } = useServerSideGrid({
    tableName: 'work-cell-queue',
    colDef,
    fetchPage: isNotNil(selectedWorkCell) ? fetchPage : emptyApiResponse,
    rowId: 'workCellTaskId',
    detailTables: [],
  });

  const defaultColDef = useMemo<typeof agGridProps['defaultColDef']>(
    () => ({
      ...agGridProps.defaultColDef,
      cellClassRules: {
        'disabled-row': (r) => !r.data?.isEnabled && isNotNil(r.colDef.field ?? r.colDef.headerName),
      },
    }),
    [agGridProps.defaultColDef],
  );

  return (
    <>
      <BasePageHeader
        title="Work Cell Queue"
        showOpen={showOpen}
        setShowOpen={setShowOpen}
        gridRef={agGridProps.ref}
        filterIsSet={filterIsSet}
        bottomLeftComponent={
          <>
            {(loading || loadingWorkCells) && <Loader size="sm" />}
            {isNotNil(entireCount) && isNotNil(selectedWorkCell) && !loading && entireCount > 0 && (
              <Text c="dimmed" fz="sm">
                {entireCount} total task{entireCount !== 1 && 's'}
              </Text>
            )}
          </>
        }
        topRightComponent={
          <WrappedSelect
            value={selectedWorkCell}
            onChange={setSelectedWorkCell}
            placeholder={loadingWorkCells ? 'Loading work cells...' : 'Select work cell...'}
            searchable
            disabled={loadingWorkCells}
            data={
              workCells?.map((c) => ({
                label: c.workCellName,
                value: c.workCellId,
              })) ?? []
            }
          />
        }
      />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<WorkCellTask>
          {...agGridProps}
          defaultColDef={defaultColDef}
          noRowsOverlayComponent={
            loadingWorkCells
              ? () => (
                  <Flex c="gray" align="center" justify="center" gap="sm">
                    <Loader size="xs" />
                    <Text>Loading work cells...</Text>
                  </Flex>
                )
              : () => (
                  <NoRowsOverlay
                    label={
                      isNil(selectedWorkCell)
                        ? 'Start by selecting a work cell from the dropdown list.'
                        : `This work cell has no ${showOpen} tasks.`
                    }
                    gridRef={agGridProps.ref}
                    icon="WorkCell"
                  />
                )
          }
        />
      </div>
    </>
  );
};

export const WorkCellQueuePage = ({ selectedFacility }: { selectedFacility: SelectedFacility }) => {
  const { taskStatusTypes } = useTasks();
  const { activeShopConfiguration } = useShopConfigurations();
  if (isNil(taskStatusTypes) || isNil(activeShopConfiguration)) {
    return <Loader m="lg" />;
  }
  return (
    <WorkCellQueueTable
      selectedFacility={selectedFacility}
      activeShopConfiguration={activeShopConfiguration}
      taskStatusTypes={taskStatusTypes}
    />
  );
};
