import { useEffect, useState } from 'react';

import { useUser } from 'app/UserContext';
import { Event } from 'graphql/types';
import { isNotNil } from 'helpers/isNotNil';
import { validateUserNameOrEmail } from 'helpers/userHelpers';
import type { UserId } from 'types/types-api';

import { useFetchTaskTypeImage } from './useFetchTaskTypeImage';

type CachedUserDetails = {
  username: string;
  avatar: string; // presigned URL
};

type CachedUserObject = {
  [key: UserId]: CachedUserDetails;
};

const getUniqueUserIds = (events: Event[] = []): UserId[] => {
  const allUserIds = events.map((row) => row.userId).filter((uid): uid is UserId => isNotNil(uid));
  return [...new Set(allUserIds)];
};

export default function useCachedUserPhotos(events: Event[] = []) {
  const { updateUserListener } = useUser();
  const [cachedUserPhotos, setCachedUserPhotos] = useState<CachedUserObject>({});
  const { fetchTaskTypeUserData } = useFetchTaskTypeImage();

  useEffect(() => {
    const controller = new AbortController();

    const getInitialProfilePhotos = async () => {
      if (!events.length) return;
      const uniqueUserIds = getUniqueUserIds(events);
      const nonCachedUserIds = uniqueUserIds.filter((userId) => !cachedUserPhotos[userId]);
      if (!nonCachedUserIds.length) return;

      const usersData = await Promise.all(
        nonCachedUserIds.map(async (userId) =>
          fetchTaskTypeUserData(userId)
            .then((data) => ({ userId, data }))
            .catch(() => null),
        ),
      );

      setCachedUserPhotos((prevCachedUserPhotos) => {
        const cachedData = { ...prevCachedUserPhotos };
        usersData.forEach((resp) => {
          if (resp) cachedData[resp.userId] = resp.data as CachedUserDetails;
        });
        return cachedData;
      });
    };

    getInitialProfilePhotos();

    return () => controller.abort();
  }, [cachedUserPhotos, events, fetchTaskTypeUserData]);

  useEffect(() => {
    let isMounted = true;
    if (updateUserListener?.user?.userId && updateUserListener?.getPresignedURL && isMounted) {
      setCachedUserPhotos((prev) => ({
        ...prev,
        [updateUserListener?.user?.userId]: {
          username: validateUserNameOrEmail(updateUserListener?.user),
          avatar: updateUserListener.getPresignedURL,
        },
      }));
    }
    return () => {
      isMounted = false;
    };
  }, [updateUserListener]);

  return {
    cachedUserPhotos,
  };
}
