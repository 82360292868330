import { gql, useLazyQuery } from '@apollo/client';

import { messagePriorities as MESSAGE_PRIORITIES } from 'graphql/queries';

const useFetchMessagePriorities = () => {
  const [fetchPriorities, { data: messagePriorities }] = useLazyQuery(gql(MESSAGE_PRIORITIES), {
    fetchPolicy: 'cache-first',
  });

  const getHighPriorityType = async () => {
    const { data } = await fetchPriorities();

    const highPriorityType = data.messagePriorities?.find(
      ({ priorityCode }: { priorityCode: string }) => priorityCode === 'HIGH',
    );

    return highPriorityType;
  };

  return { messagePriorities, getHighPriorityType };
};

export default useFetchMessagePriorities;
