import { ReactNode, useRef } from 'react';

import { Flex, Paper, useMantineTheme } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { TextWithRef } from 'components/Mantine/TextWithRef';
import { isNotNil } from 'helpers/isNotNil';
import type { TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { DocumentImage } from './useDocumentImage';

export const SelectableTask = ({
  task,
  width = 400,
  rightComponent,
}: {
  task: TaskType;
  width?: number;
  rightComponent?: ReactNode;
}) => {
  const theme = useMantineTheme();
  const rightDivRef = useRef<HTMLDivElement>(null);
  const rightComponentWidth = rightDivRef.current?.clientWidth ?? 0;
  return (
    <Paper
      withBorder
      p={0}
      bg="violet.1"
      style={{ width, height: 48, overflow: 'hidden', borderColor: theme.colors.violet[6] }}
    >
      <Flex style={{ width }}>
        <Flex bg="white" align="center" justify="center" style={{ width: 48, height: 48, minWidth: 48 }}>
          <DocumentImage
            documentId={task.taskTypeImageId}
            alt={task.taskTypeName}
            fallback={<EvolveIcon icon="WorkCellTask" color="violet.8" />}
          />
        </Flex>
        <Flex
          pl="sm"
          direction="column"
          justify="space-evenly"
          style={{ width: width - 48 - rightComponentWidth, overflow: 'hidden', userSelect: 'none' }}
        >
          <Flex gap="xs" pr="xs" style={{ width: width - 48 - rightComponentWidth }}>
            {isNotNil(task.workCellTaskTypes) && task.workCellTaskTypes.length > 0 && (
              <AgGridStyleTooltip
                label={
                  <>
                    Mapped to <b>{task.workCellTaskTypes.map((t) => t.workCellName).join(', ')}</b>
                  </>
                }
                withArrow
                openDelay={0}
              >
                <EvolveIcon icon="Pin" color="violet.9" size="sm" />
              </AgGridStyleTooltip>
            )}
            <AgGridStyleTooltip label={task.taskTypeName} position="top-start">
              <TextWithRef fz="sm" c="violet.8">
                {task.taskTypeName}
              </TextWithRef>
            </AgGridStyleTooltip>
          </Flex>
          {/* Using !! so an empty string isn't rendered, either */}
          {!!task.taskTypeDescription && (
            <AgGridStyleTooltip label={task.taskTypeDescription} position="top-start">
              <TextWithRef fz="xs" c="violet.9">
                {task.taskTypeDescription}
              </TextWithRef>
            </AgGridStyleTooltip>
          )}
        </Flex>
        <Flex justify="flex-end" ref={rightDivRef} style={{ minWidth: 26 }}>
          {rightComponent}
        </Flex>
      </Flex>
    </Paper>
  );
};
