import { Navigate } from 'react-router-dom';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { FacilityAndProjectWrapper } from 'components';
import { ProjectRequired } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { PopoverFilterProvider } from 'components/Popover/FilterPopover/context/PopoverFilterContext';
import { WorkOrderUpdateProvider } from 'hooks-api/useWorkOrderUpdate';
import { DrawingRegister, DrawingRegisterProvider } from 'modules/Design/DrawingRegister';
import { UpdateWorkRequestStatusProvider } from 'modules/Field/WorkRequests/WorkRequest/hooks/useUpdateWorkRequestStatus/useUpdateWorkRequestStatus';
import { WorkRequestOrderDetailsPane } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetailsPane';
import { WorkRequestItemsTable } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/WorkRequestItemsTable';
import { WorkRequestSelectedWrapper } from 'modules/Shop/WorkOrders/WorkOrder/WorkOrderItemsPage/WorkRequestSelectedWrapper';

import { ActiveRowProvider } from './LocationsAndWorkPhases/contexts/ActiveRowContext';
import { DeleteModalProvider } from './LocationsAndWorkPhases/contexts/DeleteModalContext';
import { DragAndDropProvider } from './LocationsAndWorkPhases/contexts/DragAndDropContext';
import { LocationsAndWorkPhaseProvider } from './LocationsAndWorkPhases/contexts/LocationsAndWorkPhaseContext';
import { LocationTypeAndFormatProvider } from './LocationsAndWorkPhases/contexts/LocationTypeAndFormatContext';
import { ProjectProvider } from './LocationsAndWorkPhases/contexts/ProjectContext';
import { LocationsAndWorkPhaseContent } from './LocationsAndWorkPhases/LocationsAndWorkPhaseContent';
import WorkBreakdownStructure from './WorkBreakdownStructure/WorkBreakdownStructure';
import WorkPackages from './WorkPackages/WorkPackages';
import { WorkRequestsPage } from './WorkRequests/WorkRequestsList/WorkRequestsPage/WorkRequestsPage';

export const Dashboard = () => (
  <ComponentPaneLayout>
    <Navigate to="work-requests" />
  </ComponentPaneLayout>
);

export const FieldMembersInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <div>Field members dashboard</div>
  </ComponentPaneLayout>
);

export const WorkPackagesInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <WorkPackages />
  </ComponentPaneLayout>
);

export const WorkBreakDownStructureInterfaceNavContent = () => (
  <ComponentPaneLayout>
    <WorkBreakdownStructure />
  </ComponentPaneLayout>
);

export const LocationsAndWorkPhasesInterfaceNavContent = () => (
  <DeleteModalProvider>
    <LocationTypeAndFormatProvider>
      <ProjectProvider>
        <ActiveRowProvider>
          <LocationsAndWorkPhaseProvider>
            <DragAndDropProvider>
              <LocationsAndWorkPhaseContent />
            </DragAndDropProvider>
          </LocationsAndWorkPhaseProvider>
        </ActiveRowProvider>
      </ProjectProvider>
    </LocationTypeAndFormatProvider>
  </DeleteModalProvider>
);

export const DrawingRegisterInterfaceNavContent = () => (
  <FacilityAndProjectWrapper isFacilityRequired isProjectRequired>
    <WorkOrderUpdateProvider>
      <UpdateWorkRequestStatusProvider>
        <DrawingRegisterProvider>
          <PopoverFilterProvider>
            <DrawingRegister />
          </PopoverFilterProvider>
        </DrawingRegisterProvider>
      </UpdateWorkRequestStatusProvider>
    </WorkOrderUpdateProvider>
  </FacilityAndProjectWrapper>
);

export const WorkRequestsNavContent = () => (
  <ProjectRequired>
    {(selectedProject) => (
      <ComponentPaneLayout>
        <WorkRequestsPage selectedItem={selectedProject} />
      </ComponentPaneLayout>
    )}
  </ProjectRequired>
);

export const WorkRequestNavContent = () => (
  <ProjectRequired>
    {(selectedItem) => (
      <WorkRequestSelectedWrapper selectedItem={selectedItem}>
        <ComponentPaneLayout sizes={[1.4, 1]}>
          <WorkRequestItemsTable />
          <WorkRequestOrderDetailsPane />
        </ComponentPaneLayout>
      </WorkRequestSelectedWrapper>
    )}
  </ProjectRequired>
);
