import React, { useRef, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

const ProfilePhoto = ({
  src,
  width = 122,
  height = 122,
  sx = {},
  isEditable = false,
  setValue,
  isShowCaption = false,
  name = 'profilePicture',
}) => {
  const [pictureSrc, setPictureSrc] = useState(src);
  const pictureInputRef = useRef();

  const handleSelectFile = () => {
    pictureInputRef?.current.click();
  };

  const handleFileChange = (event) => {
    const [picture] = event.target.files;
    setValue(name, picture, { shouldDirty: true });
    setPictureSrc(URL.createObjectURL(picture));
  };

  return (
    <Stack direction="column" alignItems="center" spacing={1} sx={{ margin: '16px 0px' }}>
      <Stack sx={{ position: 'relative' }}>
        <Avatar alt="profile" src={pictureSrc} sx={{ width, height, ...sx }} />
        {isEditable && (
          <>
            <input
              ref={pictureInputRef}
              hidden
              id="picture"
              type="file"
              name={name}
              accept="image/jpeg,image/png"
              onChange={handleFileChange}
            />
            <IconButton
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              onClick={handleSelectFile}
            >
              <Custom.AddCircleOutline sx={{ color: 'white' }} />
            </IconButton>
          </>
        )}
      </Stack>

      {isShowCaption && (
        <Typography
          variant="caption"
          display="block"
          color="#00000080"
          sx={{ lineHeight: '24px', letterSpacing: '0.4px' }}
        >
          Select the image you want to upload. Images should not be more than 8MB in size. Only .jpg, .jpeg, .png files
          are accepted.
        </Typography>
      )}
    </Stack>
  );
};

export default ProfilePhoto;
