import { useMemo } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { useUser } from 'app/UserContext';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { ProductPool } from 'types/types-api';

import { getLicensesColDef } from './columnDefs';

export const LicensesPage = () => {
  const { user } = useUser();
  const { fetchPage } = useWrappedPaginatedGet<ProductPool>('admin/productpool', {
    lazy: true,
    defaultConfig: {
      params: {
        companyId: user?.companyId,
      },
    },
  });
  const colDef = useMemo(() => getLicensesColDef(), []);
  const { agGridProps } = useServerSideGrid({
    colDef,
    fetchPage,
    rowId: 'productPoolId',
    tableName: 'licenses',
  });

  const defaultColDef = useMemo(
    () => ({
      ...agGridProps.defaultColDef,
      sortable: false,
    }),
    [agGridProps.defaultColDef],
  );

  return (
    <>
      <BasePageHeader title="Licenses" />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<ProductPool> {...agGridProps} defaultColDef={defaultColDef} />
      </div>
    </>
  );
};
