import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AccordionTabs from './components/AccordionTabs';
import AudioRecording from './components/AudioRecording';
import Header from './components/Header';
import { ROUNDED_BORDER_STYLE } from './constants/constants';
import GraphicSection from './GraphicSection/GraphicSection';
import { TaskViewerProvider } from './TaskViewerContext';

const TaskViewer = () => {
  const [viewerRenderComplete, setViewerRenderComplete] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    setViewerRenderComplete(false);
  }, [setViewerRenderComplete]);

  return (
    <TaskViewerProvider>
      <Stack>
        <Header viewerRenderComplete={viewerRenderComplete} currentPage={currentPage} />
        <InfoSections setViewerRenderComplete={setViewerRenderComplete} setCurrentPage={setCurrentPage} />
      </Stack>
    </TaskViewerProvider>
  );
};
export default TaskViewer;

const InfoSections = ({ setViewerRenderComplete, setCurrentPage }) => (
  <Stack direction="row" gap="8px" sx={{ height: 'calc(100vh - 154px)', mt: 2 }}>
    <Stack sx={{ flex: 3, ...ROUNDED_BORDER_STYLE }}>
      <AudioRecording />
      <AccordionTabs />
    </Stack>
    <Box sx={{ flex: 7, ...ROUNDED_BORDER_STYLE }}>
      <GraphicSection setViewerRenderComplete={setViewerRenderComplete} setCurrentPage={setCurrentPage} />
    </Box>
  </Stack>
);
