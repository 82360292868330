import { ApolloClient, gql } from '@apollo/client';

import {
  addBillOfMaterialLineItemByLBS as ADD_ITEM_LBS,
  moveTakeoffPadItems as MOVE_ITEMS,
  addWorkRequestItemFromLineItem as ADD_WORK_REQUEST_ITEM_FROM_LINE_ITEM,
  addBillOfMaterialLineItemByLBSBulk as ADD_ITEM_LBS_BULK,
  quantitiesCreate as QUANTITIES_CREATE,
} from 'graphql/mutations';
import { billOfMaterialItem as BILL_OF_MATERIAL_ITEM, takeoffPadItems as TAKE_OFF_PAD_ITEMS } from 'graphql/queries';

import {
  BulkBodyType,
  CreateItemsResourcesType,
  CreateLocationResourcesType,
  GetLocationPromisesType,
  GetWorkPhasesPromisesType,
  HandleCloseModalType,
  HandlePreviousClickType,
  HandleSubtitleType,
} from '../@types/ItemsIntegrationModalHelpers';
import { getQtyFieldName } from '../components/QuantityStep/components/ItemsIntegrationTable/components/QtyField/helpers/qtyFieldHelpers';
import { QuantityFieldType } from '../components/QuantityStep/components/ItemsIntegrationTable/components/QuantityField/hooks/@types/fieldHookTypes';
import { UNASSIGNED } from '../components/QuantityStep/components/ItemsIntegrationTable/constants';

export const getWorkPhasesPromises = ({
  workPhasesChildren,
  getBOMTableValues,
  id,
  itemIntegratedId,
  client,
}: GetWorkPhasesPromisesType) =>
  workPhasesChildren.map(async ({ id: workPhaseId, locationId }: any) => {
    const childAmount = getBOMTableValues(`qtyToAdd__${locationId}__${id}__${workPhaseId}`);
    return client.mutate({
      refetchQueries: ['BillOfMaterialLineItemByLBS'],
      mutation: gql(ADD_ITEM_LBS),
      variables: {
        body: {
          ern: `ern:shop:workrequestitem:${itemIntegratedId}`,
          workPhaseId,
          amount: childAmount ? parseInt(childAmount, 10) : 0,
        },
      },
    });
  });

export const getLocationPromises = ({
  lineItemChildren,
  getBOMTableValues,
  id,
  itemIntegratedId,
  client,
  integratedItemsERN,
}: GetLocationPromisesType) =>
  lineItemChildren.map(async ({ id: locationId }: any) => {
    const childAmount = getBOMTableValues(`qtyToAdd__${locationId}__${id}`);
    return client.mutate({
      refetchQueries: ['BillOfMaterialLineItemByLBS'],
      mutation: gql(ADD_ITEM_LBS),
      variables: {
        body: {
          ern: `${integratedItemsERN}:${itemIntegratedId}`,
          locationId,
          amount: childAmount ? parseInt(childAmount, 10) : 0,
        },
      },
    });
  });

export const createLocationResources = async ({ client, bulkBody }: CreateLocationResourcesType) => {
  await client.mutate({
    update(cache: any) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          billOfMaterialItem(_: any, { DELETE }: any) {
            return DELETE;
          },
        },
      });
      cache.gc();
    },
    mutation: gql(ADD_ITEM_LBS_BULK),
    variables: {
      body: {
        bulk: bulkBody,
      },
    },
    awaitRefetchQueries: true,
  });
};

export const addBOMItems = async ({ client, BOMid, takeoffPadId, lineItems, projectId, lineItemsFields }: any) => {
  const {
    data: { moveTakeoffPadItems },
  } = await client.mutate({
    mutation: gql(MOVE_ITEMS),
    variables: {
      body: {
        TakeoffPadId: takeoffPadId,
        BillOfMaterialsId: BOMid,
        SendSpecificQuanties: true,
        LineItems: lineItems.map(({ id }: any) => ({
          LineItemId: id,
          Quantity: lineItemsFields?.find((lineItemField: any) => lineItemField[0].includes(id))[1],
        })),
      },
    },
    update(cache: any) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          takeoffPadItems(_: any, { DELETE }: any) {
            return DELETE;
          },
          billOfMaterialItem(_: any, { DELETE }: any) {
            return DELETE;
          },
        },
      });
      cache.gc();
    },
    refetchQueries: [
      {
        query: gql(BILL_OF_MATERIAL_ITEM),
        variables: { params: { projectId } },
      },
      {
        query: gql(TAKE_OFF_PAD_ITEMS),
        variables: { params: { projectId } },
      },
    ],
  });
  return moveTakeoffPadItems.map(({ lineItemId, previousLineItemId }: any) => ({
    id: previousLineItemId,
    itemIntegratedId: lineItemId,
  }));
};

export const addWorkRequestItemsFromLineItem = async ({
  client,
  currentWorkRequestId,
  lineItems,
  lineItemsFields,
}: {
  client: ApolloClient<any>;
  currentWorkRequestId: string;
  lineItems: QuantityFieldType[];
  lineItemsFields: any[];
}) => {
  const {
    data: { addWorkRequestItemFromLineItem },
  } = await client.mutate({
    mutation: gql(ADD_WORK_REQUEST_ITEM_FROM_LINE_ITEM),
    variables: {
      body: {
        workRequestId: currentWorkRequestId,
        lineItemIds: lineItems.map(({ id, itemName, unitOfMeasureId, partId }) => ({
          lineItemId: id,
          lineItemName: itemName,
          unitOfMeasureId,
          quantity: lineItemsFields?.find((lineItemField: any) => lineItemField[0].includes(id))[1],
          partId: partId ?? undefined,
        })),
      },
    },
    update(cache: any) {
      cache.modify({
        id: 'ROOT_QUERY',
        fields: {
          workRequestItems(_: any, { DELETE }: any) {
            return DELETE;
          },
        },
      });
      cache.gc();
    },
  });
  return addWorkRequestItemFromLineItem;
};

export const createItemsResources = async ({
  itemsIntegrated,
  client,
  integratedItemsERN,
  getBOMTableValues,
  companyId,
  projectId,
  baseERN = '',
}: CreateItemsResourcesType) => {
  const itemsPromises = itemsIntegrated.map(async ({ id, itemIntegratedId }) => {
    const amount = getBOMTableValues(`qtyToAdd__bomItem__${id}`);
    return client.mutate({
      mutation: gql(QUANTITIES_CREATE),
      variables: {
        body: {
          ern: `${integratedItemsERN}:${itemIntegratedId}`,
          parentResourceERN: `${baseERN}:${id}`,
          amount,
          properties: {
            companyId,
            projectId,
          },
        },
      },
    });
  });
  await Promise.all(itemsPromises);
};

const getChildrenLocations = ({ rows, lineItemId }: any) =>
  rows
    .find(({ id: rowId }: any) => rowId === lineItemId)
    ?.children.map(({ locationId }: any) => ({
      locationId,
      autoPopulate: true,
    }))
    .filter((child: any) => child.locationId);

const getChildrenWorkPhases = ({ rows, lineItemId }: any) =>
  rows
    .find(({ id: rowId }: any) => rowId === lineItemId)
    ?.workPhases.map(({ workPhaseId }: any) => ({
      workPhaseId,
      autoPopulate: true,
    }))
    .filter((child: any) => child.workPhaseId);
type ItemFieldType = [string, string | number];
interface IGetUnassignedValues {
  isAddAllRemainingQuantities: boolean;
  itemFields: ItemFieldType[];
  quantityFields: QuantityFieldType[];
  getValues: (fieldId: string) => any;
}

export const getItemUnassignedValue = ({
  quantityField,
  childrenTotalAmount,
}: {
  quantityField: QuantityFieldType;
  childrenTotalAmount: number;
}): number => (quantityField.qtyAggregate as number) - childrenTotalAmount;

export const getItemChildrenTotalAmount = (quantityField: QuantityFieldType): number =>
  quantityField?.children?.reduce((acc: number, child: any) => acc + child.amount.available.aggregate, 0) || 0;

const getUnassignedValues = ({
  isAddAllRemainingQuantities = false,
  itemFields = [],
  quantityFields = [],
  getValues = () => {},
}: IGetUnassignedValues) => {
  const getLineItemFieldName = (id: string) => ['qtyToAdd', 'bomItem', id].join('__');
  const getUnassignedValue = (itemId: string) => {
    const itemQuantityField = quantityFields.find((quantityField) => quantityField.id === itemId);
    if (!itemQuantityField?.children?.length) return getValues(getLineItemFieldName(itemQuantityField?.id as string));
    return itemQuantityField?.unassignedAmount || 0;
  };
  if (isAddAllRemainingQuantities) {
    return itemFields.map(([fieldId]) => {
      const itemId = fieldId.split('__')[2];
      const unassignedValue = getUnassignedValue(itemId);
      return [fieldId, unassignedValue];
    });
  }
  return quantityFields
    .filter(({ nestLevel }) => nestLevel === 0)
    .map((quantityField) => {
      const unassingedQtyFieldId = [UNASSIGNED, quantityField.id].join('__');
      const itemQuantityField = quantityFields.find(({ id }) => id === unassingedQtyFieldId);
      const fieldId = getLineItemFieldName(quantityField.id as string);
      if (itemQuantityField) {
        return [fieldId, getValues(`qtyToAdd__${unassingedQtyFieldId}`) || 0];
      }
      const unassignedValue = getUnassignedValue(quantityField.id as string);
      return [fieldId, unassignedValue];
    });
};

const filterLocationItems = (quantityFields: QuantityFieldType[], autoFilledFields: string[]) =>
  quantityFields.reduce((filteredLocationItems: QuantityFieldType[], currentLocation) => {
    const isTopLevelItem = currentLocation.nestLevel === 0 || currentLocation.isUnassignedRow;
    if (isTopLevelItem) return filteredLocationItems;
    const fieldName = getQtyFieldName({
      locationId: (currentLocation.isWorkPhase ? currentLocation.locationId : currentLocation.id) as string,
      lineItemId: currentLocation.lineItemId as string,
      workPhaseId: currentLocation.isWorkPhase ? currentLocation.id : undefined,
    });
    const isLBSItemAutoPopulated = autoFilledFields.includes(fieldName) && currentLocation.nestLevel !== 1;
    if (isLBSItemAutoPopulated) return filteredLocationItems;
    return [...filteredLocationItems, currentLocation];
  }, []);

const getLocationItemValues = (quantityFields: QuantityFieldType[], getBOMTableValues: (fieldName: string) => string) =>
  quantityFields.map((quantityField) => {
    const qtyFieldValue = parseInt(
      getBOMTableValues(
        getQtyFieldName({
          locationId: (quantityField.isWorkPhase ? quantityField.locationId : quantityField.id) as string,
          lineItemId: quantityField.lineItemId as string,
          workPhaseId: quantityField.isWorkPhase ? quantityField.id : undefined,
        }),
      ) || '0',
      10,
    );
    if (quantityField.qtyRemaining === quantityField.qtyAggregate || qtyFieldValue !== quantityField.qtyAggregate)
      return { ...quantityField, amount: qtyFieldValue };
    return { ...quantityField, autoPopulate: true };
  });

type ItemIntegratedType = {
  id: string;
  itemIntegratedId: string;
};
interface IGetBulkBody {
  itemsIntegrated: ItemIntegratedType[];
  isAddAllRemainingQuantities: boolean;
  integratedItemsERN: string;
  rows: QuantityFieldType[];
  locationItems: QuantityFieldType[];
  getBOMTableValues: (fieldName: string) => string;
}

const getBulkBody = ({
  itemsIntegrated,
  isAddAllRemainingQuantities,
  integratedItemsERN,
  rows,
  locationItems,
  getBOMTableValues,
}: IGetBulkBody) =>
  itemsIntegrated
    .map(({ id, itemIntegratedId }) => {
      const isLineItemAutofill =
        parseInt(getBOMTableValues(`qtyToAdd__bomItem__${id}`), 10) === rows.find((row) => row.id === id)?.qtyAggregate;
      if (isAddAllRemainingQuantities || isLineItemAutofill)
        return {
          ern: `${integratedItemsERN}:${itemIntegratedId}`,
          amounts: [
            ...getChildrenLocations({ rows, lineItemId: id }),
            ...getChildrenWorkPhases({ rows, lineItemId: id }),
          ],
        };
      const lineItemChildren = locationItems.filter((quantityField) => quantityField.lineItemId === id);
      return {
        ern: `${integratedItemsERN}:${itemIntegratedId}`,
        amounts: lineItemChildren
          .map(({ id: childId, autoPopulate, isWorkPhase, locationId, amount }) => ({
            locationId: isWorkPhase ? locationId : childId,
            workPhaseId: isWorkPhase ? childId : undefined,
            amount,
            autoPopulate,
          }))
          .filter((child) => child.amount || child.autoPopulate),
      };
    })
    .filter((bulkItem) => bulkItem?.amounts?.length) as [BulkBodyType];

export const handleSubmitLBS = async ({
  setIsLoading,
  quantityFields,
  getBOMTableValues,
  addItems,
  client,
  integratedItemsERN,
  onItemsCreated,
  onError,
  getIntegrationId, // * workRequestId | takeOffPadId | etc
  rows,
  isDirty: isNewWR,
  projectId,
  isAddAllRemainingQuantities,
  autoFilledFields = [],
  refetchQueries = () => {},
}: any) => {
  try {
    setIsLoading(true);
    const { integrationId, currentWorkRequestIdentifier } = await getIntegrationId();

    const itemFields = (Object.entries(getBOMTableValues()) as ItemFieldType[]).filter((entry) =>
      entry[0].includes('__bomItem__'),
    );

    const itemFieldsWithUnassignedValues = getUnassignedValues({
      isAddAllRemainingQuantities,
      itemFields,
      quantityFields,
      getValues: getBOMTableValues,
    });

    const itemsIntegrated = await addItems({ integrationId, itemFields: itemFieldsWithUnassignedValues, rows });
    const filteredLocationItems = filterLocationItems(quantityFields, autoFilledFields);
    const locationItems = getLocationItemValues(filteredLocationItems, getBOMTableValues);

    if (itemsIntegrated?.length) {
      const bulkBody = getBulkBody({
        itemsIntegrated,
        isAddAllRemainingQuantities,
        integratedItemsERN,
        rows,
        locationItems,
        getBOMTableValues,
      });
      if (bulkBody.some((lineItem) => lineItem?.amounts?.length)) await createLocationResources({ client, bulkBody });
    }
    await refetchQueries(projectId);
    onItemsCreated({ integrationIdResp: integrationId, currentWorkRequestIdentifier, isNewWR });
  } catch (error) {
    onError();
  }
};

export const handleSubtitle = ({ addNewWRForm, isDirty, selectionModel, rows, isBOMtoWR }: HandleSubtitleType) => {
  if (!isBOMtoWR) return '';
  if (addNewWRForm || isDirty) return ' | New work request';
  if (!selectionModel.length) return '';
  return ` | ${rows.find((row: any) => row.workRequestId === selectionModel[0])?.workRequestIdentifier}`;
};

export const handlePreviousClick = ({ setActiveStep, setAddNewWRForm, isDirty }: HandlePreviousClickType) => {
  setActiveStep((current: number) => current - 1);
  if (isDirty) setAddNewWRForm(true);
};

export const handleCloseModal = ({ reset, setIsLoading, handleClose }: HandleCloseModalType) => {
  reset();
  setIsLoading(false);

  handleClose();
};

export const getModalSubtitle = ({ totalSelectedItems, handleSubtitleCallback }: any) =>
  `${totalSelectedItems} item${totalSelectedItems > 1 ? 's' : ''} with remaining quantity${handleSubtitleCallback()}`;
